import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBothSideTxByWallet, getBothSideTxCountByWallet } from '../../commonComponent/transaction/api';
import TransactionsTable from '../../commonComponent/Transactions.Table';
import TxDetailsDialog from '../../commonComponent/TxDetails.Dialog';

export default function AllSales(props) {
  const { walletId } = useParams()

  const [openDetails, setOpenDetails] = useState(false)
  const [selectedTx, setSelectedTx] = useState(null)

  return (<>
    <TransactionsTable
      walletId={walletId}
      getDataApiHitFun={getBothSideTxByWallet}
      getDataObj={{
        walletId: walletId,
        type: "Invoice"
      }}
      getNumberOfDataRowApiHit={getBothSideTxCountByWallet}
      getDataNumberObj={{
        walletId: walletId,
        type: "Invoice"
      }}
      setSelectedTx={setSelectedTx}
      setOpenDetails={setOpenDetails}
      emptyText={"No invoice available"}
    />

    {selectedTx && (
      <TxDetailsDialog
        walletId={walletId}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        selectedTx={selectedTx}
        setSelectedTx={setSelectedTx}
        editClickUrl={`/finance/${walletId}/invoice/edit/`}
        viewClickUrl={`/finance/${walletId}/invoice/view/`}
      />
    )}
  </>);
}