import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import ButtonBase from '@material-ui/core/ButtonBase';
import Typography from '@material-ui/core/Typography';
import cx from 'clsx';
import { Divider } from '@material-ui/core';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import RedoIcon from '@material-ui/icons/Redo';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import DeleteIcon from '@material-ui/icons/Delete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { MdVerified } from "react-icons/md";
import MyPopOver from '../../styled/CommonComponents/MyPopOver';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const useStyles = makeStyles((theme) => ({
  root: {

  },

  dwollaBankItem: {
    width: "32%",
    border: "1px solid #989898",
    padding: "10px",
    marginRight: "10px",
    marginBottom: "10px"
  },

  dwollaBottomOption: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  dwollaOptIconCont: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },

  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  paperStyle: {
    maxWidth: '18rem',
    margin: '1rem 0.5rem',
    padding: '1rem 0.5rem',
  },


  titleText: {
    fontSize: '1.3rem',
    fontWeight: '600',
    color: '#424242',
  },

  accountName: {
    fontSize: '1rem',
    fontWeight: '500',
    color: '#424242',
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
  },

  accountSubtype: {
    fontSize: '0.9rem',
    fontWeight: '400',
    color: '#424242',
  },
  smPaperStyle: {
    padding: '0.5rem',
    margin: '0.5rem',
    width: '100%',
  },

  smPaperSelected: {
    padding: '0.5rem',
    margin: '0.5rem',
    width: '100%',
    borderWidth: '0.2rem',
    borderColor: theme.palette.primary.main,
  },
  bankNameCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  banktextSty: {
    "& h3": {
      fontSize: "16px",
      fontWeight: "510"
    },
    "& p": {
      fontSize: "11px",
      fontWeight: "450",
      color: "gray"
    }
  },
  otherDetailsCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "14px",
      color: "gray",
      fontWeight: "400",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
      fontWeight: "450",
    }
  },
  verifiedSty: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& p": {
      marginRight: "5px",
      fontSize: "12px"
    }
  },
  moreBtn: {
    marginTop: "-10px",
    marginRight: "-10px"
  },
  singleOption: {
    padding: "3px 8px"
  }
}));

export default function DwollaBankCardItem(props) {
  const {
    item, onSelect, selected, removeBankAcc,
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {
    row, col, root, dwollaBottomOption, dwollaBankItem, dwollaOptIconCont,
    paperStyle, smPaperStyle, smPaperSelected,
    titleText, accountSubtype, accountName,
  } = classes;

  const bankName = item?.bankName;
  const bankAccountType = item?.bankAccountType;

  return (

    <Paper className={dwollaBankItem} >

      <div className={classes.bankNameCont} >
        <div className={classes.banktextSty} >
          <h3>{bankName}</h3>
          <p>{item?.name}</p>
        </div>
        <div className={classes.verifiedSty} >
          {selected ? (<>
            <p>Primary Account</p>
            <MdVerified />
          </>) : (<>
            <MyPopOver
              closeOnClick={true}
              appearContent={<IconButton className={classes.moreBtn} ><MoreVertIcon /></IconButton>}
              showContent={<div>
                <div
                  className={classes.singleOption}
                  onClick={() => {
                    if (onSelect) {
                      onSelect(item);
                    }
                  }}
                >Make Default</div>
                <div
                  className={classes.singleOption}
                  onClick={() => {
                    removeBankAcc(item, selected)
                  }}
                >Delete</div>
              </div>}
            />
          </>)}
        </div>
      </div>

      <div className={classes.otherDetailsCont} style={{ margin: "10px 0px 4px" }} >
        <h3>Account Type</h3>
        <p>{bankAccountType}</p>
      </div>
      <div className={classes.otherDetailsCont} >
        <h3>Dwolla Balance</h3>
        <p>$ 0</p>
      </div>
    </Paper>
  );
}
