import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import nonLogin from "../styled/nonLogin";
import GuestLayoutHeader from "../Navbar/GuestLayout.Header";
import GuestLayout from "../layout/GuestLayout";
import PostSideBar from "../post/PostSideBar";
import SuggestedCommunitySidebar from "../community/SuggestedCommunitiesSidebar";
import PostFile from "../post/PostFile";
import { getSuggestedCommunities } from "../community/api.call";

const useStyles = makeStyles((theme) => ({
  ...nonLogin,
  container: {
    display: 'flex',
    flexDirection: "column",
    padding: "1rem",
    paddingBottom: "60px",
    gap: "1rem",
    maxWidth: "1200px",
    margin: "0 auto",
    [theme.breakpoints.up('md')]: {
      flexDirection: "row",
    },
  },
  overLay: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0)"
  }
}));

export default function PublicPostView() {
  const classes = useStyles();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [suggestedCommunites, setSuggestedCommunities] = useState(null)
  const [suggestionLoading, setSuggestionLoading] = useState(false)

  const getAllSuggestedCommunities = async () => {
    setSuggestionLoading(true)
    await getSuggestedCommunities({
      profile: user?.profile,
      limit: 10,
      page: 0,
    })
      .then((data) => {
        setSuggestedCommunities(data || [])
        setSuggestionLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    getAllSuggestedCommunities()
  }, [user?.profile])

  return (
    <GuestLayout
      HeaderProp={GuestLayoutHeader}
      headerHeight={"50px"}
    >
      <div className={classes.container}>
        <div style={{ flex: 1 }}>
          <PostFile />
        </div>
        <div style={{ position: "relative" }} >
          <PostSideBar />
          <div style={{ width: "100%", height: "15px" }} ></div>
          <SuggestedCommunitySidebar
            suggestedCommunites={suggestedCommunites}
            suggestionLoading={suggestionLoading}
          />
          <div className={classes.overLay} ></div>
        </div>
      </div>
    </GuestLayout>
  )
}