import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import AccountingRoute from "../components/PrivateRoute/AccountingRoute";
import BankTransactions from "../components/finance2o/bankTransactions/Bank.Transactions";
import BankAccounts from "../components/finance2o/accounts/BankAccounts/Bank.Accounts";
import PaymentMethods from "../components/finance2o/accounts/paymentMethods/Payment.Methods";
import Reports from "../components/finance2o/financialStatements/reports/Reports";
import ChartAccounts from "../components/finance2o/financialStatements/chartOfAccounts/Chart.Accounts";
import JournalEntry from "../components/finance2o/financialStatements/journalEntry/Journal.Entry";
import Customers from "../components/finance2o/networks/customers/Customers";
import Employees from "../components/finance2o/networks/employees/Employees";
import Contractors from "../components/finance2o/networks/contractors/Contractors";
import Vendors from "../components/finance2o/networks/vendors/Vendors";
import Investors from "../components/finance2o/networks/investors/Investors";
import Lenders from "../components/finance2o/networks/lenders/Lenders";
import ProductsServices from "../components/finance2o/productsAndServices/Products.Services";
import AllSales from "../components/finance2o/salesAndInvoices/allSales/All.Sales";
import InvoiceGenerators from "../components/finance2o/salesAndInvoices/generator/Invoice.Generators";
import Invoices from "../components/finance2o/salesAndInvoices/invoices/Invoices";
import AllExpenses from "../components/finance2o/expenseAndBills/allExpenses/All.Expenses";
import Bills from "../components/finance2o/expenseAndBills/bills/Bills";
import BillGenerators from "../components/finance2o/expenseAndBills/generator/Bill.Generators";
import CreateJournalEntry from "../components/finance2o/financialStatements/journalEntry/CreateJournalEntry";
import TxEdit from "../components/finance2o/commonComponent/transaction/transaction.edit";
import TxTemplateEdit from "../components/finance2o/commonComponent/generator/template.edit";
import ShowFullTxs from "../components/finance2o/networks/ShowFullTxs";
import RelationAnalysis from "../components/finance2o/networks/Relation.Analysis";
import Settings from "../components/finance2o/settings/Settings";
import StripeConnectHandle from "../components/finance2o/accounts/paymentMethods/stripe/stripe.connect.handle";
import HandlePlaidAuth from "../components/finance2o/accounts/BankAccounts/plaid/handle.auth";

const AccountingRoutes = () => {
    return [
        //1. unknown
        <AccountingRoute
            exact
            path="/finance/:walletId"
            Component={BankAccounts}
            header="Bank Accounts"
            curOption="account"
            curSubOption="bankAccounts"
        />,


        //1. account
        <AccountingRoute
            exact
            path="/finance/:walletId/bank-accounts"
            Component={BankAccounts}
            header="Accounts"
            curOption="account"
            curSubOption="bankAccounts"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/payment-methods"
            Component={PaymentMethods}
            header="Payment Methods"
            curOption="account"
            curSubOption="paymentMethods"
        />,




        //1. bank transactions
        <AccountingRoute
            exact
            path="/finance/:walletId/bank-transactions"
            Component={BankTransactions}
            header="Bank Transactions"
            curOption="bankTransactions"
        />,




        //1. financial statements
        <AccountingRoute
            exact
            path="/finance/:walletId/accounting-reports"
            Component={Reports}
            header="Accounting Reports"
            curOption="financialStatements"
            curSubOption="reports"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/chart-accounts"
            Component={ChartAccounts}
            header="Chart Accounts"
            curOption="financialStatements"
            curSubOption="chartAccounts"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/journal-entry"
            Component={JournalEntry}
            header="Journal Entry"
            curOption="financialStatements"
            curSubOption="journalEntry"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/journal-entry/:journalId/edit"
            Component={CreateJournalEntry}
            header="Edit Journal Entry"
            curOption="financialStatements"
            curSubOption="journalEntry"
        />,


        //1.networks
        <AccountingRoute
            exact
            path="/finance/:walletId/network-customers"
            Component={Customers}
            header="Networks"
            curOption="networks"
            curSubOption="customer"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/network-employees"
            Component={Employees}
            header="Networks"
            curOption="networks"
            curSubOption="employees"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/network-contractors"
            Component={Contractors}
            header="Networks"
            curOption="networks"
            curSubOption="contractors"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/network-vendors"
            Component={Vendors}
            header="Networks"
            curOption="networks"
            curSubOption="vendors"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/network-investors"
            Component={Investors}
            header="Networks"
            curOption="networks"
            curSubOption="investors"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/network-lenders"
            Component={Lenders}
            header="Networks"
            curOption="networks"
            curSubOption="lenders"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/related-tx/:secondPartyWallet/:option"
            Component={ShowFullTxs}
            header="Mutual Transactions"
            curOption="networks"
        />,

        <AccountingRoute
            exact
            path="/admin/:walletId/relation-analysis/:secondPartyWallet/:option"
            Component={RelationAnalysis}
            header="Relation Analysis"
            curOption="networks"
        />,





        //1.products & services
        <AccountingRoute
            exact
            path="/finance/:walletId/products-services"
            Component={ProductsServices}
            header="Products & Services"
            curOption="productsServices"
        />,


        //1.sales and invoices
        <AccountingRoute
            exact
            path="/finance/:walletId/all-Sales"
            Component={AllSales}
            header="All Sales"
            curOption="invoicesSales"
            curSubOption="allSales"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/invoices"
            Component={Invoices}
            header="Invoices"
            curOption="invoicesSales"
            curSubOption="invoices"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/invoice/edit/:txId"
            Component={TxEdit}
            header="Edit Invoice"
            curOption="invoicesSales"
            curSubOption="invoices"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/invoice/view/:txId"
            Component={TxEdit}
            header="Invoice"
            curOption="invoicesSales"
            curSubOption="allSales"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/invoice-generators"
            Component={InvoiceGenerators}
            header="Invoice Generators"
            curOption="invoicesSales"
            curSubOption="invoiceGenerators"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/invoice-tmplate/edit/:txtemplateId"
            Component={TxTemplateEdit}
            header="Generator Edit"
            curOption="invoicesSales"
            curSubOption="invoiceGenerators"
        />,



        //1.bills & expenses
        <AccountingRoute
            exact
            path="/finance/:walletId/all-expenses"
            Component={AllExpenses}
            header="All Expenses"
            curOption="billExpenses"
            curSubOption="allExpenses"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/bills"
            Component={Bills}
            header="Bills"
            curOption="billExpenses"
            curSubOption="bills"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/bill/edit/:txId"
            Component={TxEdit}
            header="Edit Bill"
            curOption="billExpenses"
            curSubOption="bills"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/bill/view/:txId"
            Component={TxEdit}
            header="Bill"
            curOption="billExpenses"
            curSubOption="allExpenses"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/bill-generators"
            Component={BillGenerators}
            header="Bill Generators"
            curOption="billExpenses"
            curSubOption="billGenerators"
        />,

        <AccountingRoute
            exact
            path="/finance/:walletId/bill-tmplate/edit/:txtemplateId"
            Component={TxTemplateEdit}
            header="Generator Edit"
            curOption="billExpenses"
            curSubOption="billGenerators"
        />,

        //settings
        <AccountingRoute
            exact
            path="/finance/:walletId/settings"
            Component={Settings}
            header="Settings"
            curOption="settings"
        />,



        <Route exact path="/stripe/connect/:status">
            <StripeConnectHandle />
        </Route>,

        <Route exact path="/plaid/oauth">
            <HandlePlaidAuth />
        </Route>,
    ];
};

export default AccountingRoutes;