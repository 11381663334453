import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import WorkIcon from "@material-ui/icons/Work";
import profileExperience from "../../../Assets/profileExperience.svg"
import { FaAward } from "react-icons/fa";
import { SlPencil } from "react-icons/sl";
import { useMediaQuery } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import SeeMoreSeeLess from "../../styled/CommonComponents/SeeMore.SeeLess";
import moment from "moment";


const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.05rem",
    marginTop: "1rem",
    border: "1px solid white",
    borderRadius: "3px",
    padding: "5px 15px 15px",
    "& $h2": {
      fontWeight: "400",
      fontSize: "1.5rem",
      display: "inline",
    },
    "& p": {
      color: "grey",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        width: "100%",
      },
    }
  },
  section__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px"
    }
  },
  allExpEdit: {
    padding: "0px 30px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px"
  },
  imageCont: {
    marginRight: "15px"
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "20px",
      fontWeight: "500"
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    "& p": {
      marginTop: "5px",
      fontSize: "14px",
      fontWeight: "350"
    },
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        flexDirection: "column",
        alignItems: "flex-start"
      },
      "& h4": {
        flexDirection: "column",
        alignItems: "flex-start"
      },
      "& p": {
        marginTop: "3px",
        fontSize: "12px",
      },
    }
  }
}));

const colorArr = [
  "#F39014",
  "#0A66C2",
  "#36e055"
]

const ExperienceSection = ({
  isOwnProfile,
  profile,
  setDialog,
  setShowExperience,
  setOpenEditView,
  setProfileEditView
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const { user } = useSelector((state) => state.auth);

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const duration = (startDate, isCurrentlyWorking, endDate) => {
    const calendarMonth = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let startMonth = startDate.split("-")[0]
    let startYear = startDate.split("-")[1]
    const d = new Date();
    let endYear = d.getFullYear();
    let endMonthNum = d.getMonth() + 1

    if (!isCurrentlyWorking && endDate && endDate.length > 3) {
      let endMonth = endDate.split("-")[0]
      endMonthNum = calendarMonth.indexOf(endMonth) + 1
      endYear = endDate.split("-")[1]
    }

    const startMonthNum = calendarMonth.indexOf(startMonth) + 1

    let sDateStr = `1.${startMonthNum}.${startYear}`
    let eDateStr = `1.${endMonthNum}.${endYear}`

    var startDate = moment(sDateStr, "DD.MM.YYYY");
    var endDate = moment(eDateStr, "DD.MM.YYYY");

    var result = endDate.diff(startDate, 'months')

    if (result > 12) {
      return `${parseInt(result / 12)} yr, ${result % 12} mos`
    } else {
      return `${result} mos`
    }
  }

  return (
    <div>
      {isOwnProfile ? (
        <Paper className={classes.paper__section}>
          <div className={classes.section__header}>
            <h3><span><FaAward style={{ marginBottom: "-2px" }} /></span> Experience</h3>
            <div>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setShowExperience(true);
                  setDialog("experience");
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setProfileEditView("experience")
                  setOpenEditView(true)
                }}
              >
                <SlPencil />
              </IconButton>
            </div>
          </div>
          {user?.experience && user?.experience?.length !== 0 ? (<>
            <div className={classes.allExpEdit} >
              {user?.experience.map((expObj, index) => (
                <div
                  className={classes.singleExperienceCont}
                  key={index}
                  style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                >
                  <Avatar
                    variant="square"
                    className={classes.imageCont}
                    style={{ backgroundColor: colorArr[index % 3] }}
                  >
                    <WorkIcon />
                  </Avatar>
                  <div className={classes.experienceRight}>
                    <h3>{expObj.title}</h3>
                    <h4>
                      <div>{expObj.companyName}</div>
                      {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                      <div>{expObj?.employmentType ? expObj.employmentType : null}</div>
                    </h4>
                    <h5>
                      <div>{`${expObj?.start_date}  -  ${expObj?.isCurrentlyWorking ? "Present" : expObj?.end_date}`}</div>
                      {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                      <div>{duration(expObj?.start_date, expObj?.isCurrentlyWorking, expObj?.end_date)}</div>
                    </h5>
                    <h5>{expObj.location}</h5>
                    <p>
                      <SeeMoreSeeLess
                        text={expObj.description || ""}
                        limit={200}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>) : (<div className={classes.emptyCont} >
            <img src={profileExperience} />
          </div>)}
        </Paper>
      ) : (
        <>
          {profile?.parent?.experience && profile?.parent?.experience?.length !== 0 ? (
            <Paper className={classes.paper__section}>
              <div className={classes.section__header}>
                <h3><span><FaAward style={{ marginBottom: "-2px" }} /></span> Experience</h3>
                <div>
                </div>
              </div>
              {profile?.parent?.experience && profile?.parent?.experience?.length !== 0 ? (<>
                <div className={classes.allExpEdit} >
                  {profile?.parent?.experience.map((expObj, index) => (
                    <div
                      className={classes.singleExperienceCont}
                      key={index}
                      style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                    >
                      <Avatar
                        variant="square"
                        className={classes.imageCont}
                        style={{ backgroundColor: colorArr[index % 3] }}
                      >
                        <WorkIcon />
                      </Avatar>
                      <div className={classes.experienceRight}>
                        <h3>{expObj.title}</h3>
                        <h4>
                          <div>{expObj.companyName}</div>
                          {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                          <div>{expObj?.employmentType ? expObj.employmentType : null}</div>
                        </h4>
                        <h5>
                          <div>{`${expObj?.start_date}  -  ${expObj?.isCurrentlyWorking ? "Present" : expObj?.end_date}`}</div>
                          {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                          <div>{duration(expObj?.start_date, expObj?.isCurrentlyWorking, expObj?.end_date)}</div>
                        </h5>
                        <h5>{expObj.location}</h5>
                        <p>
                          <SeeMoreSeeLess
                            text={expObj.description || ""}
                            limit={200}
                          />
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </>) : (<div className={classes.emptyCont} >
                <img src={profileExperience} />
              </div>)}
            </Paper>
          ) : null}
        </>
      )}
    </div>
  );
};
export default ExperienceSection;