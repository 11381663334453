import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import configObject from '../../../../../config/index';
import NormalDialog from "../../../../styled/CommonComponents/NormalDialog"

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "450px",
    padding: "15px 0px",
    [theme.breakpoints.down('xs')]: {
      width: "250px",
    }
  },
  paperCont: {
    padding: "15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#edecec"
    },
    "& h3": {
      color: "#00345d",
      marginBottom: "5px",
      fontFamily: `'Poppins', sans-serif`,
    }
  },
  orCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "15px 0px",
    "& div": {
      width: "48%",
      height: "1px",
      backgroundColor: "#c5c4c4"
    },
    "& span": {
      margin: "0px 10px",
      color: "gray",
      fontSize: "13px"
    }
  },

}));

export default function SimpleDialog(props) {
  const { open, setOpen, walletId } = props;
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const attachDwollaAccount = async (type) => {
    if (walletId) {
      const token = localStorage.getItem('token');
      const data = {
        walletId,
        type,
        client: 'web',
        user: user._id,
        token,
        action: 'customer.create',
      };
      const dwollaWebapp = configObject.dwollaWebapp;
      const searchParams = new URLSearchParams(data);
      const url = dwollaWebapp + 'customer/?' + searchParams;
      if (url) {
        window.location.replace(url);
      }
    }
  };


  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Select Dwolla Account Type"}
      content={<div className={classes.mainCont} >
        <Paper
          elevation={3}
          className={classes.paperCont}
          onClick={() => {
            attachDwollaAccount('personal');
          }}
        >
          <h3>Personal Verified Account</h3>
          <p>This can also be used on behalf of a business ( not standard but it's quick and easy and functionally same  😉 ).</p>
        </Paper>

        <div className={classes.orCont} >
          <div></div>
          <span>OR</span>
          <div></div>
        </div>

        <Paper
          elevation={3}
          className={classes.paperCont}
          onClick={() => {
            attachDwollaAccount('business');
          }}
        >
          <h3>Business Verified Account</h3>
          <p>Creating a business verified account will require you to provide information about the business entity as well as a controller.</p>
        </Paper>
      </div>}
    />
  );
}