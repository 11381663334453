import React, { useState } from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useDebounce } from "react-use";

export default function DateResponse(props) {
  const { viewMode, questionId, formRes, updateApi } = props;
  const [value, setValue] = useState(formRes[0]?.date || "");

  useDebounce(
    () => {
      if (questionId) {
        let formResId = formRes[0]?._id;
        // console.log(formResId);
        const obj = {
          formResId,
          updateObj: {
            date: value,
          },
        };
        if (!viewMode) updateApi(obj);
      }
    },
    100,
    [value]
  );

  return (
    <div style={{ width: "100%" }}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          variant="inline"
          margin="normal"
          id="date-picker-dialog"
          label="Date picker"
          format="MM/dd/yyyy"
          value={value ? value : new Date()}
          onChange={(date) => {
            if (!viewMode) setValue(date);
          }}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          disabled={viewMode}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}
