import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
        borderRadius: "7px"
    },
}));

function TableSkeleton() {
    const classes = useStyles();
    return (
        <div className={classes.mainCont} >
            <Skeleton style={{ width: "100%", height: "80px" }} variant="text" />
            <Skeleton style={{ width: "100%", height: "60px" }} variant="text" />
            <Skeleton style={{ width: "100%", height: "60px" }} variant="text" />
            <Skeleton style={{ width: "100%", height: "60px" }} variant="text" />
        </div>
    );
}
export default TableSkeleton; 