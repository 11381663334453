import React, { useEffect, useState } from "react";
import Api from "../../helpers/Api";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core";
import TouchAppIcon from "@material-ui/icons/TouchApp";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
  },
  top_panel: {
    padding: "1rem",
    "& p": {
      color: "black",
      fontWeight: "400",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  bottom_panel: {
    padding: "1rem",
    "& p": {
      color: "black",
      fontWeight: "400",
    },
  },
}));

export default function CloneTemplateDialog({
  cloneTemplates,
  open,
  setOpen,
  setOpenChoose,
  profileId,
}) {
  const classes = useStyles();
  const { container, top_panel, bottom_panel } = classes;
  const [publicTemplates, setPublicTemplates] = useState([]);
  const [checked, setChecked] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getPublicTemplates = async () => {
    const res = await Api.post("issue/template/getPublicTemplates");
    if (res?.data) {
      setPublicTemplates(res?.data);
    }
  };

  useEffect(() => {
    getPublicTemplates();
  }, []);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title">Import Template(s)</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <div className={container}>
              <div className={top_panel}>
                <p>Import from your own</p>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<TouchAppIcon />}
                  onClick={() => {
                    setOpen(false);
                    setOpenChoose(true);
                  }}
                  style={{ textTransform: "none" }}
                >
                  Choose
                </Button>
              </div>
              <div className={bottom_panel}>
                <p>Import global Templates</p>
                <List>
                  {publicTemplates.map((template) => {
                    const labelId = `checkbox-list-label-${template?._id}`;
                    return (
                      <ListItem
                        key={template?._id}
                        role={undefined}
                        dense
                        button
                        onClick={handleToggle(template?._id)}
                      >
                        <ListItemIcon>
                          <Checkbox
                            edge="start"
                            color="primary"
                            checked={checked.indexOf(template?._id) !== -1}
                            tabIndex={-1}
                            disableRipple
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </ListItemIcon>
                        <ListItemText id={labelId} primary={template?.title} />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              cloneTemplates(checked);
              handleClose();
            }}
            color="primary"
          >
            Clone Templates
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
