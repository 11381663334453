import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { SlPencil } from "react-icons/sl";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import profileLanguage from "../../../Assets/profileLanguage.svg"
import { IoLanguageSharp } from "react-icons/io5";


const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.05rem",
    marginTop: "1rem",
    border: "1px solid white",
    borderRadius: "3px",
    padding: "5px 15px 15px",
  },
  section__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
  },
  languageCard: {
    padding: "10px 30px",
    "& p": {
      fontSize: "15px",
      color: "gray",
      marginTop: "3px"
    },
    "& h4": {
      fontSize: "17px",
      fontWeight: "510"
    }
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px"
    }
  }
}));


const LanguageSection = ({
  isOwnProfile,
  profile,
  setShowLang,
  setDialog,
  setOpenEditView,
  setProfileEditView
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);

  const classes = useStyles();


  return (
    <div>
      {isOwnProfile ? (
        <Paper className={classes.paper__section}>
          <div className={classes.section__header}>
            <h3><span><IoLanguageSharp style={{ marginBottom: "-2px" }} /></span> Languages</h3>
            <div>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setShowLang(true);
                  setDialog("languages");
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setProfileEditView("language")
                  setOpenEditView(true)
                }}
              >
                <SlPencil />
              </IconButton>
            </div>
          </div>
          {user?.languages && user?.languages?.length !== 0 ? (
            <div>
              {user?.languages.map((language, index) => {
                return (
                  <div
                    className={classes.languageCard}
                    key={index}
                    style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                  >
                    <h4>{language.name}</h4>
                    {language?.proficiency ? (<p>{language?.proficiency}</p>) : null}
                  </div>
                );
              })}
            </div>
          ) : (<div className={classes.emptyCont} >
            <img src={profileLanguage} />
          </div>)}
        </Paper>
      ) : (
        <>
          {profile?.parent?.languages && profile?.parent?.languages?.length !== 0 ? (
            <Paper className={classes.paper__section}>
              <div className={classes.section__header}>
                <h3><span><IoLanguageSharp style={{ marginBottom: "-2px" }} /></span> Languages</h3>
                <div>
                </div>
              </div>
              <div>
                {profile?.parent?.languages.map((language, index) => (
                  <div
                    className={classes.languageCard}
                    key={index}
                    style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                  >
                    <h4>{language.name}</h4>
                    {language?.proficiency ? (<p>{language?.proficiency}</p>) : null}
                  </div>
                ))}
              </div>
            </Paper>
          ) : null}
        </>
      )}
    </div>
  );
};

export default LanguageSection;