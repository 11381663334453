import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, InputBase, Paper, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	row: {
		width: "100%",
		display: "flex",
		flexDirection: "column"
	},
	col: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
	},
	text: {
		fontSize: 20,
		fontWeight: "bold",
		margin: "15px 1rem 0px",
		[theme.breakpoints.down('sm')]: {
			fontSize: "17px",
			fontWeight: "510"
		}
	},
	titleInput: {
		fontSize: 19,
	},
	paper: {
		backgroundColor: "#fafafa",
		boxShadow: "none",
		margin: "1rem",
		[theme.breakpoints.down("xs")]: {
			width: "100%"
		},
		borderRadius: "2vw",
	}
}));

function CustomTitleInput(props) {
	const classes = useStyles();

	const { title, setTitle, placeholder, type, isMobile, text } = props;

	return (
		<div className={isMobile ? classes.col : classes.row}>
			<Typography className={classes.text}>{text}</Typography>
			<TextField
				id="fullName"
				value={title}
				onChange={(event) => setTitle(event.target.value)}
				margin="dense"
				variant="outlined"
				fullWidth
				style={{ padding: "0px 16px 10px" }}
				className={classes.titleInput}
				placeholder={placeholder}
				type={type}
			/>

		</div>
	);
}

export default CustomTitleInput;