import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, IconButton, Button } from "@material-ui/core";
import { getDocsByTypes } from "./api.call";
import arrayToReducer from "../../../helpers/arrayToReducer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import html2plaintext from "html2plaintext";
import Api from "../../../helpers/Api";
import { UpdateOutlined } from "@material-ui/icons";
import { format } from "timeago.js";
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import TableSkeleton from "../../styled/CommonComponents/Table.Skeleton";
import emptyIcon from "../../../Assets/emptyData.svg"
import CircularProgress from '@material-ui/core/CircularProgress';
import DocCard from "../profile.doc.card";
import PreviewDialog from "./Preview.Dialog";

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "20px 10px"
    },
    iconSty: {
        fontSize: "20px",
        marginRight: "5px",
        marginBottom: "-5px"
    },
    head: {
        fontSize: "15px",
        fontWeight: "510"
    },
    bodyCont: {
        maxHeight: "calc(100vh - 290px)",
        overflowY: "auto"
    },
    loadMoreBtn: {
        width: "100%",
        margin: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    emptyImageCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "60px"
    },
    emptySty: {
        width: "300px",
        height: "auto"
    },
    cardCont: {
        maxHeight: "calc(100vh - 290px)",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    }
}));

export default function ESignEnvelopeTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { profileId } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();

    const { auth } = useSelector((state) => state);
    const user = auth?.user;
    const docSignState = useSelector((state) => state.docSign);
    const accessToken = docSignState.accessToken;
    const isSignedIn = docSignState.isSignedIn;
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [rows, setRows] = useState(null);
    const [docs, setDocs] = useState([])
    const [docsArrIds, setDocsArrIds] = useState([])
    const [docsArrDic, setDocsArrDic] = useState({})
    const [curPage, setCurPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const [selectedVal, setSelectedVal] = useState(null)
    const [openAddNew, setOpenAddNew] = useState(false)
    const dataLimit = 10

    console.log(docSignState)


    const getDocs = async () => {
        setLoading(true)
        await getDocsByTypes({
            profileId,
            docType: "ESIGN_ENVELOPE",
            curPage,
            limit: dataLimit
        })
            .then((data) => {
                if (curPage === 0) {
                    setDocs(data)
                } else {
                    setDocs((prev) => [...prev, ...data])
                }

                if (data.length < dataLimit) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                setLoading(false)
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getDocs()
    }, [curPage])

    useEffect(() => {
        const { newDict, idArr } = arrayToReducer(docs);
        setDocsArrIds(idArr)
        setDocsArrDic(newDict)
    }, [docs])

    useEffect(() => {
        if (docsArrIds && docsArrIds.length > 0) {
            let tempRows = docsArrIds.map((id) => {
                let doc = docsArrDic[id];
                // *******DO NOT DELETE*********
                // FUTURE PLANS TO REPLACE THIS WITH WEBHOOKS
                // FOR NOW A CHECK STATUS BUTTON ADDED.
                // if (!!doc.signTracker.id) {
                //   let envelopeId = doc.signTracker.id;
                //   getEnvelopeStatus(envelopeId, id);
                // }
                let status = docsArrDic[id].signTracker.status;
                return {
                    status,
                    ...doc
                };
            });
            setRows([...tempRows]);
        }
    }, [docsArrIds, docsArrDic]);

    const getEnvelopeStatus = async (envId, id) => {
        // envId => Id of the Docusign Server
        // id => ID of the envelope from MongoDB
        const payload = { accessToken: accessToken, envelopeId: envId, docId: id };
        if (!!envId) {
            await Api.post("doc/sign/envelopedata", payload)
                .then((res) => {
                    let locObj = {
                        ...docsArrDic
                    }
                    locObj[id].signTracker.status = res.status;
                    setDocsArrDic(locObj)
                })
                .catch((err) => {
                    console.log(err);
                    let locObj = {
                        ...docsArrDic
                    }
                    locObj[id].signTracker.status = "Request Failure..";
                    setDocsArrDic(locObj)
                });
        }
    };

    const handleClickGetStatus = (envelopeId, id) => {
        getEnvelopeStatus(envelopeId, id);
    };

    const onClickDataRow = (data) => {
        setSelectedVal(data)
        setOpenAddNew(true)
    }


    return (
        <>
            {!rows && loading ? (
                <TableSkeleton />
            ) : (<>{!rows ? (<div className={classes.emptyImageCont} >
                <img src={emptyIcon} className={classes.emptySty} />
            </div>) : (<>
                {rows && rows.length > 0 && (<div className={classes.root}>
                    {isMobile ? (<div className={classes.cardCont} >
                        {rows && rows.map((docData, i) => (
                            <span onClick={() => { onClickDataRow(docData) }}>
                                <DocCard
                                    key={i}
                                    doc={docData}
                                    envelopeId={docData?.signTracker.id}
                                    isEnvelope={true}
                                    handleClickGetStatus={handleClickGetStatus}
                                />
                            </span>
                        ))}
                        {hasMore && (
                            <div className={classes.loadMoreBtn} >
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        onClick={() => { setCurPage((prev) => { return prev + 1 }) }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Load More
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>) : (
                        <TableContainer component={Paper} className={classes.bodyCont} >
                            <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
                                <TableHead style={{ position: "relative", zIndex: "99" }} >
                                    <TableRow>
                                        <TableCell align="left" >
                                            <span className={classes.head} >Author</span>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <span className={classes.head} >Title</span>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <span className={classes.head} >Files</span>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <span className={classes.head} >participants</span>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <span className={classes.head} >Version</span>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <span className={classes.head} >Status</span>
                                        </TableCell>
                                        <TableCell align="right" >
                                            <span className={classes.head} >Date</span>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className={classes.bodyCont} >
                                    {rows && rows.map((docData) => (
                                        <TableRow
                                            key={docData?._id}
                                            onClick={() => {
                                                onClickDataRow(docData)
                                            }}
                                        >
                                            <TableCell align="left" style={{ padding: "0px" }} >
                                                <div>
                                                    <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                        <Avatar
                                                            alt={docData?.user?.displayName}
                                                            src={docData?.user?.displayPicture?.thumbUrl || docData?.user?.displayPicture?.url}
                                                            style={{ marginLeft: "7px", width: "30px", height: "30px" }}
                                                        />
                                                        <p style={{ marginLeft: "10px" }} >{docData?.user?.displayName}</p>
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{docData?.title}</TableCell>
                                            <TableCell align="right">{docData?.files?.length || 0}</TableCell>
                                            <TableCell align="right"  >
                                                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                                    <AvatarGroup max={4}>
                                                        {docData?.shared && docData?.shared.map((userData, i) => (
                                                            <Avatar
                                                                key={i}
                                                                alt={userData?.parent?.displayName}
                                                                src={userData?.parent?.displayPicture?.thumbUrl || userData?.parent?.displayPicture?.url}
                                                                style={{ width: "30px", height: "30px" }}
                                                            />
                                                        ))}
                                                    </AvatarGroup>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{docData?.versions?.length || 1}</TableCell>
                                            <TableCell align="right" style={{ padding: "0px" }} >
                                                <div>
                                                    <span style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
                                                        <p>{html2plaintext(docData.status)}</p>
                                                        {isSignedIn ? (
                                                            <IconButton
                                                                aria-label="get status"
                                                                title="Check Status"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    handleClickGetStatus(docData.signTracker.id, docData._id);
                                                                }}
                                                            >
                                                                <UpdateOutlined />
                                                            </IconButton>
                                                        ) : null}
                                                    </span>
                                                </div>
                                            </TableCell>
                                            <TableCell align="right">{format(docData?.updatedAt)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                            {hasMore && (
                                <div className={classes.loadMoreBtn} >
                                    {loading ? (
                                        <CircularProgress />
                                    ) : (
                                        <Button
                                            onClick={() => { setCurPage((prev) => { return prev + 1 }) }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            Load More
                                        </Button>
                                    )}
                                </div>
                            )}
                        </TableContainer>
                    )}
                </div>)}
            </>)}
            </>)}
            <PreviewDialog
                data={selectedVal}
                openAddNew={openAddNew}
                setOpenAddNew={setOpenAddNew}
                envelopeBool={true}
            />
        </>
    );
}