import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import React from "react";
import MultipleChoice from "./multiple.choice";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";
import LinearScale from "./linear.scale";
import MultipleChoiceGrid from "./multiple.choice.grid";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import CheckboxGrid from "./checkbox.grid";
import FormTable from "./form.table";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // border: '1px solid green',
    width: "100%",
    margin: "1vh 0 1vw",
    // flexWrap: 'wrap',
  },
}));
export default function FormTableQuestionOptions(props) {
  const classes = useStyles();
  const {
    type,

    // optionIds,
    // setOptionIds,
    // optionDict,
    // setOptionDict,
    // optionColIds,
    // setOptionColIds,
    // optionColDict,
    // setOptionColDict,
    // optionRowIds,
    // setOptionRowIds,
    // optionRowDict,
    // setOptionRowDict,
    questionId,
    question,
    isActive,
  } = props;

  //unlike form, formtable questions can only have short answer, long answer,
  //date and dropdown(paused for ambiguity) as question type.

  const typeComp = (type) => {
    switch (type) {
      case "Short Answer":
        return (
          <div style={{ width: "100%" }}>
            <TextField
              variant="standard"
              disabled={true}
              label="Short Answer Text"
              style={{ width: "60%" }}
            />
          </div>
        );
      case "Long Answer":
        return (
          <div style={{ width: "100%" }}>
            <TextField
              variant="standard"
              disabled={true}
              label="Long Answer Text"
              style={{ width: "90%" }}
            />
          </div>
        );
      case "Date":
        return (
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Date picker"
              format="MM/dd/yyyy"
              disabled
              // value={selectedDate}
              // onChange={handleDateChange}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
            />
          </MuiPickersUtilsProvider>
        );
      case "Number":
        return (
          <div style={{ width: "100%" }}>
            <TextField
              variant="standard"
              disabled={true}
              label="Enter Number"
              style={{ width: "60%" }}
            />
          </div>
        );
    }
  };
  return <div className={classes.root}>{typeComp(type)}</div>;
}
