export const RentalROICalcuations = (reportData) => {
    const {
        purchasePrice,
        ARV,
        propertyTax,
        propertyInsurance,
        LoanType: localLoanType,
        isFinancing,
        DownPayment: localDownPayment,
        InterestRate: localInterestRate,
        LoanTerm: localLoanTerm,
        rehabPeriod,
        RehabCostPercent: localRehabCostPercent,
        financeMortgageType,
        Upfront: localUpfront,
        Recurring: localRecurring,
        purchaseTotal,
        rehabTotal,
        costOverrun,
        holdingTotal,
        Period,
        GrossRent,
        Vacancy,
        otherIncomeTotal,
        operatingExpenseTotal,
        Appreciation,
        SellingCosts,
    } = reportData;

    let DownPayment = 0
    let InterestRate = 0
    let LoanTerm = 0
    let LoanType = 0
    let RehabCostPercent = 0
    let Upfront = 0
    let Recurring = 0

    if (isFinancing) {
        DownPayment = localDownPayment
        InterestRate = localInterestRate
        LoanTerm = localLoanTerm
        LoanType = localLoanType
        RehabCostPercent = localRehabCostPercent
        Upfront = localUpfront
        Recurring = localRecurring
    } else {
        LoanTerm = 30;
    }

    let rehabCombined = Number(rehabTotal);
    let overrunAmount = (Number(costOverrun) * Number(rehabTotal)) / 100;
    rehabCombined += Number(overrunAmount);

    let loanAmount = 0;
    if (DownPayment) {
        loanAmount += Number(purchasePrice) * ((100 - Number(DownPayment)) / 100);
    }
    let rehabFinancedAmount = 0;
    if (RehabCostPercent) {
        rehabFinancedAmount = (Number(RehabCostPercent) * rehabCombined) / 100;
    }

    loanAmount += rehabFinancedAmount;
    let amountFinanced = loanAmount;

    let downPaymentCash = 0;
    let totalCashNeeded = 0;
    if (amountFinanced) {
        downPaymentCash = Number(purchasePrice) - amountFinanced;
    }

    if (RehabCostPercent) {
        downPaymentCash += rehabCombined;
    }

    if (downPaymentCash) {
        if (RehabCostPercent) {
            totalCashNeeded = downPaymentCash + Number(purchaseTotal);
        } else {
            totalCashNeeded =
                downPaymentCash + Number(purchaseTotal) + Number(rehabCombined);
        }
    } else {
        totalCashNeeded =
            Number(purchasePrice) + Number(purchaseTotal) + Number(rehabCombined);
    }

    let pmi = 0,
        mortgageUpfrontAmount = 0,
        mortgageRecurringAmount = 0;

    if (financeMortgageType !== "Single-Premium Mortgage Insurance") {
        mortgageRecurringAmount = (Number(amountFinanced) * Number(Recurring)) / 1200;
    }

    if (financeMortgageType !== "Borrower-paid Mortgage Insurance(BPMI)") {
        mortgageUpfrontAmount = (Number(amountFinanced) * Number(Upfront)) / 100;
    }

    pmi = mortgageRecurringAmount;
    totalCashNeeded += mortgageUpfrontAmount;


    let loanToCost = 0;
    if (RehabCostPercent) {
        loanToCost = loanAmount / (Number(purchasePrice) + rehabCombined);
    } else {
        loanToCost = loanAmount / Number(purchasePrice);
    }
    loanToCost = loanToCost * 100.0;


    let loanPayment = 0;
    let rateOfInterest = Number(InterestRate) / 1200;
    if (loanAmount) {
        if (LoanType === "Interest Only") {
            loanPayment = loanAmount * rateOfInterest;
        } else {
            loanPayment =
                (loanAmount * rateOfInterest) /
                (1 - Math.pow(1 + rateOfInterest, -12 * Number(LoanTerm)));
            console.log(loanPayment);
            loanPayment += pmi;
            console.log(pmi);
        }
    }

    let otherIncomePerMonth = 0, operatingExpensePerMonth = 0;
    let GrossRentPerMonth = 0;
    const returnPerMonthRentals = () => {
        if (Period === "Per Day") {
            GrossRentPerMonth = Number(GrossRent) * 30;
            otherIncomePerMonth = Number(otherIncomeTotal) * 30;
            operatingExpensePerMonth = Number(operatingExpenseTotal) * 30;
        } else if (Period === "Per Week") {
            GrossRentPerMonth = Number(GrossRent) * 4;
            otherIncomePerMonth = Number(otherIncomeTotal) * 4;
            operatingExpensePerMonth = Number(operatingExpenseTotal) * 4;
        } else if (Period === "Per Month") {
            GrossRentPerMonth = Number(GrossRent);
            otherIncomePerMonth = Number(otherIncomeTotal);
            operatingExpensePerMonth = Number(operatingExpenseTotal);
        } else if (Period === "Per Quarter") {
            GrossRentPerMonth = Number(GrossRent) / 4.0;
            otherIncomePerMonth = Number(otherIncomeTotal) / 4.0;
            operatingExpensePerMonth = Number(operatingExpenseTotal) / 4.0;
        } else {
            GrossRentPerMonth = Number(GrossRent) / 12.0;
            otherIncomePerMonth = Number(otherIncomeTotal) / 12.0;
            operatingExpensePerMonth = Number(operatingExpenseTotal) / 12.0;
        }
    };
    returnPerMonthRentals();

    let propertyTaxAmt = (Number(propertyTax) * Number(ARV)) / 1200;
    let insuranceAmt = Number(propertyInsurance) / 12;
    let rentalPeriod = 12 - rehabPeriod;

    let AnnualRentalIncome = GrossRentPerMonth * rentalPeriod;

    let VacancyExpense = (Number(AnnualRentalIncome) * Number(Vacancy)) / 100;

    let AnnualOtherIncome = otherIncomePerMonth * rentalPeriod;

    let operatingIncome = Number(AnnualRentalIncome) - VacancyExpense + AnnualOtherIncome;

    let operatingExpenseCombined = rentalPeriod * (operatingExpensePerMonth + propertyTaxAmt + insuranceAmt);

    let recurringTotal = (Number(holdingTotal) + propertyTaxAmt + insuranceAmt) * Number(rehabPeriod);

    let NOI = operatingIncome - Number(operatingExpenseCombined) - Number(recurringTotal);

    let UnLeveredCashFlow = NOI;
    let LeveredCashFlow = UnLeveredCashFlow - loanPayment * 12;

    let propValue = Number(ARV) * (1 + Number(Appreciation) / 100);
    let totalEquity = Number(propValue);

    let loanBalance = 0;
    if (loanAmount) {
        if (LoanType === "Interest Only") {
            loanBalance = loanAmount - 12 * loanPayment;
        } else {
            let loanBalanceLeft = Number(loanAmount);
            let LoanPaymentPerMonth = (Number(loanAmount) * rateOfInterest) / (1 - Math.pow(1 + rateOfInterest, -12 * Number(LoanTerm)));
            for (let i = 0; i < 12; i++) {
                let ipmt = loanBalanceLeft * rateOfInterest;
                loanBalanceLeft -= LoanPaymentPerMonth;
                loanBalanceLeft += ipmt;
            }
            loanBalance = loanBalanceLeft;
        }
    }

    totalEquity -= loanBalance;
    let sellingCostAmt = Number((SellingCosts * propValue) / 100);

    let ROI = ((totalEquity + LeveredCashFlow - sellingCostAmt - totalCashNeeded) * 100) / totalCashNeeded;

    return ROI;
}


export const BRRRRROICalcuations = (reportData) => {
    const {
        purchasePrice,
        ARV,
        propertyTax,
        propertyInsurance,
        LoanType: localLoanType,
        DownPayment: localDownPayment,
        InterestRate: localInterestRate,
        LoanTerm: localLoanTerm,
        isFinancing,
        refinanceTime,
        refinanceLoanType,
        refinanceLoanAmount,
        remainingEquity,
        refinanceInterestRate,
        refinanceLoanTerm,
        refinanceMortgageType,
        refinanceUpfront,
        refinanceRecurring,
        refinanceCostTotal,
        rehabPeriod,
        holdingTotal,
        RehabCostPercent: localRehabCostPercent,
        financeMortgageType,
        Upfront: localUpfront,
        Recurring: localRecurring,
        purchaseTotal,
        rehabTotal,
        costOverrun,
        Period,
        GrossRent,
        Vacancy,
        otherIncomeTotal,
        operatingExpenseTotal,
        Appreciation,
        SellingCosts,
    } = reportData;

    let DownPayment = 0
    let InterestRate = 0
    let LoanTerm = 0
    let LoanType = 0
    let RehabCostPercent = 0
    let Upfront = 0
    let Recurring = 0


    if (isFinancing) {
        DownPayment = localDownPayment
        InterestRate = localInterestRate
        LoanTerm = localLoanTerm
        LoanType = localLoanType
        RehabCostPercent = localRehabCostPercent
        Upfront = localUpfront
        Recurring = localRecurring
    }

    //purchase & rehab
    //all kinds of rehab costs + overrun
    let rehabCombined = Number(rehabTotal);
    //overrun if any
    let overrunAmount = (Number(costOverrun) * Number(rehabTotal)) / 100;
    rehabCombined += Number(overrunAmount);

    //financing(purchase & rehab(if any))
    //Downpayment here is downpayment percentage
    let loanAmount = 0;
    if (DownPayment) {
        loanAmount += Number(purchasePrice) * ((100 - Number(DownPayment)) / 100);
    }
    let rehabFinancedAmount = 0;
    if (RehabCostPercent) {
        rehabFinancedAmount = (Number(RehabCostPercent) * rehabCombined) / 100;
    }
    loanAmount += rehabFinancedAmount;
    let amountFinanced = loanAmount;
    //down payment if financed
    let downPaymentCash = 0;
    let totalCashNeeded = 0;
    if (amountFinanced) {
        downPaymentCash = Number(purchasePrice) - amountFinanced;
    }
    if (RehabCostPercent) {
        downPaymentCash += rehabCombined;
    }
    //purchasetotal is purchase closing costs
    if (downPaymentCash) {
        if (RehabCostPercent) {
            totalCashNeeded = downPaymentCash + Number(purchaseTotal);
        } else {
            totalCashNeeded =
                downPaymentCash + Number(purchaseTotal) + Number(rehabCombined);
        }
    } else {
        totalCashNeeded =
            Number(purchasePrice) + Number(purchaseTotal) + Number(rehabCombined);
    }

    let pmi = 0,
        mortgageUpfrontAmount = 0,
        mortgageRecurringAmount = 0;
    if (financeMortgageType !== "Single-Premium Mortgage Insurance") {
        mortgageRecurringAmount = (loanAmount * Number(Recurring)) / 1200;
    }
    if (financeMortgageType !== "Borrower-paid Mortgage Insurance(BPMI)") {
        mortgageUpfrontAmount = (loanAmount * Number(Upfront)) / 100;
    }
    pmi = mortgageRecurringAmount;
    totalCashNeeded += mortgageUpfrontAmount;

    let loanToCost = 0;
    if (RehabCostPercent) {
        loanToCost = (loanAmount * 100) / (Number(purchasePrice) + rehabCombined);
    } else {
        loanToCost = (loanAmount * 100) / Number(purchasePrice);
    }
    loanToCost = loanToCost.toFixed(2);
    let loanToValue = (loanAmount / Number(ARV)) * 100;
    let loanPayment = 0;
    let rateOfInterest = Number(InterestRate) / 1200;
    if (loanAmount) {
        if (LoanType === "Interest Only") {
            loanPayment = loanAmount * rateOfInterest;
        } else {
            loanPayment =
                (loanAmount * rateOfInterest) /
                (1 - Math.pow(1 + rateOfInterest, -12 * Number(LoanTerm)));
            loanPayment += pmi;
        }
        loanPayment = Math.round(loanPayment);
    }

    let propertyTaxAmt = (Number(propertyTax) * Number(ARV)) / 1200;
    let insuranceAmt = Number(propertyInsurance) / 12;

    //holding costs
    let holdingCosts = 0,
        holdingLoanPayments = 0;
    //holding total is rehab recurring total which includes prop tax, insurance, itemized..
    holdingLoanPayments = loanPayment * Number(rehabPeriod);
    let recurringTotal =
        (Number(holdingTotal) + propertyTaxAmt + insuranceAmt) *
        Number(rehabPeriod);
    //holding costs for brrrr is different from flip..it doesn't include rehabcombined
    holdingCosts = recurringTotal + holdingLoanPayments;

    //rental calculations after refinance
    let otherIncomePerMonth = 0,
        operatingExpensePerMonth = 0;
    let GrossRentPerMonth = 0;
    const returnPerMonthRentals = () => {
        if (Period === "Per Day") {
            GrossRentPerMonth = Number(GrossRent) * 30;
            otherIncomePerMonth = Number(otherIncomeTotal) * 30;
            operatingExpensePerMonth = Number(operatingExpenseTotal) * 30;
        } else if (Period === "Per Week") {
            GrossRentPerMonth = Number(GrossRent) * 4;
            otherIncomePerMonth = Number(otherIncomeTotal) * 4;
            operatingExpensePerMonth = Number(operatingExpenseTotal) * 4;
        } else if (Period === "Per Month") {
            GrossRentPerMonth = Number(GrossRent);
            otherIncomePerMonth = Number(otherIncomeTotal);
            operatingExpensePerMonth = Number(operatingExpenseTotal);
        } else if (Period === "Per Quarter") {
            GrossRentPerMonth = Number(GrossRent) / 4.0;
            otherIncomePerMonth = Number(otherIncomeTotal) / 4.0;
            operatingExpensePerMonth = Number(operatingExpenseTotal) / 4.0;
        } else {
            GrossRentPerMonth = Number(GrossRent) / 12.0;
            otherIncomePerMonth = Number(otherIncomeTotal) / 12.0;
            operatingExpensePerMonth = Number(operatingExpenseTotal) / 12.0;
        }
    };
    returnPerMonthRentals();
    //only for 1st year
    let rentalPeriod = 12 - rehabPeriod;
    let AnnualRentalIncome = GrossRentPerMonth * 12;
    let VacancyExpense = (Number(AnnualRentalIncome) * Number(Vacancy)) / 100;
    let AnnualOtherIncome = otherIncomePerMonth * 12;
    let operatingIncome =
        Number(AnnualRentalIncome) - VacancyExpense + AnnualOtherIncome;
    let operatingExpenseCombined =
        12 * (operatingExpensePerMonth + propertyTaxAmt + insuranceAmt);
    //operatingExpensePerMonth includes prop tax, insurance pmo,itemized
    //..for the rental period
    let NOI = operatingIncome - Number(operatingExpenseCombined);

    //refinance calculations
    let refinanceLoanAmt = 0,
        refinanceLoanPayment = 0,
        purchaseRepayment = 0,
        refinanceLoanToValue = 0,
        netIncomeAfterRehab = 0,
        refinanceCashOut = 0,
        totalCashInvested = 0,
        refinancePmi = 0;
    //netIncomeAfterRehab is the total operating income - total loan payments during the period after rehab & before refinance
    netIncomeAfterRehab =
        (Number(NOI) / Number(rentalPeriod) - loanPayment) *
        Number(Number(refinanceTime) - Number(rehabPeriod));

    //refinance mortgage insurance calculation
    let mortgageRefUpfrontAmount = 0,
        mortgageRefRecurringAmount = 0;
    if (refinanceMortgageType !== "Single-Premium Mortgage Insurance") {
        mortgageRefRecurringAmount = (Number(refinanceLoanAmt) * Number(refinanceRecurring)) / 1200;
    } else if (
        refinanceMortgageType !== "Borrower-paid Mortgage Insurance(BPMI)"
    ) {
        mortgageRefUpfrontAmount = (Number(refinanceLoanAmt) * Number(refinanceUpfront)) / 100;
    }
    refinancePmi = mortgageRefRecurringAmount;

    let refInterestRate = Number(refinanceInterestRate) / 1200;
    if (refinanceLoanAmount) {
        refinanceLoanAmt = Number(refinanceLoanAmount);
    } else {
        refinanceLoanAmt = Number(ARV) - (Number(remainingEquity) * Number(ARV)) / 100;
    }
    if (refinanceLoanType === "Interest Only") {
        refinanceLoanPayment = refinanceLoanAmt * Number(refInterestRate);
    } else {
        refinanceLoanPayment =
            (refinanceLoanAmt * refInterestRate) /
            (1 - Math.pow(1 + refInterestRate, -12 * Number(refinanceLoanTerm)));
    }
    refinanceLoanPayment += Number(mortgageRefRecurringAmount);
    refinanceLoanToValue = (refinanceLoanAmt / Number(ARV)) * 100;
    let AnnualRefinanceLoanPayment = 12 * refinanceLoanPayment;

    //loan balance after refinance
    if (amountFinanced) {
        if (LoanType === "Interest Only") {
            purchaseRepayment = amountFinanced;
        } else {
            let loanBalanceLeft = amountFinanced;
            let intervals = Number(refinanceTime);
            let LoanPaymentPerMonth = (loanAmount * rateOfInterest) / (1 - Math.pow(1 + rateOfInterest, -12 * Number(LoanTerm)));
            for (let i = 0; i < intervals; i++) {
                let ipmt = loanBalanceLeft * rateOfInterest;
                loanBalanceLeft -= LoanPaymentPerMonth;
                loanBalanceLeft += ipmt;
            }
            purchaseRepayment = loanBalanceLeft;
        }
    }

    refinanceCashOut = refinanceLoanAmt - Number(refinanceCostTotal) - purchaseRepayment - holdingCosts + netIncomeAfterRehab - mortgageRefUpfrontAmount;
    if (refinanceCashOut >= totalCashNeeded) {
        totalCashInvested = 0;
    } else {
        totalCashInvested = totalCashNeeded - refinanceCashOut;
    }


    let UnLeveredCashFlow = NOI;
    let LeveredCashFlow = UnLeveredCashFlow - AnnualRefinanceLoanPayment;

    let propValue = Number(ARV) * (1 + Number(Appreciation) / 100);
    let totalEquity = Number(propValue);
    let loanBalance = 0;
    if (LoanType === "Interest Only") {
        loanBalance = refinanceLoanAmt - 12 * refinanceLoanAmt;
    } else {
        let pmt = Number(refinanceLoanAmt);
        let roi = Number(refinanceInterestRate) / 1200;
        let LoanPmtPerMonth = (Number(refinanceLoanAmt) * Number(roi)) / (1 - Math.pow(1 + Number(roi), -12 * Number(refinanceLoanTerm)));
        for (let i = 0; i < 12; i++) {
            let intPayment = pmt * Number(refInterestRate);
            pmt -= LoanPmtPerMonth;
            pmt += intPayment;
        }
        loanBalance = pmt;
    }
    totalEquity -= loanBalance;

    let sellingCostAmt = Number((SellingCosts * Number(propValue)) / 100);
    let ROI = ((totalEquity - sellingCostAmt + LeveredCashFlow - totalCashInvested) * 100) / totalCashInvested;

    return ROI;
}


export const FlipROICalculations = (reportData) => {
    const {
        purchasePrice,
        ARV,
        propertyTax,
        propertyInsurance,
        LoanType: localLoanType,
        DownPayment: localDownPayment,
        InterestRate: localInterestRate,
        LoanTerm: localLoanTerm,
        isFinancing,
        rehabPeriod,
        rentalPeriod,
        holdingTotal,
        RehabCostPercent: localRehabCostPercent,
        financeMortgageType,
        Upfront: localUpfront,
        Recurring: localRecurring,
        purchaseTotal,
        rehabTotal,
        costOverrun,
        Period,
        GrossRent,
        Vacancy,
        otherIncomeTotal,
        operatingExpenseTotal,
        sellingPrice,
        sellingCostTotal,
    } = reportData;

    let DownPayment = 0
    let InterestRate = 0
    let LoanTerm = 0
    let LoanType = 0
    let RehabCostPercent = 0
    let Upfront = 0
    let Recurring = 0

    if (isFinancing) {
        DownPayment = localDownPayment
        InterestRate = localInterestRate
        LoanTerm = localLoanTerm
        LoanType = localLoanType
        RehabCostPercent = localRehabCostPercent
        Upfront = localUpfront
        Recurring = localRecurring
    }

    let itr = Number(rehabPeriod);
    if (Number(rentalPeriod)) {
        itr = itr + Number(rentalPeriod);
    }

    itr = itr + 4;

    let rehabCombined = Number(rehabTotal);
    let overrunAmount = (Number(costOverrun) * Number(rehabTotal)) / 100;
    rehabCombined += Number(overrunAmount);

    let loanAmount = 0;
    if (DownPayment) {
        loanAmount += Number(purchasePrice) * ((100 - Number(DownPayment)) / 100);
    }
    let rehabFinancedAmount = 0;
    if (RehabCostPercent) {
        rehabFinancedAmount = (Number(RehabCostPercent) * rehabCombined) / 100;
    }
    loanAmount += rehabFinancedAmount;
    let amountFinanced = loanAmount;
    let downPaymentCash = 0;
    let totalCashNeeded = 0;
    if (amountFinanced) {
        downPaymentCash = Number(purchasePrice) - amountFinanced;
    }
    if (RehabCostPercent) {
        downPaymentCash += rehabCombined;
    }

    if (downPaymentCash) {
        if (RehabCostPercent) {
            totalCashNeeded = downPaymentCash + Number(purchaseTotal);
        } else {
            totalCashNeeded = downPaymentCash + Number(purchaseTotal) + Number(rehabCombined);
        }
    } else {
        totalCashNeeded = Number(purchasePrice) + Number(purchaseTotal) + Number(rehabCombined);
    }

    let pmi = 0,
        mortgageUpfrontAmount = 0,
        mortgageRecurringAmount = 0;
    if (financeMortgageType !== "Single-Premium Mortgage Insurance") {
        mortgageRecurringAmount = (loanAmount * Number(Recurring)) / 1200;
    }
    if (financeMortgageType !== "Borrower-paid Mortgage Insurance(BPMI)") {
        mortgageUpfrontAmount = (loanAmount * Number(Upfront)) / 100;
    }
    pmi = mortgageRecurringAmount;
    totalCashNeeded += mortgageUpfrontAmount;

    let loanToCost = 0;
    if (RehabCostPercent) {
        loanToCost = (loanAmount * 100) / (Number(purchasePrice) + rehabCombined);
    } else {
        loanToCost = loanAmount / Number(purchasePrice);
    }
    let loanPayment = 0;
    let rateOfInterest = Number(InterestRate) / 1200;
    if (loanAmount) {
        if (LoanType === "Interest Only") {
            loanPayment = loanAmount * rateOfInterest;
        } else {
            loanPayment = (loanAmount * rateOfInterest) / (1 - Math.pow(1 + rateOfInterest, -12 * Number(LoanTerm)));
            loanPayment += pmi;
        }
    }

    //valuation
    let propertyTaxAmt = (Number(ARV) * Number(propertyTax)) / 1200;
    let insuranceAmt = Number(propertyInsurance) / 12;

    //holding costs
    let holdingPeriod = 0;
    if (rehabPeriod) holdingPeriod += Number(rehabPeriod);
    if (rentalPeriod) holdingPeriod += Number(rentalPeriod);
    let holdingCosts = 0, holdingLoanPayments = 0;
    holdingLoanPayments = Number(loanPayment) * Number(rehabPeriod);
    let recurringTotal = (Number(holdingTotal) + propertyTaxAmt + insuranceAmt) * Number(rehabPeriod);
    holdingCosts = recurringTotal + holdingLoanPayments;

    //sale & profit
    let saleProceeds = 0, loanRepayment = 0, profitFlip = 0;
    if (Number(rentalPeriod) !== 0) {
        saleProceeds = Number(sellingPrice) - Number(sellingCostTotal);
    } else {
        saleProceeds = Number(ARV) - Number(sellingCostTotal);
    }
    if (loanAmount) {
        if (LoanType === "Interest Only") {
            loanRepayment = loanAmount;
        } else {
            let loanBalanceLeft = amountFinanced;
            let intervals = Number(holdingPeriod);
            let LoanPaymentPerMonth = (loanAmount * rateOfInterest) / (1 - Math.pow(1 + rateOfInterest, -12 * Number(LoanTerm)));
            for (let i = 0; i < intervals; i++) {
                let ipmt = loanBalanceLeft * rateOfInterest;
                loanBalanceLeft -= LoanPaymentPerMonth;
                loanBalanceLeft += ipmt;
            }
            loanRepayment = loanBalanceLeft;
        }
    }
    profitFlip = saleProceeds - loanRepayment - holdingCosts - totalCashNeeded;

    let otherIncomePerMonth = 0, operatingExpensePerMonth = 0, GrossRentPerMonth = 0;
    const returnPerMonthRentals = () => {
        if (Period === "Per Day") {
            GrossRentPerMonth = Number(GrossRent) * 30;
            otherIncomePerMonth = Number(otherIncomeTotal) * 30;
            operatingExpensePerMonth = Number(operatingExpenseTotal) * 30;
        } else if (Period === "Per Week") {
            GrossRentPerMonth = Number(GrossRent) * 4;
            otherIncomePerMonth = Number(otherIncomeTotal) * 4;
            operatingExpensePerMonth = Number(operatingExpenseTotal) * 4;
        } else if (Period === "Per Month") {
            GrossRentPerMonth = Number(GrossRent);
            otherIncomePerMonth = Number(otherIncomeTotal);
            operatingExpensePerMonth = Number(operatingExpenseTotal);
        } else if (Period === "Per Quarter") {
            GrossRentPerMonth = Number(GrossRent) / 4.0;
            otherIncomePerMonth = Number(otherIncomeTotal) / 4.0;
            operatingExpensePerMonth = Number(operatingExpenseTotal) / 4.0;
        } else {
            GrossRentPerMonth = Number(GrossRent) / 12.0;
            otherIncomePerMonth = Number(otherIncomeTotal) / 12.0;
            operatingExpensePerMonth = Number(operatingExpenseTotal) / 12.0;
        }
    };
    returnPerMonthRentals();
    let totalRentalIncome = GrossRentPerMonth * Number(rentalPeriod);
    let totalOtherIncome = otherIncomePerMonth * Number(rentalPeriod);
    let VacancyExpense = (Number(totalRentalIncome) * Number(Vacancy)) / 100;
    let totalOperatingExpense = operatingExpensePerMonth * Number(rentalPeriod);
    let holdingIncome = totalRentalIncome - VacancyExpense + totalOtherIncome - totalOperatingExpense - loanPayment * Number(rentalPeriod);
    profitFlip += holdingIncome;

    //investment returns
    let ROI = 0, cashMultiple = 0
    cashMultiple = Number(profitFlip) / Number(totalCashNeeded + holdingCosts);
    ROI = cashMultiple * 100;

    return ROI;
};