import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { Button, Chip, Divider, Switch, IconButton } from "@material-ui/core";
import AvatarLocal from "../profile/avatar";
import BootstrapTooltip from "../styled/tooltip";
import AssignBtn from "./assign.icon.btn";
import LockIcon from "@material-ui/icons/Lock";
import PublicIcon from "@material-ui/icons/Public";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import addPerson from "../../Assets/addUser.svg"

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexDirection: "column",
		minWidth: "18rem",
		maxWidth: "32rem",
		width: "90%",
		margin: "1rem",
	},

	row: {
		// flex: 1,
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		flexWrap: "wrap",
	},

	divider: {
		margin: "1rem",
	},

	paperStyle: {
		padding: "1rem",
		maxWidth: "34rem",
	},

	chipStyle: {
		marginRight: "10px",
		marginBottom: "10px"
	},
	shared: {
		fontWeight: "600",
		fontSize: "1.1rem",
	},
	private: {
		fontWeight: "500",
		fontSize: "0.95rem",
		margin: "0.5rem 0.5rem",
	},
	emptyImage: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		flexDirection: "column",
		"& img": {
			width: "300px",
			height: "auto"
		},
		"& p": {
			fontSize: "13px",
			marginTop: "15px"
		},
		[theme.breakpoints.down('xs')]: {
			flexDirection: "column",
			"& img": {
				width: "250px",
				height: "auto"
			},
			"& p": {
				fontSize: "12px",
				marginTop: "15px"
			},
		}
	}
}));

const SharedList = (sharedProps, isPrivate, setPrivate) => {
	const classes = useStyles();
	let {
		sharedDict,
		shared,
		setShared,
		assignableIds,
		assigned,
		setAssigned,
		openDialog,
		setOpenDialog,
		open,
		setOpen,
	} = sharedProps || {};
	console.log('sharedlist called with ', sharedDict, shared, assigned);

	const { root, row, col, paperStyle, chipStyle } = classes;

	const handleDelete = (profileId) => {
		const isShared = shared.indexOf(profileId) !== -1;
		var sharedSet = new Set(shared);
		if (isShared) {
			sharedSet.delete(profileId);
		}
		const newArr = Array.from(sharedSet);
		setShared(newArr);
	};

	const handleDeleteAssigned = (profileId) => {
		const isShared = shared.indexOf(profileId) !== -1;
		var sharedSet = new Set(shared);
		if (isShared) {
			sharedSet.delete(profileId);
		}
		const newArr = Array.from(sharedSet);
		setShared(newArr);
	};
	let privateButton = (
		<BootstrapTooltip title="Admin eyes means that only admins and assigned people can see private issue">
			<IconButton color="primary" onClick={() => { setPrivate(!isPrivate) }} >
				{isPrivate ? <LockIcon /> : <LockOpenIcon />}
			</IconButton>
		</BootstrapTooltip>
	);

	console.log(assigned)

	let assignButton = <AssignBtn setOpen={setOpenDialog} />;
	let assigness = (
		<div className={row}>
			{assigned?.length > 0 ? (
				<>
					{assigned.map((profileId) => {
						const profile = sharedDict[profileId];
						const entity = profile?.parent;
						if (entity?._id) {
							return (
								<Chip
									// size="small"
									variant="outlined"
									className={chipStyle}
									avatar={
										<AvatarLocal
											src={entity}
											style={{
												height: "1.6rem",
												width: "1.6rem",
												borderRadius: "0.8rem",
											}}
										/>
									}
									label={entity?.displayName}
									onDelete={() => {
										handleDeleteAssigned(profileId);
									}}
								/>
							);
						} else {
							return null;
						}
					})}
				</>
			) : (<div className={classes.emptyImage} >
				<img src={addPerson} />
				<p>No Assigness. Click Add to add people.</p>
			</div>)}
		</div>
	);

	console.log(shared,' is the shared')

	let sharedPeoples = (
		<div className={row}>
			{shared && shared.length > 0 ? (<>
				{(shared || []).map((profileId) => {
					const profile = sharedDict[profileId];
					const entity = profile?.parent;
					if (entity?._id) {
						return (
							<Chip
								variant="outlined"
								className={chipStyle}
								avatar={
									<AvatarLocal
										src={entity}
										style={{
											height: "1.6rem",
											width: "1.6rem",
											borderRadius: "0.8rem",
										}}
									/>
								}
								label={entity?.displayName}
								onDelete={() => {
									handleDelete(profileId);
								}}
							/>
						);
					} else {
						return null;
					}
				})}
			</>) : (<div className={classes.emptyImage} >
				<img src={addPerson} />
				<p>No Shared. Click Add to add people.</p>
			</div>)}
		</div>
	);

	return { privateButton, assignButton, assigness, sharedPeoples };
};

export default SharedList;
