import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    coverPic: {
        width: "100%",
        height: "240px",
        position: "relative",
        marginBottom: "40px"
    },
    profilePic: {
        position: "absolute",
        bottom: "-50px",
        left: "20px",
        height: "10rem",
        width: "10rem",
        [theme.breakpoints.down("xs")]: {
            height: "7rem",
            bottom: "-40px",
            width: "7rem",
        }
    },
    detailsCont: {
        width: "100%",
        padding: "25px",
        position: "relative",
    },
    editBtn: {
        position: "absolute",
        top: "-35px",
        right: "25px"
    }
}));

function AccountSkeleton() {
    const classes = useStyles();
    return (
        <Paper elevation={2} className={classes.root} >
            <div className={classes.coverPic} >
                <Skeleton variant="rect" style={{ width: "100%", height: "100%" }} />
                <Skeleton variant="circle" className={classes.profilePic} />
            </div>
            <div className={classes.detailsCont} >
                <Skeleton style={{ width: "200px", height: "40px" }} variant="text" />
                <Skeleton style={{ width: "150px", height: "25px" }} variant="text" />
                <div style={{ display: "flex", alignItems: "center" }} >
                    <Skeleton style={{ width: "100px", height: "25px", marginRight: "10px" }} variant="text" />
                    <Skeleton style={{ width: "100px", height: "25px" }} variant="text" />
                </div>

                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
                    <Skeleton style={{ width: "70px", height: "25px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "70px", height: "25px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "70px", height: "25px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "70px", height: "25px" }} variant="text" />
                </div>

                <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }} >
                    <Skeleton style={{ width: "70px", height: "25px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "70px", height: "25px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "70px", height: "25px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "70px", height: "25px" }} variant="text" />
                </div>

                <div style={{ display: "flex", alignItems: "center" }} >
                    <Skeleton style={{ width: "88px", height: "55px", marginRight: "5px" }} variant="text" />
                    <Skeleton style={{ width: "88px", height: "55px" }} variant="text" />
                </div>

                <Skeleton style={{ width: "88px", height: "55px" }} className={classes.editBtn} variant="text" />
            </div>
        </Paper>
    );
}
export default AccountSkeleton; 