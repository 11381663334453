import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { IconButton } from "@material-ui/core";
import profileAbout from "../../../Assets/profileAbout.svg"
import { ImProfile } from "react-icons/im";
import { SlPencil } from "react-icons/sl";
import SeeMoreSeeLess from "../../styled/CommonComponents/SeeMore.SeeLess";


const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    marginTop: "1rem",
    borderRadius: "3px",
    padding: "5px 15px 15px",
    "& p": {
      color: "grey",
      fontSize: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        color: "grey",
        fontSize: "12px",
        wordBreak: "break-all"
      },
    }
  },
  section__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px"
    }
  }
}));

const AboutSection = ({ isOwnProfile, profile, setOpenEditView, setProfileEditView }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme()
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();

  return (
    <div>
      {isOwnProfile ? (
        <Paper className={classes.paper__section}>
          <div className={classes.section__header}>
            <h3><span><ImProfile style={{ marginBottom: "-2px" }} /></span> About</h3>
            <IconButton
              className={classes.editBtn}
              onClick={() => {
                setProfileEditView("basicInfo")
                setOpenEditView(true)
              }}
            >
              <SlPencil />
            </IconButton>
          </div>
          {user?.about ? (<div>
            <p>
              <SeeMoreSeeLess
                text={user?.about || ""}
                limit={300}
              />
            </p>
          </div>) : (<div className={classes.emptyCont} >
            <img src={profileAbout} />
          </div>)}
        </Paper>
      ) : (
        <div>
          {profile?.parent?.about ? (
            <Paper className={classes.paper__section}>
              <div className={classes.section__header}>
                <h3><span><ImProfile style={{ marginBottom: "-2px" }} /></span> About</h3>
                <div></div>
              </div>
              <p>
                <SeeMoreSeeLess
                  text={profile?.parent?.about || ""}
                  limit={300}
                />
              </p>
            </Paper>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default AboutSection;