import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    paperStyle: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "15px",
        padding: "5px 10px",
        borderRadius: "7px",
        border: "1px solid #989898",
    },
    dwollaHeader: {
        width: "calc(100% - 50px)",
        paddingLeft: "15px"
    },
}));

function BankSelectCardSkeleton() {
    const classes = useStyles();

    return (
        <Paper elevation={0} className={classes.paperStyle} >
            <Skeleton
                style={{ width: "50px", height: "50px" }}
                variant="circle"
            />
            <div className={classes.dwollaHeader} >
                <Skeleton style={{ width: "80%", height: "20px", marginBottom: "5px" }} variant="rect" />
                <Skeleton style={{ width: "70%", height: "15px", marginBottom: "5px" }} variant="rect" />
                <Skeleton style={{ width: "90%", height: "15px" }} variant="rect" />
            </div>
        </Paper>
    );
}
export default BankSelectCardSkeleton; 