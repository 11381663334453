import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { ClickAwayListener } from '@material-ui/core';
import { bankLogoMap } from "../../../../../helpers/PlaidBanksWithLogo"
import { updateBankAccount } from '../../api.call';
import LessText from '../../../../styled/CommonComponents/LessText';
import EditIcon from '@material-ui/icons/Edit';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "325px",
    marginRight: "15px",
    marginBottom: "15px",
    padding: "15px 10px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    "& img": {
      height: "30px",
    },
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      borderRadius: "10px",
      marginRight: "0px",
      "& img": {
        height: "25px",
      },
    }
  },
  bankIcon: {
    width: "30px",
    height: "32px",
    color: "#0E68AC",
    [theme.breakpoints.only("xs")]: {
      width: "25px",
      height: "27px",
    }
  },
  bankLogoCont: {
    padding: "4px 8px",
    borderRadius: "50%",
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.only("xs")]: {
      padding: "2px 6px",
    }
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontSize: '15px',
      fontWeight: '450',
      color: '#424242',
    }
  },
  cardsCont: {
    display: "flex",
    justifyContent: 'center',
    flexDirection: 'column',
    padding: "20px",
    [theme.breakpoints.only("xs")]: {
      padding: "20px 20px 5px",
    }
  },
  divider: {
    borderTop: "1px solid #c9c8c8",
    margin: "10px 0px"
  },
  nameTypeCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    color: "#626060",
    "& h3": {
      fontSize: "18px",
      fontWeight: "500"
    },
    "& h4": {
      fontSize: "14px",
      fontWeight: "450"
    },
  },
  nameInputEdit: {
    width: "95%",
    border: "none",
    outline: "none",
    fontSize: "21px",
    fontWeight: '600',
    color: "#263238",
    "&:active": {
      border: "none",
      outline: "none"
    },
    "&:focus": {
      border: "none",
      outline: "none"
    },
    [theme.breakpoints.only("xs")]: {
      width: "97%",
    }
  },
  namesCont: {
    width: "calc(100% - 60px)",
    [theme.breakpoints.only("xs")]: {
      width: "calc(100% - 50px)",
    }
  },
  nameIconCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h4": {
      width: "calc(100% - 40px)",
      fontSize: "20px",
      fontWeight: '600',
      color: "#263238",
    }
  }
}));

export default function PlaidBankCard(props) {
  const {
    bankAccount, accountId, givenNickName,
    index, plaidBankAccounts, setPlaidBankAccounts
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const institution = bankAccount?.institution;
  const name = institution?.name;
  const accts = bankAccount?.accounts || [];

  const [openToEdit, setOpenToEdit] = useState(false)
  const [nickName, setNickName] = useState(givenNickName || "Add account name")

  const updateAccountData = async () => {
    setOpenToEdit(false)
    if (nickName !== givenNickName) {
      await updateBankAccount({
        bankAccountId: accountId,
        updateObj: {
          nickName: nickName
        }
      })
        .then((data) => {
          console.log(data)
          let locArr = [...plaidBankAccounts]
          locArr[index] = data;
          setPlaidBankAccounts(locArr)
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }


  return (
    <Paper className={classes.paperStyle}>
      <div className={classes.cardHeader} >
        <div className={classes.bankLogoCont} >
          {null ? (
            <img src={bankLogoMap?.[name]} />
          ) : (
            <AccountBalanceIcon className={classes.bankIcon} />
          )}
        </div>
        <div className={classes.namesCont} >
          {openToEdit ? (
            <ClickAwayListener onClickAway={() => { updateAccountData() }}>
              <input
                value={nickName}
                onChange={(e) => { setNickName(e.target.value) }}
                className={classes.nameInputEdit}
              />
            </ClickAwayListener>
          ) : (
            <div className={classes.nameIconCont} >
              <h4>
                <LessText
                  limit={15}
                  string={nickName}
                />
              </h4>
              <EditIcon
                style={{ cursor: "pointer" }}
                onClick={() => { setOpenToEdit(true) }}
              />
            </div>
          )}
          <h3>
            <LessText
              limit={23}
              string={name}
            />
          </h3>
        </div>
      </div>

      <div className={classes.cardsCont} >
        {accts.map((acct, i) => {
          if (i === 0) {
            return (
              <div className={classes.nameTypeCont} >
                <h3>{acct?.name}</h3>
                <h4>{acct?.subtype}</h4>
              </div>
            )
          } else {
            return (<div>
              <div className={classes.divider} ></div>
              <div className={classes.nameTypeCont} >
                <h3>{acct?.name}</h3>
                <h4>{acct?.subtype}</h4>
              </div>
            </div>)
          }
        })}
      </div>
    </Paper>
  );
}
