import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import { CircularProgress, Paper, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import Api from "../../helpers/Api";
import { useParams, useHistory } from "react-router-dom";
import useShared from "../share/useShared.js";
import ShareIconBtn from "../share/share.icon.btn.js";
import SharedList from "../share/sharedList.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import VersionBtn from "../styled/actionBtns/versions.btn";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useDebounce } from "react-use";
import Logs from "../logs";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import DocEditor from "./Doc.Editor";
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: "100vh",
		overflow: "hidden",
		backgroundColor: "#F8F8F8",
		padding: "0px 30px",
		[theme.breakpoints.down('sm')]: {
			padding: "0px 10px",
		},
		[theme.breakpoints.down('xs')]: {
			height: "calc(100vh - 60px)",
		}
	},
	navBar: {
		width: "100%",
		height: "70px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down('xs')]: {
			height: "50px",
		}
	},
	mainCont: {
		width: "100%",
		height: "calc(100% - 70px)",
		display: "flex",
		overflowY: "auto",
		flexDirection: "column",
		padding: "0px 0px 40px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
		[theme.breakpoints.down('sm')]: {
			padding: "0px 0px 30px",
		},
		[theme.breakpoints.down('xs')]: {
			height: "calc(100% - 50px)",
		}
	},
	flexShow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		"& h3": {
			fontWeight: "510",
			fontSize: "20px",
			color: "#00345D"
		},
		[theme.breakpoints.down('xs')]: {
			"& h3": {
				fontWeight: "500",
				fontSize: "17px",
				color: "#00345D"
			},
		},
	},
	backIcon: {
		fontSize: "30px",
		marginRight: "10px",
		cursor: "pointer",
		[theme.breakpoints.down('xs')]: {
			fontSize: "25px",
			marginRight: "5px",
		}
	},
	shareTitle: {
		fontSize: "19px",
		fontWeight: "500",
		margin: '0px 0px 10px',
		[theme.breakpoints.down('xs')]: {
			fontSize: "16px",
		}
	},
	editorCont: {
		marginTop: "20px",
		padding: "0px 10px 15px"
	},
	shareCont: {
		padding: "5px 10px",
		marginTop: "20px"
	},
	onlyEditorCont: {
		width: "100%",
		marginTop: "-30px"
	}
}));

function EditProfileDoc(props) {
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	const { docId } = useParams();

	const file = useSelector((state) => state.file);
	const { createdFileIds } = file;
	const { user } = useSelector((state) => state.auth);
	const docReducer = useSelector((state) => state.doc);
	const reducerDoc = docReducer?.tempDoc;
	const oldDoc = reducerDoc?._id == docId ? reducerDoc : null;
	const oldTitle = oldDoc?.title || "";
	const oldDescription = oldDoc?.description || "";
	const oldFiles = oldDoc?.files || [];
	const oldShared = oldDoc?.shared?.map((profile) => profile?._id) || [];

	const [open, setOpen] = useState(false);
	const [originalDoc, setOriginalDoc] = useState(null);
	const [title, setTitle] = useState(oldTitle);
	const [description, setDescription] = useState(oldDescription);
	const [files, setFiles] = useState(oldFiles);
	const [shared, setShared] = useState(oldShared);
	const [docs, setDocs] = useState(oldDoc?.versions);
	const [draftTrue, setDraftTrue] = useState(false);
	const [draftLoading, setDraftLoading] = useState(false);
	const [draftSaved, setDraftSaved] = useState(false);
	const [isPrivate, setPrivate] = useState(false);
	const [loading, setLoading] = useState(true);

	const sharedProps = useShared({ initShared: shared, initAssigned: [] });
	var { privateButton, assignButton, assigness, sharedPeoples } = SharedList(sharedProps, isPrivate, setPrivate);

	useDebounce(
		() => {
			if (!loading) {
				if (originalDoc?.title != title || originalDoc?.description != description) {
					updateDocDraft({
						_id: docId,
						title,
						description
					});
				}
			}
		},
		1000,
		[title, description]
	);

	const updateDocDraft = async (obj) => {
		setDraftLoading(true);
		const draftUpdate = await Api.post("doc/updateDraft", obj);
		if (draftUpdate?.updated) {
			setDraftSaved(true);
			setDraftTrue(true);
		} else {
			setDraftSaved(false);
			console.error(draftUpdate?.error);
		}
		setDraftLoading(false);
	};

	useEffect(() => {
		dispatch({
			type: "AddFile",
			payload: {
				createdFileIds: files,
			},
		});
	}, [files]);

	const getDocDetail = async () => {
		const res = await Api.post("doc/getDocDetail", {
			docId,
		});
		console.log(res?.data, " is the doc");
		const resDraft = await Api.post("doc/getDraft", {
			_id: docId,
		});
		const draft = resDraft?.draft;
		console.log("draft = ", draft, res.data[0]);
		if (res?.data) {
			const data = res.data[0];
			setOriginalDoc(data);
			setTitle(draft[0]?.title || data.title);
			setDescription(draft[0]?.description || data.description);
			setFiles(data.files);
			setShared(data.shared);
			setDocs(data.versions);
			setDraftTrue(draft[0]?.changes);
		} else {
			console.log("res = ", res.error);
		}
		setLoading(false);
	};

	useEffect(() => {
		getDocDetail();
	}, []);

	const updateDocApi = async (localUpdate) => {


		const docObject = {
			_id: docId,
			title,
			description,
			shared: sharedProps?.shared,
			isPrivate,
			files: createdFileIds,
			activeUserId: user,
			activeUserProfile: user?.profile,
		};

		const res = await Api.post("doc/update", docObject);

		if(localUpdate){}else{

			dispatch({
				type: "AddApiAlert",
				payload: {
					success: true,
					message: "Doc updated successfully",
				},
			});
	
			dispatch({ type: "FileUploadReset" });
			history.goBack();
		}

	};

	useEffect(() => {
		updateDocApi(true);
	},[
		sharedProps?.shared?.length,
		sharedProps?.assigned?.length,
	])

	return (
		<div className={classes.root}>
			<div className={classes.navBar} >
				<div className={classes.flexShow} >
					<KeyboardBackspaceIcon
						onClick={() => {
							history.goBack();
						}}
						className={classes.backIcon}
					/>
					<h3>Edit Document</h3>
				</div>
				<div style={{ display: "flex", alignItems: "center" }} >
					<VersionBtn
						onClick={() => setOpen(true)}
						count={docs?.length || 1}
					/>
					<CustomBtn
						startPart={<SaveIcon />}
						disabled={title && title.length > 2 ? false : true}
						text={"Update"}
						onClick={() => { updateDocApi(); }}
						style={{ marginLeft: "10px" }}
					/>
				</div>
			</div>

			<div className={classes.mainCont} >
				<Drawer anchor={"right"} open={open} onClose={() => setOpen(false)}>
					<Logs dataModelId={docId} dataModel='Doc' setOpen={setOpen} />
				</Drawer>
				{sharedProps?.shareDrawer}
				{sharedProps?.assignedDialog}
				<div>
					{draftTrue ? (
						draftLoading ? (
							<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }} >
								<CircularProgress style={{ width: "14px", height: "14px" }} />
								Updating...
							</div>
						) : draftSaved ? (
							<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "2px" }} >
								<DoneIcon style={{ color: "green", width: "14px", height: "14px", gap: "2px" }} />
								Saved as draft
							</div>
						) : (
							<div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", gap: "2px" }} >
								<ErrorOutlineIcon style={{ color: "red", width: "14px", height: "14px", gap: "2px" }} />
								Unable to save as draft
							</div>
						)
					) : null}
				</div>

				<Paper elevation={1} className={classes.editorCont} >
					<div className={classes.onlyEditorCont} >
						<DocEditor
							description={description}
							setDescription={setDescription}
							title={title}
							setTitle={setTitle}
						/>
					</div>
				</Paper>
				<Paper className={classes.shareCont} >
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
						<Typography variant="h4" className={classes.shareTitle}>Shared</Typography>
						<div>
							<span style={{ marginRight: "15px" }} >
								{privateButton}
							</span>
							<ShareIconBtn
								open={sharedProps?.open}
								setOpen={sharedProps?.setOpen}
							/>
						</div>
					</div>
					<div>{sharedPeoples}</div>
				</Paper>
			</div>
		</div>
	);
}

export default EditProfileDoc;