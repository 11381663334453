import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "31%",
        height: "560px",
        overflow: "hidden",
        padding: "40px 30px 0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        "& img": {
            width: "100%",
            height: "auto",
        },
        "& ol": {
            marginTop: "15px",
            marginLeft: "15px"
        },
        "& li": {
            fontSize: "13px",
            color: "#777575",
            marginBottom: "5px"
        },
        [theme.breakpoints.down("md")]: {
            width: "350px",
            marginBottom: "40px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "320px",
            height: "510px",
            marginBottom: "30px",
            padding: "40px 25px 0px"
        }
    },
    textCont: {
        marginTop: "70px",
        "& h3": {
            fontSize: "27px",
            fontWeight: "800",
            textAlign: "center",
            marginBottom: "3px"
        },
        "& p": {
            fontSize: "14px",
            color: "#444242",
            fontWeight: "500",
            textAlign: "center",
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: "40px",
        }
    }
}));

export default function FCard({ photo, header, text, points }) {
    const classes = useStyles();

    return (
        <div className={classes.root} data-aos={"fade-up"} >
            <img src={photo} alt="cardImage" />
            <div className={classes.textCont} >
                <h3>{header}</h3>
                <p>{text}</p>
                <ol>
                    {points.map((point) => (<li>{point}</li>))}
                </ol>
            </div>
        </div>
    );
}