import React, { useState, useEffect } from "react";
import FreeCard from "./FreeCard";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import EfficientCard from "./EfficientCard";
import UltimateCard from "./UltimateCard";
import Switch from '@material-ui/core/Switch';
import AOS from "aos";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "white",
        padding: "130px 180px 30px",
        [theme.breakpoints.down("lg")]: {
            padding: "130px 110px 30px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "90px 80px 20px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "60px 30px 20px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "40px 20px 20px",
        },
    },
    headerCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "70px",
        "& h3": {
            fontSize: "35px",
            fontWeight: "800",
            textAlign: "center",
            marginBottom: "15px"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "50px",
            "& h3": {
                fontSize: "28px",
                fontWeight: "700",
                marginBottom: "12px"
            },
        }
    },
    cardsCont: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-around",
        flexWrap: "wrap",
    },
    switchCont: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& p": {
            margin: "0px 10px",
            fontSize: "16px",
            fontWeight: "510"
        }
    }
}));

export default function Pricing({ pricingRef }) {
    const classes = useStyles();
    const theme = useTheme()
    const [isMonthly, setIsMonthly] = useState(true)

    return (
        <section className={classes.root} ref={pricingRef} id="pricing" >
            <div className={classes.headerCont} >
                <h3 style={{ textAlign: "center" }} data-aos={"fade-up"} >Pricing & Plan</h3>
                <div className={classes.switchCont} data-aos={"fade-up"} >
                    <p style={isMonthly ? { color: theme.palette.primary.main } : { color: "black" }} >Monthly</p>
                    <Switch
                        checked={isMonthly}
                        onChange={(e) => { setIsMonthly(e.target.checked); }}
                        name="checkedA"
                        color="primary"
                    />
                    <p style={isMonthly ? { color: "black" } : { color: theme.palette.primary.main }} >Yearly (20% OFF)</p>
                </div>
            </div>

            <div className={classes.cardsCont} >
                <FreeCard isMonthly={isMonthly} />
                <EfficientCard isMonthly={isMonthly} />
                <UltimateCard isMonthly={isMonthly} />
            </div>
        </section>
    );
}