import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import useDocDetail from "./useDocDetail";
import DocFullView from "../styled/CommonComponents/Docfull.View";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    overflow: "hidden",
    padding: "20px",
    [theme.breakpoints.down('md')]: {
      padding: "10px",
    },
  }
}));

export default function DocView() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { doc } = useDocDetail();

  return (
    <div className={classes.root}>
      {doc && (
        <DocFullView doc={doc} />
      )}
    </div>
  );
}
