import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import TeamActivities from "../activity/team.activity.timeline";
import styled, { css } from "styled-components";
import { IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ProjectDashboard from "./Project.Dashboard";
import OrgDashboard from "./Org.Dashboard";

const ActivitySidebar = styled.aside`
	${({ open }) => css`
		position: absolute;
		top: 0;
		right: ${open ? "0px" : "-100%"};
		bottom: 0;
		display: flex;
		flex-direction: column;
		background: #fff;
		max-height: 100%;
		min-width: 300px;
		overflow: auto;
		box-shadow: -10px 0px 20px 0px #00000030;
		transition: all 0.5s;
		.sidebar-title {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 1rem 0.5rem 1rem 1.5rem;
			font-size: 1.5rem;
			font-weight: bold;
		}
	`}
`;

export default function TeamHome({
	activitySidebar,
	setActivitySidebar,
	reports,
	loader,
	docs,
	setUnits,
	units,
	chartAccounts,
	teamId,
	transactions,
	issues,
	walletId,
	profileId,
	parent,
	parentModelName
}) {

	return (
		<>
			{parentModelName=='Project'?<ProjectDashboard
				reports={reports}
				loader={loader}
				docs={docs}
				chartAccounts={chartAccounts}
				transactions={transactions}
				issues={issues}
				units={units}
				setUnits={setUnits}
				walletId={walletId}
				profileId={profileId}
				teamId={teamId}
			/>:	<OrgDashboard
			reports={reports}
			loader={loader}
			docs={docs}
			chartAccounts={chartAccounts}
			transactions={transactions}
			issues={issues}
			units={units}
			setUnits={setUnits}
			walletId={walletId}
			profileId={profileId}
			teamId={teamId}
		/>}

			<ActivitySidebar style={{ zIndex: "99999" }} open={activitySidebar}>
				<div className="sidebar-title">
					<div>Activities</div>
					<IconButton>
						<CloseIcon onClick={() => setActivitySidebar(false)} />
					</IconButton>
				</div>
				<TeamActivities
					parent={parent}
					parentModelName={parentModelName}
				/>
			</ActivitySidebar>
		</>
	);
}
