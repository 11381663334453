import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import NormalDialog from '../../styled/CommonComponents/NormalDialog';
import LessText from '../../styled/CommonComponents/LessText';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "380px",
        maxHeight: "320px",
        padding: "10px 5px",
        overflowY: "auto",
        overflowX: "hidden",
        [theme.breakpoints.down("xs")]: {
            width: "230px",
        }
    },
    btnCont: {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        marginTop: "15px"
    },
    nameAvatarCont: {
        display: "flex",
        alignItems: "flex-start",
    },
    flexShow: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        marginBottom: "15px",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column",
            justifyContent: "center",
            marginBottom: "0px",
        }
    },
    leftSide: {
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "15px",
        }
    },
    rigthSide: {
        width: "140px",
        "& p": {
            fontSize: "15px",
            fontWeight: "500",
            color: "gray"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "510"
        },
        [theme.breakpoints.down("xs")]: {
            width: "100%",
            marginBottom: "15px",
        }
    }
}));

export default function TxDetailsDialog({
    walletId, openDetails, setOpenDetails, selectedTx,
    editClickUrl, viewClickUrl, setSelectedTx
}) {
    const classes = useStyles();
    const history = useHistory();

    function numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    const viewOrEdit = () => {
        let path = ""
        if (walletId == selectedTx?.firstPartyWallet) {
            path = `${editClickUrl}${selectedTx?._id}`
        } else if (walletId == selectedTx?.secondPartyWallet) {
            path = `${viewClickUrl}${selectedTx?._id}`
        }
        history.push(path);
    }

    console.log(selectedTx)

    return (
        <NormalDialog
            openDialog={openDetails}
            handleCloseShare={() => {
                setOpenDetails(false);
                setSelectedTx(null)
            }}
            pageTitle={"Transaction Details"}
            content={<div>
                <div className={classes.mainCont} >
                    <div className={classes.flexShow} >
                        <div className={classes.leftSide} >
                            <p>
                                {walletId === selectedTx?.firstPartyWallet ? (<>
                                    {selectedTx?.type === "Bill" && (<>Vendor</>)}
                                    {selectedTx?.type === "Invoice" && (<>Recipient</>)}
                                </>) : (<>
                                    {selectedTx?.type === "Bill" && (<>Customer</>)}
                                    {selectedTx?.type === "Invoice" && (<>Vendor</>)}
                                </>)}
                            </p>
                            <div className={classes.nameAvatarCont} >
                                {walletId === selectedTx?.firstPartyWallet ? (<>
                                    <Avatar
                                        alt={selectedTx?.secondParty?.parent?.displayName}
                                        src={selectedTx?.secondParty?.parent?.displayPicture?.url}
                                        style={{ width: "25px", height: "25px", marginRight: "5px" }}
                                    />
                                    <h3>
                                        <LessText
                                            limit={15}
                                            string={selectedTx?.secondParty?.parent?.displayName}
                                        />
                                    </h3>
                                </>) : (<>
                                    <Avatar
                                        alt={selectedTx?.firstParty?.parent?.displayName}
                                        src={selectedTx?.firstParty?.parent?.displayPicture?.url}
                                        style={{ width: "25px", height: "25px", marginRight: "5px" }}
                                    />
                                    <h3>
                                        <LessText
                                            limit={15}
                                            string={selectedTx?.firstParty?.parent?.displayName}
                                        />
                                    </h3>
                                </>)}
                            </div>
                        </div>
                        <div className={classes.rigthSide} >
                            <p>Status</p>
                            <h3>{selectedTx?.status}</h3>
                        </div>
                    </div>
                    
                    <div className={classes.flexShow} >
                        <div className={classes.leftSide} >
                            <p>Amount</p>
                            <h3>$ {numberWithCommas(selectedTx?.finalAmount || 0)}</h3>
                        </div>
                        <div className={classes.rigthSide} >
                            <p>Amount paid</p>
                            <h3>$ {numberWithCommas(selectedTx?.amountPaid || 0)}</h3>
                        </div>
                    </div>

                    {selectedTx?.lateFeeApplicable && (
                        <div className={classes.flexShow} >
                            <div className={classes.leftSide} >
                                <p>Due date</p>
                                <h3>{moment(selectedTx?.dueDate).format('MMM Do YYYY, h:mm a')}</h3>
                            </div>
                            <div className={classes.rigthSide} >
                                <p>Latefess</p>
                                <h3>$ {numberWithCommas(selectedTx?.lateFeeAmount || 0)}</h3>
                            </div>
                        </div>
                    )}

                    <div className={classes.flexShow} >
                        <div className={classes.leftSide} >
                            <p>Created at</p>
                            <h3>{moment(selectedTx?.createdAt).format('MMM Do YYYY, h:mm a')}</h3>
                        </div>
                        <div className={classes.rigthSide} >
                            {selectedTx?.creatorProfile?.parent?.displayName && (<>
                                <p>Created by</p>
                                <h3>
                                    <LessText
                                        limit={15}
                                        string={selectedTx?.creatorProfile?.parent?.displayName}
                                    />
                                </h3>
                            </>)}
                        </div>
                    </div>

                    {selectedTx?.memo && (
                        <div className={classes.leftSide} >
                            <p>Memo</p>
                            <h3>{selectedTx?.memo}</h3>
                        </div>
                    )}
                </div>

                <div className={classes.btnCont} >
                    <Button
                        variant="contained"
                        color="secondary"
                        size="small"
                        disabled
                    >
                        Delete
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        style={{ marginLeft: "10px" }}
                        onClick={viewOrEdit}
                    >
                        {selectedTx?.status === "Partially Paid" || selectedTx?.status === "Processing" || selectedTx?.status === "Failed" || selectedTx?.status === "Paid" ? (<>
                            View
                        </>) : (<>
                            {walletId === selectedTx?.firstPartyWallet ? (<>
                                Edit
                            </>) : (<>
                                View
                            </>)}
                        </>)}
                    </Button>
                </div>
            </div>}
        />
    );
}