import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import PlaidLogo from '../../../../../Assets/plaidLogo.svg';
import PlaidBankLinkHelper from './plaid.bank.link.helper';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "430px",
    padding: "15px 20px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    borderRadius: "15px",
    [theme.breakpoints.only("xs")]: {
      width: "90%",
      padding: "10px 15px",
      borderRadius: "10px",
    }
  },
  cardHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    "& img": {
      height: "30px"
    },
    "& h3": {
      fontSize: "25px",
      marginLeft: "10px"
    },
    [theme.breakpoints.only("xs")]: {
      "& img": {
        height: "25px"
      },
      "& h3": {
        fontSize: "20px",
        marginLeft: "10px"
      },
    }
  },
  bankText: {
    color: "#407bff",
    fontSize: "16px",
    fontWeight: "400",
    fontFamily: `'Poppins', sans-serif`,
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    }
  },
  paraText: {
    fontSize: "16px",
    color: "#263238",
    fontWeight: "400",
    fontFamily: `'Poppins', sans-serif`,
    textAlign: "left",
    [theme.breakpoints.only("xs")]: {
      fontSize: "14px",
    }
  },
}));


export default function PlaidLinkBtn(props) {
  const classes = useStyles();

  return (
    <PlaidBankLinkHelper {...props}>
      <Paper className={classes.paperStyle}>
        <div className={classes.cardHeader}>
          <img src={PlaidLogo} />
          <h3>Plaid</h3>
        </div>

        <p className={classes.bankText} >Click here to add bank account</p>
        <p className={classes.paraText}>
          We use Plaid to access your bank account. Please note each connected bank account costs <b>$1.5</b> one time, and then <b>30c</b> per month.
        </p>
      </Paper>
    </PlaidBankLinkHelper>
  );
}