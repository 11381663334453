const { default: Api } = require("../../helpers/Api");


export const getTopCategories = async (obj) => {
    try {
        const res = await Api.post("get/categories/top", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getCategoriesByName = async (obj) => {
    try {
        const res = await Api.post("category/getCats", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};