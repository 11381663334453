import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import BankAccountSvg from "../../Assets/bank-account.svg";
import StatementSvg from "../../Assets/accounting.svg";
import ServiceSvg from "../../Assets/service.svg";
import InvoiceSvg from "../../Assets/invoice.svg";
import BillSvg from "../../Assets/bill.svg";
import NetworkSvg from "../../Assets/networkNav.svg";
import settingSvg from "../../Assets/settingIcon.svg"
import TransactionSvg from "../../Assets/transactions.svg";
import AddNewTxDialog from "../finance2o/commonComponent/transaction/AddNewTxDialog";
import { useFindWallet, useGetWallet } from "../finance/hooks";
import LessText from "../styled/CommonComponents/LessText";
import AddIcon from '@material-ui/icons/Add';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Collapse from '@material-ui/core/Collapse';
import Skeleton from '@material-ui/lab/Skeleton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer";
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        fontFamily: `'Poppins', sans-serif`,
        justifyContent: "space-between",
        alignItems: "flex-start"
    },
    sideBar: {
        width: "250px",
        height: "100%",
        padding: "25px 15px 15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        borderRight: "1px solid #dfdfdf",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down('sm')]: {
            display: "none"
        }
    },
    mainCont: {
        width: "calc(100% - 250px)",
        height: "100%",
        [theme.breakpoints.down('sm')]: {
            width: "100%",
        }
    },
    headerSty: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #dfdfdf",
        "& h3": {
            fontSize: "20px",
            marginLeft: "15px"
        },
        [theme.breakpoints.down('sm')]: {
            justifyContent: "space-between",
            height: "40px",
            "& h3": {
                fontSize: "17px",
                marginLeft: "0px"
            },
        }
    },
    bodyCont: {
        width: "100%",
        height: "calc(100% - 50px)",
        overflow: "hidden",
        [theme.breakpoints.down('sm')]: {
            height: "calc(100% - 40px)",
        }
    },
    noAppBarBodyCont: {
        width: "100%",
        height: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    hamburgerCont: {
        width: "50px",
        height: "100%",
    },
    topLogoCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        cursor: "pointer",
        "& h3": {
            fontSize: "20px",
            fontWeight: "510",
            marginLeft: "8px",
            transform: "scale(1, 1.1)",
        },
        "& img": {
            height: "30px",
            width: "auto"
        }
    },
    addNewBtn: {
        width: "100%",
        fontSize: "16px",
        fontWeight: "510",
        borderRadius: "20px",
        padding: "5px 0px",
        display: "flex",
        marginBottom: "20px",
        alignItems: "center",
        cursor: "pointer",
        justifyContent: "center",
        backgroundColor: "#00c853",
        color: "white",
        border: "none",
        "&:hover": {
            backgroundColor: "#02b94e"
        }
    },
    mainOptionCont: {
        marginBottom: "3px"
    },
    singleMainOptionCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        color: "#4a4a4a",
        cursor: "pointer",
        padding: "6px 0px"
    },
    mainIconTitleCont: {
        display: "flex",
        alignItems: "center",
        "& h3": {
            fontSize: "14px",
            marginLeft: "10px",
            fontWeight: "500"
        }
    },
    subIconTitleCont: {
        display: "flex",
        alignItems: "center",
        padding: "3px 0px",
        "& h3": {
            fontSize: "13px",
            marginLeft: "3px",
            fontWeight: "450",
        }
    },
    mainOptionIcon: {
        height: "21px"
    },
    subOptionIcon: {
        fontSize: "18px"
    },
    arrowIcon: {
        fontSize: "22px",
        opacity: "0.5"
    },
    subOptionCont: {
        paddingLeft: "28px",
        marginTop: "2px",
        marginBottom: "10px"
    },
    singleSubOptionCont: {
        width: "100%",
        cursor: "pointer",
        color: "gray",
        "&:hover": {
            backgroundColor: "#f2f0f0"
        }
    },
    blockTextStyle: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginTop: "15px",
        cursor: "pointer",
    },
    nameEmailCont: {
        marginLeft: "10px",
        "& h3": {
            fontSize: "16px",
            fontWeight: "510"
        },
        "& p": {
            fontSize: "12px",
            fontWeight: "400",
            color: "gray"
        }
    },
    avatarSty: {
        width: "35px",
        height: "35px",
    },
    infoCont: {
        borderTop: "1px solid #dedcdc",
        marginTop: "30px"
    },
    btnCont: {
        padding: "0px 25px"
    },
    drawerPaper: {
        width: "240px",
        height: "100vh",
        paddingTop: "20px",
        padding: "25px 15px 15px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none"
    },
    loaderCont: {
        position: 'fixed',
        top: "0px",
        right: "0px",
        width: "100vw",
        height: "100vh",
        zIndex: "1000",
        backgroundColor: "rgba(0, 0, 0, 0.7)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        [theme.breakpoints.down('xs')]: {
            paddingLeft: "0px",
        },
    },
}));

export default function AccountingPrivate(props) {
    const classes = useStyles();
    const walletId = useFindWallet();
    const theme = useTheme();
    const dispatch = useDispatch();
    const history = useHistory();

    const { noTopBar, header, curOption, curSubOption, } = props
    const { wallet } = useGetWallet(walletId);
    const { user } = useSelector((state) => state.auth);
    const { financeLoading } = useSelector((state) => state.wallet);

    const [mobileOpen, setMobileOpen] = useState(false);
    const [openAccounting, setOpenAccounting] = useState(false)
    const [openFinancialStatements, setOpenFinancialStatements] = useState(false)
    const [openNetworks, setOpenNetworks] = useState(false)
    const [openSalesInvoices, setOpenSalesInvoices] = useState(false)
    const [openExpenseBills, setOpenExpenseBills] = useState(false)

    const isMdSmall = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        dispatch({
            type: 'AddWallet',
            payload: {
                financeLoading: false
            }
        })
    }, [])

    const onClickTitle = () => {
        let type = wallet?.parentModelName == "User" ? "User" : wallet?.parent?.parentModelName;
        let path = "/";

        switch (type) {
            case "Project":
                path = "/projects/" + wallet?.parent?._id;
                history.push(path);
                break;

            case "Organization":
                path = "/organizations/" + wallet?.parent?._id;
                history.push(path);
                break;

            case "User":
                path = "/";
                history.push(path);
                break;
        }
    };

    useEffect(() => {
        if (curOption === "account") {
            setOpenAccounting(true)
        } else if (curOption === "financialStatements") {
            setOpenFinancialStatements(true)
        } else if (curOption === "networks") {
            setOpenNetworks(true)
        } else if (curOption === "invoicesSales") {
            setOpenSalesInvoices(true)
        } else if (curOption === "billExpenses") {
            setOpenExpenseBills(true)
        }
    }, [curOption, curSubOption])

    const sideBarCont = <>
        <div>
            <div
                className={classes.topLogoCont}
                onClick={() => { history.push("/") }}
            >
                <img src={require("../../Assets/LogoV3.svg")} />
                <h3>ContractFlo</h3>
            </div>
            <div className={classes.btnCont} >
                <AddNewTxDialog
                    btnComp={
                        <button className={classes.addNewBtn} >
                            <AddIcon style={{ marginRight: "5px", fontSize: "20px" }} /> <span>Add New</span>
                        </button>
                    }
                    walletId={walletId}
                />
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { setOpenAccounting(!openAccounting) }}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={BankAccountSvg} className={classes.mainOptionIcon} />
                        <h3>Accounts</h3>
                    </div>
                    {openAccounting ? (
                        <MdKeyboardArrowUp className={classes.arrowIcon} />
                    ) : (
                        <MdKeyboardArrowDown className={classes.arrowIcon} />
                    )}
                </div>
                <Collapse in={openAccounting} collapsedSize={0}>
                    <div className={classes.subOptionCont} >
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/bank-accounts`) }}
                            style={curSubOption === "bankAccounts" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Bank Accounts</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/payment-methods`) }}
                            style={curSubOption === "paymentMethods" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Payment Methods</h3>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { history.push(`/finance/${walletId}/bank-transactions`) }}
                    style={curOption === "bankTransactions" ? { color: theme.palette.primary.main } : {}}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={TransactionSvg} className={classes.mainOptionIcon} />
                        <h3>Transactions</h3>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { setOpenFinancialStatements(!openFinancialStatements) }}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={StatementSvg} className={classes.mainOptionIcon} />
                        <h3>Financial Statements</h3>
                    </div>
                    {openFinancialStatements ? (
                        <MdKeyboardArrowUp className={classes.arrowIcon} />
                    ) : (
                        <MdKeyboardArrowDown className={classes.arrowIcon} />
                    )}
                </div>
                <Collapse in={openFinancialStatements} collapsedSize={0}>
                    <div className={classes.subOptionCont} >
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/accounting-reports`) }}
                            style={curSubOption === "reports" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Reports</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/chart-accounts`) }}
                            style={curSubOption === "chartAccounts" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Chart of Accounts</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/journal-entry`) }}
                            style={curSubOption === "journalEntry" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>journal Entry</h3>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { setOpenNetworks(!openNetworks) }}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={NetworkSvg} className={classes.mainOptionIcon} />
                        <h3>Networks</h3>
                    </div>
                    {openNetworks ? (
                        <MdKeyboardArrowUp className={classes.arrowIcon} />
                    ) : (
                        <MdKeyboardArrowDown className={classes.arrowIcon} />
                    )}
                </div>
                <Collapse in={openNetworks} collapsedSize={0}>
                    <div className={classes.subOptionCont} >
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/network-customers`) }}
                            style={curSubOption === "customer" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Customers</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/network-employees`) }}
                            style={curSubOption === "employees" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Employees</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/network-contractors`) }}
                            style={curSubOption === "contractors" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Contractors</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/network-vendors`) }}
                            style={curSubOption === "vendors" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Vendors</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/network-investors`) }}
                            style={curSubOption === "investors" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Investors</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/network-lenders`) }}
                            style={curSubOption === "lenders" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Lenders</h3>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { history.push(`/finance/${walletId}/products-services`) }}
                    style={curOption === "productsServices" ? { color: theme.palette.primary.main } : {}}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={ServiceSvg} className={classes.mainOptionIcon} />
                        <h3>Products & Services</h3>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { setOpenSalesInvoices(!openSalesInvoices) }}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={InvoiceSvg} className={classes.mainOptionIcon} />
                        <h3>Sales & Invoices</h3>
                    </div>
                    {openSalesInvoices ? (
                        <MdKeyboardArrowUp className={classes.arrowIcon} />
                    ) : (
                        <MdKeyboardArrowDown className={classes.arrowIcon} />
                    )}
                </div>
                <Collapse in={openSalesInvoices} collapsedSize={0}>
                    <div className={classes.subOptionCont} >
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/all-Sales`) }}
                            style={curSubOption === "allSales" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>All Sales</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/invoices`) }}
                            style={curSubOption === "invoices" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Invoices</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/invoice-generators`) }}
                            style={curSubOption === "invoiceGenerators" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Generator</h3>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { setOpenExpenseBills(!openExpenseBills) }}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={BillSvg} className={classes.mainOptionIcon} />
                        <h3>Expense & Bills</h3>
                    </div>
                    {openExpenseBills ? (
                        <MdKeyboardArrowUp className={classes.arrowIcon} />
                    ) : (
                        <MdKeyboardArrowDown className={classes.arrowIcon} />
                    )}
                </div>
                <Collapse in={openExpenseBills} collapsedSize={0}>
                    <div className={classes.subOptionCont} >
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/all-expenses`) }}
                            style={curSubOption === "allExpenses" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>All Expense</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/bills`) }}
                            style={curSubOption === "bills" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Bills</h3>
                            </div>
                        </div>
                        <div
                            className={classes.singleSubOptionCont}
                            onClick={() => { history.push(`/finance/${walletId}/bill-generators`) }}
                            style={curSubOption === "billGenerators" ? { color: theme.palette.primary.main } : {}}
                        >
                            <div className={classes.subIconTitleCont} >
                                <h3>Generator</h3>
                            </div>
                        </div>
                    </div>
                </Collapse>
            </div>
            <div className={classes.mainOptionCont} >
                <div
                    className={classes.singleMainOptionCont}
                    onClick={() => { history.push(`/finance/${walletId}/settings`) }}
                    style={curOption === "settings" ? { color: theme.palette.primary.main } : {}}
                >
                    <div className={classes.mainIconTitleCont} >
                        <img src={settingSvg} className={classes.mainOptionIcon} />
                        <h3>Settings</h3>
                    </div>
                    <div></div>
                </div>
            </div>
        </div>
        <div className={classes.infoCont} >
            {wallet?.parentModelName == "User" ? (
                <div
                    className={classes.blockTextStyle}
                    onClick={onClickTitle}
                >
                    {user?.displayPicture?.thumbUrl || user?.displayPicture?.url ? (
                        <Avatar
                            src={user?.displayPicture?.thumbUrl || user?.displayPicture?.url}
                            className={classes.avatarSty}
                        />
                    ) : (
                        <Skeleton variant="circle" width={40} height={40} />
                    )}
                    <div className={classes.nameEmailCont} >
                        {user?.displayName || user?.email ? (<>
                            <h3>
                                <LessText limit={15} string={user?.displayName} />
                            </h3>
                            <p>
                                <LessText limit={20} string={user?.email} />
                            </p>
                        </>) : (<>
                            <Skeleton variant="text" style={{ width: "170px", height: "30px" }} />
                            <Skeleton variant="text" style={{ width: "150px", height: "15px", marginTop: "-6px" }} />
                        </>)}
                    </div>
                </div>
            ) : (
                <div
                    className={classes.blockTextStyle}
                    onClick={onClickTitle}
                >
                    {wallet?.parent?.parent?.displayPicture?.thumbUrl || wallet?.parent?.parent?.displayPicture?.url ? (
                        <Avatar
                            src={wallet?.parent?.parent?.displayPicture?.thumbUrl || wallet?.parent?.parent?.displayPicture?.url}
                            className={classes.avatarSty}
                        />
                    ) : (
                        <Skeleton variant="circle" width={40} height={40} />
                    )}
                    <div className={classes.nameEmailCont} >
                        {wallet?.parent?.parent?.displayName || wallet?.parent?.parent?.email ? (<>
                            <h3>
                                <LessText limit={15} string={wallet?.parent?.parent?.displayName} />
                            </h3>
                            <p>
                                <LessText limit={20} string={wallet?.parent?.parent?.email} />
                            </p>
                        </>) : (<>
                            <Skeleton variant="text" style={{ width: "170px", height: "30px" }} />
                            <Skeleton variant="text" style={{ width: "150px", height: "15px", marginTop: "-6px" }} />
                        </>)}
                    </div>
                </div>
            )}
        </div>
    </>


    return (
        <div className={classes.root}>
            <div className={classes.sideBar} >
                {sideBarCont}
            </div>

            <Drawer
                variant="temporary"
                anchor={"left"}
                open={mobileOpen}
                onClose={() => { setMobileOpen(false) }}
                classes={{ paper: classes.drawerPaper }}
                ModalProps={{ keepMounted: true }}
            >
                {sideBarCont}
            </Drawer>

            <div className={classes.mainCont} >
                {noTopBar ? (
                    <div className={classes.noAppBarBodyCont} >
                        {props.children}
                    </div>
                ) : (<>
                    <div className={classes.headerSty} >
                        {isMdSmall && (
                            <div className={classes.hamburgerCont} >
                                <IconButton
                                    style={{ padding: "7px" }}
                                    onClick={() => { setMobileOpen(!mobileOpen) }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </div>
                        )}
                        <h3>{header}</h3>
                        <div className={classes.hamburgerCont} ></div>
                    </div>
                    <div className={classes.bodyCont} >
                        {props.children}
                    </div>
                </>)}
            </div>
{/* 
            {financeLoading &&
                <div className={classes.loaderCont} >
                    <CircularProgress
                        size={60}
                        thickness={3}
                        style={{ color: 'rgb(92, 144, 242)' }}
                    />
                </div>
            } */}
        </div>
    );
}
