import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { Button, IconButton, Checkbox, useMediaQuery } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from "@material-ui/core/TextField";
import AddIcon from '@material-ui/icons/Add';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DeleteIcon from '@material-ui/icons/Delete';
import { countriesStateMap } from "../../styled/countryState"
import { updateUserInfo } from '../api';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles((theme) => ({
    header: {
        height: "60px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "1px solid #e4e7e7",
        padding: "0px 25px",
        "& h3": {
            fontSize: "17px",
            fontWeight: "450",
            color: "#170f49"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "0px 10px",
        }
    },
    body: {
        width: "100%",
        height: "calc(100% - 65px)",
        overflowY: "auto",
        padding: "20px 40px 40px",
        paddingRight: "0px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down("md")]: {
            padding: "15px 10px 30px",
        }
    },
    singleEducationCont: {
        width: "700px",
        marginBottom: "20px",
        [theme.breakpoints.down("md")]: {
            width: "100%",
        },
    },
    textLineCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down("xs")]: {
            flexDirection: "column"
        }
    },
    halfCont: {
        width: "47%",
        [theme.breakpoints.down("xs")]: {
            width: "100%",
        }
    },
    checkedCont: {
        display: "flex",
        alignItems: "center",
        marginTop: "10px",
        marginBottom: "-10px",
        "& h3": {
            fontSize: "15px",
            marginRight: "15px",
            fontWeight: "500",
        }
    },
    label: {
        fontSize: "15px",
        fontWeight: "500",
        marginTop: "15px",
        marginBottom: "2px"
    }
}));

const employmentTypes = [
    "Full-time",
    "Part-time",
    "Self-Employed",
    "Freelance",
    "Internship",
    "Trainee",
];

const calendarMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export default function ExperienceEdit({ setOpenEditView }) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();
    const state = useSelector((state) => state);
    const { user } = useSelector((state) => state.auth);

    const [stateChange, setStateChange] = useState(false)
    const [calendarYear, setCalendarYear] = useState([]);
    const [experienceObjArr, setExperienceObjArr] = useState([])
    const [locationStrArr, setLocationStrArr] = useState([])
    const [loading, setLoading] = useState(false)

    const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

    useEffect(() => {
        let allCountries = Object.keys(countriesStateMap)
        let objArr = []
        allCountries.map((country) => {
            const statsArr = countriesStateMap?.[country]
            objArr.push(country)

            statsArr.map((s) => {
                objArr.push(`${s}, ${country}`)
            })
        })
        setLocationStrArr(objArr)
    }, [])


    useEffect(() => {
        let localExperience = user?.experience || []
        if (localExperience && localExperience.length > 0) {
            let arr = []
            localExperience.map((experience) => {
                let loc_startMonth
                let loc_startYear
                let loc_endMonth
                let loc_endYear

                let locStartDate = experience?.start_date || ""
                if (locStartDate && locStartDate.length > 3) {
                    loc_startMonth = locStartDate.split("-")[0]
                    loc_startYear = locStartDate.split("-")[1]
                } else {
                    loc_startMonth = ""
                    loc_startYear = ""
                }

                let locEndDate = experience?.end_date || ""
                if (locEndDate && locEndDate.length > 3) {
                    loc_endMonth = locEndDate.split("-")[0]
                    loc_endYear = locEndDate.split("-")[1]
                } else {
                    loc_endMonth = ""
                    loc_endYear = ""
                }

                arr.push({
                    designation: experience?.title || "",
                    employmentType: experience?.employmentType || "",
                    companyName: experience?.companyName || "",
                    Location: experience?.location || "",
                    isCurrentlyWorking: experience?.isCurrentlyWorking || "",
                    startMonth: loc_startMonth,
                    startYear: loc_startYear,
                    endMonth: loc_endMonth,
                    endYear: loc_endYear,
                    description: experience?.description || "",
                })
            })

            setExperienceObjArr(arr)
        } else {
            setExperienceObjArr([{
                designation: "",
                employmentType: "",
                companyName: "",
                Location: "",
                isCurrentlyWorking: false,
                startMonth: null,
                startYear: null,
                endMonth: null,
                endYear: null,
                description: "",
            }])
        }
    }, [user, user?.experience])

    useEffect(() => {
        const years = [];
        const date = new Date();
        const endYear = date.getFullYear();
        for (let i = 1951; i <= endYear; i++) {
            years.push(i + "");
        }
        setCalendarYear(years);
    }, []);

    const addNewExperience = () => {
        setExperienceObjArr([
            ...experienceObjArr,
            {
                designation: "",
                employmentType: "",
                companyName: "",
                Location: "",
                isCurrentlyWorking: false,
                startMonth: null,
                startYear: null,
                endMonth: null,
                endYear: null,
                description: "",
            }])
        setStateChange(!stateChange)
    }

    const onChangeValue = (value, i, key) => {
        let arr = [...experienceObjArr]
        arr[i][key] = value
        setExperienceObjArr(arr)
        setStateChange(!stateChange)
    }

    const deleteLine = (i) => {
        let arr = [...experienceObjArr]
        arr.splice(i, 1)
        setExperienceObjArr(arr)
        setStateChange(!stateChange)
    }

    const updateProfileSection = async () => {
        setLoading(true)
        let objArr = []
        experienceObjArr.map((experienceData) => {
            objArr.push({
                title: experienceData?.designation || "",
                employmentType: experienceData?.employmentType || "",
                companyName: experienceData?.companyName || "",
                location: experienceData?.Location || "",
                start_date: `${experienceData?.startMonth}-${experienceData?.startYear}`,
                isCurrentlyWorking: experienceData?.isCurrentlyWorking || false,
                end_date: `${experienceData?.endMonth}-${experienceData?.endYear}`,
                description: experienceData?.description || "",
            })
        })

        const userObj = {
            experience: objArr,
        };

        console.log(userObj);

        await updateUserInfo({
            userId: user?._id,
            userInfo: userObj,
        })
            .then((data) => {

                console.log(data);

                dispatch({
                    type: "AddAuth",
                    payload: {
                        user: {
                            ...user,
                            ...userObj,
                        },
                    },
                });
                setLoading(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <>
            <div className={classes.header} >
                <h3>{isSmall ? "Experience" : "Edit Experience"}</h3>
                <div>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<AddIcon />}
                        onClick={addNewExperience}
                        size={isSmall ? "small" : "medium"}
                    >
                        Add
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<EditIcon />}
                        style={{ marginLeft: "10px" }}
                        onClick={updateProfileSection}
                        size={isSmall ? "small" : "medium"}
                    >
                        Save
                    </Button>
                    <IconButton
                        style={{ marginLeft: "10px" }}
                        onClick={() => { setOpenEditView(false) }}
                        size={isSmall ? "small" : "medium"}
                    >
                        <ClearIcon />
                    </IconButton>
                </div>
            </div>
            <div style={{ height: "5px" }} >
                {loading && (<LinearProgress />)}
            </div>
            <div className={classes.body} >
                {experienceObjArr && experienceObjArr.length > 0 && experienceObjArr.map((experienceObj, i) => (
                    <div className={classes.singleEducationCont} >
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Designation</p>
                                <TextField
                                    required
                                    id="school"
                                    placeholder="Enter designation"
                                    value={experienceObj?.designation}
                                    onChange={(e) => {
                                        onChangeValue(e.target.value, i, "designation")
                                    }}
                                    size="small"
                                    variant="outlined"
                                    style={{ width: "100%" }}
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Employment Type</p>
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={experienceObj?.employmentType}
                                    options={employmentTypes}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option == experienceObj?.employmentType
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter Employment Type"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "employmentType");
                                    }}
                                    style={{ width: "100%" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Company Name</p>
                                <TextField
                                    id="field"
                                    placeholder="Enter company name"
                                    value={experienceObj?.companyName}
                                    onChange={(e) => {
                                        onChangeValue(e.target.value, i, "companyName");
                                    }}
                                    size="small"
                                    variant="outlined"
                                    fullWidth
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <p className={classes.label} >Location</p>
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={experienceObj?.Location}
                                    options={locationStrArr}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option == experienceObj?.Location
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter Location"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "Location");
                                    }}
                                    style={{ width: "100%" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <p className={classes.label} >Start date</p>
                        <div className={classes.textLineCont} >
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={experienceObj?.startMonth}
                                    options={calendarMonth}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === experienceObj?.startMonth
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter Start Month"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "startMonth")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                            <div className={classes.halfCont} >
                                <Autocomplete
                                    id="free-solo-demo"
                                    freeSolo
                                    value={experienceObj?.startYear}
                                    options={calendarYear}
                                    getOptionLabel={(option) => { return option }}
                                    getOptionSelected={(option) => {
                                        return option === experienceObj?.startYear
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            style={{ margin: "0px" }}
                                            margin="normal"
                                            variant="outlined"
                                            placeholder={"Enter Start Year"}
                                        />
                                    )}
                                    onChange={(event, value) => {
                                        onChangeValue(value, i, "startYear")
                                    }}
                                    style={{ width: "100%", marginBottom: "5px" }}
                                    size="small"
                                />
                            </div>
                        </div>
                        <div className={classes.checkedCont} >
                            <h3>Currently Working</h3>
                            <Checkbox
                                color="primary"
                                checked={experienceObj?.isCurrentlyWorking}
                                onChange={(e) => { onChangeValue(e.target.checked, i, "isCurrentlyWorking") }}
                            />
                        </div>
                        {experienceObj?.isCurrentlyWorking ? null : (<>
                            <p className={classes.label} >End date</p>
                            <div className={classes.textLineCont} >
                                <div className={classes.halfCont} >
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={experienceObj?.endMonth}
                                        options={calendarMonth}
                                        getOptionLabel={(option) => { return option }}
                                        getOptionSelected={(option) => {
                                            return option === experienceObj?.endMonth
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ margin: "0px" }}
                                                margin="normal"
                                                variant="outlined"
                                                placeholder={"Enter End Month"}
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            onChangeValue(value, i, "endMonth")
                                        }}
                                        style={{ width: "100%", marginBottom: "5px" }}
                                        size="small"
                                    />
                                </div>
                                <div className={classes.halfCont} >
                                    <Autocomplete
                                        id="free-solo-demo"
                                        freeSolo
                                        value={experienceObj?.endYear}
                                        options={calendarYear}
                                        getOptionLabel={(option) => { return option }}
                                        getOptionSelected={(option) => {
                                            return option === experienceObj?.endYear
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                style={{ margin: "0px" }}
                                                margin="normal"
                                                variant="outlined"
                                                placeholder={"Enter End Year"}
                                            />
                                        )}
                                        onChange={(event, value) => {
                                            onChangeValue(value, i, "endYear")
                                        }}
                                        style={{ width: "100%", marginBottom: "5px" }}
                                        size="small"
                                    />
                                </div>
                            </div>
                        </>)}
                        <p className={classes.label} >Description</p>
                        <TextField
                            id="description"
                            placeholder="Write anything notable..."
                            multiline
                            rows={3}
                            variant="outlined"
                            size="small"
                            value={experienceObj?.description}
                            onChange={(e) => {
                                onChangeValue(e.target.value, i, "description");
                            }}
                            fullWidth
                        />
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "15px 0px 30px" }} >
                            <div></div>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<DeleteIcon />}
                                onClick={() => { deleteLine(i) }}
                                size={isSmall ? "small" : "medium"}
                            >
                                Delete
                            </Button>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}