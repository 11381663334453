import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: theme.palette.primary.main,
        padding: "80px 180px",
        marginTop: "60px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        "& h3": {
            width: "80%",
            fontSize: "35px",
            color: "white",
            textAlign: "center",
            marginBottom: "20px"
        },
        [theme.breakpoints.down("lg")]: {
            padding: "80px 110px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "70px 80px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "40px 40px",
            "& h3": {
                width: "90%",
                fontSize: "28px",
            },
        },
        [theme.breakpoints.down("xs")]: {
            padding: "30px 20px",
            "& h3": {
                width: "95%",
                fontSize: "20px",
            },
        },
    },
    whiteBtn: {
        backgroundColor: "white",
        padding: "8px 25px",
        "&:hover": {
            backgroundColor: "#ececec"
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            color: theme.palette.primary.main,
            fontSize: "16px",
            fontWeight: "700"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "3px 15px",
            "& .MuiButton-label": {
                fontSize: "14px",
                fontWeight: "600"
            },
        }
    }
}));

export default function SignupBar({ signupBarRef }) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <section className={classes.root} ref={signupBarRef} id="signupBar" >
            <h3 data-aos={"fade-right"} >Take control of your real estate investments and projects with ContractFlo</h3>
            <Button
                className={classes.whiteBtn}
                data-aos={"fade-left"}
                onClick={() => {
                    const data = { type: "signup" }
                    const searchParams = new URLSearchParams(data)
                    const url = "signup/?" + searchParams;
                    history.push(url)
                }}
            >Sign up for Free</Button>
        </section>
    );
}