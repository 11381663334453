import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { makeStyles } from "@material-ui/core/styles";
import { format } from "timeago.js";
import ApartmentIcon from "@material-ui/icons/Apartment";
import LessText from "../styled/CommonComponents/LessText";
import { Avatar } from "@material-ui/core";
import { TbCrane } from "react-icons/tb";
import Api from "../../helpers/Api";
import teamUtils from "../team/team.utils";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		height: "225px",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	btnCont: {
		display: "flex",
		alignItems: "flex-end",
		flexDirection: "column",
		justifyContent: "center",
		"& button": {
			padding: "4px 7px",
			color: "white",
			fontSize: "13px",
			cursor: "pointer",
			borderRadius: "4px",
			fontWeight: "510",
			border: "none",
		},
	},
	timeSty: {
		fontSize: "12px",
		fontWeight: "500",
		color: "gray",
	},
	tbHeaderCell: {
		borderTop: "1px solid #e0e0e0",
		color: "#696969",
		fontSize: "15px",
		padding: "0px",
	},
	tbBodyCell: {
		border: "none",
		padding: "8px 5px",
	},
}));

const DashboardInvitations = (props) => {
	const { addCreatedOne } = props;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { handleTeams } = teamUtils;

	let { invitations } = useSelector((state) => state.team);
	const state = useSelector((state) => state);

	const [invites, setInvites] = useState([]);

	useEffect(() => {
		setInvites(invitations || []);
	}, [invitations]);

	const handleReject = async (invite) => {
		const reject = await Api.post("invite/reject", { invite: invite._id });
		console.log(reject);
		let filteredInvites = invites.filter((el) => el._id !== invite._id);
		dispatch({
			type: "AddTeam",
			payload: {
				invitations: filteredInvites,
			},
		});
		setInvites(filteredInvites);
	};

	const handleAccept = async (invite) => {
		const newlyAddedTeam = await Api.post("invite/accept", {
			invite: invite._id,
		});
		handleTeams([newlyAddedTeam], state, dispatch);
		if (invite?.teamType === "Organization") {
			addCreatedOne(newlyAddedTeam);
		}

		let filteredInvites = invites.filter((el) => el._id !== invite._id);
		dispatch({
			type: "AddTeam",
			payload: {
				invitations: filteredInvites,
			},
		});
		setInvites(filteredInvites);
	};

	return (
		<TableContainer className={classes.container}>
			<Table stickyHeader aria-label="sticky table" size="small">
				<TableHead>
					<TableRow
						style={{
							position: "relative",
							zIndex: "99",
							height: "35px",
						}}
					>
						<TableCell
							align="left"
							className={classes.tbHeaderCell}
							style={{ paddingLeft: "5px" }}
						>
							Name
						</TableCell>
						<TableCell
							align="center"
							className={classes.tbHeaderCell}
							style={{ paddingRight: "5px", width: "60px" }}
						>
							Role
						</TableCell>
						<TableCell
							align="right"
							className={classes.tbHeaderCell}
							style={{ paddingRight: "5px", width: "82px" }}
						>
							Action
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{invites.map((invite) => (
						<TableRow
							hover
							role="checkbox"
							tabIndex={-1}
							key={invite._id}
							style={{
								cursor: "pointer",
								padding: "0px",
								height: "25px",
							}}
						>
							<TableCell
								align={"left"}
								className={classes.tbBodyCell}
								style={{ paddingLeft: "5px" }}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									{invite?.teamType === "Project" ? (
										<Avatar
											style={{
												backgroundColor: "#F39014",
											}}
										>
											<TbCrane
												style={{ fontSize: "30px" }}
											/>
										</Avatar>
									) : (
										<Avatar
											style={{
												backgroundColor: "#0A66C2",
											}}
										>
											<ApartmentIcon
												style={{ fontSize: "30px" }}
											/>
										</Avatar>
									)}
									<div style={{ marginLeft: "5px" }}>
										<p
											style={{
												fontSize: "13px",
												color: "#101828",
												fontWeight: "510",
											}}
										>
											<LessText
												limit={13}
												string={
													invite?.team?.parent
														?.displayName
												}
											/>
										</p>
										<p
											style={{
												fontSize: "12px",
												color: "gray",
											}}
										>
											{`By `}
											<LessText
												limit={14}
												string={
													invite?.invitedById?.parent
														?.displayName
												}
											/>
										</p>
										<p className={classes.timeSty}>
											{format(invite?.createdAt)}
										</p>
									</div>
								</div>
							</TableCell>
							<TableCell
								align="center"
								className={classes.tbBodyCell}
								style={{
									color: "#4F4F4F",
									fontSize: "13px",
									fontWeight: "510",
									paddingRight: "5px",
								}}
							>
								{invite?.role}
							</TableCell>
							<TableCell
								align="right"
								className={classes.tbBodyCell}
								style={{ paddingRight: "5px" }}
							>
								<div className={classes.btnCont}>
									<button
										onClick={() => {
											handleAccept(invite);
										}}
										style={{
											backgroundColor: "#407BFF",
											marginBottom: "7px",
										}}
									>
										<span>Accept</span>
									</button>
									<button
										onClick={() => {
											handleReject(invite);
										}}
										style={{
											backgroundColor: "white",
											border: "1px solid gray",
											color: "#263238",
										}}
									>
										<span>Reject</span>
									</button>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};
export default DashboardInvitations;
