import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "100%",
    padding: "20px 30px 30px",
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    marginBottom: "30px",
    [theme.breakpoints.down('xs')]: {
      padding: "15px 15px 20px",
    }
  },
  dwollaHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

function PaymentMethodSkeleton() {
  const classes = useStyles();

  return (
    <Paper className={classes.paperStyle} >
      <div className={classes.dwollaHeader} >
        <Skeleton style={{ width: "140px", height: "35px" }} variant="rect" />
        <Skeleton style={{ width: "140px", height: "35px" }} variant="rect" />
      </div>

      <Skeleton style={{ width: "100%", height: "15px" }} variant="rect" />
      <Skeleton style={{ width: "60%", height: "15px", marginTop: "7px" }} variant="rect" />
      <Skeleton style={{ width: "40%", height: "15px", marginTop: "15px" }} variant="rect" />
    </Paper>
  );
}
export default PaymentMethodSkeleton; 