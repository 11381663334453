import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from 'react-redux';
import arrayToReducer from "../../helpers/arrayToReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDebounce } from "react-use";
import { getFollowersAndFollowingsId, getUserForMainSearch } from "./api";
import { useParams } from 'react-router-dom';
import BasicUserCard from './basic.card';
import UserCardSkeleton from "../styled/CommonComponents/UserCard.Skeleton";

const useStyles = makeStyles((theme) => ({
    feedBodyXCont: {
        width: "100%",
        overflow: "auto"
    },
    progressBox: {
        width: "100%",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
    }
}));

export default function UserSearchFeed(props) {
    const classes = useStyles();
    const postFromBackend = 10
    const { searchSlug } = useParams();
    const observer = useRef();
    const { locationTags = [], filterSelectedCategories = [] } = props;

    const { user } = useSelector((state) => state.auth);

    const [userIds, setUserIds] = useState([]);
    const [userDict, setUserDict] = useState([]);
    const [hasMoreItems, sethasMoreItems] = useState(true);
    const [currPage, setCurrPage] = useState(0)
    const [userArr, setUserArr] = useState([])
    const [loading, setLoading] = useState(true)
    const [changePageState, setChangePageState] = useState(true)
    const [followerIds, setFollowerIds] = useState([])
    const [followingIds, setFollowingIds] = useState([])


    const findPostsForScroll = async () => {
        setLoading(true)
        let filterLocationTags = new Set([]);
        let categoryTags = new Set([]);

        locationTags.map((loc) => {
            if (loc?.isCountry) {
                filterLocationTags.add(`${loc?.country}_${loc?.country}`);
            } else {
                filterLocationTags.add(`${loc?.state}_${loc?.country}`);
                filterLocationTags.add(`${loc?.country}_${loc?.country}`);
            }
        })
        filterSelectedCategories.map((cat) => {
            categoryTags.add(cat);
        })

        let marketsArr = [...filterLocationTags]
        let categoryTagsArr = [...categoryTags]

        await getUserForMainSearch({
            searchSlug,
            marketsArr,
            categoryTagsArr,
            limit: postFromBackend,
            page: currPage,

        })
            .then((data) => {
                if (currPage === 0) {
                    setUserArr(data)
                } else {
                    setUserArr((prev) => [...prev, ...data])
                }

                if (data.length < postFromBackend) {
                    sethasMoreItems(false);
                } else {
                    sethasMoreItems(true);
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const lastElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMoreItems) {
                    setCurrPage((prev) => {
                        return prev + 1;
                    });
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMoreItems]
    );

    useEffect(() => {
        if (currPage === 0) {
            setChangePageState(!changePageState)
        } else {
            setCurrPage(0);
        }
    }, [locationTags, filterSelectedCategories, searchSlug])

    useDebounce(
        () => {
            findPostsForScroll()
        },
        700,
        [currPage, changePageState]
    );

    useEffect(() => {
        const { idArr, newDict } = arrayToReducer(userArr || []);
        setUserIds(idArr);
        setUserDict(newDict);
    }, [userArr])

    const getAllFollowersIdOnly = async () => {
        await getFollowersAndFollowingsId({ profile: user?.profile })
            .then((data) => {
                console.log(data)
                let userFFs = data || []
                let followersLocArr = []
                let followingsLocArr = []
                userFFs.map((userFF) => {
                    if (user?.profile === userFF?.profile) {
                        followersLocArr.push(userFF?.userProfile)
                    } else if (user?.profile === userFF?.userProfile) {
                        followingsLocArr.push(userFF?.profile)
                    }
                })

                setFollowingIds(followingsLocArr)
                setFollowerIds(followersLocArr)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAllFollowersIdOnly()
    }, [user?.profile])


    return (
        <>
            {userIds && userIds.length > 0 ? (
                userIds.map((el, idx) => {
                    let lastItem = userIds.length - 1;
                    return userDict[el] ? (
                        idx === lastItem ? (
                            <div ref={lastElementRef} >
                                <BasicUserCard
                                    userData={userDict[el]}
                                    followerIds={followerIds}
                                    followingIds={followingIds}
                                    getAllFollowersIdOnly={getAllFollowersIdOnly}
                                />
                            </div>
                        ) : (
                            <div>
                                <BasicUserCard
                                    userData={userDict[el]}
                                    followerIds={followerIds}
                                    followingIds={followingIds}
                                    getAllFollowersIdOnly={getAllFollowersIdOnly}
                                />
                            </div>
                        )
                    ) : (
                        <></>
                    );
                })
            ) : (<>
                {loading && (<>
                    <UserCardSkeleton />
                    <UserCardSkeleton />
                    <UserCardSkeleton />
                </>)}
            </>)}
            {userIds && userIds.length > 0 && hasMoreItems ? (
                <div className={classes.progressBox}>
                    {loading && (
                        <CircularProgress />
                    )}
                </div>
            ) : null}
        </>
    );
};