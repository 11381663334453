import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "30%",
        height: "465px",
        borderRadius: "12px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        [theme.breakpoints.down("md")]: {
            width: "350px",
            marginBottom: "30px",
            borderRadius: "10px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "320px",
            borderRadius: "8px",
        }
    },
    checkAndTextCont: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px",
        "& div": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "20px"
        },
        "& p": {
            marginLeft: "5px",
            fontSize: "17px",
            fontWeight: "510"
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "10px",
            "& p": {
                marginLeft: "5px",
                fontSize: "15px",
                fontWeight: "500"
            },
        }
    },
    textCont: {
        padding: "30px 30px 14px",
        "& h4": {
            fontSize: "16px",
        },
        "& h3": {
            fontSize: "35px",
            fontWeight: "800"
        },
        "& h5": {
            fontSize: "17px"
        },
    },
    btnSty: {
        margin: "20px 30px 15px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
    }
}));

export default function EfficientCard({ isMonthly }) {
    const classes = useStyles();

    const [projectManagement, setProjectManagement] = useState(true)
    const [documents, setDocuments] = useState(true)
    const [investmentAnalysis, setInvestmentAnalysis] = useState(false)
    const [propertyManagement, setPropertyManagement] = useState(false)
    const [accountingPayments, setAccountingPayments] = useState(false)
    const [yearlyValue, setYearlyValue] = useState(0)
    const [monthlyValue, setMonthlyValue] = useState(0)

    useEffect(() => {
        if (investmentAnalysis && propertyManagement && accountingPayments) {
            setYearlyValue(384)
            setMonthlyValue(40)
        } else if (investmentAnalysis && propertyManagement) {
            setYearlyValue(320)
            setMonthlyValue(25)
        } else if (propertyManagement && accountingPayments) {
            setYearlyValue(288)
            setMonthlyValue(30)
        } else if (investmentAnalysis && accountingPayments) {
            setYearlyValue(320)
            setMonthlyValue(25)
        } else if (investmentAnalysis) {
            setYearlyValue(96)
            setMonthlyValue(10)
        } else if (propertyManagement) {
            setYearlyValue(144)
            setMonthlyValue(15)
        } else if (accountingPayments) {
            setYearlyValue(144)
            setMonthlyValue(15)
        } else {
            setYearlyValue(0)
            setMonthlyValue(0)
        }
    }, [investmentAnalysis, propertyManagement, accountingPayments])

    return (
        <div className={classes.root} data-aos={"fade-up"} >
            <div className={classes.textCont} >
                <h4>Efficient</h4>
                <h3>$ {isMonthly ? monthlyValue : yearlyValue}
                    <span
                        style={{
                            fontSize: "13px",
                            fontWeight: "600",
                            position: "relative",
                            left: "0px",
                            top: "-3px",
                        }}
                    >/{isMonthly ? " Month" : " Year"}</span></h3>
                <h5>Apps</h5>
            </div>

            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={projectManagement}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    onChange={(e) => { setProjectManagement(e.target.checked) }}
                    color="primary"
                />
                <p style={projectManagement ? { opacity: "1" } : { opacity: "0.6" }} >Project Management</p>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={documents}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    onChange={(e) => { setDocuments(e.target.checked) }}
                    color="primary"
                />
                <p style={documents ? { opacity: "1" } : { opacity: "0.6" }} >Documents</p>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={investmentAnalysis}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    onChange={(e) => { setInvestmentAnalysis(e.target.checked) }}
                    color="primary"
                />
                <div style={investmentAnalysis ? { opacity: "1" } : { opacity: "0.6" }} >
                    <p>Investment Analysis</p>
                    <p>$ {isMonthly ? "10" : "96"}</p>
                </div>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={propertyManagement}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    onChange={(e) => { setPropertyManagement(e.target.checked) }}
                    color="primary"
                />
                <div style={propertyManagement ? { opacity: "1" } : { opacity: "0.6" }} >
                    <p>Property management</p>
                    <p>$ {isMonthly ? "15" : "144"}</p>
                </div>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={accountingPayments}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    onChange={(e) => { setAccountingPayments(e.target.checked) }}
                    color="primary"
                />
                <div style={accountingPayments ? { opacity: "1" } : { opacity: "0.6" }} >
                    <p>Accounting & Payments</p>
                    <p>$ {isMonthly ? "15" : "144"}</p>
                </div>
            </div>

            <Button
                variant="contained"
                color="primary"
                className={classes.btnSty}
                disabled={investmentAnalysis || propertyManagement || accountingPayments ? false : true}
            >
                Get Started
            </Button>
        </div>
    );
}