import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getCategoryWithCount } from "./api";
import { FaHashtag } from "react-icons/fa";
import { Skeleton } from "@material-ui/lab";
import LessText from "../styled/CommonComponents/LessText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        backgroundColor: "white",
        paddingBottom: "10px",
        borderRadius: "5px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
        marginBottom: "25px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "0px"
        }
    },
    headSty: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 15px",
        borderBottom: "1px solid #e3e0e0",
        "& h3": {
            fontSize: "17px",
            fontWeight: "500",
            color: "#00345D"
        }
    },
    communitesCont: {
        width: "100%",
        maxHeight: "235px",
        paddingTop: "3px",
    },
    communityLine: {
        cursor: "pointer",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "5px 15px",
        "& h3": {
            marginLeft: "10px",
            fontSize: "16px",
            fontWeight: "400",
            color: "#505050",
        }
    },
    seemoreSty: {
        textAlign: "center",
        padding: "0px 10px",
        cursor: "pointer",
        color: theme.palette.primary.main
    }
}))


const ResourcesCard = () => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme()
    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));

    const [categories, setCategories] = useState(null)
    const [categoriesLoading, setCategoriesLoading] = useState(false)
    const [arr, setArr] = useState([])


    useEffect(() => {
        if (isMdSmall) {
            if (categories) {
                if (categories.length > 0 && categories.length > 4) {
                    let data = categories.slice(1, 5)
                    setArr([...data])
                } else {
                    setArr([...categories])
                }
            }
        } else {
            if (categories) {
                if (categories.length > 0 && categories.length > 6) {
                    let data = categories.slice(1, 7)
                    setArr([...data])
                } else {
                    setArr([...categories])
                }
            }
        }
    }, [categories, isMdSmall])


    const getAllCategoryWithCount = async () => {
        setCategoriesLoading(true)
        await getCategoryWithCount({})
            .then((data) => {
                let sortedArr = data[0]?.category_details || []
                sortedArr.sort((a, b) => {
                    return b?.count - a?.count;
                })
                setCategories(sortedArr || [])

            })
            .catch((err) => {
                console.log(err);
                setCategories([])
            })
        setCategoriesLoading(false)
    }


    useEffect(() => {
        getAllCategoryWithCount()
    }, [])


    return (<>
        {!categoriesLoading && categories !== null && categories.length === 0 ? null : (
            <div className={classes.mainCont} >
                <div className={classes.headSty} >
                    <h3>Resources</h3>
                    <div></div>
                </div>
                <div className={classes.communitesCont} >
                    {categories === null && categoriesLoading ? (<div style={{ padding: "0px 15px" }} >
                        {isMdSmall ? (<>
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                        </>) : (<>
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                        </>)}
                    </div>) : (<>
                        {arr && arr.length > 0 && arr.map((category) => (
                            <div
                                className={classes.communityLine}
                                onClick={() => {
                                    history.push(`/explore/doc/resources/${category?.tagStrs}`)
                                }}
                            >
                                <FaHashtag
                                    style={{
                                        marginRight: "7px",
                                        color: "#808080db",
                                        fontSize: "17px"
                                    }}
                                />
                                <h3>
                                    <LessText
                                        limit={20}
                                        string={category?.tagStrs}
                                    />
                                </h3>
                            </div>
                        ))}
                    </>)}
                </div>
                {!categoriesLoading && (
                    <div
                        className={classes.seemoreSty}
                        onClick={() => {
                            history.push("/explore/doc/resources")
                        }}
                    >
                        See More
                    </div>
                )}
            </div>
        )}
    </>);
};
export default ResourcesCard;