import React from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import DocumentCard from "../dashboard/cards/DocumentCard";
import PaymentsCard from "../dashboard/cards/PaymentsCard";
import ProjectManagementCard from "../dashboard/cards/ProjectManagementCard";
import ProjectAnalysisCard from "../dashboard/cards/Project.Analysis.Card";
import PropertyManagementUnitCard from "../propertyManagement/PropertyManagementUnitCard";

const useStyles = makeStyles((theme) => ({
	left_cards: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
		marginTop: "20px",
	},
}));

const ProjectDashboard = ({
	reports,
	loader,
	docs,
	units,
	setUnits,
	chartAccounts,
	teamId,
	transactions,
	issues,
	walletId,
	profileId,
}) => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();

	return (
		<div className={classes.left_cards}>
			<ProjectAnalysisCard
				reports={reports}
				loader={loader}
				teamId={teamId}
			/>
			<ProjectManagementCard
				issues={issues}
				profileId={profileId}
				loader={loader}
			/>
			<PaymentsCard
				chartAccounts={chartAccounts}
				transactions={transactions}
				walletId={walletId}
				loader={loader}
			/>
			<PropertyManagementUnitCard
				loader={loader}
				teamId={teamId}
				units={units}
				setUnits={setUnits}
			/>
			<DocumentCard
				docs={docs}
				profileId={profileId}
				loader={loader}
			/>
		</div>
	);
};
export default ProjectDashboard;
