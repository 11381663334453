import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
	PieChart,
	Pie,
	Sector,
	Cell,
	Legend,
	Tooltip,
	ResponsiveContainer,
} from "recharts";
import Paper from "@material-ui/core/Paper";
import { Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ProjectCharts from "./ProjectCharts";
import { Grid } from "ag-grid-community";

const useStyles = makeStyles({
	borderBox: {
		border: "1px solid lightgrey",
	},
	flexCenter: {
		justifyContent: "center",
	},
});

const groupBy = (key) => (array) =>
	array.reduce((objectsByKeyValue, obj) => {
		const value = obj[key];
		objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
		return objectsByKeyValue;
	}, {});

const groupByDesc = groupBy("description");

function currencyFormatter(currency, sign) {
	if (currency) {
		var sansDec = Number(currency).toFixed(2);
		var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return sign + `${formatted}`;
	} else {
		return sign + 0;
	}
}

const getTotalAndDue = (arr) => {
	let total = 0;
	let due = 0;
	arr.map((doc) => {
		total += doc?.finalAmount || 0;
		if (doc?.status != "Paid") {
			due += doc?.finalAmount || 0;
		}
	});

	return [total, due];
};

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? "start" : "end";

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000000"}>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill="#333"
			>{`Amount $${value} `}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill="#999"
			>
				{`(${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

export default function ProjectSummary(props) {
	const classes = useStyles();
	const {
		leases,
		transactions,
		balanceData,
		vacantData,
		rowData,
		activeIndex,
		onPieEnter,
	} = props; // transactions is array of all created transactions, leases is an object of rental Relation with keys as project
	const columnDefs = [
		{
			headerName: "Summary By Property",
			headerGroupClass: classes.flexCenter,
			children: [
				{
					field: "project",
					headerName: "Property",
					sortable: true,
					filter: "agTextColumnFilter",
				},
				{
					field: "unitNo",
					headerName: "No. Of Units",
					sortable: true,
					filter: "agNumberColumnFilter",
				},
			],
		},
		{
			headerName: "Occupancy",
			children: [
				{
					field: "vacant",
					headerName: "Vacant",
					sortable: true,
					filter: "agNumberColumnFilter",
				},
				{
					field: "occupied",
					headerName: "Occupied",
					sortable: true,
					filter: "agNumberColumnFilter",
				},
				{
					field: "occupiedPercent",
					headerName: "% Occupied",
					sortable: true,
					filter: "agNumberColumnFilter",
					valueFormatter: (params) =>
						params.data.occupiedPercent.toFixed(2),
				},
			],
		},
		{
			headerName: "Rent",
			children: [
				{
					field: "total",
					headerName: "Total",
					sortable: true,
					valueFormatter: (params) =>
						currencyFormatter(params.data.total, "$"),
					filter: "agNumberColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						filterOptions: ["greaterThan"],
					},
				},
				{
					field: "avg",
					headerName: "Average",
					sortable: true,
					valueFormatter: (params) =>
						currencyFormatter(params.data.avg, "$"),
					filter: "agNumberColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						filterOptions: ["greaterThan"],
					},
				},
				{
					field: "due",
					headerName: "Balance Due",
					sortable: true,
					valueFormatter: (params) =>
						currencyFormatter(params.data.due, "$"),
					filter: "agNumberColumnFilter",
					filterParams: {
						suppressAndOrCondition: true,
						filterOptions: ["greaterThan"],
					},
				},
			],
		},
	];

	return (
		<div>
			<Typography variant="h5">Grand Totals</Typography>
			<div>
				<div>
					<div style={{ display: "flex", gap: "3%" }}>
						<Paper
							style={{
								width: "47%",
								marginTop: "1rem",
								height: "22rem",
							}}
						>
							<ResponsiveContainer
								style={{ width: "100%", height: "100%" }}
							>
								<PieChart width={500} height={400}>
									<Legend />
									<Pie
										activeIndex={activeIndex}
										activeShape={renderActiveShape}
										data={balanceData}
										cx={"52%"}
										cy={"42%"}
										innerRadius={80}
										outerRadius={100}
										fill="#8884d8"
										dataKey="balance"
										onMouseEnter={onPieEnter}
									>
										{balanceData.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={
													COLORS[
														index % COLORS.length
													]
												}
											/>
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</Paper>
						<Paper style={{ width: "47%", marginTop: "1rem" }}>
							<ResponsiveContainer width="100%" height="100%">
								<PieChart width={400} height={400}>
									<Legend />
									<Tooltip />
									<Pie
										dataKey="value"
										startAngle={180}
										endAngle={0}
										data={vacantData}
										cx="50%"
										cy="54%"
										outerRadius={120}
										fill="#8884d8"
										label
									>
										{vacantData.map((entry, index) => (
											<Cell
												key={`cell-${index}`}
												fill={
													COLORS[
														index % COLORS.length
													]
												}
											/>
										))}
									</Pie>
								</PieChart>
							</ResponsiveContainer>
						</Paper>
					</div>
				</div>
			</div>
			<div style={{ marginTop: "2rem" }}>
				<Typography variant="h5">Transaction Graph</Typography>
				{/* filters?? */}
				<ProjectCharts transactions={transactions} />
			</div>
			<div style={{ height: "24rem", marginTop: "1.5rem" }}>
				<div
					style={{ height: "100%", width: "100%" }}
					className="ag-theme-alpine"
				>
					<AgGridReact
						rowData={rowData}
						defaultColDef={{ resizable: true }}
						columnDefs={columnDefs}
						// defaultColDef={defaultColDef}
						// onGridReady={onGridReady}
					></AgGridReact>
				</div>
			</div>
		</div>
	);
}
