import {
	DataSheetGrid,
	textColumn,
	keyColumn,
	isoDateColumn,
	floatColumn,
	intColumn,
} from "react-datasheet-grid";
import React, { useCallback, useEffect, useMemo, useState } from "react";

// Import the style only once in your app!
import "react-datasheet-grid/dist/style.css";
import { Avatar, makeStyles, Typography } from "@material-ui/core";
import { format } from "date-fns";

const groupBy = (key) => (array) =>
	array.reduce((objectsByKeyValue, obj) => {
		const value = obj[key];
		objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
		return objectsByKeyValue;
	}, {});

const groupByDesc = groupBy("description");

const useStyles = makeStyles({});
const getRentCycle = (data) => {
	if (data?.intervalType == "Daily" || data?.intervalType == "Yearly") {
		return data?.intervalType;
	} else if (data?.intervalType == "Weekly") {
		if (data?.afterXWeeks == 1) {
			return "Weekly";
		} else {
			return "Bi-Weekly";
		}
	} else {
		let name = {
			1: "Monthly",
			2: "Bi-Monthly",
			4: "Quarterly",
			6: "Half Yearly",
		};
		return name[data?.afterXMonth];
	}
};

export default function ReactTable(props) {
	const classes = useStyles();
	const { leases, transactions } = props; // array of leases for a particular unit only
	// const [rows, setRows] = useState([]);
	const [groups, setGroups] = useState([
		/* Your groups */
	]);
	const [openedGroups, setOpenedGroups] = useState([]);

	// Function to toggle group visibility
	const toggleGroup = useCallback((i) => {
		setOpenedGroups((opened) => {
			if (opened.includes(i)) {
				return opened.filter((x) => x !== i);
			}

			return [...opened, i];
		});
	}, []);

	const Component = ({ rowData, focus, stopEditing }) => {
		useEffect(() => {
			if (focus) {
				toggleGroup(rowData.groupIndex);
				stopEditing({ nextRow: false });
			}
		}, [focus, rowData.groupIndex, stopEditing]);

		if (rowData.type === "CHILD") {
			return null;
		}

		return `${rowData.sno}  ${rowData.opened ? "⬇️" : "➡️"}`;
	};

	const calculateBalanceDue = (doc) => {
		let due = 0;
		(transactions[doc?._id] || []).map((txt) => {
			if (txt?.status != "Paid") {
				due += txt?.finalAmount;
			}
		});
		return due;
	};
	useEffect(() => {
		let tempRows = (leases || []).map((doc, idx) => {
			//trans for rent, recc, one time
			let childArray = [];
			if (transactions) {
				let docTransactions = groupByDesc(transactions[doc?._id] || []);
				let rent = docTransactions["Rent"] || [];
				let oneTime = docTransactions["One Time Bills"] || [];
				let recc = docTransactions["Reccuring Bills"] || [];
				let rowCount = Math.max(
					rent.length,
					oneTime.length,
					recc.length
				);
				for (let i = 0; i < rowCount; i++) {
					let obj = {};
					if (rent[i]) {
						obj.rentDate = format(
							new Date(rent[i]?.dueDate),
							"yyyy-MM-dd"
						);
						obj.rent = rent[i]?.finalAmount;
					}
					if (oneTime[i]) {
						obj.oneTimeMemo = oneTime[i]?.memo;
						obj.oneTimeCharges = oneTime[i]?.finalAmount;
					} else {
						obj.oneTimeMemo = "-";
						obj.oneTimeCharges = 0;
					}
					if (recc[i]) {
						obj.reccuringMemo = recc[i]?.memo;
						obj.reccuringCharges = recc[i]?.finalAmount;
					} else {
						obj.reccuringMemo = "-";
						obj.reccuringCharges = 0;
					}
					childArray.push(obj);
				}
			}
			return {
				topData: {
					sno: idx + 1,
					unit: doc?.unit?.name,
					tenant: `${doc?.tenantFullInfo?.contactInfo?.firstName} ${doc?.tenantFullInfo?.contactInfo?.lastName}`,
					leaseStart: format(
						new Date(doc?.leaseStarted),
						"yyyy-MM-dd"
					),
					leaseEnd: format(new Date(doc?.leaseEnd), "yyyy-MM-dd"),
					rentCycle: getRentCycle(doc?.rent?.schedulingData),
					// rentDate: '*',
					rent: doc?.rent?.finalAmount,
					reccuringCharges: doc?.monthlyBills.reduce(
						(accumulator, current) =>
							accumulator + current.finalAmount,
						0
					),
					// reccuringMemo: "*",
					// reccuringCycle: "*",
					securityDeposit: doc?.securityDeposit?.finalAmount,
					oneTimeCharges: doc?.oneTimeBills.reduce(
						(accumulator, current) =>
							accumulator + current.finalAmount,
						0
					),
					// oneTimeMemo: "*",
					balanceDue: calculateBalanceDue(doc),
				},
				children: [...childArray],
			};
		});
		setGroups([...tempRows]);
	}, [leases, transactions]);

	const rows = useMemo(() => {
		const result = [];

		for (let i = 0; i < groups.length; i++) {
			result.push({
				...groups[i].topData,
				type: "GROUP",
				groupIndex: i,
				opened: openedGroups.includes(i),
			});

			if (openedGroups.includes(i)) {
				for (let j = 0; j < groups[i].children.length; j++) {
					result.push({
						type: "CHILD",
						groupIndex: i,
						childIndex: j,
						...groups[i].children[j],
					});
				}
			}
		}

		return result;
	}, [groups, openedGroups]);
	return (
		<div style={{ marginTop: "1rem" }}>
			<div
				style={{
					display: "flex",
					gap: "7px",
					marginBottom: "0.7rem",
					alignItems: "center",
				}}
			>
				<Avatar src={leases[0]?.project?.displayPicture?.url} />
				<Typography variant={"h5"}>
					{leases[0]?.project?.displayName}
				</Typography>
			</div>

			<DataSheetGrid
				value={rows}
				onChange={(e) => {}}
				columns={[
					{
						title: "S. No.",
						disabled: ({ rowData }) => rowData.type === "CHILD",
						isCellEmpty: () => true,
						alignRight: true,
						component: Component,
					},
					{
						...keyColumn("unit", textColumn),
						disabled: true,
						title: "Unit",
						minWidth: 200,
					},
					{
						...keyColumn("tenant", textColumn),
						disabled: true,
						title: "Tenant",
						minWidth: 200,
					},
					{
						...keyColumn("leaseStart", isoDateColumn),
						disabled: true,
						title: "Lease Start",
					},
					{
						...keyColumn("leaseEnd", isoDateColumn),
						disabled: true,
						title: "Lease End",
					},
					{
						...keyColumn("rentCycle", textColumn),
						disabled: true,
						title: "Rent Cycle",
					},
					{
						...keyColumn("rentDate", isoDateColumn),
						disabled: true,
						title: "Rent Date",
					},
					{
						...keyColumn("rent", floatColumn),
						disabled: true,
						title: "Rent",
					},
					{
						...keyColumn("reccuringCycle", textColumn),
						disabled: true,
						title: "Reccuring Bill Cycle",
					},
					{
						...keyColumn("reccuringMemo", textColumn),
						disabled: true,
						title: "Reccuring Bill Memo",
						minWidth: 200,
					},
					{
						...keyColumn("reccuringCharges", floatColumn),
						disabled: true,
						title: "Reccuring Bills",
					},
					{
						...keyColumn("securityDeposit", floatColumn),
						disabled: true,
						title: "Security Deposit",
					},
					{
						...keyColumn("oneTimeMemo", textColumn),
						disabled: true,
						title: "One Time Bill Memo",
						minWidth: 200,
					},
					{
						...keyColumn("oneTimeCharges", floatColumn),
						disabled: true,
						title: "One Time Bills",
					},
					{
						...keyColumn("balanceDue", floatColumn),
						disabled: true,
						title: "Balance Due",
					},
				]}
			/>
		</div>
	);
}
