import { allStatesFullName, allStatesShortName, shortAndFullFormPair } from "../styled/allUSAStates.js"


let commonInterestRate = "6.33";
let refinanceInterestRate = "6.5"

export const rentalValues = {
    LoanType: "Amortizing",
    DownPayment: "20",
    InterestRate: commonInterestRate,
    LoanTerm: "30",
    costOverrun: "10",
    rehabPeriod: 0,
    Vacancy: "8",
    Appreciation: "3",
    IncomeIncrease: "3",
    ExpenseIncrease: "3",
    SellingCosts: "5",
    RehabCostPercent: "80"
}

export const brrrrValues = {
    LoanType: "Amortizing",
    refinanceLoanType: "Amortizing",
    remainingEquity: "20",
    DownPayment: "20",
    InterestRate: commonInterestRate,
    refinanceInterestRate: refinanceInterestRate,
    LoanTerm: "30",
    refinanceLoanTerm: "30",
    costOverrun: "10",
    Vacancy: "8",
    Appreciation: "3",
    IncomeIncrease: "3",
    ExpenseIncrease: "3",
    SellingCosts: "5",
    RehabCostPercent: "80"
}

export const flipValues = {
    propertyTax: "4.5",
    propertyInsurance: "1200",
    LoanType: "Amortizing",
    DownPayment: "20",
    InterestRate: commonInterestRate,
    LoanTerm: "30",
    costOverrun: "10",
    RehabCostPercent: "80"
}

export const fullStateTaxObj = {
    "Alabama": "0.41",
    "Alaska": "1.19",
    "Arizona": "0.66",
    "Arkansas": "0.62",
    "California": "0.76",
    "Colorado": "0.51",
    "Connecticut": "2.14",
    "Delaware": "0.57",
    "District Of Columbia": "0.56",
    "Florida": "0.86",
    "Georgia": "0.92",
    "Hawaii": "0.28",
    "Idaho": "0.69",
    "Illinois": "2.27",
    "Indiana": "0.85",
    "Iowa": "1.57",
    "Kansas": "1.41",
    "Kentucky": "0.86",
    "Louisiana": "0.55",
    "Maine": "1.36",
    "Maryland": "1.09",
    "Massachusetts": "1.23",
    "Michigan": "1.54",
    "Minnesota": "1.12",
    "Mississippi": "0.81",
    "Missouri": "0.97",
    "Montana": "0.84",
    "Nebraska": "1.73",
    "Nevada": "0.60",
    "New Hampshire": "2.18",
    "New Jersey": "2.49",
    "New Mexico": "0.8",
    "New York": "1.72",
    "North Carolina": "0.84",
    "North Dakota": "0.98",
    "Ohio": "1.56",
    "Oklahoma": "0.90",
    "Oregon": "0.97",
    "Pennsylvania": "1.58",
    "Rhode Island": "1.63",
    "South Carolina": "0.57",
    "South Dakota": "1.31",
    "Tennessee": "0.71",
    "Texas": "1.80",
    "Utah": "0.63",
    "Vermont": "1.90",
    "Virginia": "0.82",
    "Washington": "0.98",
    "West Virginia": "0.58",
    "Wisconsin": "1.85",
    "Wyoming": "0.61",
    "Other": "1.05"
}

export const shortStateTaxObj = {
    "AL": "0.41",
    "AK": "1.19",
    "AZ": "0.66",
    "AR": "0.62",
    "CA": "0.76",
    "CO": "0.51",
    "CT": "2.14",
    "DE": "0.57",
    "DC": "0.56",
    "FL": "0.86",
    "GA": "0.92",
    "HI": "0.28",
    "ID": "0.69",
    "IL": "2.27",
    "IN": "0.85",
    "IA": "1.57",
    "KS": "1.41",
    "KY": "0.86",
    "LA": "0.55",
    "ME": "1.36",
    "MD": "1.09",
    "MA": "1.23",
    "MI": "1.54",
    "MN": "1.12",
    "MS": "0.81",
    "MO": "0.97",
    "MT": "0.84",
    "NE": "1.73",
    "NV": "0.60",
    "NH": "2.18",
    "NJ": "2.49",
    "NM": "0.8",
    "NY": "1.72",
    "NC": "0.84",
    "ND": "0.98",
    "OH": "1.56",
    "OK": "0.90",
    "OR": "0.97",
    "PA": "1.58",
    "RI": "1.63",
    "SC": "0.57",
    "SD": "1.31",
    "TN": "0.71",
    "TX": "1.80",
    "UT": "0.63",
    "VT": "1.90",
    "VA": "0.82",
    "WA": "0.98",
    "WV": "0.58",
    "WI": "1.85",
    "WY": "0.61",
    "OT": "1.05"
}


export const fullStateInsuranceObj = {
    "Alabama": "1597",
    "Alaska": "1001",
    "Arizona": "1216",
    "Arkansas": "2104",
    "California": "1084",
    "Colorado": "1863",
    "Connecticut": "1216",
    "Delaware": "681",
    "District Of Columbia": "897",
    "Florida": "1648",
    "Georgia": "1373",
    "Hawaii": "378",
    "Idaho": "858",
    "Illinois": "1376",
    "Indiana": "1180",
    "Iowa": "1290",
    "Kansas": "2800",
    "Kentucky": "1820",
    "Louisiana": "2009",
    "Maine": "944",
    "Maryland": "1136",
    "Massachusetts": "1274",
    "Michigan": "1292",
    "Minnesota": "1880",
    "Mississippi": "1840",
    "Missouri": "1647",
    "Montana": "1752",
    "Nebraska": "2849",
    "Nevada": "874",
    "New Hampshire": "731",
    "New Jersey": "775",
    "New Mexico": "1939",
    "New York": "1289",
    "North Carolina": "1317",
    "North Dakota": "1872",
    "Ohio": "1119",
    "Oklahoma": "3593",
    "Oregon": "704",
    "Pennsylvania": "786",
    "Rhode Island": "1221",
    "South Carolina": "1165",
    "South Dakota": "2035",
    "Tennessee": "1644",
    "Texas": "1860",
    "Utah": "668",
    "Vermont": "668",
    "Virginia": "924",
    "Washington": "899",
    "West Virginia": "1089",
    "Wisconsin": "928",
    "Wyoming": "902",
    "Other": "1200"
}

export const shortStateInsuranceObj = {
    "AL": "1597",
    "AK": "1001",
    "AZ": "1216",
    "AR": "2104",
    "CA": "1084",
    "CO": "1863",
    "CT": "1216",
    "DE": "681",
    "DC": "897",
    "FL": "1648",
    "GA": "1373",
    "HI": "378",
    "ID": "858",
    "IL": "1376",
    "IN": "1180",
    "IA": "1290",
    "KS": "2800",
    "KY": "1820",
    "LA": "2009",
    "ME": "944",
    "MD": "1136",
    "MA": "1274",
    "MI": "1292",
    "MN": "1880",
    "MS": "1840",
    "MO": "1647",
    "MT": "1752",
    "NE": "2849",
    "NV": "874",
    "NH": "731",
    "NJ": "775",
    "NM": "1939",
    "NY": "1289",
    "NC": "1317",
    "ND": "1872",
    "OH": "1119",
    "OK": "3593",
    "OR": "704",
    "PA": "786",
    "RI": "1221",
    "SC": "1165",
    "SD": "2035",
    "TN": "1644",
    "TX": "1860",
    "UT": "668",
    "VT": "668",
    "VA": "924",
    "WA": "899",
    "WV": "1089",
    "WI": "928",
    "WY": "902",
    "OT": "1200"
}


export const cityInsuranceObj = {
    "Birmingham": "1442",
    "Huntsville": "1477",
    "Mobile": "1812",
    "Montgomery": "1340",
    "Tuscaloosa": "1466",
    "Anchorage": "851",
    "Fairbanks": "1068",
    "Juneau": "943",
    "Ketchikan": "860",
    "Wasilla": "1015",
    "Chandler": "1110",
    "Gilbert": "1107",
    "Glendale": "1237",
    "Mesa": "1208",
    "Phoenix": "1427",
    "Scottsdale": "1252",
    "Tucson": "1053",
    "Fayetteville": "1762",
    "Fort Smith": "1952",
    "Jonesboro": "2138",
    "Little Rock": "2007",
    "Springdale": "1752",
    "Anaheim": "1095",
    "Bakersfield": "976",
    "Chula Vista": "984",
    "Fontana": "1178",
    "Fremont": "899",
    "Fresno": "954",
    "Irvine": "1085",
    "Long Beach": "1144",
    "Los Angeles": "1385",
    "Modesto": "918",
    "Oakland": "1071",
    "Riverside": "1156",
    "Sacramento": "925",
    "San Bernardino": "1225",
    "San Diego": "974",
    "San Francisco": "1033",
    "San Jose": "867",
    "Santa Ana": "1064",
    "Stockton": "961",
    "Aurora": "2045",
    "Boulder": "1581",
    "Colorado": "1995",
    "Denver": "2024",
    "Fort Collins": "1436",
    "Bridgeport": "1361",
    "Hartford": "1297",
    "New Haven": "1541",
    "Stamford": "1115",
    "Waterbury": "1334",
    "Dover": "637",
    "Middletown": "594",
    "Newark": "592",
    "Smyrna": "591",
    "Wilmington": "604",
    "Fort Lauderdale": "2476",
    "Hialeah": "3319",
    "Jacksonville": "1063",
    "Miami": "3090",
    "Orlando": "1432",
    "St.Petersburg": "1122",
    "Tampa": "1453",
    "Atlanta": "1386",
    "Augusta": "1413",
    "Columbus": "1461",
    "Macon": "1379",
    "Savannah": "1421",
    "Hilo": "382",
    "Honolulu": "376",
    "Kailua": "376",
    "Lahaina": "382",
    "Pearl City": "376",
    "Boise": "831",
    "Caldwell": "834",
    "Idaho Falls": "860",
    "Meridian": "777",
    "Nampa": "818",
    "Aurora": "1180",
    "Chicago": "1708",
    "Joliet": "1288",
    "Naperville": "1222",
    "Rockford": "1216",
    "Carmel": "1162",
    "Evansville": "1187",
    "Fort Wayne": "1041",
    "Indianapolis": "1280",
    "South Bend": "1140",
    "Cedar Rapids": "1155",
    "Davenport": "1153",
    "Des Moines": "1305",
    "Iowa City": "1185",
    "Sioux City": "1434",
    "Kansas City": "2610",
    "Olathe": "2201",
    "Overland Park": "2214",
    "Topeka": "2580",
    "Wichita": "3194",
    "Bowling Green": "1851",
    "Covington": "1375",
    "Lexington": "1484",
    "Louisville": "1819",
    "Owensboro": "1781",
    "Baton Rouge": "1627",
    "Lafayette": "1799",
    "Lake Charles": "1953",
    "New Orleans": "2685",
    "Shreveport": "1448",
    "Auburn": "904",
    "Bangor": "894",
    "Lewiston": "903",
    "Portland": "932",
    "South Portland": "933",
    "Baltimore": "1273",
    "Columbia": "956",
    "Germantown": "959",
    "Silver Spring": "976",
    "Waldorf": "1181",
    "Boston": "1488",
    "Cambridge": "1140",
    "Lowell": "1157",
    "Springfield": "1231",
    "Worcester": "1211",
    "Ann Arbor": "926",
    "Detroit": "2544",
    "Grand Rapids": "1016",
    "Sterling Heights": "1170",
    "Warren": "1353",
    "Minneapolis": "1972",
    "Mankato": "1794",
    "Duluth": "1752",
    "Rochester": "1720",
    "St.Paul": "1920",
    "Biloxi": "2437",
    "Hattiesburg": "1906",
    "Jackson": "2040",
    "Gulfport": "2369",
    "Southaven": "1488",
    "Columbia": "1526",
    "Independence": "1880",
    "Kansas City": "1835",
    "Springfield": "1684",
    "St.Louis": "1561",
    "Billings": "2008",
    "Bozeman": "1510",
    "Butte": "1587",
    "Great Falls": "1713",
    "Missoula": "1370",
    "Bellevue": "2704",
    "Grand Island": "2997",
    "Kearney": "3033",
    "Lincoln": "2194",
    "Omaha": "2875",
    "Henderson": "865",
    "Las Vegas": "924",
    "North Las Vegas": "890",
    "Paradise Valley": "719",
    "Reno": "794",
    "Concord": "723",
    "Dover": "732",
    "Manchester": "715",
    "Nashua": "670",
    "Rochester": "734",
    "Edison": "689",
    "Elizabeth": "766",
    "Jersey City": "847",
    "Newark": "890",
    "Paterson": "858",
    "Albuquerque": "1555",
    "Rio Rancho": "1290",
    "Roswell": "3120",
    "Santa Fe": "1279",
    "Taos": "1449",
    "Brooklyn": "1656",
    "Buffalo": "878",
    "New York City": "1944",
    "Rochester": "766",
    "Syracuse": "817",
    "Charlotte": "1194",
    "Durham": "1, 294",
    "Greensboro": "1152",
    "Raleigh": "1324",
    "Wilmington": "2165",
    "Winston Salem": "1125",
    "Bismarck": "1999",
    "Fargo": "1740",
    "Grand Forks": "1697",
    "Minot": "1751",
    "West Fargo": "1679",
    "Akron": "1056",
    "Cincinnati": "1109",
    "Cleveland": "1165",
    "Columbus": "1139",
    "Toledo": "1146",
    "Broken Arrow": "3056",
    "Edmond": "4001",
    "Norman": "3950",
    "Oklahoma City": "4230",
    "Tulsa": "3118",
    "Eugene": "658",
    "Gresham": "690",
    "Hillsboro": "612",
    "Portland": "665",
    "Salem": "676",
    "Allentown": "676",
    "Erie": "761",
    "Philadelphia": "1132",
    "Pittsburgh": "757",
    "Reading": "660",
    "Cranston": "1223",
    "East Providence": "1205",
    "Pawtucket": "1187",
    "Providence": "1383",
    "Warwick": "1272",
    "Charleston": "1316",
    "Columbia": "1064",
    "Greenville": "984",
    "Mount Pleasant": "1276",
    "North Charleston": "1193",
    "Aberdeen": "1846",
    "Brookings": "1902",
    "Rapid City": "2200",
    "Sioux Falls": "1861",
    "Watertown": "1890",
    "Chattanooga": "1492",
    "Clarksville": "1607",
    "Knoxville": "1406",
    "Memphis": "2106",
    "Nashville": "1554",
    "Arlington": "2375",
    "Austin": "1320",
    "Corpus Christi": "1920",
    "Dallas": "2181",
    "El Paso": "1119",
    "Fort Worth": "2291",
    "Garland": "2236",
    "Houston": "1991",
    "Irving": "2236",
    "Laredo": "1387",
    "Lubbock": "2096",
    "Plano": "2014",
    "San Antonio": "1464",
    "Waco": "1698",
    "Orem": "658",
    "Provo": "659",
    "Salt Lake City": "679",
    "West Jordan": "650",
    "Woods Cross": "633",
    "Burlington": "655",
    "Hartland": "664",
    "Montpelier": "669",
    "Rutland": "688",
    "South Burlington": "622",
    "Arlington": "795",
    "Chesapeake": "1442",
    "Norfolk": "1633",
    "Richmond": "927",
    "Virginia Beach": "1763",
    "Bellevue": "860",
    "Seattle": "879",
    "Spokane": "847",
    "Tacoma": "957",
    "Vancouver": "829",
    "Washington": "897",
    "Charleston": "1082",
    "Huntington": "1192",
    "Morgantown": "940",
    "Parkersburg": "1061",
    "Wheeling": "1009",
    "Green Bay": "816",
    "Kenosha": "892",
    "Madison": "837",
    "Milwaukee": "977",
    "Racine": "881",
    "Casper": "968",
    "Cheyenne": "1160",
    "Gillette": "1015",
    "Laramie": "739",
    "Rock Springs": "615"
}


export const getPropertyTaxByState = (stateName) => {
    if (allStatesFullName.includes(stateName)) {
        return fullStateTaxObj?.[stateName]
    } else if (allStatesShortName.includes(stateName)) {
        return shortStateTaxObj?.[stateName]
    } else {
        return shortStateTaxObj?.["OT"]
    }
}

export const getPropertyInsuranceByState = (cityName, stateName) => {
    if (cityInsuranceObj?.[cityName]) {
        return cityInsuranceObj?.[cityName];
    } else {
        if (allStatesFullName.includes(stateName)) {
            return fullStateInsuranceObj?.[stateName]
        } else if (allStatesShortName.includes(stateName)) {
            return shortStateInsuranceObj?.[stateName]
        } else {
            return shortStateInsuranceObj?.["OT"]
        }
    }
}













