export const degreesArray = [
    "Bachelor of Liberal Arts - ALB",
    "Doctor of Musical Arts - AMUSD",
    "Associate of Arts - AA",
    "Associate of Applied Arts - AAA",
    "Associate of Applied Business - AAB",
    "Associate in Allied Health - AAH",
    "Associate of Arts in Nursing - AAN",
    "Associate in Applied Science - AAS",
    "Associate of Arts and Sciences - AAS",
    "Associate of Arts in Teaching - AAT",
    "Associate of Applied Technology - AAT",
    "Associate in Business - AB",
    "Bachelor of Arts - AB",
    "Associate of Business Administration - ABA",
    "Bachelor of Liberal Arts - ABL",
    "Associate Degree - AD",
    "Associate Degree of Building Design - ADBLDGDES",
    "Associate Degree of Building Surveying - ADBLDGSURV",
    "Associate Degree of Business - ADBUS",
    "Associate Degree of Engineering - ADENG",
    "Associate Degree of Information Technology - ADIT",
    "Associate Degree of Journalism - ADJOUR",
    "Associate Degree of Learning Management - ADLM",
    "Associate Degree of Multimedia Studies - ADMMST",
    "Associate Degree in Nursing - ADN",
    "Associate of Engineering - AE",
    "Alternate Entry Master of Science in Nursing - AEMSN",
    "Associate of Engineering Science - AES",
    "Associate in Engineering Technology - AET",
    "Associate of Forestry - AF",
    "Associate of Fine Arts - AFA",
    "Associate of General Studies - AGS",
    "Associate of Industrial Technology - AIT",
    "Master of Liberal Arts - ALM",
    "Associate in Medical Science - AMSC",
    "Associate of Nursing - AN",
    "Associate in Occupational Studies - AOS",
    "Associate in Occupational Technology - AOT",
    "Associate of Public Service - APS",
    "Associate of Political science - APS",
    "Associate in Physical Therapy - APT",
    "Associate of Science - AS",
    "Associate of Science in Dental Hygiene - ASDN",
    "Associate of Science in Nursing - ASN",
    "Associate in Physical Therapy - ASPT",
    "Associate Degree of Computing - ASSOCDEGCOMP",
    "Associate Degree of Technology - ASSOCDEGTECH",
    "Associate Degree of Technology Management - ASSOCDEGTECHMGT",
    "Associate of Technology - AT",
    "Doctor of Audiology - AUD",
    "Bachelor of Arts - BA",
    "Bachelor of Applied Arts - BAA",
    "Bachelor of Applied Arts and Sciences - BAAS",
    "Bachelor of Arts in Communication - BACOM",
    "Bachelor of Aerospace Engineering - BAE",
    "Bachelor of Arts and Economics - BAE",
    "Bachelor of Economics - BAECON",
    "Bachelor of Arts in Education - BAED",
    "Bachelor of Applied Studies - BAS",
    "Bachelor of Applied Science - BAS",
    "Bachelor of Architectural Studies - BAS",
    "Bachelor of Applied Science - BASC",
    "Bachelor of Art & Technology - BAT",
    "Bachelor of Applied Technology - BATECH",
    "Bachelor of Accountancy - BACC",
    "Bachelor of Agricultural Science - BAGRSC",
    "Bachelor of Applied Finance - BAPPFIN",
    "Bachelor of Applied Science - BAPPSC",
    "Bachelor of Architecture - BARCH",
    "Bachelor of Asian Studies - BAS",
    "Bachelor of Business Analysis - Financial - BBAFIN",
    "Bachelor of Business Administration - BBA",
    "Bachelor of Built Environment - BBE",
    "Bachelor of Biosystems Engineering - BBE",
    "Bachelor of Behavioural Neuroscience - BBNSC",
    "Bachelor of Business - BBUS",
    "Bachelor of Commerce and Administration - BCA",
    "Bachelor of Civil Engineering - BCE",
    "Bachelor of Computer Information Systems - BCIS",
    "Bachelor of Criminal Justice - BCJ",
    "Bachelor of Computer and Mathematical Sciences - BCM",
    "Bachelor of Computing & Mathematical Sciences - BCMS",
    "Bachelor of Surgery - BCH",
    "Bachelor of Chemical Engineering - BCHEE",
    "Bachelor of Commerce - BCOM",
    "Bachelor of Commerce - BCOMM",
    "Bachelor of Communication - BCOMN",
    "Bachelor of Computing - BCOMP",
    "Bachelor of Computer Operations Technology - BCOT",
    "Bachelor of Communications Studies - BCS",
    "Bachelor of Design - BDES",
    "Bachelor of Engineering - BE",
    "Bachelor of Electrical Engineering - BEE",
    "Bachelor of Electronics Engineering Technology - BEET",
    "Bachelor of Environmental Studies - BES",
    "Bachelor of Economics - BEC",
    "Bachelor of Finance & Economics - BECONFIN",
    "Bachelor of Economic Science - BECONSC",
    "Bachelor of Education - BED",
    "Bachelor of Environmental Design - BEND",
    "Bachelor of Engineering - BENG",
    "Bachelor of Fine Arts - BFA",
    "Bachelor of Finance - BFIN",
    "Bachelor of General Studies - BGS",
    "Bachelor of Game & Simulation Programming - BGSP",
    "Bachelor of Humanities and Arts - BHA",
    "Bachelor of Hotel Management - BHM",
    "Bachelor of Health Science - BHS",
    "Bachelor of Health Sciences - BHSC",
    "Bachelor of Interior Architecture - BIARCH",
    "Bachelor of Integrated Studies - BIS",
    "Bachelor of Interdisciplinary Studies - BIS",
    "Bachelor of Industrial and Science Engineering - BISE",
    "Bachelor of Information Technology - BIT",
    "Bachelor of Industrial Design - BINDSN",
    "Bachelor of Information Science - BINFSCI",
    "Bachelor of Information Technology - BINFTECH",
    "Bachelor of International Studies - BINTST",
    "Bachelor of Journalism - BJ",
    "Bachelor of Liberal Arts - BLA",
    "Bachelor of Landscape Architecture - BLARCH",
    "Bachelor of Liberal Studies - BLS",
    "Bachelor of Languages - BLANG",
    "Bachelor of Music - BM",
    "Bachelor of Mechanical Engineering - BME",
    "Bachelor of Music Education - BMED",
    "Bachelor of Materials Engineering - BMTLE",
    "Bachelor of Music - BMUS",
    "Bachelor of Public Affairs - BPA",
    "Bachelor of Polymer and Fiber Engineering - BPFE",
    "Bachelor of Professional Health Science - BPHS",
    "Bachelor of Science - BS",
    "Bachelor of Science in Aerospace Engineering - BSAE",
    "Bachelor of Science in Business Administration - BSBA",
    "Bachelor of Science in Biomedical Engineering - BSBME",
    "Bachelor of Science in Commerce Business Administration - BSCBA",
    "Bachelor of Science in Civil Engineering - BSCE",
    "Bachelor of Science in Computer & Information Sciences - BSCIS",
    "Bachelor of Science in Computer Science - BSCS",
    "Bachelor of Science in Computer Technology - BSCT",
    "Bachelor of Science in Chemical Engineering - BSCHEE",
    "Bachelor of Science in Chemistry - BSCHEM",
    "Bachelor of Science in Engineering - BSE",
    "Bachelor of Science in Electrical Engineering - BSEE",
    "Bachelor of Science in Engineering Technology - BSET",
    "Bachelor of Science in Education - BSED",
    "Bachelor of Science in Geology - BSGEO",
    "Bachelor of Science in Human Environmental Sciences - BSHES",
    "Bachelor of Science in Mechanical Engineering - BSME",
    "Bachelor of Science in Manufacturing Engineering Technology - BSMFT",
    "Bachelor of Science in Metallurgical Engineering - BSMET",
    "Bachelor of Science in Microbiology - BSMICR",
    "Bachelor of Science in Materials Engineering - BSMTE",
    "Bachelor of Science in Nursing - BSN",
    "Bachelor of Science in Social Work - BSSW",
    "Bachelor of Social Work - BSW",
    "Bachelor of Software Engineering - BSWE",
    "Bachelor of Textile Engineering - BTE",
    "Bachelor of Technical & Interdisciplinary Studies - BTIS",
    "Bachelor of Textile Management and Technology - BTMT",
    "Bachelor of Wireless Engineering - BWE",
    "Bachelor of Arts - BA",
    "Bachelor of Economics - BAECON",
    "Bachelor of Education - BAED",
    "Bachelor of Arts and Economics - BAE",
    "Bachelor of Journalism - BAJ",
    "Bachelor of Architecture - BARCH",
    "Bachelor of Applied Science - BAS",
    "Bachelor of Applied Studies - BAS",
    "Bachelor of Applied Science - BASC",
    "Bachelor of Arts for Teaching - BAT",
    "Bachelor of Aviation - BAVN",
    "Bachelor of Biomedical Science - BBIOMEDSC",
    "Bachelor of Business Information Systems - BBIS",
    "Bachelor of Surgery - BC",
    "Bachelor of Surgery - BCH",
    "Bachelor of Dental Surgery - BCHD",
    "Bachelor of Surgery Degree - BCHIR",
    "Bachelor of Civil Law - BCL",
    "Bachelor of Counseling - BCOUN",
    "Bachelor of Divinity - BD",
    "Bachelor of Divisionnity - BD",
    "Bachelor of Design - BDES",
    "Bachelor of Dental Surgery - BDS",
    "Bachelor of Dental Science - BDSC",
    "Bachelor of Engineering - BE",
    "Bachelor of Economics - BECON",
    "Bachelor of Economics and Finance - BECONFIN",
    "Bachelor of Science in Education - BED",
    "Bachelor of Engineering - BENG",
    "Bachelor of Fine Arts - BFA",
    "Bachelor of Finance - BFIN",
    "Bachelor of General Studies - BGS",
    "Bachelor of Health & Physical Education - BHPE",
    "Bachelor of Health Science - BHS",
    "Bachelor of Health Science - BHSC",
    "bachelor of hygiene - BHYG",
    "Bachelor of Information and Communications Technology - BICT",
    "Bachelor of Integrated Studies - BIS",
    "Bachelor of Journalism - BJ",
    "Bachelor of Kinesiology - BKIN",
    "Bachelor of Liberal Arts - BLA",
    "Bachelor of Landscape Architecture - BLARCH",
    "Bachelor of Music (degree) - BM",
    "Bachelor of Medicine - BM",
    "Bachelor of Biomedical science - BMEDSC",
    "Bachelor of Medical Science - BMEDSC",
    "Bachelor of Medical Science - BMEDSCI",
    "Bachelor of Midwifery - BMID",
    "Bachelor of Ministry - BMIN",
    "Bachelor of Medical Science - BMS",
    "Bachelor of Biomedical science - BMSC",
    "Bachelor of Medical Science - BMSC",
    "Bachelor of Music - BMUS",
    "Bachelor of Nursing - BN",
    "Bachelor of Nursing Science - BNSC",
    "Bachelor of Nursing - BNURS",
    "Bachelor of Physical Education - BPE",
    "Bachelor of Pharmacy - BPHARM",
    "Bachelor of Philosophy - BPHIL",
    "bachelor of public health nursing - BPHN",
    "Bachelor of Professional Studies - BPS",
    "Bachelor of Religious Education - BRE",
    "Bachelor of Religious Studies - BRS",
    "Bachelor of Science (clear in education line) - BS",
    "Bachelor of Science in Education - BSINED",
    "Bachelor of Science in Business Administration - BSBA",
    "Bachelor of Science (university degree) - BSC",
    "Bachelor of Science and/with Education - BSCED",
    "Bachelor of Science in Economics - BSCECON",
    "Bachelor of Science in Engineering - BSCENG",
    "Bachelor of Science in Psychology - BSCPSYCH",
    "Bachelor of Nursing Science - BSCN",
    "Bachelor of Science in Education - BSE",
    "Bachelor of Science in Engineering - BSE",
    "Bachelor of Science in Education - BSED",
    "Bachelor of Science in Environmental Health - BSEH",
    "Bachelor of Science in Engineering Technology - BSET",
    "Bachelor of Science in Family and Consumer Sciences - BSFC",
    "Bachelor of Science in General Studies - BSGS",
    "Bachelor of Journalism - BSJ",
    "Bachelor of Science in Law - BSL",
    "Bachelor of Science in Medicine - BSM",
    "Bachelor of Science in Nursing - BSN",
    "Bachelor of Science in Nuclear Engineering - BSNE",
    "Bachelor of Social Science - BSOCSC",
    "Bachelor of Science in Public Health - BSPH",
    "Bachelor of Social Work - BSW",
    "Bachelor of Teaching - BTCHG",
    "Bachelor of Technology - BTECH",
    "Bachelor of Theology - BTH",
    "Bachelor of Theology - BTHEOL",
    "Bachelor of Veterinary Medicine - BVETMED",
    "Bachelor of Veterinary Medicine and Science - BVMS",
    "Bachelor of Surgery - CB",
    "Bachelor of Surgery - CHB",
    "Doctor of Surgery - CHD",
    "Master in Surgery - CM",
    "Doctor of Arts - DA",
    "Doctor of Applied Science - DAS",
    "Doctor of Architecture - DARCH",
    "Doctor of Business Administration - DBA",
    "Doctor of Criminal Justice - DCJ",
    "Doctor of Comparative Law - DCL",
    "Doctor of Civil Law - DCL",
    "Doctor of Computer Science - DCS",
    "Doctor of Chemistry - DCHEM",
    "Doctor of Criminology - DCRIM",
    "Doctor of Engineering Science - DESC",
    "Doctor of Engineering - DENG",
    "Doctor of Environmental Science and Engineering - DENV",
    "Doctor of Forestry - DF",
    "Doctor of Fine Arts - DFA",
    "Doctor of Geological Science - DGS",
    "Doctor of Health Education - DHED",
    "Doctor of Hebrew Literature/Letters - DHL",
    "Doctor of Health and Safety - DHS",
    "Doctor of Hebrew Studies - DHS",
    "Doctor of Humane Letters - DHUMLITT",
    "Doctor of Information Technology - DIT",
    "Doctor of Industrial Technology - DIT",
    "Doctor of Law and Policy - DLP",
    "Doctor of Library Science - DLS",
    "Doctor of Literature and Philosophy - DLITTETPHIL",
    "Doctor of Ministry - DM",
    "Doctor of Musical Arts - DMA",
    "Doctor of Musical Education - DME",
    "Doctor of Modern Languages - DML",
    "Doctor of Medical Science - DMSC",
    "Doctor of Music Therapy - DMT",
    "Doctor of Ministry - DMIN",
    "Doctor of Music - DMUS",
    "Doctor of Naprapathic Medicine - DN",
    "Doctor of Nursing Science - DNS",
    "Doctor of Nursing Science - DNSC",
    "Doctor of Public Administration - DPA",
    "Doctor of Physical Education - DPE",
    "Doctor of Public Health - DPH",
    "Doctor of Professional Studies - DPS",
    "Doctor of Physical Therapy - DPT",
    "Doctor of Recreation - DR",
    "Doctor of Recreation - DREC",
    "Doctor of Sacred Music - DSM",
    "Doctor of Social Science - DSSC",
    "Doctor of Social Work - DSW",
    "Doctor of Science - DSC",
    "Doctor of Science in Dentistry - DSCD",
    "Doctor of Science and Hygiene - DSCH",
    "Doctor of Science in Veterinary Medicine - DSCVM",
    "Doctor of Business Administration - DBA",
    "Doctor of Chiropractic - DC",
    "Doctor of Surgery - DCH",
    "Doctor of Civil Law - DCL",
    "Doctor of Clinical Psychology - DCLINPSYCH",
    "Divinitatis Doctor - DD",
    "Doctor of Divisionnity - DD",
    "Divisionnitatis Doctor - DD",
    "Doctor of Divinity - DD",
    "Doctor of Dental Surgery - DDS",
    "Doctor of Dental Science - DDSC",
    "Doctor of Literature - DLIT",
    "Doctor of Letters - DLITT",
    "Doctor of Medicine - DM",
    "Doctor of Dental Medicine - DMD",
    "Doctor of Medical Education - DME",
    "Doctor of Ministry - DMIN",
    "Doctor of Medical Science - DMSC",
    "Doctor of Medical Technology - DMT",
    "Doctor of Music - DMUS",
    "Doctor of Veterinary Medicine - DMV",
    "Doctor of Nursing - DN",
    "Doctor of Nursing Education - DNE",
    "Doctor of Nursing Science - DNS",
    "Doctor of Nursing Science - DNSC",
    "Doctor of Nursing Science - DNURSSCI",
    "Doctor of Osteopathy - DO",
    "Doctor of Ocular Science - DOS",
    "Doctor of Pharmacy - DP",
    "Doctor of Public Health - DPH",
    "Doctor of Philosophy - DPHIL",
    "Doctor of Public Health Nursing - DPHN",
    "Doctor of Pediatric Medicine - DPM",
    "Doctor of Professional Studies - DPROF",
    "Doctor of Practical Theology - DPT",
    "Doctor of Design - DRDES",
    "Doctor of Public Health - DRPH",
    "Doctor of Science - DS",
    "Doctor of Science - DSC",
    "Doctor of Social Work - DSW",
    "Doctor of the University - DUNIV",
    "Doctor of Veterinary Medicine - DVM",
    "Doctor of Veterinary Medicine & Surgery - DVMS",
    "Doctor of Veterinary Radiology - DVR",
    "Doctor of Veterinary Science - DVS",
    "Doctor of Education - EDD",
    "Bachelor of Education - EDB",
    "Doctor of Education - EDD",
    "Doctor of Engineering - ENGD",
    "Doctor of Canon Law - JCD",
    "Juris Doctor - JD",
    "Doctor of Juridical Science - JSD",
    "Doctor of Law and Policy - LPD",
    "Doctor of the Science of Law - LSCD",
    "doctor of letters - LITTD",
    "Master of Laws - LLM",
    "Legum Baccalaureus (Latin: Bachelor Of Laws) - LLB",
    "doctor of laws - LLD",
    "Master of Laws - LLM",
    "Licensed Master of Social Work - LMSW",
    "Master of Arts in Education - MAED",
    "Doctor of Medicine - MD",
    "Master of Education - MED",
    "Master of Fine Arts - MFA",
    "Master of Interdisciplinary Studies - MIS",
    "Master of Library and Information Science - MLIS",
    "Master of Ministry - MMIN",
    "Master of Music - MMUS",
    "Master of Occupational Therapy - MOT",
    "Master of Science in Engineering Technology - MSET",
    "Master of Science in Education - MSED",
    "Master of Science in Nursing - MSN",
    "Master of Social Work - MSW",
    "Master of Urban Planning - MUP",
    "Master of Arts - MA",
    "Master of Arts in Education - MAED",
    "Master of Applied Anthropology - MAA",
    "Master of Accountancy - MACC",
    "Master of Arts in Christian Education - MACE",
    "Master of Accountancy - MACY",
    "Master of Arts in international economics and finance - MAIEF",
    "Master of Arts in International Hotel Management - MAIHM",
    "Master of Arts in Liberal Studies - MALS",
    "Master of Arts Management - MAM",
    "Master of Arts in Public Service - MAPS",
    "Master of Arts in Professional Writing - MAPW",
    "Master of Architecture - MARCH",
    "Master of Archival Studies - MAS",
    "Master of Engineering - MASC",
    "Master of Applied Sciences - MASC",
    "Master of Arts and Teaching - MAT",
    "Bachelor of Medicine - MB",
    "Master of Business Administration - MBA",
    "Medicinae Baccalaureus, Baccalaureus Chirurgiae (Latin: Bachelor of Medicine, Bachelor of Surgery) - MBBS",
    "Master of Biochemistry - MBIOCHEM",
    "Master of Biology - MBIOL",
    "Master of Biological Science - MBIOLSCI",
    "Master of Surgery - MC",
    "Master of Surgery - MCH",
    "Master of Chemistry - MCHEM",
    "Master of Criminal Justice - MCJ",
    "Master of Clinical Dentistry - MCLINDENT",
    "Master of Clinical Medical Science - MCMSC",
    "Doctor of Medicine - MD",
    "Master of Divisionnity - MDIV",
    "Master of Divinity - MDIV",
    "Master of Digital Media - MDM",
    "Master of Drama - MDRAMA",
    "Master of Dental Surgery - MDS",
    "Master of Engineering - ME",
    "Master of Electronic Business - MEB",
    "Master of Economics - MECON",
    "Master of Education - MED",
    "Master of Environmental Design - MEDES",
    "Master of Engineering - MENG",
    "Master of Environmental Science - MENVSCI",
    "Master of Environmental Studies - MES",
    "Master of Earth Science - MESCI",
    "Master of Educational Technology - MET",
    "Master of Fine Art - MFA",
    "Master of Forensic Sciences - MFS",
    "Master of Geography - MGEOG",
    "Master of Geology - MGEOL",
    "Master of Geophysics - MGEOPHYS",
    "Master of Health Administration - MHA",
    "Master of Health Education - MHE",
    "Master of Health Science - MHS",
    "Master of Industrial Design - MID",
    "Master of International Development - MID",
    "Master of Management in the Network Economy - MINE",
    "Master of Informatics - MINF",
    "Master of Engineering - MING",
    "Master of Information Systems Management - MISM",
    "Master of Jurisprudence - MJUR",
    "Master of Labor and Human Resources - MLHR",
    "Master of Librarianship - MLIB",
    "Master of Library and Information Science degree - MLIS",
    "Master of Letters - MLITT",
    "Master of Library Science - MLS",
    "Master of Liberal Studies - MLS",
    "Master of Music - MM",
    "Master of Mathematics - MMATH",
    "Master of Mathematics and Physics - MMATHPHYS",
    "Master of Mass Communications - MMC",
    "Master of Music Education - MME",
    "Master of Ministry - MMIN",
    "Master of Mathematics, Operational Research, Statistics and Economics - MMORSE",
    "Master of Marketing Research - MMR",
    "Master of Medical Science - MMS",
    "Master of Medical Science - MMSC",
    "Master of Management Sciences - MMSC",
    "Master of Music - MMUS",
    "Master of Nursing - MN",
    "Master of Natural Science - MNATSC",
    "Master of Science in Nursing - MNUR",
    "Master of Nursing Science - MNURSSCI",
    "Master of Oceanography - MOCEAN",
    "Master of Occupational Therapy - MOT",
    "Master of Pharmacy Administration - MPA",
    "Master of Public Administration - MPA",
    "Master of Professional Accountancy - MPA",
    "Master of Physician Assistant Studies - MPAS",
    "Master of Pharmacy - MPH",
    "Master of Public Health - MPH",
    "master of pharmacy - MPHARM",
    "Master of Philosophy - MPHIL",
    "Master of Physics - MPHYS",
    "Master of Pacific International Affairs - MPIA",
    "Master of Planning - MPLAN",
    "Master of Project Management - MPM",
    "Master of Public Management - MPM",
    "Master of Public Policy - MPP",
    "Master of Professional Studies - MPROFSTUDS",
    "Master of Professional Studies - MPS",
    "Master of Physical Therapy - MPT",
    "Master of Radiology - MRAD",
    "Master of Research - MRES",
    "Master of Science - MS",
    "Master of Surgery - MS",
    "Master of Science in Education - MSED",
    "Master of Surgery - MSC",
    "Master of Science - MSC",
    "Master in Science - MSCI",
    "Master of Science in Dentistry - MSD",
    "Master of Science in Engineering - MSE",
    "Master of Science in Electrical Engineering - MSEE",
    "Master of Science in Environmental Health - MSEH",
    "Master of Science in Finance - MSF",
    "Master of Science in Information - MSI",
    "Master of Science in Information Systems - MSIS",
    "Master of Science in Information Studies - MSIS",
    "Master of Criminal Justice - MSJA",
    "Master of Science in Library Science - MSLS",
    "Master of Sacred Music - MSM",
    "Master Of Science In Nursing - MSN",
    "Master of Science in Natural Sciences - MSNS",
    "Master of Social Science - MSOCSC",
    "Master of Science In Organizational Leadership - MSOL",
    "Master of Science in Public Health - MSPH",
    "Master of Social Science - MSSC",
    "Master of Science in Strategic Leadership - MSSL",
    "Master of Science in Social Work - MSSW",
    "Master of Studies - MST",
    "Master of Science in Taxation - MST",
    "Master of Statistics - MSTAT",
    "Master of Surgery - MSURG",
    "Master of Social Work - MSW",
    "Master of Theology - MTH",
    "Master of Theology - MTHEOL",
    "Master of the University - MUNIV",
    "Master of Urban Studies - MURB",
    "Master of Urban and Regional Planning - MURP",
    "Doctor of Music - MUSDOC",
    "Bachelor of Music - MUSB",
    "Doctor of Music - MUSD",
    "Master of Music - MUSM",
    "Doctor of Naturopathy - ND",
    "Doctor of Optometry - OD",
    "Post Master's Graduate Certificate - PMGC",
    "Doctor of Pharmacy - PD",
    "Doctor of Philosophy - PHD",
    "Doctor of Philosophy - PHD",
    "Doctor of Pharmacy - PHARMD",
    "Doctor of Pharmacy - PHARMD",
    "Doctor of Philosophy - PHD",
    "Doctor of Psychology - PSYD",
    "Doctor of Rehabilitation - RHD",
    "Doctor of Juridical Science - SJD",
    "Doctor of Sacred Theology - STD",
    "Bachelor of Science - SB",
    "Doctor of Science - SCD",
    "Doctor of Engineering Science - SCDE",
    "Scientiar Baccalaureus, Bachelor of Science - SCB",
    "Doctor of Science - SCD",
    "Master of Surgery - SM",
    "Doctor of Social Science - SOCSCID",
    "Master of Theology - STM",
    "Doctor of Theology - THD",
    "Doctor of Practical Theology - THPD",
    "Bachelor of Theology - THB",
    "Doctor of Theology - THD",
    "Master of Theology - THM",
    "Veterinary Medical Doctor - VMD"
]

export const fieldOfStudyArray = [
    "Zoology/Animal Biology",
    "Zoological Medicine Residency Program",
    "Youth Ministry",
    "Yoga Teacher Training/Yoga Therapy",
    "Youth Services/Administration",
    "Children and Youth Library Services",
    "Wood Science and Wood Products/Pulp and Paper Technology",
    "Plumbing and Related Water Supply Services",
    "Women's Studies",
    "Hydrology and Water Resources Science",
    "Wildlife, Fish and Wildlands Science and Management",
    "Wildlife Biology",
    "Data Modeling/Warehousing and Database Administration",
    "Water Resources Engineering",
    "Woodwind Instruments",
    "Water, Wetlands, and Marine Resources Management",
    "Welding Technology/Welder",
    "Social Work",
    "Foods, Nutrition, and Wellness Studies, General",
    "Creative Writing",
    "System, Networking, and LAN/WAN Management/Manager",
    "Health and Wellness, General",
    "Clinical/Medical Social Work",
    "Web/Multimedia Management and Webmaster",
    "Writing, General",
    "Web Page, Digital/Multimedia and Information Resources Design",
    "Veterinary Preventive Medicine, Epidemiology, and Public Health",
    "Veterinary Microbiology and Immunobiology",
    "Voice and Opera Virology",
    "Viticulture and Enology",
    "Photographic and Film/Video Technology/Technician and Assistant",
    "Veterinary Pathology and Pathobiology",
    "Film/Video and Photographic Arts",
    "Vision Science/Physiological Optics",
    "Practical Nursing, Vocational Nursing and Nursing Assistants",
    "Modeling, Virtual Environments and Simulation",
    "Design and Visual Communications, General",
    "Film/Cinema/Video Studies",
    "Cinematography and Film/Video Production",
    "Veterinary Medicine",
    "Animation, Interactive Technology, Video Graphics and Special Effects",
    "Visual and Performing Arts",
    "Vocational Rehabilitation Counseling/Counselor",
    "Veterinary/Animal Health Technology/Technician and Veterinary Assistant",
    "Veterinary Sciences/Veterinary Clinical Sciences, General",
    "Ukraine Studies",
    "Uralic Languages, Literatures, and Linguistics",
    "Urban Ministry Urban Forestry",
    "Make-Up Artist/Specialist",
    "Health Unit Coordinator/Ward Clerk",
    "Upholstery/Upholsterer",
    "Ukrainian Language and Literature",
    "Urdu Language and Literature",
    "Ural-Altaic and Central Asian Studies",
    "Health Unit Manager/Ward Supervisor",
    "American/United States Studies/Civilization",
    "City/Urban, Community and Regional Planning",
    "Urban Studies/Affairs",
    "American History (United States)",
    "American Government and Politics (United States)",
    "American/U.S. Law/Legal Studies/Jurisprudence",
    "Urban Education and Leadership",
    "Diagnostic Medical Sonography/Sonographer and Ultrasound Technician",
    "Land Use Planning and Management/Development",
    "Secondary Education and Teaching",
    "Early Childhood Education and Teaching ",
    "Tourism and Travel Services Management",
    "Information Technology Project Management",
    "Theoretical and Mathematical Physics",
    "Taxation",
    "Computer Technology/Computer Systems Technology",
    "Physical Therapy/Therapist",
    "Culinary Arts/Chef Training",
    "Special Education and Teaching",
    "Educational/Instructional Technology",
    "Information Technology",
    "Computer Systems Networking and Telecommunications",
    "International Business/Trade/Commerce",
    "Elementary Education and Teaching",
    "Computer/Information Technology Administration and Management",
    "Theology/Theological Studies",
    "Telecommunications Engineering",
    "Theatre/Theater",
    "Electrical, Electronic and Communications Engineering Technology/Technician",
    "Computer Programming, Specific Applications",
    "Mathematics and Computer Science",
    "Business, Management, Marketing, and Related Support Services",
    "Art/Art Studies, General",
    "Fine/Studio Arts, General",
    "Mass Communication/Media Studies",
    "Computer Software Engineering",
    "Geology/Earth Science, General",
    "Human Resources Management and Services",
    "Russian, Central European, East European and Eurasian Studies",
    "Radio and Television",
    "Information Resources Management",
    "Religion/Religious Studies",
    "Speech Communication and Rhetoric",
    "Operations Research",
    "Labor and Industrial Relations",
    "Human Resources Development",
    "Mechanics and Repairers, General",
    "Mechanical Engineering Related Technologies/Technicians",
    "Human Resources Management/Personnel Administration, General",
    "Registered Nursing/Registered Nurse",
    "International Relations and Affairs",
    "Public Relations/Image Management",
    "Real Estate",
    "Agriculture, Agriculture Operations, and Related Sciences",
    "General Sales, Merchandising and Related Marketing Operations",
    "Computer Science",
    "Political Science and Government",
    "General Studies",
    "Computer and Information Sciences and Support Services",
    "Communication and Media Studies",
    "Sociology",
    "Physical Sciences",
    "Management Information Systems, General",
    "Liberal Arts and Sciences/Liberal Studies",
    "Econometrics and Quantitative Economics",
    "Management Sciences and Quantitative Methods",
    "Research Methodology and Quantitative Methods",
    "Quality Control Technology/Technician",
    "Water Quality and Wastewater Treatment Management and Recycling Technology/Technician",
    "Psychometrics and Quantitative Psychology",
    "Quality Control and Safety Technologies/Technicians",
    "Industrial and Product Design",
    "Photography",
    "Counseling Psychology",
    "Legal Assistant/Paralegal",
    "Clinical Psychology",
    "Non-Profit/Public/Organizational Management",
    "Computer Programming",
    "Psychology",
    "Human Resources Management / Personnel Administration, General",
    "Physics",
    "Project Management",
    "Pharmacy",
    "Philosophy",
    "Public Administration",
    "Military Operational Art and Science / Studies",
    "Oceanography, Chemical and Physical",
    "Occupational Therapy / Therapist",
    "Organizational Communication, General",
    "Marine Biology and Biological Oceanography",
    "Graphic and Printing Equipment Operator, General Production",
    "Organizational Behavior Studies",
    "Business / Office Automation / Technology / Data Entry",
    "Specialized Sales, Merchandising and Marketing Operations",
    "Aviation / Airway Management and Operations",
    "Optometry",
    "Organizational Leadership",
    "Non - Profit / Public / Organizational Management",
    "Operations Management and Supervision",
    "Organic Chemistry",
    "Industrial and Organizational Psychology",
    "Business Administration, Management and Operations",
    "Natural Resources / Conservation, General",
    "Family Practice Nurse / Nursing",
    "System, Networking, and LAN / WAN Management / Manager",
    "Nuclear Engineering",
    "Network and System Administration / Administrator",
    "National Security Policy Studies",
    "Nutrition Sciences",
    "Energy, Environment, and Natural Resources Law",
    "Neurobiology and Behavior",
    "Human Nutrition",
    "Natural Resources Management and Policy",
    "Registered Nursing / Registered Nurse",
    "Neuroscience",
    "Classical, Ancient Mediterranean and Near Eastern Studies and Archaeology",
    "Naval Architecture and Marine Engineering",
    "Natural Sciences",
    "Cosmetology, Barber / Styling, and Nail Instructor",
    "Finance and Financial Management Services",
    "Medicine",
    "Business/Managerial Economics",
    "Engineering/Industrial Management",
    "Music",
    "Accounting and Business/Management",
    "Business Administration and Management, General",
    "Marketing",
    "Mechanical Engineering",
    "Mathematics",
    "Marketing/Marketing Management, General",
    "Literature",
    "Speech-Language Pathology/Pathologist",
    "Banking, Corporate, Finance, and Securities Law",
    "Library and Information Science",
    "Linguistics",
    "Landscape Architecture",
    "Library Science",
    "Legal Studies, General",
    "Clinical Laboratory Science/Medical Technology/Technologist",
    "International Law and Legal Studies",
    "Law",
    "English Language and Literature/Letters",
    "English Literature (British and Commonwealth)",
    "Logistics, Materials, and Supply Chain Management",
    "English Language and Literature, General",
    "Educational Leadership and Administration, General",
    "Law Enforcement Record-Keeping and Evidence Management",
    "Birthing and Parenting Knowledge and Skills",
    "Health-Related Knowledge and Skills",
    "Kinesiology and Exercise Science",
    "Keyboard Instruments",
    "Knowledge Management",
    "Korean Studies",
    "Korean Language and Literature",
    "Food Preparation/Professional Cooking/Kitchen Assistant",
    "Kindergarten/Preschool Education and Teaching",
    "Kinesiotherapy/Kinesiotherapist",
    "Khmer/Cambodian Language and Literature",
    "Juvenile Corrections",
    "Navy/Marine Corps JROTC/ROTC",
    "Jazz/Jazz Studies",
    "Agricultural Communication/Journalism",
    "Criminal Justice/Law Enforcement Administration",
    "Junior High/Intermediate/Middle School Education and Teaching",
    "Watchmaking and Jewelrymaking",
    "Metal and Jewelry Arts",
    "Army JROTC/ROTC",
    "Air Force JROTC/ROTC",
    "Journalism",
    "Criminal Justice and Corrections",
    "Broadcast Journalism",
    "Japanese Studies",
    "Japanese Language and Literature",
    "Criminal Justice/Police Science",
    "Canadian Law/Legal Studies/Jurisprudence",
    "Criminal Justice/Safety Studies",
    "Jewish/Judaic Studies",
    "Computer and Information Sciences, General",
    "Spanish and Iberian Studies",
    "Informatics",
    "International/Global Studies",
    "International Marketing",
    "International Business",
    "Industrial Engineering",
    "Interior Design",
    "Health Services Administration",
    "Dental Hygiene/Hygienist",
    "Public Health",
    "Health and Physical Education/Fitness",
    "Health Services/Allied Health/Health Sciences, General",
    "Higher Education/Higher Education Administration",
    "Human Services, General",
    "Human Development and Family Studies, General",
    "Homeland Security, Law Enforcement, Firefighting and Related Protective Services",
    "History",
    "Hospitality Administration/Management",
    "Health/Health Care Administration/Management",
    "Art History, Criticism and Conservation",
    "Hotel/Motel Administration/Management",
    "Environmental/Environmental Health Engineering",
    "Humanities/Humanistic Studies",
    "Biology/Biological Sciences, General",
    "Geography",
    "Microbiology, General",
    "Communication, General",
    "Business/Commerce, General",
    "Finance, General",
    "Biology, General",
    "Graphic Design",
    "International Finance",
    "French Language and Literature",
    "Financial Planning and Services",
    "Fashion Merchandising",
    "Facilities Planning and Management",
    "Food Science",
    "Accounting and Finance",
    "Banking and Financial Support Services",
    "French Studies",
    "Sport and Fitness Administration/Management",
    "Fashion/Apparel Design",
    "Aerospace, Aeronautical and Astronautical Engineering",
    "Biomedical/Medical Engineering",
    "Economics",
    "Electrical and Electronics Engineering",
    "Engineering",
    "Computer Engineering",
    "Civil Engineering",
    "Chemical Engineering",
    "Electrical, Electronics and Communications Engineering",
    "Divinity/Ministry",
    "Development Economics and International Development",
    "Pharmaceutics and Drug Design",
    "Data Processing",
    "Digital Communication and Media/Multimedia",
    "Dance",
    "Dietetics/Dietitian",
    "Business/Office Automation/Technology/Data Entry",
    "Graphic Design Interior Design",
    "Design and Applied Arts",
    "Dentistry",
    "Communication Sciences and Disorders, General",
    "Drama and Dramatics/Theatre Arts, General",
    "Health/Health Care",
    "Administration/Management",
    "Chemistry",
    "Biotechnology",
    "Business/Corporate Communications",
    "Molecular Biology",
    "Biochemistry",
    "Advertising",
    "Accounting",
    "Architecture"
]