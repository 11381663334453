import React from "react";
import NavButton from "./NavButton";
import { useMediaQuery } from "@material-ui/core";
import MailIcon from "@material-ui/icons/Mail";
import ExploreIcon from "@material-ui/icons/Explore";
import AssessmentIcon from "@material-ui/icons/Assessment";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { HiBuildingOffice2 } from "react-icons/hi2";

const NavButtonInfos = [
  {
    icon: AssessmentIcon,
    text: "Dashboard",
    link: "/",
  },
  {
    icon: HiBuildingOffice2,
    text: "Projects",
    link: "/projects",
  },
  {
    icon: MailIcon,
    text: "Messages",
    link: "/messages",
  },
  {
    icon: ExploreIcon,
    text: "Feeds",
    link: "/feed",
  },
  {
    icon: AccountBoxIcon,
    text: "Account",
    link: "/account",
  },
];

export default function Navbar(props) {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {NavButtonInfos.map((NBInfo, index) => {
        return (
          <NavButton
            key={NBInfo.text}
            text={NBInfo.text}
            icon={NBInfo.icon}
            index={index}
            link={NBInfo.link}
          />
        );
      })}
    </>
  );
}
