import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Checkbox } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import GooglePlaceAutocomplete from "../../styled/CommonComponents/Google.Place.Auto";
import PhotoDetails from "./Photo.Details";
import { addExpProjectHitApi } from "../api";
import SaveIcon from "@material-ui/icons/Save";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";
import * as yup from "yup";
import { nanoid } from "nanoid";

const schema = yup.object().shape({
	title: yup.string().min(3).max(250).required("Title should be required"),
	role: yup.string().min(3).max(250).required("Role should be required"),
	startMonth: yup.string().required("Start month should be required"),
	startYear: yup.string().required("Start year should be required"),
});

const useStyles = makeStyles((theme) => ({
	singleEducationCont: {
		width: "550px",
		maxHeight: "525px",
		overflowY: "auto",
		marginBottom: "20px",
		padding: "0px 15px 10px",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		scrollbarWidth: "none",
		[theme.breakpoints.down("sm")]: {
			width: "450px",
		},
		[theme.breakpoints.down("xs")]: {
			padding: "0px 5px 10px",
			width: "300px",
			maxHeight: "360px",
		},
	},
	textLineCont: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
		},
	},
	halfCont: {
		width: "47%",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
		},
	},
	checkedCont: {
		display: "flex",
		alignItems: "center",
		marginBottom: "-10px",
		"& h3": {
			fontSize: "15px",
			marginRight: "15px",
			fontWeight: "500",
		},
	},
	label: {
		fontSize: "15px",
		fontWeight: "500",
		marginTop: "7px",
		marginBottom: "0px",
	},
	addressCont: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
}));

const calendarMonth = [
	"January",
	"February",
	"March",
	"April",
	"May",
	"June",
	"July",
	"August",
	"September",
	"October",
	"November",
	"December",
];
const slugify = (str) =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, "")
		.replace(/[\s_-]+/g, "-")
		.replace(/^-+|-+$/g, "");
const ProjectDialog = ({ open, setOpen }) => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { createdFileIds } = useSelector((state) => state.file);

	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [projectExp, setProjectExp] = useState("");
	const [role, setRole] = useState("");
	const [startMonthVal, setStartMonthVal] = useState("");
	const [startYearVal, setStartYearVal] = useState("");
	const [endMonthVal, setEndMonthVal] = useState("");
	const [endYearVal, setEndYearVal] = useState("");
	const [isCurrentlyWorking, setIsCurrentlyWorking] = useState(false);
	const [lot, setLot] = useState("");
	const [area, setArea] = useState("");
	const [year, setYear] = useState("");
	const [price, setPrice] = useState("");
	const [fullAddressLine, setFullAddressLine] = useState("");
	const [streetAddress, setStreetAddress] = useState("");
	const [zip, setZip] = useState("");
	const [city, setCity] = useState("");
	const [region, setRegion] = useState("");
	const [regionCode, setRegionCode] = useState("");
	const [country, setCountry] = useState("");
	const [countryCode, setCountryCode] = useState("");
	const [latitude, setLatitude] = useState(null);
	const [longitude, setLongitude] = useState(null);
	const [calendarYear, setCalendarYear] = useState([]);
	const [fileObj, setFileObj] = useState([]);
	const [loading, setLoading] = useState(false);
	const [validationObj, setValidationObj] = useState({});

	const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

	const handleClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		const years = [];
		const date = new Date();
		const endYear = date.getFullYear();
		for (let i = 1951; i <= endYear; i++) {
			years.push(i + "");
		}
		setCalendarYear(years);
	}, []);

	useEffect(() => {
		setValidationObj({});
	}, [title, role, startMonthVal, startYearVal]);

	const addNewProject = async (e) => {
		e.preventDefault();
		const formData = {
			title: title,
			role: role,
			startMonth: startMonthVal,
			startYear: startYearVal,
		};

		await schema
			.validate(formData, { abortEarly: false })
			.then(async (data) => {
				setLoading(true);
				await addExpProjectHitApi({
					displayName: title,
					description: description,
					projectIdCode: `${slugify(title).slice(0, 5)}-${nanoid(8)}`,
					address: {
						streetAddress: streetAddress,
						zip: zip,
						city: city,
						region: region,
						regionCode: regionCode,
						country: country,
					},
					latitude: latitude,
					longitude: longitude,
					user: user?._id,
					userModelName: "User",
					creator: user?._id,
					createrProfile: user?.profile,
					projectExpDesc: projectExp,
					projectRole: region,
					startMonth: startMonthVal,
					startYear: startYearVal,
					endMonth: endMonthVal,
					endYear: endYearVal,
					isCurrentlyWorking: isCurrentlyWorking,
					area: area,
					year: year,
					lotSize: lot,
					price: price,
					fileObjArr: fileObj,
				})
					.then((data) => {
						dispatch({
							type: "AddAuth",
							payload: {
								user: {
									...user,
									projectExp: data?.projectExp,
								},
							},
						});
						setLoading(false);
					})
					.catch((err) => {
						console.log(err);
					});
				setTitle("");
				setDescription("");
				setProjectExp("");
				setRole("");
				setStartMonthVal("");
				setStartYearVal("");
				setEndMonthVal("");
				setEndYearVal("");
				setIsCurrentlyWorking(false);
				setLot("");
				setArea("");
				setYear("");
				setPrice("");
				setFullAddressLine("");
				setStreetAddress("");
				setZip("");
				setCity("");
				setRegion("");
				setRegionCode("");
				setCountry("");
				setCountryCode("");
				setLatitude(null);
				setLongitude(null);
				setFileObj([]);
				setOpen(false);
			})
			.catch((err) => {
				let errorArr = err?.inner;
				let obj = {};
				errorArr.map((err) => {
					obj[err?.path] = err?.message;
				});
				setValidationObj(obj);
			});
	};

	return (
		<NormalDialog
			openDialog={open}
			handleCloseShare={handleClose}
			pageTitle={"Add Project"}
			content={
				<form onSubmit={addNewProject}>
					<div className={classes.singleEducationCont}>
						<p className={classes.label}>Title</p>
						<TextField
							id="school"
							placeholder="Enter project name"
							value={title}
							onChange={(e) => {
								setTitle(e.target.value);
							}}
							size="small"
							variant="outlined"
							style={{ width: "100%" }}
						/>
						{validationObj?.title && (
							<p style={{ color: "#EE1D52" }}>
								{validationObj?.title}
							</p>
						)}
						<p className={classes.label}>Project Description</p>
						<TextField
							id="description"
							placeholder="Write anything notable..."
							multiline
							rows={3}
							variant="outlined"
							size="small"
							value={description}
							onChange={(e) => {
								setDescription(e.target.value);
							}}
							fullWidth
						/>
						<GooglePlaceAutocomplete
							inputContStyle={classes.addressCont}
							autoWidth={"100%"}
							textWidth={"100%"}
							isGetLogLat={true}
							fullAddressLine={fullAddressLine}
							setFullAddressLine={setFullAddressLine}
							streetAddress={streetAddress}
							setStreetAddress={setStreetAddress}
							zip={zip}
							setZip={setZip}
							city={city}
							setCity={setCity}
							region={region}
							setRegion={setRegion}
							regionCode={regionCode}
							setRegionCode={setRegionCode}
							country={country}
							setCountry={setCountry}
							countryCode={countryCode}
							setCountryCode={setCountryCode}
							latitude={latitude}
							setLatitude={setLatitude}
							longitude={longitude}
							setLongitude={setLongitude}
							isShowCountry={true}
							isShowCityStreet={true}
							isStateZip={true}
						/>
						<div className={classes.textLineCont}>
							<div className={classes.halfCont}>
								<p className={classes.label}>Lot Size</p>
								<TextField
									id="school"
									type="number"
									placeholder="Enter lot size"
									value={lot}
									onChange={(e) => {
										setLot(e.target.value);
									}}
									size="small"
									variant="outlined"
									style={{ width: "100%" }}
								/>
							</div>
							<div className={classes.halfCont}>
								<p className={classes.label}>Area</p>
								<TextField
									id="degree"
									type="number"
									placeholder="Enter area size"
									value={area}
									onChange={(e) => {
										setArea(e.target.value);
									}}
									size="small"
									variant="outlined"
									style={{ width: "100%" }}
								/>
							</div>
						</div>
						<div className={classes.textLineCont}>
							<div className={classes.halfCont}>
								<p className={classes.label}>Year of build</p>
								<TextField
									id="school"
									type="number"
									placeholder="Enter build year"
									value={year}
									onChange={(e) => {
										setYear(e.target.value);
									}}
									size="small"
									variant="outlined"
									style={{ width: "100%" }}
								/>
							</div>
							<div className={classes.halfCont}>
								<p className={classes.label}>
									Project size (price)
								</p>
								<TextField
									id="degree"
									type="number"
									placeholder="Enter project size"
									value={price}
									onChange={(e) => {
										setPrice(e.target.value);
									}}
									size="small"
									variant="outlined"
									style={{ width: "100%" }}
								/>
							</div>
						</div>
						<p className={classes.label}>Project Role</p>
						<TextField
							id="description"
							placeholder="Enter your role in this Project"
							variant="outlined"
							size="small"
							value={role}
							onChange={(e) => {
								setRole(e.target.value);
							}}
							fullWidth
						/>
						{validationObj?.role && (
							<p style={{ color: "#EE1D52" }}>
								{validationObj?.role}
							</p>
						)}
						<p className={classes.label}>Start date</p>
						<div className={classes.textLineCont}>
							<div className={classes.halfCont}>
								<Autocomplete
									id="free-solo-demo"
									freeSolo
									value={startMonthVal}
									options={calendarMonth}
									getOptionLabel={(option) => {
										return option;
									}}
									getOptionSelected={(option) => {
										return option === startMonthVal;
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											style={{ margin: "0px" }}
											margin="normal"
											variant="outlined"
											placeholder={"Enter start month"}
										/>
									)}
									onChange={(event, value) => {
										setStartMonthVal(value);
									}}
									style={{
										width: "100%",
										marginBottom: "5px",
									}}
									size="small"
								/>
								{validationObj?.startMonth && (
									<p style={{ color: "#EE1D52" }}>
										{validationObj?.startMonth}
									</p>
								)}
							</div>
							<div className={classes.halfCont}>
								<Autocomplete
									id="free-solo-demo"
									freeSolo
									value={startYearVal}
									options={calendarYear}
									getOptionLabel={(option) => {
										return option;
									}}
									getOptionSelected={(option) => {
										return option === startYearVal;
									}}
									renderInput={(params) => (
										<TextField
											{...params}
											style={{ margin: "0px" }}
											margin="normal"
											variant="outlined"
											placeholder={"Enter start year"}
										/>
									)}
									onChange={(event, value) => {
										setStartYearVal(value);
									}}
									style={{
										width: "100%",
										marginBottom: "5px",
									}}
									size="small"
								/>
								{validationObj?.startYear && (
									<p style={{ color: "#EE1D52" }}>
										{validationObj?.startYear}
									</p>
								)}
							</div>
						</div>
						<div className={classes.checkedCont}>
							<h3>Currently Working</h3>
							<Checkbox
								color="primary"
								checked={isCurrentlyWorking}
								onChange={(e) => {
									setIsCurrentlyWorking(e.target.checked);
								}}
							/>
						</div>
						{isCurrentlyWorking ? null : (
							<>
								<p className={classes.label}>End date</p>
								<div className={classes.textLineCont}>
									<div className={classes.halfCont}>
										<Autocomplete
											id="free-solo-demo"
											freeSolo
											value={endMonthVal}
											options={calendarMonth}
											getOptionLabel={(option) => {
												return option;
											}}
											getOptionSelected={(option) => {
												return option === endMonthVal;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													style={{ margin: "0px" }}
													margin="normal"
													variant="outlined"
													placeholder={
														"Enter End Month"
													}
												/>
											)}
											onChange={(event, value) => {
												setEndMonthVal(value);
											}}
											style={{
												width: "100%",
												marginBottom: "5px",
											}}
											size="small"
										/>
									</div>
									<div className={classes.halfCont}>
										<Autocomplete
											id="free-solo-demo"
											freeSolo
											value={endYearVal}
											options={calendarYear}
											getOptionLabel={(option) => {
												return option;
											}}
											getOptionSelected={(option) => {
												return option === endYearVal;
											}}
											renderInput={(params) => (
												<TextField
													{...params}
													style={{ margin: "0px" }}
													margin="normal"
													variant="outlined"
													placeholder={
														"Enter End Year"
													}
												/>
											)}
											onChange={(event, value) => {
												setEndYearVal(value);
											}}
											style={{
												width: "100%",
												marginBottom: "5px",
											}}
											size="small"
										/>
									</div>
								</div>
							</>
						)}
						<p className={classes.label}>Exp Description</p>
						<TextField
							id="description"
							placeholder="Write anything notable..."
							multiline
							rows={3}
							variant="outlined"
							size="small"
							value={projectExp}
							onChange={(e) => {
								setProjectExp(e.target.value);
							}}
							fullWidth
						/>
						<PhotoDetails
							fileObj={fileObj}
							setFileObj={setFileObj}
						/>
					</div>
					<div style={{ height: "5px", marginTop: "2px" }}>
						{loading && <LinearProgress />}
					</div>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							justifyContent: "space-between",
							padding: "3px 7px 0px",
						}}
					>
						<div></div>
						<Button
							variant="contained"
							color="primary"
							disabled={loading}
							type="submit"
							startIcon={<SaveIcon />}
							size={isSmall ? "small" : "medium"}
						>
							Save
						</Button>
					</div>
				</form>
			}
		/>
	);
};
export default ProjectDialog;
