import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { getAllFiles } from "../../community/api.call";
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";
import CustomBtn from "../../styled/CommonComponents/CustomBtn"
import AddIcon from '@material-ui/icons/Add';

const useStyles = makeStyles((theme) => ({
    singleFileCont: {
        width: '100%',
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginTop: "15px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center"
        }
    },
    imageSty: {
        float: "left",
        width: "180px",
        height: "190px",
        objectFit: "cover",
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            height: "auto",
            marginBottom: "10px"
        }
    },
    textInputCont: {
        paddingLeft: "15px",
        [theme.breakpoints.down("sm")]: {
            paddingLeft: "0px",
        }
    }
}));



const PhotoDetails = ({ fileObj, setFileObj }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { createdFileIds } = useSelector((state) => state.file);
    const [stateChange, setStateChange] = useState(false)

    const onValueChange = (val, i, key) => {
        let arr = [...fileObj]
        arr[i][key] = val
        setFileObj(arr)
        setStateChange(!stateChange)
    }

    const addFileObj = () => {
        if (createdFileIds && createdFileIds.length > 0) {
            getAllFiles({ files: createdFileIds })
                .then((data) => {
                    let newArr = []
                    data.map((file) => {
                        newArr.push({
                            file: file,
                            title: "",
                            description: "",
                            tag: ""
                        })
                    })
                    let arr = [...fileObj, ...newArr]
                    setFileObj(arr)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }


    return (<div style={{ padding: "15px 0px" }} >
        {fileObj && fileObj.length > 0 && fileObj.map((fileData, i) => (
            <div className={classes.singleFileCont} >
                <div className={classes.imageCont} >
                    <img src={fileData?.file?.url} className={classes.imageSty} />
                </div>
                <div className={classes.textInputCont} >
                    <TextField
                        id="Title"
                        placeholder="Enter photo title"
                        variant="outlined"
                        size="small"
                        value={fileData?.title}
                        onChange={(e) => {
                            onValueChange(e.target.value, i, "title");
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        id="Title"
                        placeholder="Enter photo tag"
                        variant="outlined"
                        size="small"
                        value={fileData?.tag}
                        onChange={(e) => {
                            onValueChange(e.target.value, i, "tag");
                        }}
                        fullWidth
                        style={{ marginBottom: "10px" }}
                    />
                    <TextField
                        id="Description"
                        placeholder="Enter description"
                        variant="outlined"
                        size="small"
                        rows={3}
                        multiline
                        value={fileData?.description}
                        onChange={(e) => {
                            onValueChange(e.target.value, i, "description");
                        }}
                        fullWidth
                    />
                </div>
            </div>
        ))}

        <div style={{ display: "flex", justifyContent: "flex-start", marginTop: "15px" }} >
            <CustomFileUploadButton
                showComponent={
                    <CustomBtn
                        text={"Add Photo/Video"}
                        startPart={<AddIcon />}
                    />
                }
                parentType={"ProjectExp"}
                parentId={null}
                fileNum={25}
                givenFileTypes={["image", "video"]}
                givenMaxSize={26214400}
                closeFunCall={addFileObj}
            />
        </div>
    </div>);
};

export default PhotoDetails;
