import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
        borderRadius: "5px",
        padding: "20px",
        marginBottom: "25px"
    },
    skillsAndMarketsCont: {
        marginTop: "10px"
    },
    tagsCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "7px",
    },
    allTagsCont: {
        width: "calc(100% - 65px)",
        display: "flex",
        flexWrap: "wrap",
    },
    nameBtnCont: {
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
    },
    avatarSty: {
        width: "60px",
        height: "60px",
        marginRight: "15px"
    },
    picNameCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& div": {
            width: "70%",
            display: "flex",
            flexDirection: "column",
        }
    }
}));

function UserCardSkeleton() {
    const classes = useStyles();
    return (
        <div className={classes.mainCont} >
            <div className={classes.nameBtnCont} >
                <div className={classes.picNameCont} >
                    <Skeleton variant="circle" className={classes.avatarSty} />
                    <div>
                        <Skeleton style={{ width: "80%", height: "30px" }} variant="text" />
                        <Skeleton style={{ width: "60%", height: "18px" }} variant="text" />
                        <Skeleton style={{ width: "60%", height: "18px" }} variant="text" />
                    </div>
                </div>
                <Skeleton style={{ width: "90px", height: "50px" }} variant="text" />
            </div>
            <div className={classes.skillsAndMarketsCont} >
                <div className={classes.tagsCont} >
                    <Skeleton style={{ width: "60px", height: "40px", marginRight: "7px" }} variant="text" />
                    <div className={classes.allTagsCont} >
                        <Skeleton style={{ width: "50px", height: "30px", marginRight: "7px" }} variant="text" />
                        <Skeleton style={{ width: "50px", height: "30px", marginRight: "7px" }} variant="text" />
                        <Skeleton style={{ width: "50px", height: "30px", marginRight: "7px" }} variant="text" />
                        <Skeleton style={{ width: "50px", height: "30px" }} variant="text" />
                    </div>
                </div>

                <div className={classes.tagsCont} style={{ marginBottom: "0px", marginTop: "-13px" }} >
                    <Skeleton style={{ width: "60px", height: "40px", marginRight: "7px" }} variant="text" />
                    <div className={classes.allTagsCont} >
                        <Skeleton style={{ width: "50px", height: "30px", marginRight: "7px" }} variant="text" />
                        <Skeleton style={{ width: "50px", height: "30px", marginRight: "7px" }} variant="text" />
                        <Skeleton style={{ width: "50px", height: "30px", marginRight: "7px" }} variant="text" />
                        <Skeleton style={{ width: "50px", height: "30px" }} variant="text" />
                    </div>
                </div>
            </div>
        </div>
    );
}
export default UserCardSkeleton; 