import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useGetTxTemplate } from '../../commonComponent/transaction/hooks';
import BillTemplateEdit from '../../expenseAndBills/generator/generatorBill/bill.template.edit';
import InvoiceTemplateEdit from '../../salesAndInvoices/generator/generatorInvoice/invoice.template.edit';


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '6rem',
  },

  top: {
    marginTop: '2rem',
    marginBottom: '1rem',
  },

  row: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
  },

  col: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },

  datePicker: {
    width: 150,
  },
}));

export default function TxTemplateEdit(props) {
  const {
    walletId, txtemplateId,
  } = useParams();

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [customer, setCustomer] = useState(null);
  const [offering, setOffering] = useState(null);
  const {
    user,
    userProfile,
  } = useSelector((state) => state.auth);
  const DateNow = new Date();
  const [dueDate, setDueDate] = useState('' + DateNow);

  const [billList, setBillList] = useState(null);
  const [billItemDictionary, setBillItemDictionary] = useState({});
  console.log(txtemplateId)

  const {
    txTemplate, setTxTemplate
  } = useGetTxTemplate(txtemplateId);



  const {
    root, row, col, top,
  } = classes;



  return (
    <div className={root} >
      {txTemplate?.type === "Bill" && (
        <BillTemplateEdit
          txTemplate={txTemplate}
          setTxTemplate={setTxTemplate}
          walletId={walletId}
        />
      )}

      {txTemplate?.type === "Invoice" && (
        <InvoiceTemplateEdit
          txTemplate={txTemplate}
          setTxTemplate={setTxTemplate}
          walletId={walletId}
        />
      )}
    </div>
  );
}
