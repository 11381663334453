import { Button, IconButton, makeStyles, TextField } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { updateUserInfo } from '../profile/api';
import { useTheme } from "@material-ui/core/styles";
import { getTopCategories } from './api.call';
import PersonIcon from '@material-ui/icons/Person';
import InputAdornment from '@material-ui/core/InputAdornment';
import AddLocationTag from '../styled/CommonComponents/Add.Location.Tag';
import CategoryAutocomplete from '../styled/CommonComponents/CategoryAutocomplate';
import CancelIcon from '@material-ui/icons/Cancel';
import networkBG from "../../Assets/globalBG.svg"
import mainLogo from "../../Assets/LogoPrimary.svg"
import { HiArrowNarrowLeft, HiArrowNarrowRight } from "react-icons/hi";
import { GoPrimitiveDot } from "react-icons/go";


const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#E5E5E5",
    backgroundImage: `url(${networkBG})`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundColor: theme.palette.primary.main,
    [theme.breakpoints.down('xs')]: {
      backgroundSize: "cover"
    }
  },
  mainCard: {
    width: "450px",
    height: "450px",
    borderRadius: "10px",
    padding: "10px 30px 20px",
    backgroundColor: "white",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    position: "relative",
    [theme.breakpoints.down('xs')]: {
      width: "300px",
      height: "500px",
      padding: "10px 15px 15px",
    }
  },
  contentCont: {
    width: "100%",
    height: "calc(100% - 75px)",
    borderTop: "1px solid #D9DBE9",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  actionBtnCont: {
    position: "absolute",
    bottom: "15px",
    padding: "0px 20px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  step1Cont: {
    width: "100%",
    padding: "15px 0px",
    "& p": {
      fontSize: "16px",
      fontWeight: "500",
      marginTop: "15px",
      color: "#00345D"
    }
  },
  step2Cont: {
    width: "100%",
    padding: "15px 0px",
    "& p": {
      fontSize: "16px",
      fontWeight: "500",
      color: "#00345D"
    }
  },
  fullNameCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down('xs')]: {
      flexDirection: "column"
    }
  },
  nameHalf: {
    width: "47%",
    "& .MuiInputAdornment-positionStart": {
      marginRight: "-10px"
    },
    [theme.breakpoints.down('xs')]: {
      width: "100%",
    }
  },
  iconTagContGiven: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& img": {
      marginTop: "10px",
      width: "150px",
      height: "auto"
    }
  },
  fullCategoriesCont: {
    maxHeight: "200px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down('xs')]: {
      maxHeight: "250px",
    }
  },
  unSelectedCategory: {
    padding: "3px 8px",
    cursor: "pointer",
    marginBottom: "5px",
    marginRight: "5px",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px"
  },
  selectedCategory: {
    display: "flex",
    alignItems: "center",
    padding: "3px 8px",
    marginBottom: "5px",
    marginRight: "5px",
    backgroundColor: "#E9F0F8",
    borderRadius: "5px"
  },
  crossIcon: {
    marginLeft: "4px",
    fontSize: "15px",
    cursor: "pointer"
  },
  iconSty: {
    fontSize: "30px",
    color: theme.palette.primary.main
  },
  dotCont: {
    width: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  dotIconSty: {
    fontSize: "20px",
    color: "gray"
  },
  mainHeader: {
    display: "flex",
    padding: "5px 0px",
    alignItems: "center",
    justifyContent: "space-between",
    "& img": {
      width: "35px",
      height: "auto"
    },
    "& h3": {
      fontSize: "20px",
      fontWeight: "700",
      color: theme.palette.primary.main
    },
    "& div": {
      width: "25px"
    }
  }
}))

const PreLogin = () => {
  const classes = useStyles();
  const theme = useTheme()
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { auth } = useSelector((state) => state);
  const { user } = auth;

  const [fName, setFName] = useState("")
  const [lName, setLName] = useState("");
  const [activeStep, setActiveStep] = useState(0)
  const [topCategories, setTopCategories] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState([])
  const [locationTags, setLocationTags] = useState([])


  useEffect(() => {
    let fullName = user?.displayName||" "
    let strArr = fullName.split(" ")
    let strLastName = "";

    for (let i = 1; i < strArr.length; i++) {
      strLastName = strLastName + " " + strArr[i]
    }

    let skillStrs = user?.skills || []
    let skillIds = user?.skillTags || []
    let objArr = []

    if (skillStrs && skillIds && skillIds.length !== 0 && skillIds.length === skillStrs.length) {
      skillStrs.map((str, i) => {
        objArr.push({
          name: str,
          _id: skillIds[i]
        })
      })
      setSelectedCategories(objArr)
      setSelectedCategoryIds(skillIds)
    }

    let locMarketsArr = user?.markets || []
    let locationObjArr = []

    locMarketsArr.map((state) => {
      let splitArr = state.split("_")

      if (splitArr[0] === splitArr[1]) {
        locationObjArr.push({
          str: `${splitArr[0]}`,
          state: splitArr[0],
          isCountry: true,
          country: splitArr[1]
        })
      } else {
        locationObjArr.push({
          str: `${splitArr[0]}, ${splitArr[1]}`,
          state: splitArr[0],
          isCountry: false,
          country: splitArr[1]
        })
      }
    })

    setLocationTags(locationObjArr)
    setFName(strArr[0])
    setLName(strLastName)
  }, [user])


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    getTopCategories({})
      .then((data) => {
        setTopCategories(data)
      })
      .catch((err) => {
        console.log(err);
      })
  }, [])

  const selectData = async (val) => {
    if (selectedCategories && selectedCategories.length > 0) {
      if (!selectedCategoryIds.includes(val?._id)) {
        setSelectedCategories([...selectedCategories, val])
      }
    } else {
      setSelectedCategories([val])
    }
  }

  useEffect(() => {
    let arr = [];
    selectedCategories.map((cat) => {
      arr.push(cat?._id)
    })
    setSelectedCategoryIds(arr)
  }, [selectedCategories])

  const removeCategory = (value) => {
    let updatedCategories = selectedCategories.filter((cat) => cat?._id !== value?._id)
    setSelectedCategories(updatedCategories)
  }

  const savePreData = async () => {
    let strArr = new Set();
    let strArrId = new Set();
    let marketsTags = new Set([])

    locationTags.map((loc) => {
      if (loc?.isCountry) {
        let str = `${loc?.country}_${loc?.country}`
        marketsTags.add(str)
      } else {
        let str = `${loc?.state}_${loc?.country}`
        marketsTags.add(str)
      }
    })

    selectedCategories.map((str) => {
      strArr.add(str?.name);
      strArrId.add(str?._id)
    })

    const userObj = {
      displayName: fName + ' ' + lName,
      skills: [...strArr],
      skillTags: [...strArrId],
      markets: [...marketsTags],
    };

    await updateUserInfo({
      userId: user?._id,
      userInfo: userObj,
    })
      .then((data) => {
        dispatch({
          type: "AddAuth",
          payload: {
            user: {
              ...user,
              ...userObj,
            },
          },
        });

        let currentNavEntries = window.navigation.entries();
        let lastPath = currentNavEntries[currentNavEntries.length - 1]

        if (lastPath.url.includes("/about")) {
          history.push("/account")
        } else {
          history.goBack();
        }
      })
      .catch((err) => {
        console.log(err);
      })
  }



  return (<div className={classes.mainCont} >
    <div className={classes.mainCard} >
      <div className={classes.mainHeader} >
        <img src={mainLogo} />
        <h3>Join Our Network</h3>
        <div></div>
      </div>

      <div className={classes.contentCont} >
        {activeStep === 0 && (<div className={classes.step1Cont} >
          <div className={classes.fullNameCont} >
            <div className={classes.nameHalf} >
              <p>First Name</p>
              <TextField
                id="name"
                placeholder='eg. John'
                value={fName}
                onChange={(e) => { setFName(e.target.value) }}
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (<InputAdornment position="start"><PersonIcon style={{ color: "#999b9b", fontSize: "30px" }} /></InputAdornment>)
                }}
                fullWidth
              />
            </div>
            <div className={classes.nameHalf} >
              <p>Last Name</p>
              <TextField
                id="name"
                placeholder='eg. Smith'
                value={lName}
                onChange={(e) => { setLName(e.target.value) }}
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (<InputAdornment position="start"><PersonIcon style={{ color: "#999b9b", fontSize: "30px" }} /></InputAdornment>)
                }}
                fullWidth
              />
            </div>
          </div>
          <p style={{ marginTop: "15px" }} >Add Market(s)</p>
          <AddLocationTag
            locationTags={locationTags}
            setLocationTags={setLocationTags}
            noemptyImage={true}
            iconTagContGiven={classes.iconTagContGiven}
          />
        </div>)}
        {activeStep === 1 && (<div className={classes.step2Cont} >
          <p style={{ marginBottom: "5px" }} >Add Role(s)</p>
          <CategoryAutocomplete
            categories={selectedCategories}
            setCategories={setSelectedCategories}
            noEmptyImageAndSelectedData={true}
            placeholder={"Search Roles"}
          />
          <div className={classes.fullCategoriesCont} style={{ marginTop: "15px" }} >
            {selectedCategories && selectedCategories.map((category) => (<div className={classes.selectedCategory} >
              {category?.name} <CancelIcon className={classes.crossIcon} onClick={() => { removeCategory(category) }} />
            </div>))}
            {topCategories && topCategories.map((category) => (<>
              {!selectedCategoryIds.includes(category?._id) && (
                <div onClick={() => { selectData(category) }} className={classes.unSelectedCategory} >
                  {category?.name}
                </div>
              )}
            </>))}
          </div>
        </div>)}
        <div className={classes.actionBtnCont} >
          <div style={{ width: "70px", textAlign: "left" }} >
            {activeStep === 0 ? (
              <div></div>
            ) : (
              <IconButton onClick={handleBack} >
                <HiArrowNarrowLeft className={classes.iconSty} />
              </IconButton>
            )}
          </div>

          <div className={classes.dotCont} >
            <GoPrimitiveDot className={classes.dotIconSty} style={activeStep === 0 ? { color: theme.palette.primary.main } : {}} />
            <GoPrimitiveDot className={classes.dotIconSty} style={activeStep === 1 ? { color: theme.palette.primary.main } : {}} />
          </div>

          <div style={{ width: "70px", textAlign: "right" }}  >
            {activeStep === 1 ? (
              <Button
                variant="contained"
                color="primary"
                onClick={savePreData}
              >
                Save
              </Button>
            ) : (
              <IconButton onClick={handleNext} >
                <HiArrowNarrowRight className={classes.iconSty} />
              </IconButton>
            )}
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}
export default PreLogin