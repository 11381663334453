import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import ClearIcon from "@material-ui/icons/Clear";
import {
	Box,
	Divider,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextareaAutosize,
	Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import AddNewUnit from "./AddNewUnit";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import tenant from "../../Assets/tenant.png";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import ChatIcon from "@material-ui/icons/Chat";
import AssignmentIndIcon from "@material-ui/icons/AssignmentInd";
import LessText from "../styled/CommonComponents/LessText";
import HouseIcon from "@material-ui/icons/House";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
	getRelationUnitsByProject,
	updateRentalUnit,
	getRelationUnitsById,
} from "./apiCall";
import KeySettings from "./KeySetting";
import PaymentSetting from "./PaymentSetting";
import DocumentSetting from "./DocumentsSetting";
import IssuesSetting from "./IssuesSetting";
import MessagingSetting from "./MessagingSetting";
import TenantDetails from "./TenantDetails";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import LeaseEditPage from "./LeaseEditPage";
import LeaseViewPage from "./LeaseViewPage";
import { useSelector } from "react-redux";

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "66px",
	},

	loaderCont: {
		position: "fixed",
		top: "0px",
		right: "0px",
		width: "100vw",
		height: "100vh",
		zIndex: "1000",
		paddingLeft: "100px",
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			paddingLeft: "0px",
		},
	},

	unitEditHeader: {
		width: "100%",
		position: "absolute",
		left: "0px",
		display: "flex",
		top: "0px",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "15px 20px",
		marginTop: "0px",
		marginBottom: "0px",
		backgroundColor: "#ffffff",
		zIndex: "10",
		boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
	},

	unitTitle: {
		fontSize: "20px",
		fontWeight: "520",
		marginLeft: "20px",
		opacity: "0.8",
	},
	backIcon: {
		fontSize: "35px",
		opacity: "0.8",
		cursor: "pointer",
	},
	unitEditBody: {
		width: "100%",
		display: "flex",
		marginTop: "20px",
		justifyContent: "space-between",
	},
	sideNavBar: {
		minHeight: "calc(100vh - 70px)",
		width: "20%",
		// borderRight: '1px solid #BDBDBD',
		paddingTop: "30px",
		paddingLeft: "20px",
		backgroundColor: "#ffffff",
	},
	editMainCont: {
		width: "80%",
		padding: "20px",
		paddingBottom: "40px",
	},
	singleOption: {
		display: "flex",
		alignItems: "center",
		height: "47px",
		fontSize: "16px",
		fontWeight: "530",
		cursor: "pointer",
		backgroundColor: "#FFFFFF",
		"&:hover": {
			background: "#e7e7e7",
		},
	},
	selectedSingleOption: {
		display: "flex",
		alignItems: "center",
		height: "47px",
		fontSize: "16px",
		fontWeight: "530",
		cursor: "pointer",
		background: "#e7e7e7",
	},
	sideBlue: {
		width: "4px",
		height: "47px",
		marginRight: "5px",
		marginLeft: "-8px",
		backgroundColor: "#46A8F1",
	},
	displayNone: {
		display: "none",
	},
	iconStyle: {
		fontSize: "27px",
		marginRight: "10px",
	},
	editStyle: {
		color: "#46A8F1",
		marginLeft: "10px",
		cursor: "pointer",
	},
	showDisplayRow: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "10px",
	},
	datePicker: {
		marginTop: "0",
	},
	link: {
		color: "#2a7ab0",
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	text: {
		fontSize: 20,
		fontWeight: "bold",
		margin: "0rem 1rem",
	},
}));

export default function RentalRelationForm(props) {
	const history = useHistory();
	const classes = useStyles();
	const { teamId, unitId } = useParams();
	// const { reloadRentalRelationUnits } = props;
	const { teamDictionary } = useSelector((state) => state.team);
	let team = teamDictionary[teamId];
	console.log("use params = ", useParams());
	const [createDialog, setCreateDialog] = useState(false);
	const [loadingBool, setLoadingBool] = useState(false);
	const [currentRentalUnit, setCurrentRentalUnit] = useState();

	useEffect(() => {
		setLoadingBool(true);
		getRelationUnitsById({ unitId: unitId })
			.then((data) => {
				console.log("data = ", data);
				if (data[0]) {
					setCurrentRentalUnit(data[0]);
				}
				setLoadingBool(false);
			})
			.catch((err) => {
				console.log(err);
			});
	}, []);

	// let EditComponent;

	// switch (rentalUnitSettingPage) {
	// 	case "tenantDetails":
	// 		EditComponent = TenantDetails;
	// 		break;
	// 	case "messaging":
	// 		EditComponent = MessagingSetting;
	// 		break;
	// 	case "issues":
	// 		EditComponent = IssuesSetting;
	// 		break;
	// 	case "documents":
	// 		EditComponent = DocumentSetting;
	// 		break;
	// 	case "payment":
	// 		EditComponent = PaymentSetting;
	// 		break;
	// 	case "keys":
	// 		EditComponent = KeySettings;
	// 		break;
	// }

	return (
		<div className={classes.root}>
			<div className={classes.unitEditHeader}>
				<div className={classes.showDisplayRow}>
					<KeyboardBackspaceIcon
						onClick={() => {
							history.goBack();
						}}
						className={classes.backIcon}
					/>
					<Typography
						className={classes.unitTitle}
						style={{ color: "#48A9F0", fontWeight: "500" }}
					>
						<LessText
							string={currentRentalUnit?.name || "Unit Name"}
						/>
					</Typography>
				</div>
			</div>
			<div>
				{currentRentalUnit?.rentalRelation ? (
					<h1>Lease Already Exists</h1>
				) : (
					<LeaseEditPage unit={currentRentalUnit} />
				)}
			</div>
			{/* {temp ? (

		        ) : (
		            <div>
		            <LeaseEditPage />
				</div>
			)} */}
			{loadingBool && (
				<div className={classes.loaderCont}>
					<CircularProgress
						size={60}
						thickness={3}
						style={{ color: "rgb(92, 144, 242)" }}
					/>
				</div>
			)}
		</div>
	);
}
