import { useState, useEffect } from "react";
import socket from "./socketio";
import { useSelector, useDispatch } from "react-redux";
import arrayToReducer from "../arrayToReducer";
import { db } from "../../index";

function useChatHook(profileId,addMsgHelper) {



}

export default useChatHook;
