import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useParams, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import AddIcon from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import TextField from "@material-ui/core/TextField";
import { Typography } from "@material-ui/core";
import {
	createRentalRelationWithUnit,
	createRentalUnits,
	getProfileById,
} from "./apiCall";
import MyAutocomplete from "../styled/CommonComponents/MyAutoComplete";
import LessText from "../styled/CommonComponents/LessText";
import Avatar from "@material-ui/core/Avatar";
import UserWithRoleComponent from "../styled/CommonComponents/UserWithRoleComponent";

const roles = ["Admin", "Owner", "Editor", "Viewer"];
const Msgroles = ["Admin", "User"];
const durationType = ["Monthly", "Weekly", "Other"];

const useStyles = makeStyles((theme) => ({
	root: {},
	contStyle: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
		overflowY: "auto",
		paddingTop: "10px",
	},
	datePickerDual: {
		width: "45%",
		margin: "0px",
		marginTop: "-10px",
	},
	datePickerTriple: {
		width: "27%",
		margin: "0px",
		marginTop: "-10px",
	},
	datePicker30Triple: {
		width: "30%",
		margin: "0px",
		marginTop: "-10px",
	},
	inputCont: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		marginBottom: "10px",
	},
	tripleInput: {
		width: "27%",
	},
	triple30Input: {
		width: "30%",
	},
	dualInput: {
		width: "45%",
	},
	singleInput: {
		width: "100%",
	},
	multiInput: {
		width: "100%",
		border: "1px solid #E1E2E5",
		padding: "10px",
		paddingTop: "15px",
		marginBottom: "15px",
	},
	addBtn: {
		padding: "4px 10px",
	},
	addBtnCont: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		marginBottom: "30px",
	},
}));

export default function AddNewUnit(props) {
	const history = useHistory();
	const classes = useStyles();
	const { teamId } = useParams();
	const {
		openCreateDialog,
		setOpenCreateDialog,
		team,
		reloadUnits,
		numRentalUnits,
	} = props;
	const DateNow = new Date();
	const { teamDictionary } = useSelector((state) => state.team);

	const [numUnits, setNumUnits] = useState(0);
	const [open, setOpen] = useState(false);

	const [msgWith, setMsgWith] = useState([]);
	const [msgWithRole, setMsgWithRole] = useState([]);

	const [docShareWith, setDocShareWith] = useState([]);
	const [docShareWithRole, setDocShareWithRole] = useState([]);

	const [issuesShareWith, setIssuesShareWith] = useState([]);
	const [issuesShareWithRole, setIssuesShareWithRole] = useState([]);

	const [multiInputBool, setmultiInputBool] = useState(false);

	const newInputObj1 = {
		type: "Rent",
		amount: 0,
		period: 0,
		Latefees: 0,
	};

	useEffect(() => {
		setOpen(openCreateDialog);
	}, [openCreateDialog]);

	const handleClose = () => {
		setOpen(false);
		setOpenCreateDialog(false);
	};

	const createRentalRelationAndUnits = async () => {
		let createArray = [];
		for (let i = 0; i < numUnits; i++) {
			let obj = {
				project: teamDictionary[teamId]?.parent?._id,
				team: teamId,
				name: `Rental Unit ${(numRentalUnits || 0) + i + 1} A`,
				vacant: true,
			};
			createArray.push(obj);
		}

		console.log("sending to create unit : ", createArray);

		await createRentalUnits({ units: createArray })
			.then((data) => {
				console.log("created units ", data);
			})
			.catch((err) => {
				console.log(err);
			});

		handleClose();
		reloadUnits();
	};

	// handle chat user selecting

	//remove user
	const removeMsgWithUser = (id) => {
		setmultiInputBool(!multiInputBool);
		const filteredUserArr = msgWith.filter((user) => user?._id !== id);
		setMsgWith(filteredUserArr);

		let newUserAccessRoleArr = [];
		msgWithRole.map((userRole) => {
			if (userRole?.user?._id !== id) {
				newUserAccessRoleArr.push(userRole);
			}
		});
		setMsgWithRole(newUserAccessRoleArr);
		setmultiInputBool(!multiInputBool);
	};

	//update user role
	const updateMsgUserWithRole = (value, index) => {
		setmultiInputBool(!multiInputBool);
		let inputDataArr = msgWithRole;
		let editObj = {
			...msgWithRole[index],
			role: value,
		};
		inputDataArr[index] = editObj;
		setMsgWithRole(inputDataArr);
		setmultiInputBool(!multiInputBool);
	};

	//add new user
	const addNewMsgAccessUser = (newAccessRoleObj) => {
		const accessableUserWithRoleObj = {
			user: newAccessRoleObj?.user,
			role: newAccessRoleObj?.role,
		};
		let newAccessableUsersWithRoleArr = [
			...msgWithRole,
			accessableUserWithRoleObj,
		];
		setMsgWithRole(newAccessableUsersWithRoleArr);
	};

	// handle documents Share user selecting

	//remove user
	const removeDocShareWithUser = (id) => {
		setmultiInputBool(!multiInputBool);
		const filteredUserArr = docShareWith.filter((user) => user?._id !== id);
		setDocShareWith(filteredUserArr);

		let newUserAccessRoleArr = [];
		docShareWithRole.map((userRole) => {
			if (userRole?.user?._id !== id) {
				newUserAccessRoleArr.push(userRole);
			}
		});
		setDocShareWithRole(newUserAccessRoleArr);
		setmultiInputBool(!multiInputBool);
	};

	//update user role
	const updateDocShareWithUserRole = (value, index) => {
		setmultiInputBool(!multiInputBool);
		let inputDataArr = docShareWithRole;
		let editObj = {
			...docShareWithRole[index],
			role: value,
		};
		inputDataArr[index] = editObj;
		setDocShareWithRole(inputDataArr);
		setmultiInputBool(!multiInputBool);
	};

	//add new user
	const addNewDocShareWithUser = (newAccessRoleObj) => {
		const accessableUserWithRoleObj = {
			user: newAccessRoleObj?.user,
			role: newAccessRoleObj?.role,
		};
		let newAccessableUsersWithRoleArr = [
			...docShareWithRole,
			accessableUserWithRoleObj,
		];
		setDocShareWithRole(newAccessableUsersWithRoleArr);
	};

	// handle issues Share user selecting

	//remove user
	const removeIssuesShareWithUser = (id) => {
		setmultiInputBool(!multiInputBool);
		const filteredUserArr = issuesShareWith.filter(
			(user) => user?._id !== id
		);
		setIssuesShareWith(filteredUserArr);

		let newUserAccessRoleArr = [];
		issuesShareWithRole.map((userRole) => {
			if (userRole?.user?._id !== id) {
				newUserAccessRoleArr.push(userRole);
			}
		});
		setIssuesShareWithRole(newUserAccessRoleArr);
		setmultiInputBool(!multiInputBool);
	};

	//update user role
	const updateIssuesShareWithUserRole = (value, index) => {
		setmultiInputBool(!multiInputBool);
		let inputDataArr = issuesShareWithRole;
		let editObj = {
			...issuesShareWithRole[index],
			role: value,
		};
		inputDataArr[index] = editObj;
		setIssuesShareWithRole(inputDataArr);
		setmultiInputBool(!multiInputBool);
	};

	//add new user
	const addNewIssuesShareWithUser = (newAccessRoleObj) => {
		const accessableUserWithRoleObj = {
			user: newAccessRoleObj?.user,
			role: newAccessRoleObj?.role,
		};
		let newAccessableUsersWithRoleArr = [
			...issuesShareWithRole,
			accessableUserWithRoleObj,
		];
		setIssuesShareWithRole(newAccessableUsersWithRoleArr);
	};

	return (
		<Dialog
			scroll={"paper"}
			onClose={handleClose}
			maxWidth={"md"}
			fullWidth
			aria-labelledby="scroll-dialog-title"
			aria-describedby="scroll-dialog-description"
			open={open}
		>
			<DialogTitle
				id="customized-dialog-title"
				onClose={handleClose}
				style={{ padding: "8px 24px" }}
			>
				Create New Unit(s)
			</DialogTitle>
			<DialogContent dividers={true}>
				<div className={classes.contStyle}>
					<div className={classes.inputCont}>
						<TextField
							id="outlined-basic"
							size="small"
							label="Number of New Units"
							variant="outlined"
							className={classes.singleInput}
							value={numUnits}
							onChange={(e) => {
								setNumUnits(e.target.value);
							}}
						/>
					</div>
				</div>
			</DialogContent>
			<DialogActions
				style={{ display: "flex", justifyContent: "space-between" }}
			>
				<Button autoFocus onClick={handleClose} color="primary">
					Cancel
				</Button>
				<Button
					autoFocus
					onClick={() => {
						createRentalRelationAndUnits();
					}}
					color="primary"
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
