import {
  Box,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilesViewer from "../file/Viewer/FilesViewer";
import QuestionOptions from "./question.options";
import QuestionType from "./questiontype";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Api from "../../helpers/Api";
import { useDebounce } from "react-use";
import arrayToReducer from "../../helpers/arrayToReducer";
import { blue } from "@material-ui/core/colors";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FormTableQuestionOptions from "./form.table.question.options";
import FormTableQuestionType from "./form.table.question.type";

const useStyles = makeStyles((theme) => ({}));

export default function FormTableRowResponse(props) {
  const classes = useStyles();
  const { question, response, index } = props;
  const [text, setText] = useState(response?.text || "");

  const updateResponse = async (param) => {
    const res = await Api.post("formtable/response/update", param);
    console.log(res);
  };
  useDebounce(
    () => {
      updateResponse({
        _id: response?._id,
        // question: option?.question,
        text,
      });
    },
    100,
    [text]
  );

  return (
    <TextField
      variant="outlined"
      style={{ width: "80%", marginBottom: "0.5rem" }}
      //   placeholder={question?.questionText || `Column ${index + 1}`}
      label={question?.questionText || `Column ${index + 1}`}
      value={text}
      onChange={(e) => {
        setText(e.target.value);
      }}
    />
  );
}
