import React, { useState, useEffect, useRef, useCallback } from "react";
import { Avatar, CircularProgress, Divider, Typography, } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import PostFile from "../post/PostFile";
import arrayToReducer from "../../helpers/arrayToReducer";
import { getAllCommunitysPosts, getSuggestedCommunities, getUserCommunities } from "./api.call";
import PostSkeleton from "../styled/CommonComponents/Post.Skeleton";
import UserCommunities from "./UserCommunites";
import SuggestedCommunitySidebar from "./SuggestedCommunitiesSidebar";
import ResourcesCard from "../resources/Resources.Card";


const useStyles = makeStyles((theme) => ({
	mainCont: {
		width: "100%",
		height: `calc(100vh - ${theme.appbar.height})`,
		display: "flex",
		justifyContent: "space-between",
		padding: "5px",
		paddingTop: "25px",
		[theme.breakpoints.down('sm')]: {
			padding: "0px",
			paddingTop: "10px",
		}
	},
	feedCont: {
		width: "56%",
		height: "100%",
		overflowY: "auto",
		padding: "0px 30px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
		[theme.breakpoints.down('md')]: {
			width: "100%",
		},
		[theme.breakpoints.down('sm')]: {
			padding: "0px 10px",
		}
	},
	leftSideCont: {
		width: "23%",
		height: "100%",
		overflowY: "auto",
		paddingLeft: "20px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	rightSideCont: {
		width: "23%",
		height: "100%",
		overflowY: "auto",
		paddingRight: "20px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	progressBox: {
		width: "100%",
		height: "70px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	slidingCard: {
		display: "flex",
		overflow: "auto",
		marginBottom: "10px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none"
	},
	singleSlideCard: {
		width: "320px",
		minWidth: "320px",
		maxWidth: "320px",
		paddingRight: "10px",
	},
}));

function Forum() {
	const history = useHistory();
	const classes = useStyles();
	const theme = useTheme()
	const { user } = useSelector((state) => state.auth);

	const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
	const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

	const observer = useRef();
	const [currPage, setCurrPage] = useState(0);
	const [loading, setLoading] = useState(true);
	const [hasMore, setHasMore] = useState(true);
	const [posts, setPosts] = useState([])
	const [postIds, setPostIds] = useState([])
	const [postDict, setPostDict] = useState({})
	const [communites, setCommunities] = useState(null)
	const [communityIds, setCommunityIds] = useState(null)
	const [communityLoading, setCommunityLoading] = useState(false)
	const [suggestedCommunites, setSuggestedCommunities] = useState(null)
	const [suggestionLoading, setSuggestionLoading] = useState(false)
	const postFromBackend = 10;

	const getOwnCommunites = async () => {
		setCommunityLoading(true)
		await getUserCommunities({
			profile: user?.profile
		})
			.then((data) => {
				console.log(data);
				setCommunities(data || [])
				setCommunityLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const getAllSuggestedCommunities = async () => {
		setSuggestionLoading(true)
		await getSuggestedCommunities({
			profile: user?.profile,
			limit: 10,
			page: 0,
		})
			.then((data) => {
				console.log(data)
				setSuggestedCommunities(data || [])
				setSuggestionLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getAllSuggestedCommunities()
		getOwnCommunites()
	}, [user?.profile])

	useEffect(() => {
		if (communites && communites.length > 0) {
			let arrId = [];
			communites.map((com) => {
				arrId.push(com?.community?._id)
			})
			setCommunityIds(arrId)
		}
	}, [communites])

	const getPostsAndScroll = async () => {
		setLoading(true)
		await getAllCommunitysPosts({
			profile: user?.profile,
			communitiesId: communityIds || [],
			page: currPage || 0,
			limit: postFromBackend,
			searchTitle: "",
		})
			.then((data) => {
				if (data) {
					setPosts((prev) => [...prev, ...data])
					if (data.length < postFromBackend) {
						setHasMore(false);
					} else {
						setHasMore(true);
					}
				}
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const lastElementRef = useCallback(
		(node) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setCurrPage((prev) => {
						return prev + 1;
					});
				}
			});
			if (node) observer.current.observe(node);
		},
		[loading, hasMore]
	);

	useEffect(() => {
		getPostsAndScroll()
	}, [currPage]);

	useEffect(() => {
		const { newDict, idArr } = arrayToReducer([...posts] || []);
		setPostIds(idArr)
		setPostDict(newDict)
	}, [posts])


	return (
		<div className={classes.mainCont} >
			{isMdSmall ? null : (
				<div className={classes.leftSideCont} >
					<ResourcesCard />
				</div>
			)}
			<div className={classes.feedCont} >
				{isMdSmall ? (
					<div className={classes.slidingCard} >
						{communityLoading ? (
							<div className={classes.singleSlideCard} >
								<UserCommunities
									communites={communites}
									communityLoading={communityLoading}
									isAddNewBtn={true}
								/>
							</div>
						) : (<>
							{communites && communites.length > 0 ? (<>
								<div className={classes.singleSlideCard} >
									<UserCommunities
										communites={communites}
										communityLoading={communityLoading}
										isAddNewBtn={true}
									/>
								</div>
							</>) : null}
						</>)}
						<div className={classes.singleSlideCard} >
							<SuggestedCommunitySidebar
								suggestedCommunites={suggestedCommunites}
								reloadSuggestion={getAllSuggestedCommunities}
								reloadOwnCommunities={getOwnCommunites}
								suggestionLoading={suggestionLoading}
							/>
						</div>
						<div className={classes.singleSlideCard} >
							<ResourcesCard />
						</div>
					</div>
				) : null}
				<div className={classes.feedBodyXCont} >
					{postIds && postIds.length > 0 ? (
						postIds.map((el, idx) => {
							let lastItem = postIds.length - 1;
							return postDict[el] ? (
								idx === lastItem ? (
									<div ref={lastElementRef} >
										<PostFile
											commentLimit={5}
											key={postDict[el]._id}
											post={postDict[el]}
											sentProfile={user?.profile}
											addLink={false}
											setPostDict={setPostDict}
											topText={postDict[el]?.parentModelName === "Community" ? (
												<div>
													<div
														onClick={() => { history.push("/explore/forum/communities/" + postDict[el]?.channels[0]?.parent?.slug) }}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px", gap: "5px", cursor: "pointer" }}
													>
														<Avatar
															alt={postDict[el]?.channels[0]?.parent?.displayName}
															src={postDict[el]?.channels[0]?.parent?.displayPicture?.thumbUrl || postDict[el]?.channels[0]?.parent?.displayPicture?.url}
															style={{ width: "25px", height: "25px" }}
														/>
														<Typography
															variant="body1"
															style={{ fontWeight: "500", fontSize: "14px" }}
														>
															c/{postDict[el]?.channels[0]?.parent?.displayName}
														</Typography>
													</div>
													<Divider />
												</div>
											) : null
											}
										/>
									</div>
								) : (
									<div>
										<PostFile
											commentLimit={5}
											key={postDict[el]._id}
											post={postDict[el]}
											sentProfile={user?.profile}
											addLink={false}
											setPostDict={setPostDict}
											topText={postDict[el]?.parentModelName === "Community" ? (
												<div>
													<div
														onClick={() => { history.push("/explore/forum/communities/" + postDict[el]?.channels[0]?.parent?.slug) }}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px", gap: "5px", cursor: "pointer" }}
													>
														<Avatar
															alt={postDict[el]?.channels[0]?.parent?.displayName}
															src={postDict[el]?.channels[0]?.parent?.displayPicture?.thumbUrl || postDict[el]?.channels[0]?.parent?.displayPicture?.url}
															style={{ width: "25px", height: "25px" }}
														/>
														<Typography
															variant="body1"
															style={{ fontWeight: "500", fontSize: "14px" }}
														>
															c/{postDict[el]?.channels[0]?.parent?.displayName}
														</Typography>
													</div>
													<Divider />
												</div>
											) : null
											}
										/>
									</div>
								)
							) : (
								<></>
							);
						})
					) : (<>
						{loading && (
							<PostSkeleton />
						)}
					</>)}
					{postIds && postIds.length > 0 && hasMore ? (
						<div className={classes.progressBox}>
							{loading && (
								<CircularProgress />
							)}
						</div>
					) : null}
				</div>
			</div>
			{isMdSmall ? null : (
				<div className={classes.rightSideCont} >
					<UserCommunities
						communites={communites}
						communityLoading={communityLoading}
						isAddNewBtn={true}
					/>
					<SuggestedCommunitySidebar
						suggestedCommunites={suggestedCommunites}
						reloadSuggestion={getAllSuggestedCommunities}
						reloadOwnCommunities={getOwnCommunites}
						suggestionLoading={suggestionLoading}
					/>
				</div>
			)}
		</div>
	);
}

export default Forum;