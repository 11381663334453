import axios from "axios";
import React, { useEffect, useState } from "react";
import Api from "../../helpers/Api";
import PostSideBarMenu from "./PostSideBarMenu";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import { Skeleton } from "@material-ui/lab";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
	mainCont: {
		width: "100%",
		backgroundColor: "white",
		paddingBottom: "10px",
		borderRadius: "5px",
		boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
		marginBottom: "25px",
		[theme.breakpoints.down('md')]: {
			marginBottom: "0px"
		}
	},
	headSty: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px 15px",
		borderBottom: "1px solid #e3e0e0",
		"& h3": {
			fontSize: "17px",
			fontWeight: "500",
			color: "#00345D"
		}
	},
	communitesCont: {
		width: "100%",
		paddingTop: "10px",
		maxHeight: "235px",
	},
	seemoreSty: {
		textAlign: "center",
		padding: "0px 10px",
		cursor: "pointer",
		color: theme.palette.primary.main
	},
}))


function PostSideBar({ selectedProfile }) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme()

	const [coordinate, setCoordinate] = useState({ lat: "21", long: "78" })
	const [suggestedUsers, setSuggestedUsers] = useState(null)
	const [loading, setLoading] = useState(false)

	const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));

	async function getLocationByIp() {
		const res = await axios.get("http://ip-api.com/json");
		if (res?.data?.status == "success") {
			setCoordinate({ lat: res?.data?.lat, long: res?.data?.lon });
		}
	}

	function getGeoLocation() {
		navigator.geolocation.getCurrentPosition(
			function (position) {
				setCoordinate({
					lat: position.coords.latitude,
					long: position.coords.longitude,
				});
			},
			function (error) {
				console.log("Error Code = " + error.code + " - " + error.message);
				getLocationByIp();
			}
		);
	}

	function handlePermission() {
		navigator.permissions
			.query({ name: "geolocation" })
			.then(function (result) {
				if (result.state == "granted") {
					getGeoLocation();
				} else if (result.state == "prompt") {
					getGeoLocation();
				} else if (result.state == "denied") {
					console.log("permission=", result);
					getLocationByIp();
				}
				result.onchange = function () {
					handlePermission();
				};
			});
	}

	useEffect(() => {
		handlePermission();
	}, []);

	const getSuggestedUsers = async () => {
		setLoading(true);
		const usersData = await Api.post("user/get-suggested-by-location", {
			profile: selectedProfile?.profile,
			longitude: coordinate.lat,
			latitude: coordinate.long,
			page: 0,
			limit: isMdSmall ? 3 : 5
		});
		let usersArr = usersData?.users || [];
		setSuggestedUsers(usersArr || [])

		setLoading(false);
	};

	useEffect(() => {
		getSuggestedUsers();
	}, [coordinate]);

	console.log(loading)
	console.log(suggestedUsers)


	return (<>
		{!loading && suggestedUsers !== null && suggestedUsers.length === 0 ? null : (
			<div className={classes.mainCont} >
				<div className={classes.headSty} >
					<h3>People Near You</h3>
					<div></div>
				</div>
				<div className={classes.communitesCont} >
					{suggestedUsers === null && loading ? (<div style={{ padding: "0px 15px" }} >
						{isMdSmall ? (<>
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
						</>) : (<>
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
							<Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
						</>)}
					</div>) : (
						<div style={{ padding: "0px 1rem" }}>
							{suggestedUsers && suggestedUsers.map((Obj, idx) => (
								<PostSideBarMenu
									key={idx}
									Obj={Obj}
									selectedProfile={selectedProfile}
								/>
							))}
						</div>
					)}
				</div>
				{!loading && (
					<div className={classes.seemoreSty} onClick={() => { history.push("/search") }} >
						See More
					</div>
				)}
			</div>
		)}
	</>);
}
export default PostSideBar;