import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from '@material-ui/core/LinearProgress';
import Api from "../../../helpers/Api";
import LikeCard from "./LikeCard";
import NormalDialog from "../../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles((theme) => ({
  progressBox: {
    width: "100%",
    marginBottom: "60px"
  },
  allUserCont: {
    minWidth: "300px",
    maxHeight: "500px",
    overflowY: "auto",
  }
}));

function LikeDialogBox({ postId, open, setOpen }) {
  const classes = useStyles();
  const [likeUsers, setLikeUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  async function getLikes() {
    setLoading(true)
    const res = await Api.post("/like/getPostLikes", { parent: postId });
    setLikeUsers(res?.data || []);
    setLoading(false);
  }

  useEffect(() => {
    getLikes();
  }, []);

  function handleClose() {
    setOpen(false);
  }


  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Likes"}
      content={<div className={classes.allUserCont} >
        {!loading ? (
          likeUsers.map((el) => (
            <LikeCard key={el?.profile?.parent?._id} likeUser={el?.profile} />
          ))
        ) : (
          <div className={classes.progressBox}>
            <LinearProgress />
          </div>
        )}
      </div>}
    />
  );
}

export default LikeDialogBox;
