import {
  Box,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilesViewer from "../file/Viewer/FilesViewer";
import QuestionOptions from "./question.options";
import QuestionType from "./questiontype";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Api from "../../helpers/Api";
import { useDebounce } from "react-use";
import arrayToReducer from "../../helpers/arrayToReducer";
import { blue } from "@material-ui/core/colors";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FormTableQuestionOptions from "./form.table.question.options";
import FormTableQuestionType from "./form.table.question.type";

const useStyles = makeStyles((theme) => ({
  question: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: '1px solid red',
    width: "100%",
    padding: "1rem",
    // marginBottom: "1rem",
    position: "relative",
    overflow: "hidden",
    // border: "1px solid grey",
    // boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
  },
  // bar: {
  //   position: "absolute",
  //   width: "10px",
  //   backgroundColor: "#407afd",
  //   height: "100%",
  //   top: "0px",
  //   left: "-6px",
  //   transition: "all 200ms ease-in",
  // },
  barActive: {
    left: "0px !important",
  },
  questionTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    // border:"1px solid green"
  },
  questionHeader: {
    textAlign: "left",
  },
  questionText: {
    width: "35%",
    padding: "2px",
    marginTop: "0.5rem",
  },
  questionAnswer: {
    width: "100%",
    // border: '1px solid red',
    // borderBottom: "1px solid grey",
  },
  questionEdit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    // border: "1px solid green",
    justifyContent: "flex-end",
  },
}));
export default function FormTableQuestion(props) {
  const classes = useStyles();
  const {
    addQuestion,
    form,
    question,
    setQuestion,
    createQuestion,
    index,
    onDelete,
    isActive,
    setActiveQuestion,
    tableId,
    questionIds,
    questionDict,
    setQuestionDict,
    setQuestionIds,
  } = props;

  const [questionId, setQuestionId] = useState(question?._id);

  const [type, setType] = useState(question?.type || "Short Answer");
  const [questionText, setquestionText] = useState(
    question?.questionText || ""
  );
  const [questionImage, setquestionImage] = useState(
    question?.questionImage || ""
  );

  // const [links, setLinks] = useState(question?.links || []);

  // const [optionIds, setOptionIds] = useState([]);
  // const [optionDict, setOptionDict] = useState([]);

  // const [optionColIds, setOptionColIds] = useState([]);
  // const [optionColDict, setOptionColDict] = useState([]);

  // const [optionRowIds, setOptionRowIds] = useState([]);
  // const [optionRowDict, setOptionRowDict] = useState([]);

  // const [questionObj, setQuestionObj] = useState({
  //   type: question?.type || "Short Answer",
  //   questionText: question?.questionText || "",
  //   questionImage: question?.questionImage || "",
  //   links: question?.links || [],
  // });

  useDebounce(
    () => {
      if (question?._id) {
        updateApi({
          _id: question?._id,
          type,
          questionText,
        });
      }
      console.log("heyyyy");
    },
    100,
    [type, questionText]
  );

  //update the question
  const updateApi = async (question) => {
    // setLoading(true);
    console.log("update api called");
    const res = await Api.post("question/update", question);
    //update the question dictionary
    if (res?.data) {
      console.log(res?.data);
      const { newDict, idArr } = arrayToReducer(res?.data);
      setQuestionDict({
        ...questionDict,
        ...newDict,
      });
      console.log({ ...questionDict, ...newDict });
    }
    // setUpdated(true);
    // setLoading(false);
  };
  // if()

  return (
    <Box
      component={Paper}
      style={{
        width: "100%",
        minWidth: "95%",
        height: "100%",
        marginBottom: "1rem",
      }}
      onClick={() => {
        console.log(question._id);
        setActiveQuestion(question._id);
      }}
    >
      <div className={classes.question}>
        <div
          className={`${classes.bar} ${isActive ? classes.barActive : ""}`}
        ></div>
        <div
          className={
            isActive
              ? `${classes.questionTypeContainer}`
              : `${classes.questionHeader}`
          }
        >
          {isActive ? (
            <TextField
              size="small"
              //   variant="filled"
              label="Question"
              className={classes.questionText}
              value={questionText}
              onChange={(e) => setquestionText(e.target.value)}
              // disabled={!isActive}
            />
          ) : (
            <Typography variant="h6" component="p">
              {questionText ? questionText : "Question"}
            </Typography>
          )}{" "}
          &nbsp;
          {isActive && <FormTableQuestionType setType={setType} type={type} />}
        </div>
        <div className={classes.questionAnswer}>
          <FormTableQuestionOptions
            type={type}
            // optionIds={optionIds}
            // setOptionIds={setOptionIds}
            // optionDict={optionDict}
            // setOptionDict={setOptionDict}
            // optionColIds={optionColIds}
            // setOptionColIds={setOptionColIds}
            // optionColDict={optionColDict}
            // setOptionColDict={setOptionColDict}
            // optionRowIds={optionRowIds}
            // setOptionRowIds={setOptionRowIds}
            // optionRowDict={optionRowDict}
            // setOptionRowDict={setOptionRowDict}

            questionId={question?._id}
            question={question}
            isActive={isActive}
          />
        </div>
        {isActive && (
          <div className={classes.questionEdit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "15%",
              }}
            >
              <Tooltip title="Add new">
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    createQuestion(index + 1);
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Add new">
                <IconButton aria-label="copy">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip> */}

              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    onDelete(question, index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </Box>
  );
}
