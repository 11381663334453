import React, { useState, useEffect } from "react";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { createAnalysisReport } from "./api.call";
import {
  rentalValues, brrrrValues, flipValues,
  getPropertyTaxByState, getPropertyInsuranceByState
} from "./default.value.js"
import NormalDialog from "../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles((theme) => ({
  btnCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginTop: "20px"
  },
  labelStyle: {
    fontSize: "17px",
    fontWeight: "510",
    margin: "10px 5px"
  },
  dialogCont: {
    width: "450px",
    [theme.breakpoints.down('xs')]: {
      width: "250px",
    }
  },
  input: {
    backgroundColor: "#FCFCFC"
  },
  orCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "15px 0px",
    "& div": {
      width: "48%",
      height: "1px",
      backgroundColor: "#c5c4c4"
    },
    "& span": {
      margin: "0px 10px",
      color: "gray",
      fontSize: "13px"
    }
  },
  cardBtnSty: {
    width: "100%",
  },
  paperCont: {
    padding: "15px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#edecec"
    },
    "& h3": {
      color: "#00345d",
      marginBottom: "5px",
      fontFamily: `'Poppins', sans-serif`,
    }
  }
}));

const ReportCreationDialog = ({
  open, setOpen, reportType,
  setReportType, reports,
  setReports, projectData
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { teamId } = useParams();
  const history = useHistory();

  const { user } = useSelector((state) => state.auth);

  const [reportTitle, setReportTitle] = useState("");
  const [showPage, setShowPage] = useState("options")

  const handleClose = () => {
    setShowPage("options")
    setOpen(false);
  };

  const createReport = async () => {
    let defaultObj;
    if (reportType === "Rental") {
      defaultObj = rentalValues
    } else if (reportType === "BRRRR") {
      defaultObj = brrrrValues
    } else if (reportType === "Flip") {
      defaultObj = flipValues
    }
    const report = await createAnalysisReport(
      {
        reportTitle,
        reportType,
        user: user?._id,
        userProfile: user?.profile,
        propertyTax: getPropertyTaxByState(projectData?.address?.region),
        propertyInsurance: getPropertyInsuranceByState(projectData?.address?.city, projectData?.address?.region),
        ...defaultObj
      },
      teamId
    );
    if (report) {
      setReports([...reports, report]);
    }
    setReportTitle("");
    handleClose();
    dispatch({
      type: "AddApiAlert",
      payload: {
        success: true,
        message: "Report created successfully",
      },
    });
  }

  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"New Report"}
      content={<div className={classes.dialogCont} >
        {showPage === "options" ? (<div style={{ padding: "15px 0px" }} >
          <Paper
            elevation={3}
            className={classes.paperCont}
            onClick={() => history.push("/investment/analysis/new")}
          >
            <h3>New Property</h3>
            <p>Click here to analyze a new property</p>
          </Paper>

          <div className={classes.orCont} >
            <div></div>
            <span>OR</span>
            <div></div>
          </div>

          <Paper elevation={3} className={classes.paperCont} onClick={() => { setShowPage("createNew") }} >
            <h3>Current Property</h3>
            <p>Click here to a create new report for the current property</p>
          </Paper>
        </div>) : (<>
          <p className={classes.labelStyle} >Report Title</p>
          <TextField
            fullWidth
            autoComplete="off"
            InputProps={{ className: classes.input }}
            value={reportTitle}
            id="outlined-basic"
            placeholder="Enter Report Title"
            variant="outlined"
            size="small"
            onChange={(e) => setReportTitle(e.target.value)}
          />
          <p className={classes.labelStyle} >Investment Type</p>
          <FormControl variant="outlined" size="small" fullWidth>
            <Select
              labelId="propertyType-label"
              id="pTypeId"
              style={{ backgroundColor: "#FCFCFC" }}
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value);
              }}
            >
              <MenuItem value="Rental">Rental</MenuItem>
              <MenuItem value="BRRRR">BRRRR</MenuItem>
              <MenuItem value="Flip">Flip</MenuItem>
            </Select>
          </FormControl>
          <div className={classes.btnCont} >
            <div></div>
            <Button
              onClick={() => { createReport() }}
              color="primary"
              variant="contained"
            >
              CREATE
            </Button>
          </div>
        </>)}
      </div>}
    />);
};

export default ReportCreationDialog;
