import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import { TbMessage } from "react-icons/tb";
import { Dialog, IconButton, InputBase, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import AvatarLocal from "../profile/avatar";
import SendIcon from "@material-ui/icons/Send";
import Api from "../../helpers/Api";
import { isChatExist } from "./apiCall";

const useStyles = makeStyles((theme) => ({
  textAreaBar: {
    maxWidth: "30rem",
    width: "90vw",
    paddingLeft: "1rem",
    margin: "0.5rem 1rem",
    paddingTop: "0.8rem",
    fontSize: 16,
    height: "6rem",
    color: "#424242",
    backgroundColor: "#ebf9fa",
  },
  editButtonSty: {
    padding: "5px 12px",
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "15px",
    border: "1px solid #cccbcb",
    color: "#2e73f8",
    cursor: "pointer",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#f7f4f4"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      borderRadius: "15px",
      fontSize: "13px",
    }
  },
  avatar: {
    height: "1.9rem",
    width: "1.9rem",
  },
  sendBtnCont: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  sendIcon: {
    fontSize: 28,
  },
}));

export default function SendMessage(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme()
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const { senderProfile, receiverProfile } = props;


  const checkConversation = async () => {
    const recProfileId = receiverProfile.profile;
    const senderProfileId = senderProfile.profile;
    const participantIds = [senderProfileId, recProfileId];

    await isChatExist({ participants: participantIds })
      .then((data) => {
        if (data && data.length > 0) {
          var path = `/profile/chat/${senderProfileId}/${data[0]?._id}`;
          history.push(path);

        } else {
          setOpen(true);
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }


  const initConv = async () => {
    const recProfileId = receiverProfile.profile;
    const senderProfileId = senderProfile.profile;
    const participantIds = [senderProfileId, recProfileId];

    const participantsRole = [
      {
        user: { _id: recProfileId },
        role: "Admin"
      },
      {
        user: { _id: senderProfileId },
        role: "Admin"
      }
    ]

    const convObj = {
      participants: participantIds,
      participantsRole,
      user: senderProfileId,
      type: "Private",
      text,
    };

    const res = await Api.post("chat/initConv", convObj);
    if (res?.data) {
      const data = res?.data;
      const conversation = data?.conversation;
      var path = `/profile/chat/${senderProfileId}/${conversation?._id}`;
      history.push(path);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <button
        className={classes.editButtonSty}
        onClick={checkConversation}
      >
        <TbMessage
          style={{
            fontSize: "19px",
            marginBottom: "-4px",
            marginRight: "2px"
          }}
        />
        <span>Message</span>
      </button>

      <Dialog open={open} onClose={() => handleClose()}>
        <List dense={true}>
          <ListItem>
            <ListItemIcon>
              <AvatarLocal src={receiverProfile} className={classes.avatar} />
            </ListItemIcon>
            <ListItemText>
              <b> Message {receiverProfile?.displayName}</b>
            </ListItemText>
          </ListItem>
        </List>
        <InputBase
          multiline
          rowsMax={5}
          value={text}
          placeholder={"Start your message here"}
          onChange={(event) => setText(event.target.value)}
          className={classes.textAreaBar}
        />
        <div className={classes.sendBtnCont}>
          <div></div>

          <IconButton
            variant="outlined"
            onClick={() => {
              initConv();
            }}
          >
            <SendIcon color="primary" className={classes.sendIcon} />
          </IconButton>
        </div>
      </Dialog>
    </>
  );
}
