import React, { useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import FCard from "./FCard";
import manageMultiImage from "../../../Assets/ContractFloAssets/SVG/ManageMultipleApps.svg"
import saveTimeandMoneyImage from "../../../Assets/ContractFloAssets/SVG/SaveTimeandMoney.svg"
import networkwithRealEstateProsImage from "../../../Assets/ContractFloAssets/SVG/NetworkwithRealEstatePros.svg"
import YouTube from 'react-youtube';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "white",
        padding: "40px 180px 55px",
        backgroundColor: theme.palette.primary.main,
        color: "color",
        [theme.breakpoints.down("lg")]: {
            padding: "40px 110px 55px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "40px 20px 40px",
        },
    },
    videoCont: {
        width: "44%",
        [theme.breakpoints.down("sm")]: {
            width: "70%",
            marginBottom: "60px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
        },
    },
    cardsCont: {
        width: "52%",
        display: "flex",
        justifyContent: "space-around",
        alignItems: "flex-start",
        flexWrap: "wrap",
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        }
    },
    mainCont: {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "flex-start",
        marginTop: "30px",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
            marginTop: "10px",
        }
    },
    header: {
        fontSize: "34px",
        fontWeight: "700",
        color: "white",
        [theme.breakpoints.down("sm")]: {
            fontSize: "26px",
        }
    }
}));


class YoutubeFrame extends React.Component {
    render() {
        const {
            videoId
        } = this.props
        const opts = {
            height: "300px",
            width: "100%",
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: true
            },
        };

        return <YouTube videoId={videoId} opts={opts} onReady={this._onReady} />;
    }

    _onReady(event) {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo();
    }
}


export default function WhyChooseCflo({ whyChooseCfloRef }) {
    const classes = useStyles();
    const theme = useTheme();

    const isMdSmall = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <section className={classes.root} ref={whyChooseCfloRef} id="whyChooseCflo" >
            <h3 className={classes.header} >Why Choose ContractFlo</h3>

            <div className={classes.mainCont} data-aos={"fade-right"} >
                <div className={classes.videoCont} >
                    {/* <YoutubeFrame videoId={"https://player.vimeo.com/video/815682077?h=18c109f9ec&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"} opt /> */}

                    <iframe width="100%" height={isMdSmall ? "220" : "300"} src="https://www.youtube.com/embed/FFde9T7Xnh0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>

                </div>
                <div className={classes.cardsCont} data-aos={"fade-left"} >
                    <FCard
                        photo={manageMultiImage}
                        header={"Manage Multiple Apps"}
                        text={"Say goodbye to app-hopping, ContractFlo has everything you need in one place."}
                    />
                    <FCard
                        photo={saveTimeandMoneyImage}
                        header={"Save Time and Money"}
                        text={"No more expensive real estate software - pay only for the tools you need."}
                    />
                    <FCard
                        photo={networkwithRealEstateProsImage}
                        header={"Network with Real Estate Pros"}
                        text={"Network smarter, not harder, with our collaborative SaaS tools."}
                    />
                </div>
            </div>
        </section>
    );
}