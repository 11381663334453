import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import teamUtils from "./team.utils";
import Typography from "@material-ui/core/Typography";
import { Box } from "@material-ui/core";
import TuneIcon from "@material-ui/icons/Tune";
import TimelineIcon from "@material-ui/icons/Timeline";
import { useParams, useHistory, useLocation } from "react-router-dom";
import TeamHome from "./team.home";
import HomeIcon from "@material-ui/icons/Home";
import MemberSetting from "./ManageMembers/member.settings";
import TeamSetting from "./TeamSettings/index";
import TeamAbout from "./about/index";
import PropertyDescription from "../ProjectAnalysis/Property.Description";
import FileUploadButton from "../file/Uploader/FileUploadButton";
import Avatar from "@material-ui/core/Avatar";
import DomainIcon from "@material-ui/icons/Domain";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import GroupIcon from "@material-ui/icons/Group";
import PostAddIcon from "@material-ui/icons/PostAdd";
import InfoIcon from "@material-ui/icons/Info";
import Api from "../../helpers/Api";
import MultiplePhotoViewer from "../styled/CommonComponents/Multiple.Photo.Viewer";
import { addPhotosToProject, getFullProjectData } from "./api.call";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

const {
	handleTeams,
	handleTeamParentData,
	updateOrgInfo,
	handleMemberProfiles,
} = teamUtils;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		padding: "10px 100px 30px",
		[theme.breakpoints.down("md")]: {
			padding: "10px 20px 30px",
		},
		[theme.breakpoints.down("sm")]: {
			padding: "5px 5px 20px",
		},
	},
	profileHeader: {
		width: "100%",
		backgroundColor: "white",
		padding: "10px",
		marginBottom: "15px",
	},
	headPart: {
		width: "100%",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column",
			alignItems: "flex-start",
		},
	},
	profilePicSty: {
		width: "40%",
		height: "300px",
		position: "relative",
		cursor: "pointer",
		borderRadius: "7px",
		overflow: "hidden",
		"&:hover": {
			"& $hoverUploadButton": {
				opacity: 1,
			},
		},
		[theme.breakpoints.down("sm")]: {
			width: "75%",
			marginBottom: "20px",
			height: "300px",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			height: "250px",
		},
	},
	hoverUploadButton: {
		transition: ".5s ease",
		position: "absolute",
		opacity: 0,
		backgroundColor: "white",
		border: "1px solid white",
		borderRadius: "100%",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
	},
	othersImagesCont: {
		width: "56%",
		height: "300px",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			height: "160px",
		},
		[theme.breakpoints.down("xs")]: {
			height: "100px",
		},
	},
	profileDetailsContent: {
		marginTop: "15px",
		marginBottom: "10px",
		"& h3": {
			fontSize: "30px",
			fontWeight: "650",
			color: "#193B56",
		},
		"& p": {
			fontSize: "15px",
			color: "#696969",
		},
		[theme.breakpoints.down("sm")]: {
			"& h3": {
				fontSize: "25px",
			},
			"& p": {
				fontSize: "13px",
				color: "#696969",
			},
		},
	},
	tabsPanel: {
		marginTop: "1rem",
		paddingLeft: "5px",
		borderTop: "2px solid #fafafa",
		flexGrow: 1,
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
}));

const CustomTab = withStyles({
	root: {
		textTransform: "none",
		fontSize: "1rem",
		minWidth: 50,
	},
})(Tab);

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box p={3}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

export default function ProjectHome(props) {
	const { viewMode, viewedTeamId } = props;
	const history = useHistory();
	const classes = useStyles();
	const dispatch = useDispatch();
	let { teamId } = useParams();
	teamId = viewMode ? viewedTeamId : teamId;

	const state = useSelector((state) => state);
	const { user } = useSelector((state) => state.auth);
	const { createdFileIds } = useSelector((state) => state.file);
	const { teamDictionary, teamIds } = useSelector((state) => state.team);

	const [team, setTeam] = useState({});
	const [activitySidebar, setActivitySidebar] = useState(false);
	const [view, setView] = useState("Home");
	const [projectData, setProjectData] = useState({});
	const [cover, setCover] = useState();
	const [participants, setParticipants] = useState();
	const [displayPicture, setDisplayPicture] = useState();
	const [value, setValue] = useState(0);
	const [actualImages, setActualImages] = useState([]);
	const [stateChange, setStateChange] = useState(false);
	const [docs, setDocs] = useState([]);
	const [issues, setIssues] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [chartAccounts, setChartAccounts] = useState([]);
	const [reports, setReports] = useState([]);
	const [units, setUnits] = useState([]);
	const [loader, setLoader] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setTeam(teamDictionary[teamId]);
		setProjectData(teamDictionary[teamId]?.parent);
		setDisplayPicture(
			teamDictionary[teamId]?.parent?.displayPicture?.url || ""
		);
		setCover(teamDictionary[teamId]?.parent?.cover?.url || "");

		const docsArr = teamDictionary[teamId]?.parent?.docs || [];
		let actualImagesArr = [];
		docsArr.map((docData) => {
			if (docData?.files && docData?.files.length > 0) {
				actualImagesArr.push(docData?.files[0]);
			}
		});

		setActualImages(actualImagesArr);
		setParticipants(teamDictionary[teamId]?.populatedParticipants || []);
	}, [teamDictionary, stateChange, teamId]);

	useEffect(() => {
		getProjectProfileData();
	}, [projectData, team]);
	useEffect(() => {
		const urlParams = new URLSearchParams(location.search).get("tab");
		switch (urlParams) {
			case "home":
				setView("Home");
				break;
			case "about":
				setView("About");
				break;
			case "people":
				setView("People");
				break;
			case "settings":
				setView("Settings");
			default:
				history.push({ search: "?tab=home" });
				break;
		}
	}, []);
	const getProjectProfileData = async () => {
		setLoader(true);
		await getFullProjectData({
			profileId: projectData?.profile,
			walletId: team?.wallet,
			teamId: teamId,
			isAdmin: true,
		})
			.then((data) => {
				setDocs(data?.docs || []);
				setIssues(data?.issues || []);
				setUnits(data?.units || []);
				setReports(data?.analysisReports || []);
				setChartAccounts(data?.chartAccounts || []);
				setTransactions(data?.transactions || []);
			})
			.catch((err) => {
				console.log(err);
			});
		setLoader(false);
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const uploadImg = async (fileType) => {
		let res;
		if (team?.parentModelName === "Project") {
			res = await Api.post("project/updatePicture", {
				projId: projectData?._id,
				fileId: createdFileIds[0],
				fileType: fileType,
			});
		} else {
			res = await Api.post("organization/updatePicture", {
				orgId: projectData?._id,
				fileId: createdFileIds[0],
				fileType: fileType,
			});
		}
		if (res?.data) {
			if (fileType === "DP") {
				handleTeamParentData(
					teamId,
					{
						displayPicture: res.data,
					},
					state,
					dispatch
				);
				setDisplayPicture(res.data.url);
			} else {
				handleTeamParentData(
					teamId,
					{
						cover: res.data,
					},
					state,
					dispatch
				);
				setCover(res.data.url);
			}
		}
	};

	const addPhotosToProjectApiCall = async () => {
		if (createdFileIds && createdFileIds.length > 0) {
			let projectId = projectData?._id;
			let userProfile = user?.profile;
			let userId = user?._id;
			let docsArr = [];

			createdFileIds.map((fileId) => {
				docsArr.push({
					image: fileId,
					title: "",
					desc: "",
					tag: "",
				});
			});

			await addPhotosToProject({
				projectId,
				userProfile,
				userId,
				docsArr,
			})
				.then((data) => {
					console.log(data);
					const updatedTeamData = {
						...team,
						parent: data,
					};

					let updatedTeamDictionary = {
						...teamDictionary,
					};

					updatedTeamDictionary[teamId] = updatedTeamData;

					dispatch({
						type: "AddTeam",
						payload: {
							teamDictionary: updatedTeamDictionary,
						},
					});

					dispatch({ type: "FileUploadReset" });
				})
				.catch((err) => {
					console.log(err);
				});
		}
	};

	let View = TeamAbout;

	switch (view) {
		case "Home":
			View = viewMode ? TeamAbout : TeamHome;
			break;

		case "About":
			View = PropertyDescription;
			break;

		case "Settings":
			View = TeamSetting;
			break;

		case "People":
			View = MemberSetting;
			break;

		default:
			break;
	}

	if (!team) {
		// TODO: Link to 404 page next time.
		return <h1>You don't have access to this project</h1>;
	}

	return (
		<div className={classes.root}>
			<Paper className={classes.profileHeader} elevation={2}>
				<div className={classes.headPart}>
					<div className={classes.profilePicSty}>
						{displayPicture ? (
							<Avatar
								variant="square"
								src={displayPicture}
								style={{ width: "100%", height: "100%" }}
							/>
						) : (
							<Avatar
								style={{ width: "100%", height: "100%" }}
								variant="square"
							>
								<DomainIcon fontSize="large" />
							</Avatar>
						)}
						<div className={classes.hoverUploadButton}>
							<FileUploadButton
								parentType="Organization"
								used={true}
								parentId={teamId}
								isDP={true}
								isCover={false}
								uploadImg={uploadImg}
							/>
						</div>
					</div>

					<div className={classes.othersImagesCont}>
						<MultiplePhotoViewer
							projectData={projectData}
							actualImages={actualImages}
							addPhotosToProjectApiCall={
								addPhotosToProjectApiCall
							}
						/>
					</div>
				</div>

				<div className={classes.profileDetailsContent}>
					<h3>{projectData?.displayName}</h3>
					{projectData?.address?.streetAddress ||
					projectData?.address?.city ||
					projectData?.address?.region ||
					projectData?.address?.zip ? (
						<p>{`${projectData?.address?.streetAddress}, ${projectData?.address?.city}, ${projectData?.address?.region}, ${projectData?.address?.zip}`}</p>
					) : (
						<p>Add address</p>
					)}
				</div>

				{participants && participants.length > 0 && (
					<AvatarGroup max={10}>
						{participants.map((participant) => (
							<Avatar
								alt={participant?.parent?.displayName}
								src={participant?.parent?.displayPicture?.url}
							/>
						))}
					</AvatarGroup>
				)}

				<div className={classes.tabsPanel}>
					<div>
						<Tabs
							value={value}
							onChange={handleChange}
							TabIndicatorProps={{
								style: { backgroundColor: "#193B56" },
							}}
							variant="scrollable"
							scrollButtons="auto"
							aria-label="scrollable auto tabs example"
						>
							{!viewMode ? (
								<CustomTab
									label={
										<div>
											<HomeIcon
												style={{
													verticalAlign: "middle",
												}}
											/>{" "}
											Home
										</div>
									}
									onClick={() => {
										setView("Home");
										setActivitySidebar(false);
										history.push({
											search: `?tab=home`,
										});
									}}
								/>
							) : null}
							{!viewMode ? (
								<CustomTab
									label={
										<div>
											<InfoIcon
												style={{
													verticalAlign: "middle",
												}}
											/>{" "}
											About
										</div>
									}
									onClick={() => {
										setView("About");
										setActivitySidebar(false);
										history.push({
											search: `?tab=about`,
										});
									}}
								/>
							) : null}
							{team?.parentModelName !== "Project" ? (
								<CustomTab
									label={
										<div>
											<PostAddIcon
												style={{
													verticalAlign: "middle",
												}}
											/>{" "}
											Posts
										</div>
									}
									onClick={() => {
										setView("Posts");
										setActivitySidebar(false);
										history.push({
											search: `?tab=posts`,
										});
									}}
								/>
							) : null}
							{!viewMode ? (
								<CustomTab
									label={
										<div>
											<GroupIcon
												style={{
													verticalAlign: "middle",
												}}
											/>{" "}
											People
										</div>
									}
									onClick={() => {
										setView("People");
										setActivitySidebar(false);
										history.push({
											search: `?tab=people`,
										});
									}}
								/>
							) : null}
							{!viewMode ? (
								<CustomTab
									label={
										<div>
											<TimelineIcon
												style={{
													verticalAlign: "middle",
												}}
											/>{" "}
											Activities
										</div>
									}
									onClick={() => {
										setView("Home");
										setActivitySidebar(true);
										history.push({
											search: `?tab=home`,
										});
									}}
								/>
							) : null}
							{!viewMode &&
							team?.parentModelName !== "Project" ? (
								<CustomTab
									label={
										<div>
											<TuneIcon
												style={{
													verticalAlign: "middle",
												}}
											/>{" "}
											Settings
										</div>
									}
									onClick={() => {
										setView("Settings");
										setActivitySidebar(false);
										history.push({
											search: `?tab=settings`,
										});
									}}
								/>
							) : null}
						</Tabs>
					</div>
				</div>
			</Paper>

			<View
				activitySidebar={activitySidebar}
				setActivitySidebar={setActivitySidebar}
				teamId={teamId}
				tabMode={true}
				isAppBar={false}
				projectData={projectData}
				setProjectData={setProjectData}
				reports={reports}
				loader={loader}
				docs={docs}
				units={units}
				setUnits={setUnits}
				chartAccounts={chartAccounts}
				transactions={transactions}
				issues={issues}
				walletId={team?.wallet}
				profileId={projectData?.profile}
				parent={projectData?._id}
				parentModelName={'Project'}
			/>
		</div>
	);
}
