import React, { useState, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import collaborationImage from "../../../Assets/ContractFloAssets/SVG/collaborationold.svg"
import networkingSAASImage from "../../../Assets/ContractFloAssets/SVG/NetworkingSAAS.svg"
import { Button } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "white",
        padding: "100px 180px 30px",
        [theme.breakpoints.down("lg")]: {
            padding: "100px 110px 30px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "90px 80px 30px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "70px 30px 30px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "40px 20px 20px",
        },
    },
    headerCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "70px",
        "& h3": {
            fontSize: "35px",
            fontWeight: "800",
            textAlign: "center",
            marginBottom: "15px"
        },
        "& p": {
            fontSize: "14px",
            color: "#777575",
            textAlign: "center",
            width: "700px",
            lineHeight: "28px"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "50px",
            "& p": {
                width: "100%",
                fontSize: "12px",
                lineHeight: "20px"
            },
            "& h3": {
                fontSize: "28px",
                fontWeight: "700",
                marginBottom: "12px"
            },
        }
    },
    mainCont: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            justifyContent: "space-around",
            alignItems: "center",
        }
    },
    mainImageCont: {
        width: "60%",
        height: "500px",
        position: "relative",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            width: "48%",
            height: "470px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "90%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
            height: "390px",
        },
    },
    imgCont: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        padding: "20px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& img": {
            height: "450px",
            width: "auto"
        },
        [theme.breakpoints.down("md")]: {
            "& img": {
                height: "360px",
                width: "auto"
            },
        },
        [theme.breakpoints.down("xs")]: {
            "& img": {
                height: "280px",
                width: "auto"
            },
        }
    },
    detailsCont: {
        width: "40%",
        marginTop: "40px",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        [theme.breakpoints.down("md")]: {
            width: "50%",
        },
        [theme.breakpoints.down("sm")]: {
            width: "70%",
            marginTop: "0px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
        },
    },
    headerUlCont: {
        width: "100%",
        height: "320px",
        position: "relative",
        overflow: "hidden",
        [theme.breakpoints.down("xs")]: {
            height: "420px",
        }
    },
    btnsCont: {
        display: "flex",
        padding: "7px 15px",
        backgroundColor: "#ECF7FF",
        marginBottom: "60px",
        borderRadius: "6px",
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontWeight: "800",
            fontSize: "19px"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "0px",
            "& .MuiButton-label": {
                textTransform: "capitalize",
                fontWeight: "700",
                fontSize: "16px"
            },
        }
    },
    textCont: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
        "& h3": {
            fontSize: "27px",
            fontWeight: "800",
            marginBottom: "15px"
        },
        "& ul": {
            marginBottom: "50px",
            marginLeft: "30px"
        },
        "& li": {
            lineHeight: "28px",

        }
    },
    smallScreenBtnCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export default function SaasProduct({ saasProductRef }) {
    const classes = useStyles();
    const theme = useTheme();

    const [collaboration, setCollaboration] = useState(true)
    const [networking, setNetworking] = useState(false)

    const isMdSmall = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <section className={classes.root} ref={saasProductRef} id="saasProduct" >
            <div className={classes.headerCont} >
                <h3 data-aos={"fade-up"} >More than SAAS Product</h3>
                <p data-aos={"fade-up"} >ContractFlo is the ultimate real estate management platform that goes beyond just providing software tools. With ContractFlo, you can collaborate with your team efficiently, find new opportunities and network with other professionals in the industry.</p>
            </div>


            {isMdSmall && (
                <div className={classes.smallScreenBtnCont} data-aos={"fade-up"} >
                    <div className={classes.btnsCont} >
                        <Button
                            variant={collaboration ? "contained" : ""}
                            color={collaboration ? "primary" : ""}
                            onClick={() => {
                                setCollaboration(true)
                                setNetworking(false)
                            }}
                        >
                            <span style={collaboration ? { color: "white" } : { color: "black" }} >Collaboration</span>
                        </Button>
                        <Button
                            variant={networking ? "contained" : ""}
                            color={networking ? "primary" : ""}
                            onClick={() => {
                                setNetworking(true)
                                setCollaboration(false)
                            }}
                        >
                            <span style={networking ? { color: "white" } : { color: "black" }} >Networking</span>
                        </Button>
                    </div>
                </div>
            )}
            <div className={classes.mainCont} >
                <div className={classes.mainImageCont} data-aos={"fade-up"} >
                    <Slide direction="right" timeout={500} in={collaboration} mountOnEnter unmountOnExit >
                        <div className={classes.imgCont} >
                            <img src={collaborationImage} />
                        </div>
                    </Slide>

                    <Slide direction="right" timeout={500} in={networking} mountOnEnter unmountOnExit >
                        <div className={classes.imgCont} >
                            <img src={networkingSAASImage} />
                        </div>
                    </Slide>
                </div>

                <div className={classes.detailsCont} >
                    {!isMdSmall && (
                        <div className={classes.btnsCont} data-aos={"fade-up"} >
                            <Button
                                variant={collaboration ? "contained" : ""}
                                color={collaboration ? "primary" : ""}
                                onClick={() => {
                                    setCollaboration(true)
                                    setNetworking(false)
                                }}
                            >
                                <span style={collaboration ? { color: "white" } : { color: "black" }} >Collaboration</span>
                            </Button>
                            <Button
                                variant={networking ? "contained" : ""}
                                color={networking ? "primary" : ""}
                                onClick={() => {
                                    setNetworking(true)
                                    setCollaboration(false)
                                }}
                            >
                                <span style={networking ? { color: "white" } : { color: "black" }} >Networking</span>
                            </Button>
                        </div>
                    )}

                    <div className={classes.headerUlCont} data-aos={"fade-up"} >
                        <Slide direction="left" timeout={500} in={collaboration} mountOnEnter unmountOnExit >
                            <div className={classes.textCont} >
                                <h3>Collaboration</h3>
                                <ul>
                                    <li>Invite people to projects and organizations with different roles.</li>
                                    <li>Control access and visibility of issues, templates, docs.</li>
                                    <li>Project collaboration tools: Team messaging, file sharing, and document storage.</li>
                                    <li>Engage in joint ventures on deals from our network.</li>
                                    <li>Not just people, even the Saas tools interact and collaborate among themselves seamlessly.</li>
                                </ul>
                            </div>
                        </Slide>

                        <Slide direction="left" timeout={500} in={networking} mountOnEnter unmountOnExit >
                            <div className={classes.textCont} >
                                <h3>Networking</h3>
                                <ul>
                                    <li>Grow your real estate network by connecting with professionals on Contractflo network.</li>
                                    <li>professional profiles: Showcase skills, experience, and projects.</li>
                                    <li>Find and post jobs and deals in c/Jobs and c/Deals community respectively.</li>
                                    <li>Participate in discussions and forums related to real estate.</li>
                                    <li>Stay updated on industry news and trends.</li>
                                </ul>
                            </div>
                        </Slide>
                    </div>
                </div>
            </div>
        </section>
    );
}