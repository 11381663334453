import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import LessText from "../styled/CommonComponents/LessText";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "100%",
		height: "295px",
		marginTop: "20px",
		"&::-webkit-scrollbar": {
			display: "none",
		},
	},
	tbHeaderCell: {
		borderTop: "1px solid #e0e0e0",
		color: "#696969",
		fontSize: "15px",
		padding: "0px",
	},
	tbBodyCell: {
		border: "none",
		padding: "8px 5px",
	},
	avatarGroupSty: {
		maxWidth: "100px",
		"& .MuiAvatar-root": {
			width: "30px",
			height: "30px",
		},
	},
}));

export default function OrgTable(props) {
	const classes = useStyles();
	const history = useHistory();
	const theme = useTheme();
	const { rows } = props;
	const { user } = useSelector((state) => state.auth);

	const [filteredRows, setFilteredRows] = useState([]);

	useEffect(() => {
		setFilteredRows(rows);
	}, [rows]);

	return (
		<TableContainer className={classes.container}>
			<Table stickyHeader aria-label="sticky table" size="small">
				<TableHead>
					<TableRow
						style={{
							position: "relative",
							zIndex: "99",
							height: "35px",
						}}
					>
						<TableCell
							align="left"
							className={classes.tbHeaderCell}
							style={{ fontSize: "15px", paddingLeft: "5px" }}
						>
							Name
						</TableCell>
						<TableCell
							align="right"
							className={classes.tbHeaderCell}
							style={{
								fontSize: "15px",
								width: "110px",
								paddingRight: "5px",
							}}
						>
							Participants
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{filteredRows.map((row) => (
						<TableRow
							hover
							role="checkbox"
							tabIndex={-1}
							key={row._id}
							onClick={() => {
								history.push(`/organizations/${row?._id}`);
							}}
							style={{
								cursor: "pointer",
								padding: "0px",
								height: "25px",
							}}
						>
							<TableCell
								align={"left"}
								className={classes.tbBodyCell}
								style={{ fontSize: "13px", paddingLeft: "5px" }}
							>
								<div
									style={{
										display: "flex",
										alignItems: "center",
									}}
								>
									<Avatar
										style={{
											width: "30px",
											height: "30px",
										}}
										alt="project pic"
										src={row?.parent?.displayPicture?.url}
									/>
									<span
										style={{
											marginLeft: "7px",
											fontSize: "12px",
											color: "#333333",
											fontWeight: "510",
											textTransform: "capitalize",
										}}
									>
										<LessText
											limit={19}
											string={row?.parent?.displayName}
										/>
									</span>
								</div>
							</TableCell>
							<TableCell
								align="right"
								className={classes.tbBodyCell}
								style={{ padding: "10px 5px" }}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
									}}
								>
									<AvatarGroup
										max={4}
										className={classes.avatarGroupSty}
									>
										{row?.populatedParticipants &&
											row?.populatedParticipants.length >
												0 &&
											row?.populatedParticipants.map(
												(participant, idx) => (
													<Avatar
														key={idx}
														alt={
															participant?.parent
																?.displayName
														}
														src={
															participant?.parent
																?.displayPicture
																?.url
														}
													/>
												)
											)}
									</AvatarGroup>
								</div>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
