import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";
import { Paper, Button, IconButton } from '@material-ui/core';
import moment from 'moment';
import CreateIcon from '@material-ui/icons/Create';
import VisibilityIcon from '@material-ui/icons/Visibility';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    marginBottom: "20px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  topbar: {
    width: "100%",
    display: "flex",
    padding: "8px 15px",
    backgroundColor: "#d1e8f5",
    alignItems: "center",
    justifyContent: "space-between",
  },
  bottomCont: {
    padding: "15px"
  },
  topInfoCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& h3": {
      fontSize: "16px",
      fontWeight: "510",
      color: "#656464"
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "510"
    }
  },
  dataCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  subCont: {
    width: "33%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    "& p": {
      fontSize: "17px",
      fontWeight: "500",
      color: "#656464"
    },
    "& h3": {
      fontSize: "17px",
      fontWeight: "510",
    },
    [theme.breakpoints.down("xs")]: {
      width: "50%",
      marginBottom: "10px"
    }
  }
}));

export default function TxCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const { walletId } = useParams();
  const { txData, editClickUrl, viewClickUrl } = props;

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const viewOrEdit = () => {
    let path = ""
    if (walletId == txData?.firstPartyWallet) {
      path = `${editClickUrl}${txData?._id}`
    } else if (walletId == txData?.secondPartyWallet) {
      path = `${viewClickUrl}${txData?._id}`
    }
    history.push(path);
  }

  return (
    <Paper elevation={2} className={classes.mainCont} >
      <div className={classes.topbar} >
        {txData?.type === "Invoice" && (<div className={classes.topInfoCont} >
          <h3>Invoice No.</h3>
          <h4>{txData?.invNo}</h4>
        </div>)}
        {txData?.type === "Bill" && (<div className={classes.topInfoCont} >
          <h3>Bill No.</h3>
          <h4>{txData?.billNo}</h4>
        </div>)}
        <div style={{ display: "flex", alignItems: "center" }} >
          <div className={classes.topInfoCont} >
            <h3>Created At</h3>
            <h4>{moment(txData?.createdAt).format('MMM Do YYYY')}</h4>
          </div>
          <IconButton
            style={{ marginLeft: "10px" }}
            onClick={viewOrEdit}
          >
            {txData?.status === "Partially Paid" || txData?.status === "Processing" || txData?.status === "Failed" || txData?.status === "Paid" ? (
              <VisibilityIcon />) : (<>
                {walletId === txData?.firstPartyWallet ? (<CreateIcon />) : (<VisibilityIcon />)}
              </>)}
          </IconButton>
        </div>
      </div>

      <div className={classes.bottomCont} >
        <div className={classes.dataCont} >
          <div className={classes.subCont} >
            <p>Amount</p>
            <h3>$ {numberWithCommas(txData?.finalAmount || 0)}</h3>
          </div>
          <div className={classes.subCont} >
            <p>Amount Paid</p>
            <h3>$ {numberWithCommas(txData?.amountPaid || 0)}</h3>
          </div>
          <div className={classes.subCont} >
            <p>Status</p>
            <h3>{txData?.status}</h3>
          </div>
        </div>
      </div>
    </Paper>
  );
}