import React from "react";
import BasicPrivateRoute from "../components/PrivateRoute/BasicPrivateRoute";
import PublicPrivateRoute from "../components/PrivateRoute/PublicPrivateRoute";
import Search from "../components/profile/advanced.search";
import JobView from "../components/job/job.view";
import InvestmentView from "../components/Investment/investment.view";
import Apply from "../components/apply/apply.create";
import { Switch, Route } from "react-router-dom";
import InvestmentFeed from "../components/explore/investment.feed";
import JobFeed from "../components/explore/job.feed";
import ProfileView from "../components/profile/profile.view";
import CommunityPage from "../components/community/CommunityPage";
import AuthBox from "../components/auth/AuthBox";
import JobApply from "../components/job/job.apply";
import InvestmentApply from "../components/Investment/investment.apply";
import ApplyCreate from "../components/apply/apply.create";
import ApplyInvestmentCreate from "../components/Investment/apply.create";
import PostPageLayout from "../components/post/PostPageLayout";
import Forum from "../components/community/Forum";
import SchedulerFormTester from "../components/scheduler/SchdeulerFormTester";
import PublicProfileView from "../components/profile/public.profile.view";
import PreLogin from "../components/preLogin/PreLogin";
import PublicPostView from "../components/profile/public.post.view";
import DocResources from "../components/resources/Doc.Resources"
import SinglePostView from "../components/post/Single.Post.View";
import DocPostView from "../components/doc/Doc.Post.View";


const Routes = () => {
  return [
    <BasicPrivateRoute
      exact
      path="/feed"
      component={PostPageLayout}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/explore/forum/"
      component={Forum}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/explore/doc/resources"
      component={DocResources}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/doc/resources/:categorySlug"
      component={DocResources}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/forum/communities/:communityNameSlug"
      component={CommunityPage}
      useBothSide
    />,
    <PublicPrivateRoute
      path="/explore/forum/post/:curPostId"
      component={SinglePostView}
      useBothSide
      PublicComponent={PublicPostView}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/doc/:docId"
      component={DocPostView}
      useBothSide={true}
    />,
    <PublicPrivateRoute
      exact
      path="/profile/view/:profileId"
      component={ProfileView}
      PublicComponent={PublicProfileView}
      useBothSide={true}
    />,
    <BasicPrivateRoute
      exact
      path="/feed/apply"
      component={Apply}
    />,
    <BasicPrivateRoute
      exact
      path="/temp"
      component={SchedulerFormTester}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/invest"
      component={InvestmentFeed}
    />,
    <BasicPrivateRoute
      exact
      path="/explore/jobs"
      component={JobFeed}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/job/apply/:jobId"
      component={JobApply}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/investment/apply/:investmentId"
      component={InvestmentApply}
      noAppbar={true}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/investment/apply/:investmentId/applyform/:userId"
      component={ApplyInvestmentCreate}
      noAppbar={true}
    />,
    <BasicPrivateRoute
      exact
      path="/dashboard/job/apply/:jobId/applyform/:userId"
      noAppbar={true}
      component={ApplyCreate}
    />,
    <Route
      exact
      path="/explore/test"
      component={AuthBox}
    />,
    <Route
      exact
      path="/preLogin"
      component={PreLogin}
    />,
    <BasicPrivateRoute
      exact
      path="/feed/job/:jobId"
      component={JobView}
    />,
    <BasicPrivateRoute
      exact
      path="/feed/investment/:investmentId"
      component={InvestmentView}
    />,
    <BasicPrivateRoute
      exact
      path="/search"
      component={Search}
      useBothSide
    />,
    <BasicPrivateRoute
      exact
      path="/search/:searchSlug"
      component={Search}
      useBothSide
    />,
  ];
};
export default Routes;