import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { addProfileSectionApiHit } from "../api";
import { useMediaQuery } from '@material-ui/core';
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Checkbox } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { degreesArray, fieldOfStudyArray } from "../../styled/CommonComponents/degreeArray"
import LinearProgress from '@material-ui/core/LinearProgress';
import * as yup from "yup";

const schema = yup.object().shape({
  school: yup.string().min(2).max(250).required("School should be required"),
  degree: yup.string().required("degree should be required"),
  fieldOfStudy: yup.string().required("Field of study should be required"),
  grade: yup.number().typeError("Grade should be a number").positive("Grade should be a positive number"),
  startMonth: yup.string().required("Start month should be required"),
  startYear: yup.string().required("Start year should be required"),
  description: yup.string().max(350),
})

const useStyles = makeStyles((theme) => ({
  singleEducationCont: {
    width: "550px",
    maxHeight: "450px",
    overflowY: "auto",
    marginBottom: "20px",
    padding: "0px 15px 10px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("sm")]: {
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "0px 5px 10px",
      width: "300px",
      maxHeight: "360px",
    },
  },
  textLineCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  halfCont: {
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  checkedCont: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-10px",
    "& h3": {
      fontSize: "15px",
      marginRight: "15px",
      fontWeight: "500",
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    marginTop: "7px",
    marginBottom: "0px"
  }
}));

const calendarMonth = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const EducationDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const { user } = useSelector((state) => state.auth);

  const [school, setSchool] = useState("");
  const [degree, setDegree] = useState("");
  const [field, setField] = useState("");
  const [grade, setGrade] = useState("");
  const [description, setDescription] = useState("");
  const [startMonthVal, setStartMonthVal] = useState("")
  const [startYearVal, setStartYearVal] = useState("")
  const [endMonthVal, setEndMonthVal] = useState("")
  const [endYearVal, setEndYearVal] = useState("")
  const [isCurrentlyStudying, setIsCurrentlyStudying] = useState(false)
  const [calendarYear, setCalendarYear] = useState([]);
  const [loading, setLoading] = useState(false)
  const [validationObj, setValidationObj] = useState({})

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const years = [];
    const date = new Date();
    const endYear = date.getFullYear();
    for (let i = 1951; i <= endYear; i++) {
      years.push(i + "");
    }
    setCalendarYear(years);
  }, []);

  console.log(validationObj)


  useEffect(() => {
    setValidationObj({})
  }, [
    school,
    degree,
    field,
    grade,
    description,
    startMonthVal,
    startYearVal,
  ])

  const saveData = async (e) => {
    e.preventDefault();

    const formData = {
      school: school,
      degree: degree,
      fieldOfStudy: field,
      grade: grade,
      startMonth: startMonthVal,
      startYear: startYearVal,
      description: description,
    }

    await schema.validate(formData, { abortEarly: false })
      .then(async (data) => {
        console.log(data)

        setLoading(true)
        let userId = user?._id
        let arrayKey = "education"
        let sectionDataArr = [{
          school: school,
          degree: degree,
          field: field,
          start_date: `${startMonthVal}-${startYearVal}`,
          isCurrentlyStudying: isCurrentlyStudying,
          end_date: `${endMonthVal}-${endYearVal}`,
          grade: grade,
          description: description
        }]

        await addProfileSectionApiHit({ userId, arrayKey, sectionDataArr })
          .then((data) => {
            console.log(data)
            dispatch({
              type: "AddAuth",
              payload: {
                user: {
                  ...user,
                  education: data?.education,
                },
              },
            });
            setLoading(false)
          })
          .catch((err) => {
            console.log(err);
          })

        setSchool("")
        setDegree("")
        setField("")
        setGrade("")
        setDescription("")
        setStartMonthVal("")
        setStartYearVal("")
        setEndMonthVal("")
        setEndYearVal("")
        setIsCurrentlyStudying(false)
        setOpen(false)


      })
      .catch((err) => {
        let errorArr = err?.inner
        let obj = {}
        errorArr.map((err) => {
          obj[err?.path] = err?.message
        })
        setValidationObj(obj)
      })
  };


  return (<>
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Add Education"}
      content={<form onSubmit={saveData} >
        <div className={classes.singleEducationCont} >
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <p className={classes.label} >School</p>
              <TextField
                id="school"
                placeholder="Ex: Stanford University"
                value={school}
                onChange={(e) => {
                  setSchool(e.target.value)
                }}
                size="small"
                variant="outlined"
                style={{ width: "100%" }}
              />
              {validationObj?.school && (<p style={{ color: "#EE1D52" }} >{validationObj?.school}</p>)}
            </div>
            <div className={classes.halfCont} >
              <p className={classes.label} >Degree</p>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={degree}
                options={degreesArray}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === degree
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Ex: Bachelor's"}
                  />
                )}
                onChange={(event, value) => {
                  setDegree(value);
                }}
                style={{ width: "100%" }}
                size="small"
              />
              {validationObj?.degree && (<p style={{ color: "#EE1D52" }} >{validationObj?.degree}</p>)}
            </div>
          </div>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <p className={classes.label} >Field of Study</p>
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={field}
                options={fieldOfStudyArray}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === field
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder="Ex: Business"
                  />
                )}
                onChange={(event, value) => {
                  setField(value);
                }}
                style={{ width: "100%" }}
                size="small"
              />
              {validationObj?.fieldOfStudy && (<p style={{ color: "#EE1D52" }} >{validationObj?.fieldOfStudy}</p>)}
            </div>
            <div className={classes.halfCont} >
              <p className={classes.label} >Grade</p>
              <TextField
                id="grade"
                placeholder="Ex: CGPA/Percentage"
                value={grade}
                onChange={(e) => {
                  setGrade(e.target.value);
                }}
                size="small"
                variant="outlined"
                fullWidth
              />
              {validationObj?.grade && (<p style={{ color: "#EE1D52" }} >{validationObj?.grade}</p>)}
            </div>
          </div>
          <p className={classes.label} >Start date</p>
          <div className={classes.textLineCont} >
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={startMonthVal}
                options={calendarMonth}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === startMonthVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter Start Month"}
                  />
                )}
                onChange={(event, value) => {
                  setStartMonthVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.startMonth && (<p style={{ color: "#EE1D52" }} >{validationObj?.startMonth}</p>)}
            </div>
            <div className={classes.halfCont} >
              <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={startYearVal}
                options={calendarYear}
                getOptionLabel={(option) => { return option }}
                getOptionSelected={(option) => {
                  return option === startYearVal
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    style={{ margin: "0px" }}
                    margin="normal"
                    variant="outlined"
                    placeholder={"Enter Start Year"}
                  />
                )}
                onChange={(event, value) => {
                  setStartYearVal(value)
                }}
                style={{ width: "100%", marginBottom: "5px" }}
                size="small"
              />
              {validationObj?.startYear && (<p style={{ color: "#EE1D52" }} >{validationObj?.startYear}</p>)}
            </div>
          </div>
          <div className={classes.checkedCont} >
            <h3>Currently Studing</h3>
            <Checkbox
              color="primary"
              checked={isCurrentlyStudying}
              onChange={(e) => { setIsCurrentlyStudying(e.target.checked) }}
            />
          </div>
          {isCurrentlyStudying ? null : (<>
            <p className={classes.label} >End date</p>
            <div className={classes.textLineCont} >
              <div className={classes.halfCont} >
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={endMonthVal}
                  options={calendarMonth}
                  getOptionLabel={(option) => { return option }}
                  getOptionSelected={(option) => {
                    return option === endMonthVal
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ margin: "0px" }}
                      margin="normal"
                      variant="outlined"
                      placeholder={"Enter End Month"}
                    />
                  )}
                  onChange={(event, value) => {
                    setEndMonthVal(value)
                  }}
                  style={{ width: "100%", marginBottom: "5px" }}
                  size="small"
                />
                {validationObj?.endMonth && (<p style={{ color: "#EE1D52" }} >{validationObj?.endMonth}</p>)}
              </div>
              <div className={classes.halfCont} >
                <Autocomplete
                  id="free-solo-demo"
                  freeSolo
                  value={endYearVal}
                  options={calendarYear}
                  getOptionLabel={(option) => { return option }}
                  getOptionSelected={(option) => {
                    return option === endYearVal
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      style={{ margin: "0px" }}
                      margin="normal"
                      variant="outlined"
                      placeholder={"Enter End Year"}
                    />
                  )}
                  onChange={(event, value) => {
                    setEndYearVal(value)
                  }}
                  style={{ width: "100%", marginBottom: "5px" }}
                  size="small"
                />
                {validationObj?.endYear && (<p style={{ color: "#EE1D52" }} >{validationObj?.endYear}</p>)}
              </div>
            </div>
          </>)}
          <p className={classes.label} >Description</p>
          <TextField
            id="description"
            placeholder="Write anything notable..."
            multiline
            rows={3}
            variant="outlined"
            size="small"
            value={description}
            onChange={(e) => {
              setDescription(e.target.value);
            }}
            fullWidth
          />
          {validationObj?.description && (<p style={{ color: "#EE1D52" }} >{validationObj?.description}</p>)}
        </div>
        <div style={{ height: "5px", marginTop: "2px" }} >
          {loading && (<LinearProgress />)}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "3px 7px" }} >
          <div></div>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            startIcon={<SaveIcon />}
            size={isSmall ? "small" : "medium"}
          >Save</Button>
        </div>
      </form>}
    />
  </>);
};

export default EducationDialog;
