import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        backgroundColor: "white",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
        borderRadius: "7px"
    },
    postHead: {
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #e3e0e0",
        marginBottom: "15px",
        padding: "10px",
    }
}));

function PostSkeleton() {
    const classes = useStyles();
    return (
        <div className={classes.mainCont} >
            <div className={classes.postHead} >
                <Skeleton variant="circle" width={50} height={50} />
                <div style={{ marginLeft: "10px", width: "45%" }} >
                    <Skeleton style={{ width: "100%", height: "27px" }} variant="text" />
                    <Skeleton style={{ width: "70%", height: "18px" }} variant="text" />
                </div>
            </div>
            <div style={{ padding: "0px 10px" }} >
                <Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
                <Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
                <Skeleton style={{ width: "100%", height: "18px" }} variant="text" />
                <Skeleton variant="rect" style={{ width: "100%", margin: "20px 0px 10px" }} height={200} />
                <Skeleton style={{ width: "100%", height: "85px" }} variant="text" />
            </div>
        </div>
    );
}
export default PostSkeleton; 