import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { getBothSideTxByWallet, getBothSideTxCountByWallet } from '../../commonComponent/transaction/api';
import TransactionsTable from '../../commonComponent/Transactions.Table';
import TxDetailsDialog from '../../commonComponent/TxDetails.Dialog';

export default function AllExpenses(props) {
  const { walletId } = useParams()
  const [openDetails, setOpenDetails] = useState(false)
  const [selectedTx, setSelectedTx] = useState(null)

  return (<>
    <TransactionsTable
      walletId={walletId}
      getDataApiHitFun={getBothSideTxByWallet}
      getDataObj={{
        walletId: walletId,
        type: "Bill"
      }}
      getNumberOfDataRowApiHit={getBothSideTxCountByWallet}
      getDataNumberObj={{
        walletId: walletId,
        type: "Bill"
      }}
      setSelectedTx={setSelectedTx}
      setOpenDetails={setOpenDetails}
      emptyText={"No expenses available"}
    />

    {selectedTx && (
      <TxDetailsDialog
        walletId={walletId}
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        selectedTx={selectedTx}
        setSelectedTx={setSelectedTx}
        editClickUrl={`/finance/${walletId}/bill/edit/`}
        viewClickUrl={`/finance/${walletId}/bill/view/`}
      />
    )}
  </>);
}