import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Avatar, CircularProgress } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { joinOrLeaveCommunity } from "./api.call";
import { Skeleton } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import LessText from "../styled/CommonComponents/LessText";
import NormalDialog from "../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    backgroundColor: "white",
    paddingBottom: "10px",
    borderRadius: "5px",
    boxShadow:
      "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
    },
  },
  headSty: {
    padding: "10px 15px",
    borderBottom: "1px solid #e3e0e0",
    fontSize: "17px",
    fontWeight: "500",
    color: "#00345D"
  },
  communitesCont: {
    width: "100%",
    maxHeight: "235px",
    paddingTop: "3px",
  },
  communityLine: {
    cursor: "pointer",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "5px 15px",
    "& h3": {
      fontSize: "15px",
      fontWeight: "400",
      marginLeft: "10px"
    },
  },
  imgNameCont: {
    width: "calc(100% - 50px)",
    display: "flex",
    alignItems: "center",
  },
  AddIconCont: {
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .MuiSvgIcon-root": {
      fontSize: "30px",
      color: theme.palette.primary.main,
    },
  },
  seemoreSty: {
    textAlign: "center",
    padding: "0px 10px",
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
  dialogBody: {
    width: "350px",
    height: "500px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down("xs")]: {
      width: "290px",
    },
  },
}));

function SuggestedCommunitySidebar({
  suggestionLoading,
  suggestedCommunites,
  reloadSuggestion,
  reloadOwnCommunities,
}) {
  const { user } = useSelector((state) => state.auth);
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));

  const [joinLoading, setJoinLoading] = useState(false);
  const [curCom, setCurCom] = useState(null);
  const [sugComHasMore, setSugComHasMore] = useState(false);
  const [arr, setArr] = useState([]);
  const [openFullView, setOpenFullView] = useState(false);

  const handleClose = () => {
    setOpenFullView(false);
  };

  useEffect(() => {
    if (isMdSmall) {
      if (suggestedCommunites) {
        if (suggestedCommunites.length > 0 && suggestedCommunites.length > 3) {
          setSugComHasMore(true);
          let data = suggestedCommunites.slice(1, 4);
          setArr([...data]);
        } else {
          setSugComHasMore(false);
          setArr([...suggestedCommunites]);
        }
      }
    } else {
      if (suggestedCommunites) {
        if (suggestedCommunites.length > 0 && suggestedCommunites.length > 5) {
          setSugComHasMore(true);
          let data = suggestedCommunites.slice(1, 6);
          setArr([...data]);
        } else {
          setSugComHasMore(false);
          setArr([...suggestedCommunites]);
        }
      }
    }
  }, [suggestedCommunites, isMdSmall]);

  const joinCommunity = async (community) => {
    setJoinLoading(true);
    joinOrLeaveCommunity({
      user: user?._id,
      profile: user?.profile,
      communityObj: community,
      communityProfile: community?.profile,
    })
      .then(async (data) => {
        await reloadSuggestion();
        await reloadOwnCommunities();
        setJoinLoading(false);
        setCurCom(null);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (<>
    {!suggestionLoading && suggestedCommunites !== null && suggestedCommunites.length === 0 ? null : (
      <div className={classes.mainCont}>
        <h3 className={classes.headSty}>Suggested Communities</h3>
        <div className={classes.communitesCont}>
          {suggestionLoading && suggestedCommunites === null ? (
            <div style={{ padding: "0px 15px" }}>
              {isMdSmall ? (
                <>
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                </>
              ) : (
                <>
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                  <Skeleton
                    style={{ width: "100%", height: "40px" }}
                    variant="text"
                  />
                </>
              )}
            </div>
          ) : (
            <>
              {arr &&
                arr.length > 0 &&
                arr.map((community, i) => (
                  <div className={classes.communityLine}>
                    <div
                      className={classes.imgNameCont}
                      onClick={() => {
                        history.push(
                          `/explore/forum/communities/${community?.slug}`
                        );
                      }}
                    >
                      <Avatar
                        style={{ width: "35px", height: "35px" }}
                        src={
                          community?.displayPicture?.thumbUrl ||
                          community?.displayPicture?.url
                        }
                        alt={"pic"}
                      />
                      <h3>
                        c/
                        <LessText limit={13} string={community?.displayName} />
                      </h3>
                    </div>
                    {curCom && curCom === community?._id && joinLoading ? (
                      <CircularProgress size={30} thickness={3} />
                    ) : (
                      <div
                        className={classes.AddIconCont}
                        onClick={() => {
                          joinCommunity(community);
                          setCurCom(community?._id);
                        }}
                      >
                        <AddIcon />
                      </div>
                    )}
                  </div>
                ))}
            </>
          )}
        </div>
        {sugComHasMore && (
          <div
            className={classes.seemoreSty}
            onClick={() => {
              setOpenFullView(true);
            }}
          >
            See More
          </div>
        )}
        <NormalDialog
          openDialog={openFullView}
          handleCloseShare={handleClose}
          pageTitle={"Suggested Communities"}
          content={
            <div className={classes.dialogBody}>
              {arr &&
                arr.length > 0 &&
                arr.map((community, i) => (
                  <div className={classes.communityLine}>
                    <div
                      className={classes.imgNameCont}
                      onClick={() => {
                        history.push(
                          `/explore/forum/communities/${community?.slug}`
                        );
                      }}
                    >
                      <Avatar
                        style={{ width: "35px", height: "35px" }}
                        src={
                          community?.displayPicture?.thumbUrl ||
                          community?.displayPicture?.url
                        }
                        alt={"pic"}
                      />
                      <h3>
                        c/
                        <LessText limit={24} string={community?.displayName} />
                      </h3>
                    </div>
                    {curCom && curCom === community?._id && joinLoading ? (
                      <CircularProgress size={30} thickness={3} />
                    ) : (
                      <div
                        className={classes.AddIconCont}
                        onClick={() => {
                          joinCommunity(community);
                          setCurCom(community?._id);
                        }}
                      >
                        <AddIcon />
                      </div>
                    )}
                  </div>
                ))}
            </div>
          }
        />
      </div>
    )}
  </>);
}
export default SuggestedCommunitySidebar;