import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import { MdVerified } from "react-icons/md";
import MyPopOver from '../../../../styled/CommonComponents/MyPopOver';
import LessText from "../../../../styled/CommonComponents/LessText"
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

const useStyles = makeStyles((theme) => ({
  dwollaBankItem: {
    width: "32%",
    border: "1px solid #989898",
    padding: "10px",
    marginRight: "10px",
    marginTop: "10px",
    [theme.breakpoints.down('md')]: {
      width: "48%",
    },
    [theme.breakpoints.down('xs')]: {
      width: "98%",
      marginRight: "0px",
    }
  },
  bankNameCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  banktextSty: {
    "& h3": {
      fontSize: "16px",
      fontWeight: "510"
    },
    "& p": {
      fontSize: "11px",
      fontWeight: "450",
      color: "gray"
    },
    [theme.breakpoints.down('sm')]: {
      "& h3": {
        fontSize: "14px",
      },
    }
  },
  otherDetailsCont: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "14px",
      color: "gray",
      fontWeight: "400",
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
      fontWeight: "450",
    }
  },
  verifiedSty: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main,
    "& p": {
      marginRight: "5px",
      fontSize: "12px"
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: "11px"
    }
  },
  moreBtn: {
    marginTop: "-10px",
    marginRight: "-10px"
  },
  singleOption: {
    padding: "5px 10px",
    fontSize: "15px",
    fontWeight: "500",
    cursor: "pointer",
  }
}));

export default function DwollaBankCardItem(props) {
  const { item, setDefaultSrc, selected, removeBankAcc } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const bankName = item?.bankName;
  const bankAccountType = item?.bankAccountType;

  return (
    <Paper className={classes.dwollaBankItem} >
      <div className={classes.bankNameCont} >
        <div className={classes.banktextSty} >
          <h3>
            <LessText
              limit={12}
              string={bankName}
            />
          </h3>
          <p>
            <LessText
              limit={20}
              string={item?.name}
            />
          </p>
        </div>
        <div className={classes.verifiedSty} >
          {selected ? (<>
            <p>Primary Account</p>
            <MdVerified />
          </>) : (<>
            <MyPopOver
              closeOnClick={true}
              appearContent={<IconButton className={classes.moreBtn} ><MoreHorizIcon /></IconButton>}
              showContent={<div>
                <div
                  className={classes.singleOption}
                  onClick={() => {
                    setDefaultSrc(item)
                  }}
                >Make Default</div>
                <div
                  className={classes.singleOption}
                  onClick={() => {
                    removeBankAcc(item, selected)
                  }}
                >Delete</div>
              </div>}
            />
          </>)}
        </div>
      </div>
      <div className={classes.otherDetailsCont} style={{ margin: "10px 0px 4px" }} >
        <h3>Account Type</h3>
        <p>{bankAccountType}</p>
      </div>
      <div className={classes.otherDetailsCont} >
        <h3>Dwolla Balance</h3>
        <p>$ 0</p>
      </div>
    </Paper>
  );
}
