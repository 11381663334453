import React, { useEffect, useState } from "react";
import { Avatar, Button } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import CreateCommunity from "./CreateCommunity";
import AddIcon from '@material-ui/icons/Add';
import { Skeleton } from "@material-ui/lab";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import LessText from "../styled/CommonComponents/LessText";
import NormalDialog from "../styled/CommonComponents/NormalDialog";

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        backgroundColor: "white",
        paddingBottom: "10px",
        borderRadius: "5px",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
        marginBottom: "25px",
        [theme.breakpoints.down('md')]: {
            marginBottom: "0px"
        }
    },
    headSty: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "10px 15px",
        borderBottom: "1px solid #e3e0e0",
        "& h3": {
            fontSize: "17px",
            fontWeight: "500",
            color: "#00345D"
        }
    },
    communitesCont: {
        width: "100%", 
        maxHeight: "235px",
        paddingTop: "3px"
    },
    communityLine: {
        cursor: "pointer",
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "5px 15px",
        "& h3": {
            marginLeft: "10px",
            fontSize: "15px",
            fontWeight: "400"
        }
    },
    seemoreSty: {
        textAlign: "center",
        padding: "0px 10px",
        cursor: "pointer",
        color: theme.palette.primary.main
    },
    dialogBody: {
        width: "350px",
        height: "500px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down('xs')]: {
            width: "290px",
        }
    }
}))


const UserCommunities = ({ communites, getOwnCommunites, communityLoading, isAddNewBtn }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme()
    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));

    const [openCreate, setOpenCreate] = useState(false)
    const [comHasMore, setComHasMore] = useState(false)
    const [arr, setArr] = useState([])
    const [openFullView, setOpenFullView] = useState(false)

    console.log(arr,' is the community arr')

    const handleClose = () => {
        setOpenFullView(false)
    }

    useEffect(() => {
        if (isMdSmall) {
            if (communites) {
                if (communites.length > 0 && communites.length > 3) {
                    setComHasMore(true)
                    let data = communites.slice(1, 4)
                    setArr([...data])
                } else {
                    setComHasMore(false)
                    setArr([...communites])
                }
            }
        } else {
            if (communites) {
                if (communites.length > 0 && communites.length > 5) {
                    setComHasMore(true)
                    let data = communites.slice(1, 6)
                    setArr([...data])
                } else {
                    setComHasMore(false)
                    setArr([...communites])
                }
            }
        }
    }, [communites, isMdSmall])


    return (<>
        {!communityLoading && communites !== null && communites.length === 0 ? null : (
            <div className={classes.mainCont} >
                <div className={classes.headSty} >
                    <h3>Your Communities</h3>
                    {isAddNewBtn ? (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => { setOpenCreate(true) }}
                            size="small"
                        >
                            <AddIcon />   Add
                        </Button>
                    ) : (<div></div>)}
                </div>
                <div className={classes.communitesCont} >
                    {communites === null && communityLoading ? (<div style={{ padding: "0px 15px" }} >
                        {isMdSmall ? (<>
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                        </>) : (<>
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                            <Skeleton style={{ width: "100%", height: "40px" }} variant="text" />
                        </>)}
                    </div>) : (<>
                        {arr && arr.length > 0 && arr.map((community) => (
                            <div className={classes.communityLine} onClick={() => { history.push(`/explore/forum/communities/${community?.community?.slug}`) }}   >
                                <Avatar style={{ width: "35px", height: "35px" }} src={community?.community?.displayPicture?.thumbUrl || community?.community?.displayPicture?.url} alt={"pic"} />
                                <h3>c/
                                    <LessText
                                        limit={13}
                                        string={community?.community?.displayName}
                                    />
                                </h3>
                            </div>
                        ))}
                    </>)}
                </div>
                <CreateCommunity
                    open={openCreate}
                    setOpen={setOpenCreate}
                    getOwnCommunites={getOwnCommunites}
                />
                {comHasMore && (
                    <div className={classes.seemoreSty} onClick={() => { setOpenFullView(true) }} >
                        See More
                    </div>
                )}
                <NormalDialog
                    openDialog={openFullView}
                    handleCloseShare={handleClose}
                    pageTitle={"Your Communities"}
                    content={<div className={classes.dialogBody} >
                        {communites && communites.length > 0 && communites.map((community) => (
                            <div className={classes.communityLine} onClick={() => { history.push(`/explore/forum/communities/${community?.community?.slug}`) }}   >
                                <Avatar style={{ width: "35px", height: "35px" }} src={community?.community?.displayPicture?.thumbUrl || community?.community?.displayPicture?.url} alt={"pic"} />
                                <h3>c/
                                    <LessText
                                        limit={24}
                                        string={community?.community?.displayName}
                                    />
                                </h3>
                            </div>
                        ))}
                    </div>}
                />
            </div>
        )}
    </>);
};

export default UserCommunities;
