import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, InputAdornment } from "@material-ui/core";
import bgImage from "../../../Assets/ContractFloAssets/Images/bgimage.png"
// import peoples from "../../../Assets/ContractFloAssets/Images/BannerImage.png"
import { useHistory } from "react-router-dom";
import "aos/dist/aos.css";

const useStyles = makeStyles((theme) => ({
    root: {
        height: "calc(100vh - 90px)",
        width: "100%",
        backgroundColor: "#F8F8F6",
        padding: "50px 180px 0px",
        [theme.breakpoints.down("lg")]: {
            padding: "50px 110px 0px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "50px 50px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "110vh",
            padding: "30px 30px 0px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "20px 20px 0px",
            height: "calc(100vh - 90px)",
        },
    },
    searchText: {
        width: "100%",
        backgroundColor: "white",
        borderRadius: "5px",
        margin: "0px",
        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
        position: "relative",
        zIndex: "6"
    },
    noBorder: {
        border: "none",
    },
    leftSide: {
        width: "54%",
        "& h3": {
            fontSize: "42px",
            color: theme.palette.primary.main,
            fontWeight: "800"
        },
        "& h4": {
            fontSize: "32px",
            fontWeight: "800"
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginBottom: "20px",
        },
        [theme.breakpoints.down("xs")]: {
            "& h3": {
                fontSize: "26px",
                fontWeight: "700"
            },
            "& h4": {
                fontSize: "22px",
                fontWeight: "700"
            },
        },
    },
    rightSide: {
        width: "42%",
        [theme.breakpoints.down("sm")]: {
            width: "70%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "95%",
        }
    },
    mainCont: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
        }
    },
    numbersCont: {
        display: "flex",
        alignItems: "center",
        margin: "15px 0px",
        "& div": {
            width: "70px",
            height: "2px",
            backgroundColor: "black",
            margin: "0px 10px"
        },
        "& h3": {
            fontSize: "20px"
        },
        [theme.breakpoints.down("xs")]: {
            "& h3": {
                fontSize: "16px"
            },
        }
    },
    rightText: {
        lineHeight: "27px",
        fontSize: "13px",
        color: "#777575",
        fontFamily: `'Poppins', sans-serif`,
        [theme.breakpoints.down("xs")]: {
            fontSize: "12px", 
        }
    },
    imageCont: {
        width: "100%"
    },
    imageSty: {
        width: "100%",
        position: "relative",
        top: "32vh",
        left: "0px",
        zIndex: "2",
        textAlign: "center",
        [theme.breakpoints.down("md")]: {
            top: "25vh",
        },
        [theme.breakpoints.down("xs")]: {
            top: "13vh",
        },
    },
    bgImgSty: {
        width: "100%",
        height: "auto",
        position: "relative",
        zIndex: "3",
        [theme.breakpoints.down("md")]: {
            width: "85%",
        },
        [theme.breakpoints.down("xs")]: {
            width: "93%",
        },
    },
    peopleImgSty: {
        position: "absolute",
        borderBottomLeftRadius: "24%",
        borderBottomRightRadius: "27%",
        width: "115%",
        height: "auto",
        bottom: "0px",
        left: "-120px",
        overflow: "hidden",
        zIndex: "5",
        "& img": {
            width: "100%",
            height: "auto"
        },
        [theme.breakpoints.down("md")]: {
            left: "34px",
            width: "95%",
        },
        [theme.breakpoints.down("md")]: {
            left: "-12px",
            width: "102%",
        }
    },
    signupCont: {
        borderRadius: "4px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        }
    }
}));

export default function HomePage({ homePageRef }) {
    const classes = useStyles();
    const history = useHistory();

    const [email, setEmail] = useState("")

    const onSubmitSignup = (e) => {
        e.preventDefault();
        const data = { type: "signup", mailId: email }
        const searchParams = new URLSearchParams(data)
        const url = "signup/?" + searchParams;
        history.push(url)
        console.log("getClick")
    }


    return (
        <section className={classes.root} ref={homePageRef} id="homePage" >
            <div className={classes.mainCont} >
                <div className={classes.leftSide} >
                    <h4>Real Estate Network OS</h4>
                    <h3>Collaborative Tools, <br /> Powerful Connections, <br />& Endless Opportunities</h3>
                </div>
                <div className={classes.rightSide} >
                    <p className={classes.rightText} >
                        Streamline Your Real Estate Projects with Our SaaS Tools. From investment analysis to property management, project management, accounting, and payments, our tools are designed to help real estate professionals collaborate on their projects more effectively.
                    </p>
                    <div className={classes.numbersCont} >
                        <h3>110+ Projects</h3>
                        <div></div>
                        <h3>100+ Client</h3>
                    </div>
                    <form onSubmit={onSubmitSignup} >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            value={email}
                            onChange={(e) => { setEmail(e.target.value) }}
                            id="phoneNumber"
                            disableUnderline={false}
                            autoFocus
                            className={classes.searchText}
                            placeholder="Enter your email address..."
                            InputProps={{
                                endAdornment: (<InputAdornment position="end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        className={classes.signupCont}
                                    >
                                        Sign up
                                    </Button>
                                </InputAdornment>),
                                classes: { notchedOutline: classes.noBorder }
                            }}
                        />
                    </form>
                </div>
            </div>

            <div className={classes.imageCont} >
                <div className={classes.imageSty} >
                    <img src={bgImage} className={classes.bgImgSty} />
                    <div className={classes.peopleImgSty} data-aos={"fade-up"} >
                        <img src={"https://firebasestorage.googleapis.com/v0/b/contractflo-prod.appspot.com/o/BannerImage.png?alt=media"} />
                    </div>
                </div>
            </div>
        </section>
    );
}