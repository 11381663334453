import { Grid, Paper, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useDebounce } from "react-use";
import QuestionTypeRendererWithValue from "./question.type.renderer.with.value";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    // margin: theme.spacing(2),
    boxSizing: "border-box",
    height: "100%",
  },
  questionText: {
    margin: theme.spacing(2),
    // marginBottom: theme.spacing(2),
  },
  questionType: {
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
}));
export default function QuestionView(props) {
  const classes = useStyles();

  const {
    viewMode,
    issue,
    question,
    updateApi,
    questionId,
    formResDict,
    setformResDict,
    qMap,
    tMap,
    setFormTMap,
    table,
    formRes,
  } = props;
  console.log(qMap);
  console.log(tMap);
  console.log(question);
  console.log(formResDict);
  // const oldFormRes = qMap[questionId];
  // const oldFormResId = oldFormRes?._id;
  // const formRes = formResDict[oldFormResId];
  console.log(formRes, "form response");
  const [type, setType] = useState(question?.type || "Short Answer");
  let respObj = {};
  if (question?.table) respObj = tMap[question?.table?._id];
  const [tableRes, setTableRes] = useState(respObj);

  const { root, questionText, questionType } = classes;

  useEffect(() => {
    console.log("received:", question);
  }, []);

  let flexSpace = 1;

  if (
    question?.type === "Linear Scale" ||
    question?.type === "Date" ||
    question?.type === "Time" ||
    question?.type === "Short Answer" ||
    question?.type === "Boolean"
  ) {
    flexSpace = 2;
  }

  return (
    <Grid item xs={12 / flexSpace}>
      <Paper variant="outlined" className={root}>
        <div
          className={questionText}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography>{question?.questionText}</Typography>
        </div>
        <div className={questionType}>
          <QuestionTypeRendererWithValue
            viewMode={viewMode}
            issue={issue}
            updateApi={updateApi}
            questionId={questionId}
            formResDict={formResDict}
            formRes={formRes}
            setformResDict={setformResDict}
            qMap={qMap}
            tMap={tMap}
            setFormTMap={setFormTMap}
            question={question}
            type={type}
            table={table}
            tableRes={tableRes}
            setTableRes={setTableRes}
          />
        </div>
      </Paper>
    </Grid>
  );
}
