import React, { useState } from "react";
// import { useMinimalSelectStyles } from '@mui-treasury/styles/select/minimal';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import questiontypeStyles from "./questiontype.styles";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ShortTextIcon from "@material-ui/icons/ShortText";
import SubjectIcon from "@material-ui/icons/Subject";
import { ListItemIcon, useMediaQuery } from "@material-ui/core";
import EventIcon from "@material-ui/icons/Event";
import ScheduleIcon from "@material-ui/icons/Schedule";
import FunctionsIcon from "@material-ui/icons/Functions";
import { useTheme } from "styled-components";
// Original design here: https://github.com/siriwatknp/mui-treasury/issues/540

const useStyles = makeStyles((theme) => ({
  selectComponent: {
    borderRadius: "10px",
  },
  selectForm: {
    width: "40%",
    // marginRight: "1rem",
  },
}));

const MinimalSelect = (props) => {
  const classes = useStyles();
  const { setType, type } = props;
  const typeToValue = (value) => {
    if (value == "Short Answer") return 0;
    else if (value == "Long Answer") return 1;
    else if (value == "Date") return 6;
    else if (value == "Time") return 7;
    else if (value == "Number") return 9;
    else if (value == "Organization") return 13;
    else if (value == "Project") return 14;
    else if (value == "User") return 15;
    else if (value == "Issue") return 16;
    else if (value == "Template") return 17;
  };
  const [val, setVal] = useState(typeToValue(type) ? typeToValue(type) : 0);
  const valueToType = (value) => {
    if (value == 0) return "Short Answer";
    else if (value == 1) return "Long Answer";
    else if (value == 6) return "Date";
    else if (value == 7) return "Time";
    else if (value == 9) return "Number";
    else if (value == 13) return "Organization";
    else if (value == 14) return "Project";
    else if (value == 15) return "User";
    else if (value == 16) return "Issue";
    else if (value == 17) return "Template";
  };
  const handleChange = (event) => {
    setVal(event.target.value);
    setType(valueToType(event.target.value));
  };

  const minimalSelectClasses = questiontypeStyles();

  const iconComponent = (props) => {
    return (
      <ExpandMoreIcon
        className={props.className + " " + minimalSelectClasses.icon}
      />
    );
  };
  // moves the menu below the select input
  const menuProps = {
    classes: {
      paper: minimalSelectClasses.paper,
      list: minimalSelectClasses.list,
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
    getContentAnchorEl: null,
  };
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <FormControl
      className={classes.selectForm}
      size="small"
      sx={{ m: 1, minWidth: 120 }}
      style={
        isSmall
          ? { width: "55%", marginRight: "0", paddingLeft: "0" }
          : { width: "40%", marginRight: "1rem" }
      }
    >
      <Select
        // disableUnderline
        classes={{ root: minimalSelectClasses.select }}
        className={classes.selectComponent}
        // componentsProps={{
        //   listbox: {
        //     sx: { backgroundColor: "#eff3fd" },
        //   },
        // }}
        MenuProps={menuProps}
        IconComponent={iconComponent}
        value={val}
        onChange={handleChange}
        variant="outlined"
        style={isSmall ? { paddingLeft: "0" } : { paddingLeft: "10px" }}
      >
        <optgroup label="General"></optgroup>
        <MenuItem value={0}>
          <ListItemIcon classes={{ root: minimalSelectClasses.listIcon }}>
            <ShortTextIcon />
          </ListItemIcon>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Short Answer</span>
        </MenuItem>
        <MenuItem value={1}>
          <ListItemIcon classes={{ root: minimalSelectClasses.listIcon }}>
            <SubjectIcon />
          </ListItemIcon>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Long Answer</span>
        </MenuItem>
        {/* <MenuItem value={4}>
          <ListItemIcon classes={{ root: minimalSelectClasses.listIcon }}>
            <ArrowDropDownCircleIcon />
          </ListItemIcon>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Dropdown</span>
        </MenuItem> */}
        <MenuItem value={6}>
          <ListItemIcon classes={{ root: minimalSelectClasses.listIcon }}>
            <EventIcon />
          </ListItemIcon>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Date</span>
        </MenuItem>
        <MenuItem value={7}>
          <ListItemIcon classes={{ root: minimalSelectClasses.listIcon }}>
            <ScheduleIcon />
          </ListItemIcon>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Time</span>
        </MenuItem>
        <MenuItem value={9}>
          <ListItemIcon classes={{ root: minimalSelectClasses.listIcon }}>
            <FunctionsIcon />
          </ListItemIcon>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Number</span>
        </MenuItem>
        <optgroup label="Platform"></optgroup>
        <MenuItem value={13}>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Organization</span>
        </MenuItem>
        <MenuItem value={14}>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Project</span>
        </MenuItem>
        <MenuItem value={15}>
          <span style={{ marginTop: 2, fontSize: "14px" }}>User</span>
        </MenuItem>
        <MenuItem value={16}>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Issue</span>
        </MenuItem>
        <MenuItem value={17}>
          <span style={{ marginTop: 2, fontSize: "14px" }}>Template</span>
        </MenuItem>
      </Select>
    </FormControl>
  );
};

export default MinimalSelect;
