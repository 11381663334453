import {
  Box,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilesViewer from "../file/Viewer/FilesViewer";
import QuestionOptions from "./question.options";
import QuestionType from "./questiontype";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Api from "../../helpers/Api";
import { useDebounce } from "react-use";
import arrayToReducer from "../../helpers/arrayToReducer";
import { blue } from "@material-ui/core/colors";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import FormTableQuestionOptions from "./form.table.question.options";
import FormTableQuestionType from "./form.table.question.type";
import FormTableRowResponse from "./form.table.row.response";

const useStyles = makeStyles((theme) => ({
  question: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: '1px solid red',
    width: "100%",
    padding: "1rem",
    // marginBottom: "1rem",
    position: "relative",
    overflow: "hidden",
    border: "1px solid grey",
    // boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
  },
  bar: {
    position: "absolute",
    width: "10px",
    backgroundColor: "#407afd",
    height: "100%",
    top: "0px",
    left: "-6px",
    transition: "all 200ms ease-in",
  },
  barActive: {
    left: "0px !important",
  },
  questionTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    // border:"1px solid green"
  },
  questionText: {
    width: "35%",
    padding: "2px",
    marginTop: "0.5rem",
  },
  questionAnswer: {
    width: "100%",
    // border: '1px solid red',
    // borderBottom: "1px solid grey",
  },
  questionEdit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    // border: "1px solid green",
    justifyContent: "flex-end",
  },
}));
export default function FormTableRow(props) {
  const classes = useStyles();
  const {
    row,
    form,
    formTable,
    question,
    isActive,
    questionDict,
    questionIds,
  } = props;

  return (
    <div>
      {row?.responses?.length > 0 &&
        row.responses.map((response, index) => {
          const question = questionDict[questionIds[index]];
          return (
            <FormTableRowResponse
              key={index}
              response={response}
              question={question}
              index={index}
            />
          );
        })}
    </div>
  );
}
