import { eachMonthOfInterval } from "date-fns";
import React, { useEffect, useState } from "react";
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
} from "recharts";

let monthArray = [
	"Jan",
	"Feb",
	"Mar",
	"Apr",
	"May",
	"Jun",
	"Jul",
	"Aug",
	"Sep",
	"Oct",
	"Nov",
	"Dec",
];
let tempData = [
	{
		Month: "Mar-2023",
		amount: "3000",
		due: "1340",
	},
	{
		Month: "Apr-2023",
		amount: "3590",
		due: "140",
	},
	{
		Month: "May-2023",
		amount: "2600",
		due: "120",
	},
	{
		Month: "Jun-2023",
		amount: "3200",
		due: "450",
	},
	{
		Month: "Jul-2023",
		amount: "3000",
		due: "1340",
	},
	{
		Month: "Aug-2023",
		amount: "3000",
		due: "1340",
	},
];

function ProjectCharts(props) {
	// create an array fo objects with X and y
	const [data, setData] = useState([]);
	const { transactions } = props;
	// console.log(transactions);

	const setDataByMonth = () => {
		let temp = Object.values(transactions || {})
			.flat(1)
			.map((doc) => {
				return new Date(doc?.updatedAt);
			});
		temp.sort();
		if (temp.length) {
			let minDate = temp[0];
			let maxDate = temp[temp.length - 1];
			let result = eachMonthOfInterval({
				start: minDate,
				end: maxDate,
			});
			let obj = {};
			result.map((date) => {
				obj[`${monthArray[date.getMonth()]}-${date.getFullYear()}`] = {
					amount: 0,
					due: 0,
				};
			});
			Object.values(transactions || {})
				.flat(1)
				.map((doc) => {
					obj[
						`${
							monthArray[new Date(doc?.updatedAt).getMonth()]
						}-${new Date(doc?.updatedAt).getFullYear()}`
					].amount += doc?.finalAmount;
					if (doc?.status != "Paid") {
						obj[
							`${
								monthArray[new Date(doc?.updatedAt).getMonth()]
							}-${new Date(doc?.updatedAt).getFullYear()}`
						].due += doc?.finalAmount;
					}
				});
			// now convert this key into an object with other vales and merge all in an array
			let finalData = [];
			console.log("obj=", obj);
			finalData = Object.keys(obj || {}).map((key) => {
				return {
					Month: key,
					...obj[key],
				};
			});
			setData([...finalData]);
		}
	};
	useEffect(() => {
		if (transactions) {
			setDataByMonth();
		}
	}, [transactions]);
	return (
		<div style={{ width: "100%", height: "30rem" }}>
			<ResponsiveContainer width="100%" height="100%">
				<LineChart
					width={500}
					height={300}
					data={data}
					margin={{
						top: 5,
						right: 30,
						left: 20,
						bottom: 5,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="Month" />
					<YAxis />
					<Tooltip />
					<Legend />
					<Line
						type="monotone"
						dataKey="amount"
						stroke="#8884d8"
						activeDot={{ r: 8 }}
					/>
					<Line type="monotone" dataKey="due" stroke="#82ca9d" />
				</LineChart>
			</ResponsiveContainer>
		</div>
	);
}

export default ProjectCharts;
