import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Search as SearchIcon } from '@material-ui/icons';
import { ClickAwayListener, TextField, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useHistory } from 'react-router-dom';
import serachIcon from "../../Assets/searchIcon.svg"
import Api from "../../helpers/Api";
import { AiOutlineFieldTime } from "react-icons/ai";
import ClearIcon from '@material-ui/icons/Clear';
import { useDebounce } from "react-use";
import { useParams } from 'react-router-dom';
import LessText from "../styled/CommonComponents/LessText"
import { TbFilter } from "react-icons/tb";

const useStyles = makeStyles((theme) => ({
  noBorder: {
    border: "none",
  },
  titleInput: {
    fontSize: "18px",
    backgroundColor: "#f5f4f6",
    borderRadius: "10px",
    "& .MuiOutlinedInput-input": {
      padding: "12px 10px"
    }
  },
  mainCont: {
    width: "350px",
    position: "relative",
    [theme.breakpoints.down('sm')]: {
      width: "300px",
    },
    [theme.breakpoints.down('xs')]: {
      width: "85%",
    },
  },
  fromSty: {
    width: "100%"
  },
  showCard: {
    position: "absolute",
    top: "58px",
    left: "0px",
    padding: "15px 10px",
    paddingTop: "5px",
    width: "100%",
    minHeight: "325px",
    maxHeight: "510px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px",
    backgroundColor: "white"
  },
  emptyCont: {
    width: "100%",
    height: "350px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "15px",
    "& img": {
      width: "180px",
      height: "auto"
    }
  },
  searchHistoryIcon: {
    fontSize: "35px",
    padding: "4px",
    backgroundColor: "#edecec",
    borderRadius: "50%",
    color: "gray"
  },
  singleSearch: {
    width: "100%",
    padding: "5px 10px",
    display: "flex",
    cursor: "pointer",
    borderRadius: "12px",
    color: "black",
    alignItems: "center",
    justifyContent: "space-between",
    "& div": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& p": {
        fontSize: "17px",
        marginLeft: "10px"
      }
    }
  },
  suggestionSingleSearch: {
    width: "100%",
    padding: "5px 10px",
    display: "flex",
    cursor: "pointer",
    color: "black",
    borderRadius: "12px",
    alignItems: "center",
    justifyContent: "space-between",
    "&:hover": {
      backgroundColor: "#edecec"
    },
    "& div": {
      width: "96%",
      display: "flex",
      alignItems: "center",
      "& p": {
        fontSize: "17px",
        marginLeft: "10px"
      }
    }
  }
}));

const SearchBar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const { searchSlug } = useParams();
  const { profileSearchArr, drawerToggle } = useSelector((state) => state.profile);

  const isMdSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const [searchText, setSearchText] = useState("")
  const [showCard, setShowCard] = useState(false)
  const [users, setUsers] = useState()

  useEffect(() => {
    if (searchSlug) {
      setSearchText(searchSlug)
    }
  }, [searchSlug])

  const handleClickAway = () => {
    setShowCard(false)
  }

  const submitSearch = (e) => {
    e.preventDefault();
    handleClickAway()
    if (searchText && searchText.length > 2) {
      let arr = [searchText]
      if (profileSearchArr) {
        arr = [searchText, ...profileSearchArr]
      }

      let setArr = new Set([])

      arr.map((text) => {
        setArr.add(text)
      })

      dispatch({
        type: 'AddProfile',
        payload: {
          profileSearchArr: [...setArr],
        },
      })

      history.push(`/search/${searchText}`);

    } else {
      history.push(`/search`);
    }
  }

  const sendQuery = async () => {
    if (searchText && searchText.length > 2) {
      await Api.post("searchPeople/for-name", { name: searchText })
        .then((data) => {
          console.log(data?.data)
          let arr = new Set([])
          let resultUsers = data?.data || []

          resultUsers.map((user) => {
            let str = user?.displayName.replace(/\s+/g, ' ').trim()
            arr.add(str)
          })
          setUsers([...arr])
        })
        .catch((err) => {
          console.log(err);
        })
    } else {
      setUsers([])
    }
  };

  useDebounce(
    () => {
      sendQuery()
    },
    700,
    [searchText]
  );

  const onClickSearch = (str) => {
    handleClickAway()
    let arr = [str]
    if (profileSearchArr) {
      arr = [str, ...profileSearchArr]
    }
    let setArr = new Set([])

    arr.map((text) => {
      setArr.add(text)
    })

    dispatch({
      type: 'AddProfile',
      payload: {
        profileSearchArr: [...setArr],
      },
    })

    history.push(`/search/${str}`);
  }

  const onClickExisiting = (str) => {
    handleClickAway()

    const path = `/search/${str}`

    history.push(path);
  }

  const removeSearchText = (str) => {
    let updatedArr = profileSearchArr.filter((text) => str !== text)

    dispatch({
      type: 'AddProfile',
      payload: {
        profileSearchArr: [...updatedArr],
      },
    })
  }

  const openFilter = () => {
    dispatch({
      type: 'AddProfile',
      payload: {
        drawerToggle: !drawerToggle,
      },
    })
  }

  useEffect(() => {
    if (drawerToggle) {
      setShowCard(false)
    }
  }, [drawerToggle])

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.mainCont} >
        <form className={classes.fromSty} onSubmit={submitSearch} onClick={() => { setShowCard(true) }} >
          {isMdSmall ? (
            <TextField
              variant="outlined"
              value={searchText}
              onChange={(e) => { setSearchText(e.target.value); }}
              fullWidth
              placeholder={"Search"}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
                className: classes.titleInput,
                startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "#7C7C8D" }} /></InputAdornment>),
                endAdornment: (
                  <InputAdornment position="start">
                    <TbFilter
                      style={{
                        cursor: "pointer"
                      }}
                      onClick={() => { openFilter() }}
                    />
                  </InputAdornment>),
              }}
            />
          ) : (
            <TextField
              variant="outlined"
              value={searchText}
              onChange={(e) => { setSearchText(e.target.value); }}
              fullWidth
              placeholder={"Search"}
              InputProps={{
                classes: { notchedOutline: classes.noBorder },
                className: classes.titleInput,
                startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "#7C7C8D" }} /></InputAdornment>)
              }}
            />
          )}
        </form>

        {showCard && (
          <div className={classes.showCard} >
            {users && users.length > 0 ? users.map((userName) => (
              <div className={classes.singleSearch} onClick={() => { onClickSearch(userName) }} >
                <div>
                  <SearchIcon className={classes.searchHistoryIcon} />
                  <p>
                    <LessText
                      limit={27}
                      string={userName}
                    />
                  </p>
                </div>
                <div></div>
              </div>
            )) : (<>
              {profileSearchArr && profileSearchArr.length > 0 ? profileSearchArr.slice(0, 15).map((searchText) => (
                <div className={classes.suggestionSingleSearch} >
                  <div onClick={() => { onClickExisiting(searchText) }} >
                    <AiOutlineFieldTime className={classes.searchHistoryIcon} />
                    <p>
                      <LessText
                        limit={27}
                        string={searchText}
                      />
                    </p>
                  </div>
                  <ClearIcon
                    style={{
                      fontSize: "18px",
                      color: "gray",
                      marginLeft: "8px"
                    }}
                    onClick={() => { removeSearchText(searchText) }}
                  />
                </div>
              )) : (
                <div className={classes.emptyCont} >
                  <img src={serachIcon} />
                </div>
              )}
            </>)}
          </div>
        )}
      </div>
    </ClickAwayListener>
  );
};
export default SearchBar;