import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import html2plaintext from "html2plaintext";
import docImage from "../../Assets/FileIcon/docs.png";
import { AvatarGroup } from "@material-ui/lab";
import { format } from "timeago.js";
import LessText from '../styled/CommonComponents/LessText';
import { AiTwotoneLike } from "react-icons/ai";
import { TiRefresh } from "react-icons/ti";
import { GoComment } from "react-icons/go";
import { IoIosAttach } from "react-icons/io";

const useStyles = makeStyles((theme) => ({
  root_Container: {
    position: "relative",
    width: "300px",
    display: "flex",
    color: "gray",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "0px",
    marginBottom: "15px",
    paddingBottom: "40px",
    border: "1px solid #d8d5d5",
    boxShadow: "none",
  },
  parent_Container: {
    width: "100%",
    position: "relative",
    padding: "5px 3px 15px"
  },
  title: {
    fontSize: "19px",
    fontWeight: "500",
    padding: "5px 8px 0px"
  },
  img: {
    height: "40px",
    width: "40px",
    marginRight: "10px",
  },
  published: {
    position: "absolute",
    bottom: "0px",
    left: "0px",
    width: "100%",
    borderTop: "1px solid #d8d5d5",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "5px 10px"
  },
  likeComntCont: {
    display: "flex",
    alignItems: "center",
  },
  infoCont: {
    position: "absolute",
    display: "flex",
    alignItems: "center",
    top: "3px",
    right: "10px",
    fontSize: "25px",
    "& span": {
      fontSize: "13px",
      marginLeft: "5px",
      fontWeight: "500"
    }
  },
  authorCont: {
    display: "flex",
    alignItems: "center",
    "& div": {
      marginLeft: "10px"
    }
  },
  nameCont: {
    "& h5": {
      fontWeight: "450"
    }
  }
}));

export default function DocCard(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const { doc, isEnvelope, handleClickGetStatus } = props;

  const docSignState = useSelector((state) => state.docSign);
  const accessToken = docSignState.accessToken;
  const isSignedIn = docSignState.isSignedIn;

  return (
    <Paper
      className={classes.root_Container}
      square
    >
      <div className={classes.parent_Container} >
        <div className={classes.authorCont} >
          <img src={docImage} style={{ width: "30px" }} />
          <div className={classes.nameCont} >
            <h4>
              <LessText
                limit={20}
                string={doc?.title || ""}
              />
            </h4>
            <h5>{format(doc?.updatedAt)}</h5>
          </div>
        </div>
        {isEnvelope ? (<div className={classes.infoCont} >
          {isSignedIn ? (
            <TiRefresh
              onClick={(e) => {
                e.stopPropagation();
                handleClickGetStatus(doc.signTracker.id, doc._id);
              }}
            />
          ) : null}
          <span>{html2plaintext(doc.status)}</span>
        </div>) : null}
      </div>
      <div className={classes.published}>
        <div>
          <AvatarGroup max={4}>
            {doc?.shared && doc?.shared.map((userData, i) => (
              <Avatar
                key={i}
                alt={userData?.parent?.displayName}
                src={userData?.parent?.displayPicture?.thumbUrl || userData?.parent?.displayPicture?.url}
                style={{ width: "30px", height: "30px" }}
              />
            ))}
          </AvatarGroup>
        </div>
        <div className={classes.likeComntCont} >
          <IoIosAttach /><span style={isEnvelope ? { marginRight: "0px", marginLeft: "5px" } : { marginRight: "15px", marginLeft: "5px" }} >{doc?.files?.length || 0}</span>
          {isEnvelope ? null : (<>
            <AiTwotoneLike /><span style={{ marginRight: "15px", marginLeft: "5px" }} >{doc?.likeCount}</span>
            <GoComment /><span style={{ marginLeft: "5px" }} >{doc?.commentCount}</span>
          </>)}
        </div>
      </div>
    </Paper>
  );
}
