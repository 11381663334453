import { Box, Button, Grid, IconButton, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Api from "../../helpers/Api";
import arrayToReducer from "../../helpers/arrayToReducer";
import Question from "./question";
import CircularProgress from "@material-ui/core/CircularProgress";
import Kanban from "../styled/Kanban/Kanban";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { TimelineOppositeContent } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "5px",
    overflowY: "scroll",
    maxHeight: "2000px",
    overflowX: "hidden",
    alignText: "left",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    paddingBottom: "1rem",
  },
  active: {
    backgroundColor: "#007bff",
  },
  container: {
    width: "75%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  timeline_container: {
    width: "80%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

export default function Form(props) {
  const { form, setForm } = props;
  const classes = useStyles();
  const { formContainer, timeline_container } = classes;
  const [questions, setQuestions] = useState(form?.questions || []);
  const [questionState, setQuestionState] = useState({});
  const [loading, setLoading] = useState(false);
  const [questionIds, setQuestionIds] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState(0);
  const [questionDict, setQuestionDict] = useState([]);
  const { user } = useSelector((state) => state.auth);
  const [columns, setColumns] = useState([]);
  const [timeLineData, setTimeLineData] = useState([]);
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("md"));
  const isMedium = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (isMedium) {
      const questions = questionIds.map((questionId, index) => {
        const question = questionDict[questionId];
        let obj = {
          _id: questionId,
          content: (
            <Question
              key={questionId}
              index={index}
              question={question}
              questionIds={questionIds}
              setQuestionIds={setQuestionIds}
              setQuestionDict={setQuestionDict}
              questionDict={questionDict}
              createQuestion={createQuestion}
              setActiveQuestion={setActiveQuestion}
              onDelete={onDelete}
              // setQuestion={setQuestion}
              form={form}
              isActive={activeQuestion === questionId}
            />
          ),
        };
        return obj;
      });
      const cols = {
        1: {
          title: "Form",
          items: questions,
        },
      };
      console.log(cols);
      setColumns(cols);
    } else {
      const questions = questionIds.map((questionId, index) => {
        const question = questionDict[questionId];
        let obj = {
          id: questionId,
          name: question?.questionText || "Untitled",
          isActive: activeQuestion === questionId,
          question: question,
        };
        return obj;
      });
      setTimeLineData(questions);
    }
  }, [questionDict, questionIds.length, activeQuestion, isMedium]);

  const createQuestion = async (index) => {
    console.log(index);
    const obj = {
      index: index,
      questionObj: {
        type: "Short Answer",
        form,
        profile: user?.profile,
        questionText: "",
      },
    };
    const res = await Api.post("question/create", obj);
    console.log("res here = ", res);
    if (res?.data) {
      //res?.data is the entire form
      setForm(res?.data);
    }
  };

  const createQuestionDefault = async (index) => {
    console.log("createQuestion");
    const obj = {
      index: index,
      questionObj: {
        type: "Short Answer",
        form: form?._id,
        questionText: "Select Issue Priority",
        profile: user?.profile,
      },
    };
    const res = await Api.post("question/create/default", obj);
    console.log("createQuestion res ", res);
    if (res?.data) {
      const { newDict, idArr } = arrayToReducer(res?.data?.questions);

      setQuestionDict(newDict);
      setQuestionIds(idArr);
      setActiveQuestion(idArr[0] ? idArr[0] : "");
    }
    setLoading(false);
  };

  useEffect(() => {
    if (form?.questions.length > 0) {
      const { newDict, idArr } = arrayToReducer(form?.questions);
      // setActiveQuestion(idArr[0])
      setQuestionIds(idArr);
      setQuestionDict(newDict);
    } else {
      console.log(loading, " is the loading");
      if (loading) {
      } else {
        // createQuestion(0);
        createQuestionDefault(0);
      }
    }
  }, [form?.questions]);

  const onDelete = async (question, index) => {
    console.log("on delete called");
    console.log(question);
    const res = await Api.post("question/delete", {
      question,
      formId: form?._id,
    });
    if (res?.data) {
      setForm(res?.data);
      console.log("Question deleted ????", res);
    }
  };

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    let temp = copiedItems.map((obj) => obj?._id);
    setQuestionIds([...temp]);

    setQuestionState(copiedItems[0]._id);

    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems,
      },
    });
  };

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : isMedium ? (
        <Box
          marginTop={"1rem"}
          style={{
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderRadius: "5px",
          }}
        >
          {questionIds?.length > 0 ? (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createQuestion(questionIds?.length);
                }}
                style={{ float: "right", margin: "1rem" }}
              >
                Create Question
              </Button>
              <Kanban
                dragDirection="vertical"
                columns={columns}
                setColumns={setColumns}
                columnStyles={{
                  backgroundColor: "white",
                  flex: "1 0 100%",
                  overflowY: "hidden",
                }}
                itemStyles={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "7px",
                  marginLeft: "auto",
                  marginRight: "auto",
                  // flexWrap: "wrap",
                }}
                onDragEnd={onDragEnd}
                // itemStyles={{ display: "flex", flexDirection: "column" }}
                containerStyles={
                  isSmall
                    ? { width: "100%", margin: 0 }
                    : {
                        width: "50%",
                        margin: 0,
                      }
                }
              />
            </>
          ) : (
            <>
              <Typography>
                {" "}
                Form is empty. Start by adding any number of question
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createQuestion(questionIds?.length);
                }}
              >
                Create Question
              </Button>
            </>
          )}
        </Box>
      ) : (
        <Box marginTop={"1rem"} className={formContainer}>
          {questionIds?.length > 0 ? (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  position: "sticky",
                }}
              >
                <h3
                  style={{
                    color: "#10957d",
                    background: "rgba(16, 149, 125, 0.15)",
                    padding: "2px 10px",
                    borderRadius: "5px",
                    marginLeft: "1rem",
                    fontWeight: "normal",
                  }}
                >
                  Form
                </h3>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    createQuestion(questionIds?.length);
                  }}
                  style={{ float: "right", margin: "1rem" }}
                >
                  Create Question
                </Button>
              </div>
              {/* <QuestionTimeline timeLineData={timeLineData} /> */}
              <div className={classes.timeline_container}>
                {timeLineData.map((currentObj, index) => {
                  return (
                    <TimelineItem key={currentObj?.id}>
                      <TimelineOppositeContent style={{ flex: "0.1" }}>
                        <Typography>{index + 1}</Typography>
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineDot
                          className={currentObj?.isActive ? classes.active : ""}
                        />
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent>
                        <div style={{ marginLeft: "1rem" }}>
                          <Question
                            key={currentObj?.id}
                            index={index}
                            question={currentObj?.question}
                            questionIds={questionIds}
                            setQuestionIds={setQuestionIds}
                            setQuestionDict={setQuestionDict}
                            questionDict={questionDict}
                            createQuestion={createQuestion}
                            setActiveQuestion={setActiveQuestion}
                            onDelete={onDelete}
                            // setQuestion={setQuestion}
                            form={form}
                            isActive={activeQuestion === currentObj?.id}
                          />
                        </div>
                      </TimelineContent>
                    </TimelineItem>
                  );
                })}
              </div>
            </>
          ) : (
            <>
              <Typography>
                {" "}
                Form is empty. Start by adding any number of question
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  createQuestion(questionIds?.length);
                }}
              >
                Create Question
              </Button>
            </>
          )}
        </Box>
      )}
    </>
  );
}

// useEffect(()=>{
//     if(questions.length==0){
//         createQuestionDefault();
//     }
// },[questions])
// const getQuestions = () => {
//     setLoading(true);
//     console.log(loading, "loading")
//     var questiontempArr = [];
//     Api.post('question/getQuestions', { form: form?._id })
//         .then(res => {
//             console.log(res, "Questions res")
//             questiontempArr.push(...res?.data);
//         })
//     setQuestion(questiontempArr);
//     setLoading(false);
//     console.log(loading, "loading")
// }
// useEffect(() => {

//     getQuestions();

// }, [setQuestion, setLoading])

// useEffect(() => {
//     if (questions.length > 0) {
//         const { newDict, idArr } = arrayToReducer(questions);
//         console.log(newDict, "New Dict");

//     }
// }, [questions]);
