import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineContent from "@material-ui/lab/TimelineContent";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineDot from "@material-ui/lab/TimelineDot";
import { TimelineOppositeContent } from "@material-ui/lab";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  // card: {
  //   maxWidth: 345,
  //   margin: "auto",
  // },
  // title: {
  //   fontSize: 14,
  // },
  // pos: {
  //   marginBottom: 12,
  // },
  active: {
    backgroundColor: "#007bff",
  },
  container: {
    width: "75%",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

const QuestionTimeline = ({ timeLineData }) => {
  const classes = useStyles();

  useEffect(() => {
    console.log("timeline data");
    console.log(timeLineData);
  }, []);

  return (
    <div className={classes.container}>
      {timeLineData.map((question, index) => (
        <TimelineItem key={index}>
          <TimelineOppositeContent style={{ flex: "0.3" }}>
            <Typography>{index + 1}</Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot className={question?.isActive ? classes.active : ""} />
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <div style={{ marginLeft: "1rem" }}>{question?.content}</div>
            {/* <Card className={classes.card}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {item.date}
                </Typography>
                <Typography variant="h5" component="h2">
                  {item.title}
                </Typography>
                <Typography className={classes.pos} color="textSecondary">
                  {item.location}
                </Typography>
                <Typography variant="body2" component="p">
                  {item.description}
                </Typography>
              </CardContent>
            </Card> */}
          </TimelineContent>
        </TimelineItem>
      ))}
    </div>
  );
};

export default QuestionTimeline;
