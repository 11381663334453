import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import stripeLogo from '../../../../../Assets/stripeLogo.png'
import Api from '../../../../../helpers/Api';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "100%",
    padding: "20px 30px 30px",
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down('xs')]: {
      padding: "15px 15px 20px",
    }
  },
  dwollaHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoCont: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginLeft: "5px"
    },
    [theme.breakpoints.down('xs')]: {
      "& h3": {
        fontSize: "22px",
        fontWeight: "500",
        marginLeft: "1px"
      },
    }
  },
  imgStyle: {
    height: "30px",
    width: "auto",
    [theme.breakpoints.down('xs')]: {
      height: "27px",
    }
  },
  paraText: {
    fontSize: '0.9rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      cursor: "pointer"
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "13px"
    }
  }
}));

export default function StripeCustomer(props) {
  const { wallet, setWallet } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const createConnectAccount = async () => {
    if (wallet.stripeConnectAccountId) {
      dispatch({
        type: 'AddWallet',
        payload: {
          connectWalletId: wallet?._id,
          financeLoading: true
        },
      });

      const res = await Api.post('stripe/connect/account/link', { walletId: wallet?._id });
      const accountLink = res.data;
      const url = accountLink?.url;
      if (url) {
        window.location.replace(url);
      }

      dispatch({
        type: 'AddWallet',
        payload: {
          financeLoading: false
        }
      })
    }
  };

  return (
    <Paper className={classes.paperStyle}>
      <div className={classes.dwollaHeader} >
        <div className={classes.logoCont}>
          <img
            src={stripeLogo}
            className={classes.imgStyle}
          />
          <h3>Stripe</h3>
        </div>
        <div></div>
      </div>

      <p className={classes.paraText}>Stripe Connect 🌍</p>
      <p className={classes.paraText}>
        <a onClick={createConnectAccount} >Click here</a> to go to your Stripe profile to manage bank accounts, cashflows and more.
      </p>
    </Paper>);
}
