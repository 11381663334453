import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import SelectBankAccountDialog from './select.bank.account.dialog';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import { linkDwollaWithPlaid } from '../../api.call';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const useStyles = makeStyles((theme) => ({

}));

export default function AddDwollaBank(props) {
  const { wallet, dwollaCustomer, fundSrcs, setFundSrcs, setWallet, fundSrcIds } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const [addLoading, setAddLoading] = useState(false)
  const [openError, setOpenError] = useState(false)

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const addFundingSrcfun = async (selectedPlaidBankAccount, selectedFundSrc) => {
    if (selectedPlaidBankAccount && selectedFundSrc) {
      setAddLoading(true)
      await linkDwollaWithPlaid({
        item: selectedFundSrc,
        plaidAccount: selectedPlaidBankAccount,
        customerUrl: dwollaCustomer?.dwollaPath,
        customerId: dwollaCustomer?._id,
        walletId: wallet?._id,
        defaultSrc: wallet?.defaultDwollaBankAccount ? false : true
      })
        .then((data) => {
          console.log(data)
          if (data) {
            if (data?.dwollaBankAcc) {
              const newBank = data?.dwollaBankAcc
              let arr = [newBank, ...fundSrcs]
              setFundSrcs(arr)
            }

            if (data?.updatedWallet) {
              setWallet(data?.updatedWallet)
            }
          } else {
            // err occer to add bank
            setOpenError(true)
          }
          setAddLoading(false)
          setOpen(false)
        })
        .catch((err) => {
          setOpenError(true)
          console.log(err)
        })
    }
  }


  return (
    <>
      <Snackbar
        open={openError}
        autoHideDuration={4000}
        onClose={() => { setOpenError(false) }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert onClose={() => { setOpenError(false) }} severity="error">
          Something went wrong. Please try again
        </Alert>
      </Snackbar>

      <Button
        onClick={() => { setOpen(true) }}
        variant="contained"
        color="primary"
        startIcon={<AccountBalanceIcon />}
        size={smallScreen ? "small" : "medium"}
      >
        Add Bank Account
      </Button>

      {open && (
        <SelectBankAccountDialog
          open={open}
          setOpen={setOpen}
          wallet={wallet}
          header={"Connect Bank With Dwolla"}
          addFundingSrcfun={addFundingSrcfun}
          addLoading={addLoading}
          fundSrcIds={fundSrcIds}
        />
      )}
    </>
  );
}