import React, { useState, useEffect, useCallback, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getCommunityPostWithFilter } from "./api.call";
import { useSelector } from "react-redux";
import PostFile from "../post/PostFile";
import arrayToReducer from "../../helpers/arrayToReducer";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDebounce } from "react-use";
import PostSkeleton from "../styled/CommonComponents/Post.Skeleton";


const useStyles = makeStyles((theme) => ({
    feedBodyXCont: {
        width: "100%",
        overflow: "auto",
    },
    progressBox: {
        width: "100%",
        height: "70px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    }
}));

export default function CommunityFeeds(props) {
    const classes = useStyles();
    const postFromBackend = 10
    const { user } = useSelector((state) => state.auth);

    const {
        locationTags = [], filterSelectedCategories = [], searchTitle = "",
        communityId = null, community, setChangePageState, changePageState, slug
    } = props;

    const [postIds, setPostIds] = useState([]);
    const [postDict, setPostDict] = useState([]);
    const [hasMoreItems, sethasMoreItems] = useState(true);
    const [currPage, setCurrPage] = useState(0)
    const [postsC, setPostsC] = useState([])
    const [loading, setLoading] = useState(true)
    const observer = useRef();

    const findPostsForScroll = async () => {
        setLoading(true)
        let stateTags = new Set([]);
        let categoryTags = new Set([]);

        locationTags.map((loc) => {
            stateTags.add(loc);
        })
        filterSelectedCategories.map((cat) => {
            categoryTags.add(cat);
        })
        let statesArr = [...stateTags]
        let categoryTagsArr = [...categoryTags]

        await getCommunityPostWithFilter({
            searchTitle,
            stateTags: statesArr,
            selectedCategories: categoryTagsArr,
            _id: communityId,
            slug: slug,
            userProfile: user?.profile,
            limit: postFromBackend,
            page: currPage,

        })
            .then((data) => {
                if (currPage === 0) {
                    setPostsC(data)
                } else {
                    setPostsC((prev) => [...prev, ...data])
                }

                if (data.length < postFromBackend) {
                    sethasMoreItems(false);
                } else {
                    sethasMoreItems(true);
                }

                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const lastElementRef = useCallback(
        (node) => {
            if (loading) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && hasMoreItems) {
                    setCurrPage((prev) => {
                        return prev + 1;
                    });
                }
            });
            if (node) observer.current.observe(node);
        },
        [loading, hasMoreItems]
    );

    useEffect(() => {
        if (currPage === 0) {
            setChangePageState(!changePageState)
        } else {
            setCurrPage(0);
        }
    }, [locationTags, filterSelectedCategories, searchTitle])

    useDebounce(
        () => {
            findPostsForScroll()
        },
        700,
        [currPage, changePageState]
    );

    useEffect(() => {
        const { idArr, newDict } = arrayToReducer(postsC || []);
        setPostIds(idArr);
        setPostDict(newDict);
    }, [postsC])


    return (
        <div className={classes.feedBodyXCont} >
            {postIds && postIds.length > 0 ? (
                postIds.map((el, idx) => {
                    let lastItem = postIds.length - 1;
                    return postDict[el] ? (
                        idx === lastItem ? (
                            <div ref={lastElementRef} >
                                <PostFile
                                    commentLimit={5}
                                    key={postDict[el]._id}
                                    post={postDict[el]}
                                    sentProfile={user?.profile}
                                    addLink={false}
                                    community={community}
                                    setPostDict={setPostDict}
                                />
                            </div>
                        ) : (
                            <div>
                                <PostFile
                                    commentLimit={5}
                                    key={postDict[el]._id}
                                    post={postDict[el]}
                                    sentProfile={user?.profile}
                                    addLink={false}
                                    community={community}
                                    setPostDict={setPostDict}
                                />
                            </div>
                        )
                    ) : (
                        <></>
                    );
                })
            ) : (<>
                {loading && (
                    <PostSkeleton />
                )}
            </>)}
            {postIds && postIds.length > 0 && hasMoreItems ? (
                <div className={classes.progressBox}>
                    {loading && (
                        <CircularProgress />
                    )}
                </div>
            ) : null}
        </div>
    );
};


