import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FCard from "./FCard";
import softwareImg from "../../../Assets/ContractFloAssets/SVG/Software.svg"
import collaborateImg from "../../../Assets/ContractFloAssets/SVG/Collaborate.svg"
import networkingImg from "../../../Assets/ContractFloAssets/SVG/Networking.svg"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "white",
        padding: "52vh 180px 0px",
        [theme.breakpoints.down("lg")]: {
            padding: "58vh 110px 10px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "35vh 80px 10px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "30vh 30px 10px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "20vh 20px 10px",
        },
    },
    cardsCont: {
        display: "flex",
        alignItems: "flex-start",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginTop: "40px",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-around",
        }
    },
    headerCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "70px",
        "& h3": {
            fontSize: "35px",
            fontWeight: "800",
            textAlign: "center",
            marginBottom: "15px"
        },
        "& p": {
            fontSize: "14px",
            color: "#777575",
            textAlign: "center",
            width: "700px",
            lineHeight: "28px"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "50px",
            "& p": {
                width: "100%",
                fontSize: "12px",
                lineHeight: "20px"
            },
            "& h3": {
                fontSize: "28px",
                fontWeight: "700",
                marginBottom: "12px"
            },
        }
    }
}));

export default function HowCfloWorks({ howCfloWorksRef }) {
    const classes = useStyles();

    return (
        <section className={classes.root} ref={howCfloWorksRef} id="howCfloWorks" >
            <div className={classes.headerCont} >
                <h3 data-aos={"fade-up"} >How ContractFlo works?</h3>
                <p data-aos={"fade-up"} >Manage your real estate projects seamlessly with Contractflo. Our collaborative Saas tools enable efficient project management, while our built-in network connects you with other real estate professionals for joint ventures and deal-making.</p>
            </div>

            <div className={classes.cardsCont} >
                <FCard
                    photo={softwareImg}
                    header={"Software"}
                    text={"Software tools help you manage the property throughout its lifecycle."}
                    points={[
                        "Signup/Login to Contractflo.",
                        "Create a project and add property details.",
                        "Start using our Saas tools.",
                    ]}
                />
                <FCard
                    photo={collaborateImg}
                    header={"Collaborate"}
                    text={"Collaborate with others on your real estate projects."}
                    points={[
                        "Create organizations/projects on Contractflo.",
                        "Navigate to the “people” tab & invite members.",
                        "Start working together on projects.",
                    ]}
                />
                <FCard
                    photo={networkingImg}
                    header={"Networking"}
                    text={"Network efficiently to find the right people to suit your work."}
                    points={[
                        "Login and create your profile on Contractflo.",
                        "Add skills, achievements and markets.",
                        "Join relevant communities and follow people.",
                    ]}
                />
            </div>
        </section>
    );
}