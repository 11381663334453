import React, { useEffect, useState } from "react";
import SuggestedCommunitySidebar from "../community/SuggestedCommunitiesSidebar";
import Post from "./Post";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PostSideBar from "./PostSideBar";
import UserCommunities from "../community/UserCommunites";
import ResourcesCard from "../resources/Resources.Card";
import { getSuggestedCommunities, getUserCommunities } from "../community/api.call";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from '@material-ui/core';
import CreateFeedsPost from "./Create.Feeds.Post";
import { Button, TextField } from "@material-ui/core";
import { createUserFeedPost } from "./api.call";
import moment from "moment";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PostAddIcon from '@material-ui/icons/PostAdd';

const useStyles = makeStyles((theme) => ({
	mainCont: {
		width: "100%",
		height: `calc(100vh - ${theme.appbar.height})`,
		display: "flex",
		justifyContent: "space-between",
		padding: "5px",
		paddingTop: "25px",
	},
	feedCont: {
		width: "56%",
		height: "100%",
		overflowY: "auto",
		padding: "0px 30px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	leftSideCont: {
		width: "23%",
		height: "100%",
		overflowY: "auto",
		paddingLeft: "20px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	rightSideCont: {
		width: "23%",
		height: "100%",
		overflowY: "auto",
		paddingRight: "20px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none",
	},
	smallMainCont: {
		padding: "10px 10px 0px"
	},
	slidingCard: {
		display: "flex",
		overflow: "auto",
		marginBottom: "10px",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		scrollbarWidth: "none"
	},
	singleSlideCard: {
		width: "320px",
		minWidth: "320px",
		maxWidth: "320px",
		paddingRight: "10px",
	},
	searchText: {
		backgroundColor: "white",
		borderRadius: "12px",
		margin: "0px"
	},
	noBorder: {
		border: "none",
	},
}))

function getTomorrowDateTime() {
	var tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const date = moment(tomorrow).format("YYYY-MM-DDTkk:mm");
	return date;
}

const PostPageLayout = () => {
	const classes = useStyles();
	const theme = useTheme()
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { createdFileIds } = useSelector((state) => state.file);

	const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
	const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

	const [posts, setPosts] = useState([]);
	const [communites, setCommunities] = useState(null)
	const [communityIds, setCommunityIds] = useState(null)
	const [communityLoading, setCommunityLoading] = useState(false)
	const [suggestedCommunites, setSuggestedCommunities] = useState(null)
	const [suggestionLoading, setSuggestionLoading] = useState(false)

	const [postCreateLoading, setPostCreateLoading] = useState(false)
	const [newPostCreate, setNewPostCreate] = useState(true)
	const [searchTitle, setSearchTitle] = useState("")
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [isPoll, setIsPoll] = useState(false)
	const [pollOptions, setPollOptions] = useState([""]);
	const [pollExireAt, setPollExireAt] = useState(getTomorrowDateTime());


	const getOwnCommunites = async () => {
		setCommunityLoading(true)
		await getUserCommunities({
			profile: user?.profile
		})
			.then((data) => {
				console.log(data);
				setCommunities(data || [])
				setCommunityLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	const getAllSuggestedCommunities = async () => {
		setSuggestionLoading(true)
		await getSuggestedCommunities({
			profile: user?.profile,
			limit: 10,
			page: 0,
		})
			.then((data) => {
				console.log(data)
				setSuggestedCommunities(data || [])
				setSuggestionLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}

	useEffect(() => {
		getOwnCommunites()
		getAllSuggestedCommunities()
	}, [])

	const publish = async () => {
		if (title.length > 2 && description.length > 3) {
			setPostCreateLoading(true);
			let postBody = {
				title,
				description,
				published: true,
				profile: user?.profile,
				files: [...(Array.isArray(createdFileIds) ? createdFileIds : [])]
			};

			if (isPoll) {
				postBody.poll = {};
				postBody.poll.options = pollOptions;
				postBody.poll.expireAt = pollExireAt;
				postBody.poll.user = user;
				postBody.poll.profile = user?.profile;
				postBody.poll.parentModelName = "Post";
			}

			await createUserFeedPost(postBody)
				.then((data) => {
					console.log(data)
					let updatedPosts = [data, ...posts]
					setPosts(updatedPosts)

					dispatch({ type: "FileUploadReset" });
					setNewPostCreate(false)
					setTitle("")
					setDescription("")
					setIsPoll(false)
					setPollOptions([""]);
					setPollExireAt(getTomorrowDateTime());
					setPostCreateLoading(false)
				})
				.catch((err) => {
					console.log(err);
				})
		}
	}

	useEffect(() => {
		if (communites && communites.length > 0) {
			let arrId = [];
			communites.map((com) => {
				arrId.push(com?.community?._id)
			})
			setCommunityIds(arrId)
		}
	}, [communites])

	console.log(title)

	return (
		<div>
			{isMdSmall ? (<div className={classes.smallMainCont} >
				<div style={{ marginBottom: "15px" }} >
					{newPostCreate ? (<>
						<CreateFeedsPost
							titlePlaceholder={"Title"}
							descPlaceholder={"What is in your mind"}
							closeIt={() => { setNewPostCreate(false) }}
							description={description}
							setDescription={setDescription}
							title={title}
							setTitle={setTitle}
							isPoll={isPoll}
							setIsPoll={setIsPoll}
							pollOptions={pollOptions}
							setPollOptions={setPollOptions}
							pollExireAt={pollExireAt}
							setPollExireAt={setPollExireAt}
							publish={publish}
							postCreateLoading={postCreateLoading}
						/>
					</>) : (
						<TextField
							variant="outlined"
							margin="normal"
							fullWidth
							value={searchTitle}
							onChange={(e) => { setSearchTitle(e.target.value) }}
							id="phoneNumber"
							disableUnderline={false}
							autoFocus
							className={classes.searchText}
							placeholder="Search or create a post..."
							InputProps={{
								startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "#7C7C8D" }} /></InputAdornment>),
								endAdornment: (<InputAdornment position="end">
									<Button
										variant="contained"
										color="primary"
										startIcon={<PostAddIcon />}
										style={{ borderRadius: "15px" }}
										onClick={() => {
											setTitle(searchTitle)
											setNewPostCreate(true)
										}}
									>
										{isExSmall ? "Post" : "New Post"}
									</Button>
								</InputAdornment>),
								classes: { notchedOutline: classes.noBorder }
							}}
						/>
					)}
				</div>
				<div className={classes.slidingCard} >
					{communityLoading ? (
						<div className={classes.singleSlideCard} >
							<UserCommunities
								communites={communites}
								communityLoading={communityLoading}
								isAddNewBtn={false}
							/>
						</div>
					) : (<>
						{communites && communites.length > 0 ? (<>
							<div className={classes.singleSlideCard} >
								<UserCommunities
									communites={communites}
									communityLoading={communityLoading}
									isAddNewBtn={false}
								/>
							</div>
						</>) : null}
					</>)}
					<div className={classes.singleSlideCard} >
						<SuggestedCommunitySidebar
							suggestedCommunites={suggestedCommunites}
							reloadSuggestion={getAllSuggestedCommunities}
							reloadOwnCommunities={getOwnCommunites}
							suggestionLoading={suggestionLoading}
						/>
					</div>
					<div className={classes.singleSlideCard} >
						<ResourcesCard />
					</div>
					<div className={classes.singleSlideCard} style={{ paddingRight: "0px" }} >
						<PostSideBar
							selectedProfile={user}
						/>
					</div>
				</div>
				<Post
					posts={posts}
					setPosts={setPosts}
					searchTitle={searchTitle}
					setSearchTitle={setSearchTitle}
					communityIds={communityIds}
				/>
			</div>) : (<div className={classes.mainCont} >
				<div className={classes.leftSideCont} >
					<ResourcesCard />
					<PostSideBar
						selectedProfile={user}
					/>
				</div>
				<div className={classes.feedCont} >
					<Post
						posts={posts}
						setPosts={setPosts}
						searchTitle={searchTitle}
						setSearchTitle={setSearchTitle}
						communityIds={communityIds}
					/>
				</div>
				<div className={classes.rightSideCont} >
					<UserCommunities
						communites={communites}
						communityLoading={communityLoading}
						isAddNewBtn={false}
					/>
					<SuggestedCommunitySidebar
						suggestedCommunites={suggestedCommunites}
						reloadSuggestion={getAllSuggestedCommunities}
						reloadOwnCommunities={getOwnCommunites}
						suggestionLoading={suggestionLoading}
					/>
				</div>
			</div>)}
		</div>
	);
};

export default PostPageLayout;