import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import { SlPencil } from "react-icons/sl";
import { IconButton } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import emptyEducation from "../../../Assets/profileEducation.svg"
import { MdCastForEducation } from "react-icons/md";
import SeeMoreSeeLess from "../../styled/CommonComponents/SeeMore.SeeLess";
import { FaSchool } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.05rem",
    marginTop: "1rem",
    border: "1px solid white",
    borderRadius: "3px",
    padding: "5px 15px 15px",
    "& $h2": {
      fontWeight: "400",
      fontSize: "1.5rem",
      display: "inline",
    },
    "& p": {
      color: "grey",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        width: "100%",
      },
    }
  },
  section__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px"
    }
  },
  allExpEdit: {
    padding: "0px 30px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px"
  },
  imageCont: {
    marginRight: "15px"
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "16px",
      fontWeight: "500"
    },
    "& h4": {
      fontSize: "14px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    "& p": {
      marginTop: "5px",
      fontSize: "14px",
      fontWeight: "350"
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        marginTop: "3px",
        fontSize: "12px",
      },
    }
  }
}));

const colorArr = [
  "#F39014",
  "#0A66C2",
  "#36e055"
]

const EducationSection = ({
  isOwnProfile,
  profile,
  setDialog,
  setShowEducation,
  setOpenEditView,
  setProfileEditView
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const classes = useStyles();

  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      {isOwnProfile ? (
        <Paper className={classes.paper__section}>
          <div className={classes.section__header}>
            <h3><span><MdCastForEducation style={{ marginBottom: "-2px" }} /></span> Education</h3>
            <div>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setShowEducation(true);
                  setDialog("education");
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setProfileEditView("education")
                  setOpenEditView(true)
                }}
              >
                <SlPencil />
              </IconButton>
            </div>
          </div>
          {user?.education && user?.education?.length !== 0 ? (<>
            <div className={classes.allExpEdit} >
              {user?.education.map((eduObj, index) => (
                <div
                  className={classes.singleExperienceCont}
                  key={index}
                  style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                >
                  <Avatar
                    variant="square"
                    className={classes.imageCont}
                    style={{ backgroundColor: colorArr[index % 3] }}
                  >
                    <FaSchool style={{ color: "white" }} />
                  </Avatar>
                  <div className={classes.experienceRight}>
                    <h3>{eduObj.school}</h3>
                    <h4>{`${eduObj.degree}, in ${eduObj.field ? `${eduObj.field}` : null}`}</h4>
                    <h5>{`${eduObj?.start_date}  -  ${eduObj?.isCurrentlyStudying ? "Present" : eduObj?.end_date}`}</h5>
                    <p>
                      <SeeMoreSeeLess
                        text={eduObj.description || ""}
                        limit={200}
                      />
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </>) : (<div className={classes.emptyCont} >
            <img src={emptyEducation} />
          </div>)}
        </Paper>
      ) : (
        <div>
          {profile?.parent?.education && profile?.parent?.education?.length !== 0 ? (
            <Paper className={classes.paper__section}>
              <div className={classes.section__header}>
                <h3><span><MdCastForEducation style={{ marginBottom: "-2px" }} /></span> Education</h3>
                <div></div>
              </div>
              <div className={classes.allExpEdit} >
                {profile?.parent?.education.map((eduObj, index) => (
                  <div
                    className={classes.singleExperienceCont}
                    key={index}
                    style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                  >
                    <Avatar
                      variant="square"
                      className={classes.imageCont}
                      style={{ backgroundColor: colorArr[index % 3] }}
                    >
                      <FaSchool style={{ color: "white" }} />
                    </Avatar>
                    <div className={classes.experienceRight}>
                      <h3>{eduObj.school}</h3>
                      <h4>{`${eduObj.degree}, in ${eduObj.field ? `${eduObj.field}` : null}`}</h4>
                      <h5>{`${eduObj?.start_date}  -  ${eduObj?.isCurrentlyStudying ? "Present" : eduObj?.end_date}`}</h5>
                      <p>
                        <SeeMoreSeeLess
                          text={eduObj.description || ""}
                          limit={200}
                        />
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          ) : null}
        </div>
      )}
    </div>
  );
};
export default EducationSection;