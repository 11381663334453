const { default: Api } = require("../../helpers/Api");


export const getAllFiles = async (obj) => {
    try {
        const res = await Api.post("file/get", obj);
        const data = res?.result;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const getFeedPosts = async (obj) => {
    try {
        const res = await Api.post("post/get/for-user/main-feed", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const createUserFeedPost = async (obj) => {
    try {
        const res = await Api.post("post/create", obj);
        const data = res?.data;
        return data;
    } catch (error) {
        console.log(error);
    }
};