import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box, Button, Checkbox, CircularProgress, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, FormControl, FormControlLabel, FormGroup,
  Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { useParams, useHistory } from "react-router-dom";
import Api from "../../helpers/Api";
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import useShared from "../share/useShared.js";
import ShareIconBtn from "../share/share.icon.btn.js";
import SharedList from "../share/sharedList.js";
import useGetProfile from "../profile/useGetProfile";
import useGetDocs from "./useGetDocs";
import CustomTitleInput from "../styled/customTitle.input";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DocEditor from "./Doc.Editor";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#F8F8F8",
    padding: "0px 30px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "calc(100vh - 60px)",
    }
  },
  navBar: {
    width: "100%",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      height: "50px",
    }
  },
  mainCont: {
    width: "100%",
    height: "calc(100% - 70px)",
    display: "flex",
    overflowY: "auto",
    flexDirection: "column",
    padding: "0px 0px 40px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 0px 30px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "calc(100% - 50px)",
    }
  },
  flexShow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontWeight: "510",
      fontSize: "20px",
      color: "#00345D"
    },
    [theme.breakpoints.down('xs')]: {
      "& h3": {
        fontWeight: "500",
        fontSize: "17px",
        color: "#00345D"
      },
    },
  },
  backIcon: {
    fontSize: "30px",
    marginRight: "10px",
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      fontSize: "25px",
      marginRight: "5px",
    }
  },
  shareTitle: {
    fontSize: "19px",
    fontWeight: "500",
    margin: '0px 0px 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
  editorCont: {
    marginTop: "20px",
    padding: "0px 10px 15px"
  },
  shareCont: {
    padding: "5px 10px",
    marginTop: "20px"
  },
  fileUploadCont: {
    padding: "5px 10px",
    marginTop: "20px",
    [theme.breakpoints.down('xs')]: {
      padding: "5px 0px",
    }
  },
  onlyEditorCont: {
    width: "100%",
    marginTop: "-30px"
  },
  optionCont: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down('sm')]: {
      flexDirection: "column",
      alignItems: "flex-start",
      marginBottom: "15px"
    }
  },
  radiolabel: {
    fontSize: "16px",
    fontWeight: "510",
    [theme.breakpoints.down('xs')]: {
      fontSize: "12px",
      fontWeight: "500",
    }
  },
  fileUploadOrTemplateCont: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px 16px 10px"
  },
  headerText: {
    fontSize: "20px",
    margin: "15px 1rem 0px",
    [theme.breakpoints.down('sm')]: {
      fontSize: "17px",
      fontWeight: "510"
    }
  },
  uploadBtn: {
    padding: "0px 300px 5px"
  },
  isTemplate: {
    display: "flex",
    marginLeft: "15px"
  },
  allInputCont: {
    padding: "10px 16px",
  },
  nameEmailCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputsCont: {
    width: "calc(100% - 170px)",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      width: "calc(100% - 90px)",
      flexDirection: "column"
    },
    [theme.breakpoints.down('xs')]: {
      width: "calc(100% - 50px)",
      flexDirection: "column"
    },
  },
  actionBtnCont: {
    width: "120px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      width: "60px",
      flexDirection: "column"
    },
    [theme.breakpoints.down('xs')]: {
      width: "30px",
      flexDirection: "column"
    }
  },
  inputSty: {
    width: "48%",
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      marginBottom: "5px"
    }
  }
}));
const CircularIndeterminate = () => {
  return (
    <Box sx={{ display: "flex" }}>
      <CircularProgress />
    </Box>
  );
};

function CreateProfileEnvelope(props) {
  const history = useHistory();
  const classes = useStyles();

  const dispatch = useDispatch();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  //Docusign Auth Keys
  const docSignState = useSelector((state) => state.docSign);
  const isSignedIn = docSignState.isSignedIn;
  const [open, setOpen] = useState(false);
  //Document
  const [documentId, setDocumentId] = useState(0);
  const [documentName, setDocumentName] = useState("");
  //Recepient
  const [recepientMail, setRecepientMail] = useState("");
  const [recepientName, setRecepientName] = useState("");
  const [recepientId, setRecepientId] = useState(0);
  //CC
  const [ccMail, setCcMail] = useState("");
  const [ccName, setCcName] = useState("");
  const [cctId, setCcId] = useState(0);
  // Way of saving
  const [saveType, setSaveType] = useState("UPLOAD_DIRECTLY");
  //files to sign from url
  let fileToSignBase64data = "";
  // loading form
  const [isFormLoading, setIsFormLoading] = useState(false);
  // sign data tracker
  const signTracker = {
    provider: process.env.REACT_APP_DOCUMENT_SIGN_PROVIDER,
    id: "",
    status: "No Status",
  };
  // creating as template option
  const [createAsTemplate, setCreateAsTemplate] = useState(false);
  const saveRef = useRef();

  let selectedTemplateFiles;
  const { profileId } = useParams();
  const [selectedTemplateIdForEnvelope, setSelectedTemplateIdForEnvelope] =
    useState("");
  const file = useSelector((state) => state.file);

  const { user } = useSelector((state) => state.auth);

  const userId = user._id;
  const userProfileId = user?.profile;
  const [isPrivate, setPrivate] = useState(false);
  const sharedProps = useShared({
    initShared: [profileId, userProfileId],
    initAssigned: [],
  });
  //   console.log(Array.from(new Set(sharedProps?.shared)), "Type of");
  const profile = useGetProfile(profileId);
  let { createdFileIds } = file;
  const [recepients, setRecipients] = useState([{ mail: "", name: "", id: 1 }]);
  const [linkError, setLinkError] = useState(false);
  var { privateButton, sharedPeoples } = SharedList(
    sharedProps,
    isPrivate,
    setPrivate
  );
  const { docIds, docDictionary } = useGetDocs(profileId);
  // Pending Save Operation
  const [isSavePending,setIsSavePending] = useState(false);
  // const loadTemplate = () => {
  let avialableTemplates =
    docIds != []
      ? docIds.map((docId) => {
        if (docDictionary[docId]?.docType == "ESIGN_TEMPLATE") {
          return { id: docId, ...docDictionary[docId] };
        } else {
          return {};
        }
      })
      : [];
  avialableTemplates = avialableTemplates.filter(function (element) {
    return Boolean(Object.keys(element).length);
  });
  const sendMailToSign = async () => {
    const accessToken = docSignState.accessToken;
    // let base64 = file.upload ? file.upload[0].base64 : "No";
    // base64 = base64.split(",")[1];
    const payload = {
      accessToken: accessToken,
      documentBase64: fileToSignBase64data,
      signerName: recepientName,
      signerEmail: recepientMail,
      signerId: Math.floor(Math.random() * 100),
      ccEmail: ccMail,
      ccName: ccName,
      ccId: Math.floor(Math.random() * 100),
      docName: title,
      documentId: Math.floor(Math.random() * 100),
      signersArray: recepients,
    };
    // setIsFormLoading(true);
    await Api.post("doc/sign/uploadEnvelope", payload)
      .then(async (res) => {
        // setEMailSignSuccess(true);

        if (res.status == "sent") {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: true,
              message: `Redirecting to Docusign Dashboard `,
            },
          });
          signTracker.id = res.envelopeId;
          signTracker.status = res.status;
          await createDocApi();

          setIsFormLoading(false);
          let url = res.redirectUrl;
          window.open(url, "_blank", "noopener,noreferrer");
        } else {
          dispatch({
            type: "AddApiAlert",
            payload: {
              success: false,
              message:
                "Oops ! There was some problem while sending the E-mail. ",
            },
          });
          // setIsFormLoading(false);
        }
      })
      .catch((err) => {
        setIsFormLoading(false);
        dispatch({
          type: "AddApiAlert",
          payload: {
            success: false,
            message: "Error Occured on the server .",
          },
        });
        console.log("error sending", err);
      });
  };
  const convertFileIdsToBase64 = async () => {
    //for single file only for now
    let fileBase64 = "";
    let fileUrl = "";
    let fileId;
    if (saveType == "UPLOAD_DIRECTLY") {
      fileId = createdFileIds[0]; // getting single id
      console.log("file is ", fileId);
    } else {
      fileId = selectedTemplateFiles[0]; // getting single id
    }
    await Api.post("file/get", { files: [fileId] }).then((res) => {
      // const {result: files} = res;
      // setFiles(files, fileReducer, dispatch);
      // converting URL to BASE64 Encod
      fileUrl = res.result[0].url;
    });

    let blob = await fetch(fileUrl).then((r) => r.blob());
    let reader = new FileReader();

    reader.readAsDataURL(blob);
    reader.onloadend = async function () {
      fileToSignBase64data = reader.result.split(";base64,")[1];
      await sendMailToSign();
    };
    let flag = true;
  };
  let getSelectedFileIdFromAvailableTemplates = async () => {
    if (avialableTemplates != [] && avialableTemplates != undefined) {
      let currentFilesList = avialableTemplates.filter(
        (temp) => temp.id == selectedTemplateIdForEnvelope
      );
      console.log("current fil lis is ", currentFilesList);
      if (currentFilesList != undefined) {
        currentFilesList = currentFilesList[0].files;
        selectedTemplateFiles = currentFilesList;
        console.log(
          "retrieved files are ",
          selectedTemplateFiles,
          currentFilesList
        );
        await convertFileIdsToBase64();
      }
    }
  };

  const onMailChange = (newValue, idx) => {
    let temp = [...recepients];
    temp[idx]["mail"] = newValue;
    setRecipients([...temp]);
  };
  const onNameChange = (newValue, idx) => {
    let temp = [...recepients];
    temp[idx]["name"] = newValue;
    setRecipients([...temp]);
  };
  const addNew = (idx) => {
    let temp = { mail: "", name: "", id: Math.floor(Math.random() * 100) };
    setRecipients([
      ...recepients.slice(0, idx),
      temp,
      ...recepients.slice(idx),
    ]);
  };
  const onDelete = (idx) => {
    if (recepients.length > 1) {
      setRecipients([
        ...recepients.slice(0, idx),
        ...recepients.slice(idx + 1),
      ]);
    }
  };

  const handleSelectedTemplateIdForEnvelope = (event) => {
    setSelectedTemplateIdForEnvelope(event.target.value);
  };
  // Creating a template with local uploded file
  const createTemplateApi = async () => {
    let check = true;
    if (recepients.length == 1) {
      if (Boolean(recepients[0].mail) ^ Boolean(recepients[0].name)) {
        check = false;
        setLinkError(true);
        setTimeout(() => {
          setLinkError(false);
        }, 4000);
      }
    } else {
      for (var i = 0; i < recepients.length; i++) {
        let temp = recepients[i];
        if (temp.mail && temp.name) {
          continue;
        } else {
          check = false;
          setLinkError(true);
          setTimeout(() => {
            setLinkError(false);
          }, 4000);
          break;
        }
      }
    }
    if (check) {
      const docObject = {
        user: user._id,
        profile: profileId,
        title,
        description,
        shared: Array.from(new Set(sharedProps?.shared)),
        assigned: sharedProps?.assigned,
        isPrivate,
        files: createdFileIds,
        activeUserId: user?._id,
        activeUserProfile: user?.profile,
        docType: "ESIGN_TEMPLATE",
        //adding dummy parent id as templates don't have parent
        parentTemplate: "507f191e810c19729de860ea",
      };

      const res = await Api.post("doc/create", docObject);

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Template created successfully",
        },
      });

      dispatch({ type: "FileUploadReset" });
    }
  };
  // Creating a envelope with avialable templates
  const createDocApi = async () => {
    if (createAsTemplate) {
      console.log("Creating template");
      await createTemplateApi();
    }

    let check = true;
    if (recepients.length == 1) {
      if (Boolean(recepients[0].mail) ^ Boolean(recepients[0].name)) {
        check = false;
        setLinkError(true);
        setTimeout(() => {
          setLinkError(false);
        }, 4000);
      }
    } else {
      for (var i = 0; i < recepients.length; i++) {
        let temp = recepients[i];
        if (temp.mail && temp.name) {
          continue;
        } else {
          check = false;
          setLinkError(true);
          setTimeout(() => {
            setLinkError(false);
          }, 4000);
          break;
        }
      }
    }
    // sendMailToSign();

    if (check) {
      console.log("Creating Envelope");
      const docObject = {
        user: user._id,
        profile: profileId,
        title,
        description,
        shared: Array.from(new Set(sharedProps?.shared)),
        assigned: sharedProps?.assigned,
        isPrivate,
        files:
          saveType == "UPLOAD_DIRECTLY"
            ? createdFileIds
            : selectedTemplateFiles,
        activeUserId: user?._id,
        activeUserProfile: user?.profile,
        docType: "ESIGN_ENVELOPE",
        parentTemplate:
          saveType == "UPLOAD_DIRECTLY"
            ? "507f191e810c19729de860ea"
            : selectedTemplateIdForEnvelope,
        signTracker: signTracker,
        signData: {
          recepientName: recepientName,
          recepientMail: recepientMail,
          ccName: ccName,
          ccMail: ccMail,
        },
      };

      const res = await Api.post("doc/create", docObject);

      dispatch({
        type: "AddApiAlert",
        payload: {
          success: true,
          message: "Doc created successfully",
        },
      });

      dispatch({ type: "FileUploadReset" });

      history.goBack();
    }
  };
  const fetchBase64FromFileIds = async () => {
    await convertFileIdsToBase64();
  };
  const handleClickCreateBtn = async () => {
    if (!isSavePending ) {
      setIsSavePending(true);
    //   saveRef.current.setAttribute("disabled", true);
      if (saveType == "GENERATE_TEMPLATE") {
        console.log("Generate template");
        await getSelectedFileIdFromAvailableTemplates();
      }
      if (saveType == "UPLOAD_DIRECTLY") {
        console.log("Calling directly");
        await fetchBase64FromFileIds();
      }
    //   saveRef.current.removeAttribute("disabled");
      setIsSavePending(false)
    }
  };
  const handleChangeCreateAsTemplateOption = (e) => {
    setCreateAsTemplate(e.target.checked);
  };
  const handleCloseIsSignedIn = () => {
    history.goBack();
  };



  return (
    <div className={classes.root}>
      <div className={classes.navBar} >
        <div className={classes.flexShow} >
          <KeyboardBackspaceIcon
            onClick={() => {
              history.goBack();
            }}
            className={classes.backIcon}
          />
          <h3>Create Envelope</h3>
        </div>
        <CustomBtn
          startPart={<SaveIcon />}
          disabled={isSavePending}
          text={"Save"}
          onClick={() => { handleClickCreateBtn(); }}
        />
      </div>
      <div className={classes.mainCont} >
        <Dialog
          open={!isSignedIn}
          onClose={handleCloseIsSignedIn}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Docusign Verification Failed !"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You are currently not signed in with docusign. You will be
              redirected to the previous page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseIsSignedIn} autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {sharedProps?.shareDrawer}
        {sharedProps?.assignedDialog}


        {isFormLoading ? (
          <div>
            <CircularIndeterminate />
          </div>
        ) : (<>
          <Paper elevation={1} className={classes.editorCont} >
            <div className={classes.onlyEditorCont} >
              <DocEditor
                description={description}
                setDescription={setDescription}
                title={title}
                setTitle={setTitle}
                noFileUploadBtn={true}
              />
            </div>
          </Paper>

          <Paper className={classes.fileUploadCont} >
            <div className={classes.fileUploadOrTemplateCont} >
              {avialableTemplates && avialableTemplates.length > 0 ? (<>
                <h3 className={classes.headerText} style={{ marginLeft: "0px", marginRight: "0px" }} >Choose how you want to save the document</h3>
                <FormControl
                  component="fieldset"
                >
                  <RadioGroup
                    className={classes.optionCont}
                    aria-label="gender"
                    name="docType"
                    value={saveType}
                    onChange={(e) => {
                      setSaveType(e.target.value)
                      console.log(e.target.value)
                    }}
                  >
                    <FormControlLabel
                      value={"GENERATE_TEMPLATE"}
                      control={<Radio color="primary" />}
                      label={<p className={classes.radiolabel} >Generate From Available Template</p>}
                    />
                    <FormControlLabel
                      value={"UPLOAD_DIRECTLY"}
                      control={<Radio color="primary" />}
                      label={<p className={classes.radiolabel} >Upload Directly</p>}
                    />
                  </RadioGroup>
                </FormControl>
                {saveType == "UPLOAD_DIRECTLY" && (<>
                  <CustomFileUploadButton
                    showComponent={
                      <CustomBtn
                        startPart={<AddIcon />}
                        text={"Upload File"}
                      />
                    }
                    parentType={"Doc"}
                    parentId={null}
                    fileNum={1}
                    givenMaxSize={26214400}
                    closeFunCall={() => { console.log("close dialog") }}
                  />
                  <FormGroup style={{ marginTop: "7px" }} >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={createAsTemplate}
                          onChange={handleChangeCreateAsTemplateOption}
                          defaultChecked
                          color="primary"
                        />
                      }
                      label="Create as a template"
                    />
                  </FormGroup>
                </>)}
                {saveType == "GENERATE_TEMPLATE" && (<>
                  <FormControl
                    fullWidth
                  >
                    <InputLabel id="demo-simple-select-label">
                      Select from available envelopes
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedTemplateIdForEnvelope}
                      label="Generate From"
                      onChange={handleSelectedTemplateIdForEnvelope}
                    >
                      {avialableTemplates.map((template) => (
                        <MenuItem value={template.id}>{template.title}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </>)}
              </>) : (<>
                <h3 className={classes.headerText} style={{ marginLeft: "0px", marginRight: "0px" }} >Upload document</h3>
                <CustomFileUploadButton
                  showComponent={
                    <CustomBtn
                      startPart={<AddIcon />}
                      text={"Upload File"}
                    />
                  }
                  parentType={"Doc"}
                  parentId={null}
                  fileNum={1}
                  givenMaxSize={26214400}
                  closeFunCall={() => { console.log("close dialog") }}
                />
                <FormGroup style={{ marginTop: "7px" }} >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={createAsTemplate}
                        onChange={handleChangeCreateAsTemplateOption}
                        defaultChecked
                        color="primary"
                      />
                    }
                    label="Create as a template"
                  />
                </FormGroup>
              </>)}
            </div>

            <h3 className={classes.headerText}>Recipient Records</h3>
            <div className={classes.allInputCont} >
              {recepients.map((obj, idx) => (<div className={classes.nameEmailCont} >
                <div className={classes.inputsCont} >
                  <TextField
                    label="Email"
                    value={recepients[idx]["mail"]}
                    onChange={(e) => onMailChange(e.target.value, idx)}
                    placeholder="Enter E-mail of recipient"
                    variant="outlined"
                    type="email"
                    size="small"
                    className={classes.inputSty}
                  />
                  <TextField
                    label="Name"
                    value={recepients[idx]["name"]}
                    onChange={(e) => onNameChange(e.target.value, idx)}
                    placeholder="Enter name of recipients"
                    variant="outlined"
                    size="small"
                    className={classes.inputSty}
                  />
                </div>
                <div className={classes.actionBtnCont} >
                  <IconButton
                    onClick={() => addNew(idx)}
                  >
                    <AddIcon />
                  </IconButton>
                  <IconButton
                    disabled={recepients.length == 1}
                    onClick={() => onDelete(idx)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </div>
              </div>))}
              {linkError && (
                <Alert severity="error">
                  Please fill all fields or delete them if not required.
                </Alert>
              )}
            </div>

            {/* CC Email */}
            <Grid item xs={12}>
              <CustomTitleInput
                title={ccMail}
                type={"email"}
                text={"Email of the CC "}
                placeholder={"cc@example.com"}
                setTitle={setCcMail}
                label="CC Email"
                name="CC Email"
              />
            </Grid>

            {/* CC Name */}
            <Grid item xs={12}>
              <CustomTitleInput
                title={ccName}
                text={"Name of the CC "}
                placeholder={"Mr. John Doe"}
                setTitle={setCcName}
                label="CC Name"
                name="CC Name"
              />
            </Grid>
          </Paper>

          <Paper className={classes.shareCont} >
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
              <Typography variant="h4" className={classes.shareTitle}>Shared</Typography>
              <div>
                <span style={{ marginRight: "15px" }} >
                  {privateButton}
                </span>
                <ShareIconBtn
                  open={sharedProps?.open}
                  setOpen={sharedProps?.setOpen}
                />
              </div>
            </div>
            <div>{sharedPeoples}</div>
          </Paper>
        </>)}
      </div>
    </div>
  );
}

export default CreateProfileEnvelope;
