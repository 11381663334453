import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import MultipleChoice from "./multiple.choice";
import FormTableQuestion from "./form.table.question";
import arrayToReducer from "../../helpers/arrayToReducer";
import { useSelector } from "react-redux";
import Api from "../../helpers/Api";
import { IconButton, Tooltip } from "@material-ui/core";
import FormTableRow from "./form.table.row";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import CircularProgress from "@material-ui/core/CircularProgress";
import DefaultResponses from "./DefaultResponses";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
    // border: '1px solid #000',
  },
  row: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "left",
    width: "100%",
    height: "100%",
    // border: '1px solid red'
  },
  col: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    alignItems: "left",
    // border: '1px solid green',
    width: "100%",
    height: "100%",
  },
  question_container: {
    // border: "1px solid grey",
    borderRadius: "5px",
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    padding: "1rem",
  },
  column_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export default function FormTable(props) {
  const classes = useStyles();
  const { root, row, col, question_container, column_header } = classes;
  const {
    form,
    // optionIds,
    // setOptionIds,
    // optionDict,
    // setOptionDict,
    // optionColIds,
    // setOptionColIds,
    // optionColDict,
    // setOptionColDict,
    // questionId,
    question,
    // createOption,
    // createOptionCols,
    // deleteOption,
    // deleteOptionCols,
  } = props;

  const [activeQuestion, setActiveQuestion] = useState(0);
  const [questionIds, setQuestionIds] = useState([]);
  const [questionDict, setQuestionDict] = useState({});
  const [rowIds, setRowIds] = useState([]);
  const [rowDict, setRowDict] = useState({});
  const { user } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(false);
  const [table, setTable] = useState(question?.table || {});

  const createQuestion = async (index) => {
    const obj = {
      questionObj: {
        type: "Short Answer",
        form,
        profile: user?.profile,
        questionText: "",
      },
      rowIds,
      tableId: table._id,
      formId: form._id,
      index: index,
    };
    //api to create new question
    console.log(obj);
    const res = await Api.post("formtable/question/create", obj);
    console.log("res here = ", res);
    if (res?.data) {
      // const { newDict, idArr } = arrayToReducer(res?.data?.questions);
      // setActiveQuestion(idArr[0]);
      // setQuestionIds(idArr);
      // setQuestionDict(newDict);
      // const { newDict: newTempDict, idArr: newIdArr } = arrayToReducer(
      //   res?.data?.defaultRows
      // );
      // setRowDict(newTempDict);
      // setRowIds(newIdArr);
      setTable(res?.data);
    }
  };

  //api to create default question at the start
  const createQuestionDefault = async (index, tableId) => {
    console.log("default question in the making");
    const obj = {
      questionObj: {
        type: "Short Answer",
        form: form?._id,
        profile: user?.profile,
        questionText: "Full Name",
      },
      tableId,
      formId: form._id,
      index: index,
    };
    const res = await Api.post("formtable/question/createDefault", obj);
    console.log("res here = ", res);
    console.log(res?.data);
    if (res?.data) {
      // const { newDict, idArr } = arrayToReducer(res?.data?.questions);
      // console.log("Question = ", newDict);
      // //updating the question dictionary and array
      // setQuestionDict({
      //   ...questionDict,
      //   ...newDict,
      // });
      // setQuestionIds([
      //   ...questionIds.slice(0, index + 1),
      //   idArr[idArr.length - 1],
      //   ...questionIds.slice(index + 1),
      // ]);
      //we need default row as well
      createDefaultRow(res?.data?.questions, tableId);
    }
  };

  const createDefaultRow = async (ques, tableId) => {
    let allResponses = [];
    ques.map((currentQues) => {
      allResponses.push({
        form: form,
        question: currentQues._id,
      });
    });
    let newRow = {
      question: question,
      form: form,
      formTable: tableId,
      createdBy: user?.profile,
    };

    const res = await Api.post("formtable/row/create", {
      allResponses: allResponses,
      newRow: newRow,
      tableId,
    });
    if (res?.data) {
      // const { newDict, idArr } = arrayToReducer(res?.data?.defaultRows);
      // setRowDict(newDict);
      // setRowIds(idArr);
      setTable(res?.data);
    }
  };

  const createRow = async (tableId) => {
    //first create number of empty responses= number of questions,
    //each of these response has the same form, different question
    //create a row
    //link these responses with current row and save
    console.log("create row called");
    console.log("len");
    console.log(questionIds.length);
    let allResponses = [];
    questionIds.map((questionId) => {
      allResponses.push({
        form: form,
        question: questionId,
      });
    });
    let newRow = {
      question: question,
      form: form,
      formTable: tableId,
      createdBy: user?.profile,
    };
    console.log("table id", tableId);
    const res = await Api.post("formtable/row/create", {
      allResponses: allResponses,
      newRow: newRow,
      tableId,
    });
    //res contains all the rows attached to this form table
    if (res?.data) {
      // console.log("all rows");
      // console.log(res?.data);
      // const { newDict, idArr } = arrayToReducer(res?.data?.defaultRows);
      // setRowDict(newDict);
      // setRowIds(idArr);
      setTable(res?.data);
    }
  };

  const deleteRow = async (row, index) => {
    //delete the rowId from formTable, and then delete all the responses and the corresponding FormtableRow
    //do it in frontend first
    setRowIds([...rowIds.slice(0, index), ...rowIds.slice(index + 1)]);
    let deletedResponses = [];
    row.responses.map((response) => {
      deletedResponses.push(response._id);
    });
    await Api.post("formtable/row/delete", {
      row,
      deletedResponses,
    });
    console.log("deleted successfully");
  };

  const createFormTable = async () => {
    setLoading(true);
    let formTableObj = {
      question,
      form,
      createdBy: user?.profile,
    };
    const res = await Api.post("formtable/create", formTableObj);
    if (res?.data) {
      console.log("form table created");
      console.log(res.data);
      createQuestionDefault(0, res.data._id);
    }
    setLoading(false);
  };

  //form table question delete
  const onDelete = async (question, index) => {
    console.log("on delete called");
    //first delete all the responses attached to this question and update all rows
    //api to delete question
    const res = await Api.post("formtable/question/delete", {
      question,
      rowIds,
      tableId: table?._id,
    });
    // const { newDict, idArr } = arrayToReducer(res?.data);
    // setRowDict(newDict);
    // setRowIds(idArr);
    // setQuestionIds([
    //   ...questionIds.slice(0, index),
    //   ...questionIds.slice(index + 1),
    // ]);

    //needs to be done though
    // const questionId = question._id;
    // await Api.post("question/delete", {
    //   questionId,
    // });
    if (res?.data) {
      console.log(res?.data);
      // console.log("Question deleted ->", questionDeleted);
      setTable(res?.data);
    }
  };

  useEffect(() => {
    console.log("here's the q");
    console.log(question);
    console.log(table);
    if (table?._id) {
      if (table?.questions?.length > 0) {
        const { newDict, idArr } = arrayToReducer(table?.questions);
        setQuestionDict(newDict);
        setQuestionIds(idArr);
        setActiveQuestion(idArr[0]);
      }
      if (table?.defaultRows?.length > 0) {
        const { newDict, idArr } = arrayToReducer(table?.defaultRows);
        setRowDict(newDict);
        setRowIds(idArr);
      }
    } else {
      console.log("calleeeeed");
      createFormTable();
    }
  }, [table?.questions, table?.defaultRows]);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <div className={root}>
          <div className={row}>
            <div className={col}>
              <div className={column_header}>
                <Typography variant="h6">Columns</Typography>
                <IconButton
                  color="primary"
                  aria-label="Add Row"
                  component="span"
                  onClick={() => {
                    createQuestion(questionIds.length);
                  }}
                >
                  <AddIcon />
                </IconButton>
              </div>
              <div
                style={{
                  width: "100%",
                  marginTop: "1vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className={question_container}
              >
                {questionIds.length > 0 ? (
                  questionIds.map((questionId, index) => {
                    const question = questionDict[questionId];
                    console.log("current index: ", index);
                    console.log(question);
                    return (
                      <FormTableQuestion
                        key={questionId}
                        index={index}
                        question={question} //current question
                        createQuestion={createQuestion} //create a new question
                        setActiveQuestion={setActiveQuestion} //zooming feature
                        onDelete={onDelete} //delete the current question
                        // setQuestion={setQuestion}
                        isActive={activeQuestion === questionId}
                        tableId={table._id}
                        questionIds={questionIds}
                        questionDict={questionDict}
                        setQuestionDict={setQuestionDict}
                        setQuestionIds={setQuestionIds}
                      />
                    );
                  })
                ) : (
                  <Button
                    variant="outlined"
                    onClick={() => {
                      createQuestion(questionIds.length);
                    }}
                  >
                    Create Columns
                  </Button>
                )}
              </div>
            </div>
            {rowIds.length > 0 ? (
              <DefaultResponses
                rowIds={rowIds}
                setRowIds={setRowIds}
                rowDict={rowDict}
                form={form}
                formTable={table}
                questionDict={questionDict}
                questionIds={questionIds}
                setTable={setTable}
                tableId={table?._id}
                user={user}
                question={question}
              />
            ) : (
              <span>No rows to show...</span>
            )}
          </div>
        </div>
      )}
    </>
  );
}

{
  /* <Button
                    variant="outlined"
                    onClick={() => {
                      createRow(table?._id);
                    }}
                  >
                    Create Rows
                  </Button> */
}

{
  /* <MultipleChoice
                      createOption={createOption}
                      option={option}
                      index={index}
                      deleteOption={deleteOption}
                      isRow={true}
                    /> */
}
