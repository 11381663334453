import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ProfileSelectList from "./profile.select.list";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    margin: "1rem 0px",
    alignItems: "center",
    justifyContent: "space-around",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      margin: "0rem 0px 10px",
      alignItems: "flex-start",
    },
  },
  ownerText: {
    fontSize: "1rem",
  },
}));

const ProfileSelect = (props) => {
  const { owner, adminProfiles, onChange, title, displayOwner, hideTitle } = props;
  const classes = useStyles();
  const [entity, setEntity] = useState(owner);

  useEffect(() => {
    if (entity?._id) {
      onChange(entity);
    }
  }, [entity?._id]);

  useEffect(() => {
    setEntity(owner);
  }, owner?._id);

  return (
    <div className={classes.root}>
      {title && !hideTitle ? (
        <Typography className={classes.ownerText}>{title}</Typography>
      ) : null}
      <ProfileSelectList
        entity={entity}
        setEntity={setEntity}
        entities={adminProfiles}
        displayOwner={displayOwner}
      />
    </div>
  );
};

export default ProfileSelect;
