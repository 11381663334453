import {
	Divider,
	Grid,
	IconButton,
	Paper,
	Tab,
	Tabs,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState, useMemo, useCallback } from "react";
import PrintIcon from "@material-ui/icons/Print";
import GetAppIcon from "@material-ui/icons/GetApp";
import ReactTable from "./RentTable";
import ProjectSummary from "./ProjectSummary";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Api from "../../helpers/Api";
import ProfileAppbar from "../profile/profile.appbar";
import UpdatePortfolioDialog from "../dashboard/Property/updatePortfolio.dialog";
import SettingsIcon from "@material-ui/icons/Settings";
import {
	PieChart,
	Pie,
	Sector,
	Cell,
	Legend,
	Tooltip,
	ResponsiveContainer,
} from "recharts";

function currencyFormatter(currency, sign) {
	if (currency) {
		var sansDec = Number(currency).toFixed(2);
		var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		return sign + `${formatted}`;
	} else {
		return sign + 0;
	}
}

const getTotalAndDue = (arr) => {
	let total = 0;
	let due = 0;
	arr.map((doc) => {
		total += doc?.finalAmount || 0;
		if (doc?.status != "Paid") {
			due += doc?.finalAmount || 0;
		}
	});

	return [total, due];
};

const renderActiveShape = (props) => {
	const RADIAN = Math.PI / 180;
	const {
		cx,
		cy,
		midAngle,
		innerRadius,
		outerRadius,
		startAngle,
		endAngle,
		fill,
		payload,
		percent,
		value,
	} = props;
	const sin = Math.sin(-RADIAN * midAngle);
	const cos = Math.cos(-RADIAN * midAngle);
	const sx = cx + (outerRadius + 10) * cos;
	const sy = cy + (outerRadius + 10) * sin;
	const mx = cx + (outerRadius + 30) * cos;
	const my = cy + (outerRadius + 30) * sin;
	const ex = mx + (cos >= 0 ? 1 : -1) * 22;
	const ey = my;
	const textAnchor = cos >= 0 ? "start" : "end";

	return (
		<g>
			<text x={cx} y={cy} dy={8} textAnchor="middle" fill={"#000000"}>
				{payload.name}
			</text>
			<Sector
				cx={cx}
				cy={cy}
				innerRadius={innerRadius}
				outerRadius={outerRadius}
				startAngle={startAngle}
				endAngle={endAngle}
				fill={fill}
			/>
			<Sector
				cx={cx}
				cy={cy}
				startAngle={startAngle}
				endAngle={endAngle}
				innerRadius={outerRadius + 6}
				outerRadius={outerRadius + 10}
				fill={fill}
			/>
			<path
				d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
				stroke={fill}
				fill="none"
			/>
			<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				textAnchor={textAnchor}
				fill="#333"
			>{`Amount $${value} `}</text>
			<text
				x={ex + (cos >= 0 ? 1 : -1) * 12}
				y={ey}
				dy={18}
				textAnchor={textAnchor}
				fill="#999"
			>
				{`(${(percent * 100).toFixed(2)}%)`}
			</text>
		</g>
	);
};
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];

const groupBy = (key) => (array) =>
	array.reduce((objectsByKeyValue, obj) => {
		const value = obj[key];
		objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
		return objectsByKeyValue;
	}, {});

const groupByParent = groupBy("parent");
const groupByProjectUnits = groupBy("project");
const groupByDesc = groupBy("description");

const groupByProject = (array) =>
	array.reduce((objectByKeyValue, obj) => {
		const id = obj?.project?._id;
		objectByKeyValue[id] = (objectByKeyValue[id] || []).concat(obj);
		return objectByKeyValue;
	}, {});

function ProjectPortfolioView() {
	const [tabValue, setTabValue] = useState(0);
	const { id } = useParams();
	const { user } = useSelector((state) => state.auth);
	const [loading, setLoading] = useState(false);
	const [allLeases, setAllLeases] = useState({});
	const [allTransactions, setAllTransactions] = useState({});
	const [allUnits, setAllUnits] = useState({});
	const [portfolio, setPortfolio] = useState(null);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [rowData, setRowsData] = useState([]);
	const [projectArr, setProjectArr] = useState([]);
	const [vacantData, setVacantData] = useState([
		{
			name: "Occupied",
			value: 0,
		},
		{ name: "Vacant", value: 0 },
	]);
	const [balanceData, setBalanceData] = useState([
		{ name: "Rent", balance: 0, due: 0 },
		{ name: "Reccuring Charges", balance: 0, due: 0 },
		{
			name: "Security Charges",
			balance: 0,
			due: 0,
		},
		{
			name: "One Time Payments",
			balance: 0,
			due: 0,
		},
	]);
	const [activeIndex, setActiveIndex] = useState(0);
	const onPieEnter = useCallback(
		(_, index) => {
			setActiveIndex(index);
		},
		[setActiveIndex]
	);
	// console.log("user = ", user?.profile);
	const getLeases = async () => {
		setLoading(true);
		const res = await Api.post("projectportfolio/getbyid", {
			userId: user?.profile,
			porfolioId: id,
		});
		// console.log("res = ", res);
		if (res?.data) {
			const { leases, transactions, units, portfolio } = res?.data;
			setAllLeases(groupByProject(leases));
			const obj = groupByParent(transactions);
			setAllTransactions({ ...obj });
			setAllUnits(groupByProjectUnits(units));
			setPortfolio(portfolio);
		} else {
			console.error("ERROR:", res?.error);
		}
		setLoading(false);
	};
	useEffect(() => {
		getLeases();
	}, []);

	useEffect(() => {
		if (allTransactions) {
			let allTransactionArray = Object.values(allTransactions || {}).flat(
				1
			);
			let transactionArray = groupByDesc(allTransactionArray);
			console.log("all", allTransactionArray, transactionArray);
			let [rentTotal, rentDue] = getTotalAndDue(
				transactionArray["Rent"] || []
			);
			let [oneTimeTotal, oneTimeDue] = getTotalAndDue(
				transactionArray["One Time Bills"] || []
			);
			let [reccTotal, reccDue] = getTotalAndDue(
				transactionArray["Reccuring Bills"] || []
			);
			let [securityDepTotal, securityDepDue] = getTotalAndDue(
				transactionArray["Security Deposit"] || []
			);
			let [total, due] = getTotalAndDue(allTransactionArray);

			setBalanceData([
				{ name: "Rent", balance: rentTotal, due: rentDue },
				{ name: "Reccuring Charges", balance: reccTotal, due: reccDue },
				{
					name: "Security Charges",
					balance: securityDepTotal,
					due: securityDepDue,
				},
				{
					name: "One Time Payments",
					balance: oneTimeTotal,
					due: oneTimeDue,
				},
			]);
		}
	}, [allTransactions]);
	useEffect(() => {
		if (portfolio?.projects) {
			let pArr = (portfolio?.projects || []).map((arr) => arr?.project);
			setProjectArr([...pArr]);
		}
	}, [portfolio]);
	useEffect(() => {
		let totalUnits = 0;
		let totalVacant = 0;
		let rows = projectArr.map((doc, idx) => {
			let project = doc?.displayName;
			let unitNo = (allUnits[doc?._id] || []).length;
			let vacant = 0;
			let occupied = 0;
			let total = 0;
			let due = 0;
			(allUnits[doc?._id] || []).map((unit) => {
				if (unit?.vacant) {
					vacant += 1;
				} else {
					occupied += 1;
				}
				let rentalRelationId = unit?.rentalRelation;
				let transArray = allTransactions[rentalRelationId] || [];
				transArray.map((transDoc) => {
					if (transDoc?.description == "Rent") {
						total += transDoc?.finalAmount || 0;
						if (transDoc?.status != "Paid") {
							due += transDoc?.finalAmount || 0;
						}
					}
				});
			});
			let avg = (total / unitNo).toFixed(2);
			let occupiedPercent = (occupied * 100) / unitNo;

			totalVacant += vacant;
			totalUnits += unitNo;
			return {
				project,
				unitNo,
				vacant,
				occupied,
				occupiedPercent,
				total,
				avg,
				due,
			};
		});
		setRowsData([...rows]);
		setVacantData([
			{
				name: "Occupied",
				value: totalUnits - totalVacant,
			},
			{ name: "Vacant", value: totalVacant },
		]);
	}, [projectArr, allUnits, allTransactions]);

	return (
		<div>
			<ProfileAppbar name={portfolio?.name || "Portfolio"} />
			<UpdatePortfolioDialog
				open={dialogOpen}
				setOpen={setDialogOpen}
				portfolio={portfolio}
			/>
			<div style={{ backgroundColor: "white", paddingTop: "4rem" }}>
				<div
					style={
						{
							// marginTop: "1rem",
							// border: "1px solid lightgrey",
							// borderRadius: "10px",
						}
					}
				>
					<div
						style={{
							display: "flex",
							justifyContent: "right",
							padding: "0.5rem 1rem",
							gap: "3px",
						}}
					>
						<IconButton onClick={() => setDialogOpen(true)}>
							<SettingsIcon />
						</IconButton>
						<IconButton>
							<PrintIcon />
						</IconButton>
						<IconButton>
							<GetAppIcon />
						</IconButton>
						{/* icons */}
					</div>
					<Divider />
					<div>
						<Paper square>
							<Tabs
								value={tabValue}
								indicatorColor="primary"
								textColor="primary"
								onChange={(e, newValue) =>
									setTabValue(newValue)
								}
								aria-label="disabled tabs example"
							>
								<Tab label="Details" />
								<Tab label="Summary" />
							</Tabs>
						</Paper>
					</div>
					<div style={{ padding: "1rem" }}>
						{/* table  */}
						{tabValue == 0 ? (
							<>
								<div>
									<div>
										<div
											style={{
												display: "flex",
												gap: "3%",
											}}
										>
											<Paper
												style={{
													width: "47%",
													marginTop: "1rem",
													height: "22rem",
												}}
											>
												<ResponsiveContainer
													style={{
														width: "100%",
														height: "100%",
													}}
												>
													<PieChart
														width={500}
														height={400}
													>
														<Legend />
														<Pie
															activeIndex={
																activeIndex
															}
															activeShape={
																renderActiveShape
															}
															data={balanceData}
															cx={"52%"}
															cy={"42%"}
															innerRadius={80}
															outerRadius={100}
															fill="#8884d8"
															dataKey="balance"
															onMouseEnter={
																onPieEnter
															}
														>
															{balanceData.map(
																(
																	entry,
																	index
																) => (
																	<Cell
																		key={`cell-${index}`}
																		fill={
																			COLORS[
																				index %
																					COLORS.length
																			]
																		}
																	/>
																)
															)}
														</Pie>
													</PieChart>
												</ResponsiveContainer>
											</Paper>
											<Paper
												style={{
													width: "47%",
													marginTop: "1rem",
												}}
											>
												<ResponsiveContainer
													width="100%"
													height="100%"
												>
													<PieChart
														width={400}
														height={400}
													>
														<Legend />
														<Tooltip />
														<Pie
															dataKey="value"
															startAngle={180}
															endAngle={0}
															data={vacantData}
															cx="50%"
															cy="54%"
															outerRadius={120}
															fill="#8884d8"
															label
														>
															{vacantData.map(
																(
																	entry,
																	index
																) => (
																	<Cell
																		key={`cell-${index}`}
																		fill={
																			COLORS[
																				index %
																					COLORS.length
																			]
																		}
																	/>
																)
															)}
														</Pie>
													</PieChart>
												</ResponsiveContainer>
											</Paper>
										</div>
									</div>
								</div>
								{Object.keys(allLeases).map(
									(projectId, idx) => (
										<div
											key={idx}
											style={{ marginTop: "1rem" }}
										>
											<ReactTable
												transactions={allTransactions}
												leases={allLeases[projectId]}
											/>
										</div>
									)
								)}
							</>
						) : (
							<ProjectSummary
								transactions={allTransactions}
								leases={allLeases}
								units={allUnits}
								portfolio={portfolio}
								balanceData={balanceData}
								vacantData={vacantData}
								rowData={rowData}
								activeIndex={activeIndex}
								onPieEnter={onPieEnter}
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProjectPortfolioView;
