const { default: Api } = require("../../helpers/Api");

export const addPhotosToProject = async (obj) => {
    try {
        const propertyType = await Api.post("public/project/addFiles", obj);
        const data = propertyType?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};

export const getFullProjectData = async (obj) => {
    try {
        const propertyType = await Api.post("project/get/data/for/dashboard", obj);
        const data = propertyType?.data;
        return data;
    } catch (error) {
        console.log(error);
        return null;
    }
};