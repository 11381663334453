import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "28%",
        [theme.breakpoints.down("sm")]: {
            width: "190px",
            marginBottom: "30px"
        },
        [theme.breakpoints.down("xs")]: {
            width: "250px",
        },
    },
    textCont: {
        marginTop: "10px",
        "& h3": {
            fontSize: "18px",
            fontWeight: "700",
            marginBottom: "5px",
            color: "white",
            lineHeight: "22px"
        },
        "& p": {
            fontSize: "13px",
            color: "white",
            fontWeight: "400",
            marginTop: "8px",
            lineHeight: "22px"
        }
    },
    photoCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        marginBottom: "5px",
        padding: "20px 30px",
        "& img": {
            width: "100%",
            height: "auto",
        },
        [theme.breakpoints.down("md")]: {
            padding: "10px 15px",
        }
    }
}));

export default function FCard({ photo, header, text }) {
    const classes = useStyles();

    return (
        <div className={classes.root} >
            <div className={classes.photoCont} >
                <img src={photo} alt="cardImage" />
            </div>
            <div className={classes.textCont} >
                <h3>{header}</h3>
                <p>{text}</p>
            </div>
        </div>
    );
}