import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { useGetTx } from './hooks';
import BillEdit from '../../expenseAndBills/bills/bill.edit';
import InvoiceEdit from '../../salesAndInvoices/invoice.edit';
import { updateTx } from './api';


const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: '6rem',
    width: '100%',
  }
}));

export default function TxEdit(props) {
  const {
    walletId, txId,
  } = useParams();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();


  console.log(walletId)
  console.log(txId)

  const {
    user,
    userProfile,
  } = useSelector((state) => state.auth);

  const {
    tx, setTx,
  } = useGetTx(txId);

  const {
    root
  } = classes;

  let TxView = null;

  switch (tx?.type) {
    case 'Bill':
      TxView = <BillEdit
        tx={tx}
        setTx={setTx}
        updateApi={updateTx}
        walletId={walletId}
      />
      break;
    case 'Invoice':
      TxView = <InvoiceEdit
        tx={tx}
        setTx={setTx}
        updateApi={updateTx}
        walletId={walletId}
      />
      break;
    default:
      break;
  }


  useEffect(() => {
    if (tx?.billList?._id) {
    }
  }, [tx?._id]);


  return (
    <div className={classes.root}>

      {TxView}

    </div>
  );
}
