import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { format } from "timeago.js";
import ReactHtmlParser from "react-html-parser";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import Api from "../../helpers/Api";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Linkify from 'react-linkify';
import SmartCommentList from "../comment/smart.comment.list";
import LikeDialogBox from "./LikeDialogBox/LikeDialogBox";
import configObject from "../../config";
import AllFileViewerFeed from "../styled/CommonComponents/AllFiles.Viewer.Feed";
import MyPopOver from "../styled/CommonComponents/MyPopOver";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PostSkeleton from "../styled/CommonComponents/Post.Skeleton";
import { BiLike } from "react-icons/bi";
import { FaRegCommentDots } from "react-icons/fa";
import ShareIcon from '@material-ui/icons/Share';
import SocialShareBtn from "../styled/CommonComponents/Social.Share.Btn";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import CopyToClipboard from "react-copy-to-clipboard"
import LinkIcon from '@material-ui/icons/Link';
import DeleteIcon from '@material-ui/icons/Delete';
import { BsFillTagsFill } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  progressBox: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "10rem"
  },
  root: {
    border: "1.5px solid rgba(0, 0, 0, 0.12)",
    marginBottom: "0.8rem",
    borderRadius: "7px",
    boxShadow: "none",
    overflow: "hidden",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  avatar: {
    width: "45px",
    height: "45px",
    cursor: "pointer",
    border: "3px solid",
    borderColor: theme.palette.background.highlight
  },
  displayName: {
    fontSize: "15px",
    fontWeight: "600",
    lineHeight: 1,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  time: {
    fontSize: "12px",
    color: "#00000099",
    display: "flex",
    alignItems: "center"
  },
  title: {
    fontSize: "15px",
    fontWeight: "450"
  },
  description: {
    color: "#5d5c5cf0",
    height: "100px",
    overflow: "hidden",
    "& a": {
      textDecoration: "none"
    }
  },
  showAllDesc: {
    color: "#7f7f7f",
    "& a": {
      textDecoration: "none"
    }
  },
  headerDetails: {
    marginLeft: "10px",
    marginTop: "8px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  headerBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  headerLeftBox: {
    display: "flex",
    padding: "10px 16px",
    paddingBottom: "8px",
    alignItems: "center",
  },
  headerRightBox: {
    padding: "10px 15px",
  },
  infoBox: {
    padding: "0px 18px 10px",
    marginBottom: "20px"
  },
  seeMoreDiv: {
    backgroundColor: "white",
    color: theme.palette.primary.main,
    position: "relative",
    zIndex: "999",
    fontWeight: "500",
    boxShadow: "-20px -5px 20px 14px #ffffff",
    "& p": {
      margin: "0px"
    }
  },
  pollContainer: {
    margin: "20px 0",
    padding: "0 16px",
  },
  pollBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 10px",
    paddingBottom: "10px",
    border: "1px solid rgba(0, 0, 0, 0.08)",
    borderRadius: "7px",
  },
  pollHeader: {
    width: "100%",
    margin: "10px 0",
  },
  pollHeading: {
    color: "#111",
    textAlign: "left",
    fontWeight: "500",
    fontSize: "16px"
  },
  pollOption: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    padding: "10px 15px",
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    borderRadius: "5px",
    margin: "5px",
    cursor: "pointer",
  },
  pollInfo: {
    display: "flex",
    width: "100%",
  },
  actionCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px 10px",
    borderTop: "1px solid #F4F4F4",
    marginTop: "10px",
  },
  leftActionCont: {
    display: "flex",
    alignItems: "center",
  },
  itemCont: {
    display: "flex",
    alignItems: "center",
    marginRight: "8px",
    borderRight: "1px solid #F4F4F4",
    "& p": {
      fontSize: "15px",
      fontWeight: "450"
    }
  },
  tagCont: {
    display: "flex",
    alignItems: "center",
    paddingLeft: "20px",
  },
  onlyTagCont: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
  },
  unSelectedChip: {
    marginRight: "7px",
    marginBottom: "10px",
    marginTop: "2px",
    color: "black",
    fontSize: "13px",
    fontWeight: "400",
    padding: "2px 7px",
    borderRadius: "5px"
  },
}));

const colorArr = [
  "#FFE7E7",
  "#FFEDD8",
  "#FFEFEF",
  "#F8F6E9",
  "#F8E9E9",
  "#E9F8F0",
  "#E9F8F0"
]

function PostFileMemo({
  post: postReceived,
  setPostDict = () => { },
  commentLimit = -1,
  isLiked: isLikedReceived,
  isSaved: isSavedReceived,
  likeCount: likeCountReceived,
  commentCount: commentCountReceived,
  topText,
  guestView,
  community
}) {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();


  const { curPostId } = useParams();
  const { user } = useSelector((state) => state.auth);
  const profile = user?.profile;

  const [post, setPost] = useState(postReceived || location?.state?.post);
  const [isLiked, setIsLiked] = useState(isLikedReceived || post?.isLiked || false);
  const [isSaved, setIsSaved] = useState(isSavedReceived || post?.isSaved || false);
  const [likeCount, setLikeCount] = useState(likeCountReceived || post?.likeCount || 0);
  const [commentCount, setCommentCount] = useState(commentCountReceived || post?.commentCount || 0);
  const [likeLoading, setLikeLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openComment, setOpenComment] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [postLink, setPostLink] = useState(false);
  const [poll, setPoll] = useState(post?.poll);
  const [isPoll, setIsPoll] = useState(poll ? true : false);
  const [pollOptions, setPollOptions] = useState(poll?.options || []);
  const [pollOptionsVotePercentage, setPollOptionsVotePercentage] = useState([]);
  const [pollVotesCount, setPollVotesCount] = useState(0);
  const [pollVotes, setPollVotes] = useState(poll?.votes || {});
  const [canDelete, setCanDelete] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [showFull, setShowFull] = useState(true);
  const [shareLink, setShareLink] = useState("")

  const files = post?.files;
  const postUserId = post?.user?._id || post?.user;

  const classes = useStyles();

  useEffect(() => {
    let url = `${configObject?.BASE_URL}explore/forum/post/${post?._id}`
    setShareLink(url)
  }, [post])

  // social share
  const handleCloseDialog = () => {
    setIsOpen(false)
  }

  //delete post
  function handleDelete(event) {
    const postId = post?._id;
    if (!postId) return;
    Api.post("post/delete", { postId })
      .then((data) => {
        setPostDict((prev) => {
          const newDict = { ...prev };
          newDict[postId] = null;
          return newDict;
        });
      });
  }

  // like or remove like post (done)
  async function createLike() {
    if (!likeLoading) {
      setLikeLoading(true);
      let isLikedLocal;

      if (isLiked) {
        isLikedLocal = true;
        setLikeCount((prev) => prev - 1);
        setIsLiked(false)
      } else {
        isLikedLocal = false;
        setLikeCount((prev) => prev + 1);
        setIsLiked(true)
      }

      const like = await Api.post("like/like", {
        parent: post?._id,
        parentModelName: "Post",
        user: user?._id,
        profile: profile,
        isLikedLocal,
        userName: user?.displayName
      });

      if (like.data) {
        console.log("successfull")
      } else {
        console.log("fail")
      }
      setLikeLoading(false);
    }
  }

  // save post (done)
  async function createSave() {
    setIsSaved((prev) => !prev);
    const obj = {
      parent: post?._id,
      parentModelName: "Post",
      user: user?._id,
      profile: profile,
    }
    const save = await Api.post("save/create", obj);

    if (save.save) {
      setIsSaved(true);
    } else {
      setIsSaved(false);
    }
  }

  // vote for poll
  async function voteForPoll(idx) {
    if (!isPoll) return;
    if (poll?.expireAt && new Date(poll?.expireAt) < Date.now()) return;
    const pollId = poll?._id;
    const pollRes = await Api.post("/poll/vote", {
      pollId,
      option: `${idx}`,
      profile,
    });

    setPoll(pollRes.poll);
  }

  // get post for single view
  useEffect(() => {
    async function getPost() {
      setPostLink(curPostId ? true : false);
      if (!post && curPostId) {
        const res = await Api.post("post/get", {
          _id: curPostId,
          userProfile: profile,
        });
        setPost(res);
        setPoll(res?.poll);
        setIsLiked(res?.isLiked || false);
        setLikeCount(res?.likeCount || 0);
      }
    }

    if (curPostId) {
      getPost();
    }
  }, [curPostId]);

  // set post data
  useEffect(() => {
    setPost(postReceived || location?.state?.post);
  }, [postReceived || location?.state?.post]);


  useEffect(() => {
    function calcPollPercentage() {
      if (!isPoll) return;
      const pollPercent = [...pollOptions];
      const votes = [...pollOptions];
      votes.fill(0);
      pollPercent.fill(0);

      for (let [key, value] of Object.entries(pollVotes)) {
        votes[value * 1]++;
      }

      const totalVotes = votes.reduce(
        (previousValue, currentValue) => previousValue + currentValue,
        0
      );

      if (totalVotes > 0)
        votes.forEach((el, idx) => {
          pollPercent[idx] = parseFloat(
            Number.parseFloat((el / totalVotes) * 100).toFixed(2)
          );
        });

      setPollVotesCount(totalVotes);
      setPollOptionsVotePercentage(pollPercent);
    }

    calcPollPercentage();
  }, [pollOptions, pollVotes]);

  // initialize poll data
  useEffect(() => {
    setIsPoll(poll ? true : false);
    setPollOptions(poll?.options || []);
    setPollVotes(poll?.votes || {});
  }, [poll]);

  // initialize usestate with post data
  useEffect(() => {
    setIsPoll(post?.poll ? true : false);
    setPoll(post?.poll);
    setIsSaved(post?.isSaved || false);
    setIsLiked(post?.isLiked || false);
    setLikeCount(post?.likeCount || 0);
    setCommentCount(post?.commentCount || 0);
    if (post?.description) {
      if (post?.description.length < 350) {
        setShowFull(true)
      } else {
        setShowFull(false)
      }
    }
  }, [post]);

  // check is user can delete the post
  useEffect(() => {
    if (community) {
      const canDeleteTemp = post?.profile?._id === profile || postUserId === user?._id || community?.userProfile?._id === profile || community?.user?._id === user?._id;
      setCanDelete(canDeleteTemp);
    } else if (user?._id) {
      const canDeleteTemp = post?.profile?._id === profile || postUserId === user?._id
      setCanDelete(canDeleteTemp);
    } else {
      setCanDelete(false);
    }
  }, [post?.profile?._id, postUserId, profile, user?._id]);

  // add comment count
  const commentAdded = () => {
    setCommentCount((prev) => prev + 1)
  }

  console.log(post)

  return (
    <>
      <Paper className={classes.root}>
        {post ? (
          <>
            {/********** Likes List Dialog Box ***************/}
            {open && (<LikeDialogBox postId={post._id} open={open} setOpen={setOpen} />)}
            <div>
              {topText ? topText : null}

              {/******************* Header Box ********************/}
              <div className={classes.headerBox}>
                <div className={classes.headerLeftBox}>
                  <Avatar
                    imgProps={{ referrerPolicy: "no-referrer" }}
                    aria-label="recipe"
                    className={classes.avatar}
                    src={post?.profile?.parent?.displayPicture?.url}
                    onClick={() => { history.push(`/profile/view/${post?.profile?._id}`) }}
                  ></Avatar>
                  <div className={classes.headerDetails}>
                    <Typography variant="h6"
                      className={classes.displayName}
                      onClick={() => { history.push(`/profile/view/${post?.profile?._id}`) }}
                    >
                      {post?.profile?.parent?.displayName}
                    </Typography>
                    <Typography variant="subtitle1" className={classes.time}>{format(post?.createdAt)}</Typography>
                  </div>
                </div>
                <div className={classes.headerRightBox}>
                  <MyPopOver
                    closeOnClick={true}
                    appearContent={<IconButton><MoreHorizIcon /></IconButton>}
                    showContent={<div>
                      <MenuList
                        autoFocusItem={openDropdown}
                        id="menu-list-grow"
                        className={classes.menuList}
                      >
                        {canDelete && (
                          <MenuItem
                            className={classes.menuItem}
                            onClick={handleDelete}
                          >
                            <DeleteIcon style={{ marginRight: "7px" }} />  Delete Post
                          </MenuItem>
                        )}

                        <CopyToClipboard text={shareLink} >
                          <MenuItem
                            className={classes.menuItem}
                          >
                            <LinkIcon style={{ marginRight: "7px" }} />  Copy Post Link
                          </MenuItem>
                        </CopyToClipboard>
                      </MenuList>
                    </div>}
                  />
                </div>
              </div>

              {post?.tagStrs && post?.tagStrs.length > 0 && (<div className={classes.tagCont} >
                <div className={classes.onlyTagCont} >
                  {post?.tagStrs && post.tagStrs.map((tag, i) => (
                    <div
                      className={classes.unSelectedChip}
                      style={{ backgroundColor: colorArr[i % 7] }}
                    >
                      {tag}
                    </div>
                  ))}
                </div>
              </div>)}

              <div className={classes.infoBox}>
                {/******************* Title Box ********************/}
                <Typography variant="h5" component="h2" className={classes.title}>
                  {post?.title}
                </Typography>

                {/******************* Description Box ********************/}
                <Typography
                  variant="body2"
                  component="p"
                  className={showFull ? classes.showAllDesc : classes.description}
                >
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                        {decoratedText}
                      </a>
                    )}
                  >
                    {ReactHtmlParser(post?.description)}
                  </Linkify>
                </Typography>
                {post?.description && post?.description.length > 350 && (
                  <div className={classes.seeMoreDiv} style={showFull ? { boxShadow: "none" } : {}}  >
                    <p
                      style={{
                        marginBottom: "-15px",
                        marginTop: "0px",
                        width: "100px",
                        cursor: "pointer"
                      }}
                      onClick={() => { setShowFull(!showFull) }}
                    > {showFull ? "Show Less" : "Read More"}</p>
                  </div>
                )}
              </div>

              {/************ Files Box **********/}
              {files && files.length > 0 ? (
                <AllFileViewerFeed
                  picVideoViewerHeight={"400px"}
                  picVideoViewerHeightSmall={"300px"}
                  picVideoViewerWidth={"100%"}
                  files={files ? files : []}
                />
              ) : null}

              {/************** Poll Box *************/}
              {isPoll ? (
                <div className={classes.pollContainer}>
                  <div className={classes.pollBox}>
                    <div className={classes.pollHeader}>
                      <Typography variant="h5" className={classes.pollHeading}>Poll</Typography>
                    </div>
                    {pollOptions.map((el, idx) => (
                      <div
                        key={`${idx}-${el}`}
                        style={{
                          background: `linear-gradient(to right, ${pollVotes[profile] * 1 === idx
                            ? "rgb(113,201,248)"
                            : "#dadada"
                            } 0 ${pollOptionsVotePercentage[idx]}%, white ${pollOptionsVotePercentage[idx]
                            }% 100%)`,
                        }}
                        className={classes.pollOption}
                        onClick={() => voteForPoll(idx)}
                      >
                        <Typography style={{ cursor: "pointer", fontWeight: pollVotes[profile] * 1 === idx ? "600" : "auto" }}>
                          {el}
                        </Typography>
                        <Typography style={{ cursor: "pointer", fontWeight: pollVotes[profile] * 1 === idx ? "600" : "auto" }}>
                          {pollOptionsVotePercentage[idx]}%
                        </Typography>
                      </div>
                    ))}
                    <div className={classes.pollInfo}>
                      <Typography variant="caption" display="block">{`${pollVotesCount.toLocaleString()} Votes`}</Typography>
                      <Typography variant="caption" display="block" style={{ margin: "0 3px" }}>|</Typography>
                      <Typography variant="caption" display="block">
                        {poll?.expireAt && new Date(poll?.expireAt) > Date.now() ? `${format(poll?.expireAt)} Left` : "Poll Ended"}
                      </Typography>
                    </div>
                  </div>
                </div>
              ) : (
                <div />
              )}
            </div>

            {/* actions on the post */}
            {user?._id && (
              <div className={classes.actionCont} >
                <div className={classes.leftActionCont} >
                  <div className={classes.itemCont} >
                    <IconButton onClick={createLike} >
                      <BiLike style={isLiked ? { color: theme.palette.primary.main, fontSize: "25px" } : { fontSize: "25px" }} />
                    </IconButton>
                    <IconButton style={{ marginRight: "10px" }} onClick={() => { if (likeCount) { setOpen(true) } }} disabled={likeCount ? false : true} >
                      <p style={{ cursor: "pointer" }} >{likeCount}</p>
                    </IconButton>
                  </div>
                  <div className={classes.itemCont} >
                    <IconButton onClick={() => { setOpenComment(!openComment) }} >
                      <FaRegCommentDots style={openComment ? { color: theme.palette.primary.main, fontSize: "22px" } : { fontSize: "22px" }} />
                    </IconButton>
                    <IconButton disabled style={{ marginRight: "10px" }} >
                      <p>{commentCount}</p>
                    </IconButton>
                  </div>
                  <div className={classes.itemCont} >
                    <IconButton onClick={() => { createSave() }} style={{ marginRight: "10px" }} >
                      <BookmarkIcon style={isSaved ? { color: theme.palette.primary.main, fontSize: "24px" } : { fontSize: "24px" }} />
                    </IconButton>
                  </div>
                </div>
                <div className={classes.itemCont}
                  style={{
                    marginRight: "0px",
                    borderRight: "none",
                    paddingLeft: "8px",
                    borderLeft: "1px solid #F4F4F4"
                  }}
                >
                  <IconButton onClick={() => { setIsOpen(true) }} >
                    <ShareIcon style={{ fontSize: "21px" }} />
                  </IconButton>
                </div>
              </div>
            )}

            {/**************** Smart Comments ******************/}
            {openComment && (<SmartCommentList
              limit={commentLimit}
              commentAdded={commentAdded}
              parent={post?._id}
              sentProfile={user}
              guestView={guestView}
              parentModelName="Post"
              styleBody={{ marginTop: "0" }}
            />)}
          </>
        ) : (
          <div className={classes.progressBox}>
            <PostSkeleton />
          </div>
        )}
      </Paper>
      {/* Social media share */}
      <NormalDialog
        openDialog={isOpen}
        handleCloseShare={handleCloseDialog}
        pageTitle={"Share post on Social"}
        content={<div style={{ padding: "15px 40px" }} >
          <SocialShareBtn shareLink={`${configObject.BASE_URL}explore/forum/post/${post?._id}`} />
        </div>}
      />
    </>
  );
}

const PostFile = React.memo(PostFileMemo);
export default PostFile;
