import {
	alpha,
	Avatar,
	Button,
	makeStyles,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import useGetTemplates from "../issue/useGetTemplates";
import DoneIcon from "@material-ui/icons/Done";
import { Autocomplete } from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";

const useStyles = makeStyles((theme) => ({
	root: {
		width: 221,
		fontSize: 13,
	},
	button: {
		fontSize: 13,
		width: "100%",
		textAlign: "left",
		paddingBottom: 8,
		color: "#586069",
		fontWeight: 600,
		"&:hover,&:focus": {
			color: "#0366d6",
		},
		"& span": {
			width: "100%",
		},
		"& svg": {
			width: 16,
			height: 16,
		},
	},
	tag: {
		marginTop: 3,
		height: 20,
		padding: ".15em 4px",
		fontWeight: 600,
		lineHeight: "15px",
		borderRadius: 2,
	},
	popper: {
		border: "1px solid rgba(27,31,35,.15)",
		boxShadow: "0 3px 12px rgba(27,31,35,.15)",
		borderRadius: 3,
		width: 300,
		zIndex: 1,
		fontSize: 13,
		color: "#586069",
		backgroundColor: "#f6f8fa",
	},
	header: {
		borderBottom: "1px solid #e1e4e8",
		padding: "8px 10px",
		fontWeight: 600,
	},
	inputBase: {
		padding: 10,
		width: "100%",
		borderBottom: "1px solid #dfe2e5",
		"& input": {
			borderRadius: 4,
			backgroundColor: theme.palette.common.white,
			padding: 8,
			transition: theme.transitions.create([
				"border-color",
				"box-shadow",
			]),
			border: "1px solid #ced4da",
			fontSize: 14,
			"&:focus": {
				boxShadow: `${alpha(
					theme.palette.primary.main,
					0.25
				)} 0 0 0 0.2rem`,
				borderColor: theme.palette.primary.main,
			},
		},
	},
	paper: {
		boxShadow: "none",
		margin: 0,
		color: "#586069",
		fontSize: 13,
	},
	option: {
		minHeight: "auto",
		alignItems: "flex-start",
		padding: 8,
		'&[aria-selected="true"]': {
			backgroundColor: "transparent",
		},
		'&[data-focus="true"]': {
			backgroundColor: theme.palette.action.hover,
		},
	},
	popperDisablePortal: {
		position: "relative",
	},
	iconSelected: {
		width: 17,
		height: 17,
		marginRight: 5,
		marginLeft: -2,
	},
	color: {
		width: 14,
		height: 14,
		flexShrink: 0,
		borderRadius: 3,
		marginRight: 8,
		marginTop: 2,
	},
	text: {
		flexGrow: 1,
	},
	close: {
		opacity: 0.6,
		width: 18,
		height: 18,
	},
}));

function IssueTemplateSelect(props) {
	const { template, setTemplate } = props;
	const history = useHistory();
	const { teamId } = useParams();
	const classes = useStyles();
	const { teamDictionary } = useSelector((state) => state.team);
	const profileId = teamDictionary?.[teamId]?.parent?.profile;
	// const [template, setTemplate] = useState("");
	const [options, setOptions] = useState([]);
	const {
		templateIds,
		setTemplateIds,
		templateDictionary,
		setTemplateDictionary,
	} = useGetTemplates(profileId);
	console.log("options = ", options, templateDictionary);
	useEffect(() => {
		// removing tempated with no title
		let temp = Object.keys(templateDictionary).filter((id) => {
			// let obj = teamDictionary["63fde0cab6d0d325bc69dbd3"];
			// console.log("check = ", templateDictionary[id], typeof id, `'${id}'`);
			return Boolean(templateDictionary[id]?.title);
		});
		setOptions([...temp]);
	}, [templateDictionary]);

	return (
		<div>
			<div
				style={{ width: "100%", opacity: "0.5", margin: "1rem 0 2px" }}
			>
				<div style={{ display: "flex", gap: "8px" }}>
					<Typography>Issue Template</Typography>{" "}
					<Tooltip title="The tenants will be creating issues using this template only">
						<InfoIcon style={{ color: "dimgray" }} />
					</Tooltip>
				</div>
			</div>
			<div style={{ display: "flex", justifyContent: "space-around" }}>
				<Autocomplete
					id="combo-box-demo"
					options={options}
					value={template}
					onChange={(event, newValue) => {
						setTemplate(newValue);
					}}
					getOptionLabel={(option) =>
						templateDictionary[option]?.title || ""
					}
					inputValue={templateDictionary[template]}
					noOptionsText="No Templates available"
					renderOption={(option, { selected }) => (
						<React.Fragment>
							<DoneIcon
								className={classes.iconSelected}
								style={{
									visibility: selected ? "visible" : "hidden",
								}}
							/>
							<div className={classes.text}>
								{templateDictionary[option].title}
							</div>
						</React.Fragment>
					)}
					style={{ width: "80%" }}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Select a Template"
							variant="outlined"
						/>
					)}
				/>
				<Button
					// style={{ margin: "5px" }}
					color="primary"
					variant="contained"
					onClick={() =>
						history.push(
							`/issues/profile/${teamDictionary[teamId]?.parent?.profile}`
						)
					}
				>
					Manage
				</Button>
			</div>
		</div>
	);
}

export default IssueTemplateSelect;
