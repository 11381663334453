import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            "& h3": {
                fontSize: "16px"
            },
            "& p": {
                fontSize: "14px"
            }
        },
        [theme.breakpoints.down("xs")]: {
            "& h3": {
                fontSize: "14px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "12px",
                fontWeight: "400"
            }
        },
    }
}));

export default function QuestionsAndAns({ faqArr }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            {faqArr.map((qaa) => (
                <Accordion style={{ backgroundColor: "rgba(0,0,0,0)", boxShadow: "none" }} >
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <h3>{qaa?.question}</h3>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>{qaa?.ans}</p>
                    </AccordionDetails>
                </Accordion>
            ))}
        </div>
    );
}