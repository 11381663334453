import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { Avatar } from "@material-ui/core";
import LessText from "../../styled/CommonComponents/LessText";
import { format } from "timeago.js";
import { AvatarGroup } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    tbBodyCell: {
        border: "none",
        padding: "8px 5px"
    },
    timeSty: {
        fontSize: "10px",
        fontWeight: "500",
        color: "gray"
    },
    statusCont: {
        fontSize: "11px",
        padding: "1px 3px",
        borderRadius: "3px",
        backgroundColor: "#fde5d7",
        color: "orange"
    },
    avatarGroupSty: {
        maxWidth: "100px",
        "& .MuiAvatar-root": {
            width: "30px",
            height: "30px"
        }
    }
}));

const ProjectManagementSingleCard = ({ issue, setSelectedIssue, setOpen, index }) => {
    const classes = useStyles();
    const [currentState, setCurrentState] = useState({})

    useEffect(() => {
        if (issue?.status) {
            let curStateId = issue?.status;
            let locStatusArr = issue?.template?.pipeline || []

            locStatusArr.map((status) => {
                if (curStateId === status?._id) {
                    setCurrentState(status)
                }
            })
        } else if (issue?.template?.startState) {
            let curStateId = issue?.template?.startState;
            let locStatusArr = issue?.template?.pipeline || []

            locStatusArr.map((status) => {
                if (curStateId === status?._id) {
                    setCurrentState(status)
                }
            })

        } else if (issue?.template?.pipeline && issue?.template?.pipeline.length > 0) {
            setCurrentState(issue?.template?.pipeline[0])
        }
    }, [issue])

    return (
        <TableRow
            key={index}
            onClick={() => {
                setSelectedIssue(issue);
                setOpen(true);
            }}
            style={{ cursor: "pointer" }}
        >
            <TableCell align="left" className={classes.tbBodyCell} style={{ paddingLeft: "5px" }} >
                <div style={{ display: "flex", alignItems: "center" }} >
                    <Avatar src={issue?.user?.displayPicture?.url} style={{ width: "30px", height: "30px" }} />
                    <div style={{ marginLeft: "5px" }} >
                        <p style={{ fontSize: "13px", color: "#333333", fontWeight: "510", textTransform: "capitalize" }} >
                            <LessText
                                limit={13}
                                string={issue.title}
                            />
                        </p>
                        <p className={classes.timeSty} >{format(issue?.createdAt)}</p>
                    </div>
                </div>
            </TableCell>
            <TableCell align="center" className={classes.tbBodyCell} style={{ padding: "0px" }} >
                <p style={{ color: "#4F4F4F", fontSize: "13px", fontWeight: "510", textTransform: "capitalize" }} >
                    <LessText
                        limit={11}
                        string={issue?.template?.title}
                    />
                </p>
                {currentState?.text && (<span className={classes.statusCont} style={{ color: currentState?.color }} >
                    {currentState?.text}
                </span>)}
            </TableCell>
            <TableCell align="right" className={classes.tbBodyCell} style={{ paddingRight: "5px" }} >
                <div style={{ display: "flex", justifyContent: "flex-end" }} >
                    <AvatarGroup max={3} className={classes.avatarGroupSty} >
                        {issue?.shared && issue?.shared.length > 0 && issue?.shared.map((participant, idx) => (
                            <Avatar
                                key={idx}
                                alt={participant?.parent?.displayName}
                                src={participant?.parent?.displayPicture?.url}
                            />
                        ))}
                    </AvatarGroup>
                </div>
            </TableCell>
        </TableRow>
    );
};
export default ProjectManagementSingleCard;