import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Organizations from "../organization/organizations";
import { Snackbar, useMediaQuery } from "@material-ui/core";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import AnalysisCardSvg from "../../Assets/analysisCard.svg";
import ProjectManagementCardSvg from "../../Assets/proj_mgmt_logo.svg";
import DocumentCardSvg from "../../Assets/Doc_logo.svg";
import AccountingCardSvg from "../../Assets/accounting_logo.svg";
import propertyManagement from "../../Assets/propertyManagement.svg";
import { Alert } from "@material-ui/lab";
import Api from "../../helpers/Api";
import AnalysisCard from "./cards/AnalysisCard";
import DocumentCard from "./cards/DocumentCard";
import PaymentsCard from "./cards/PaymentsCard";
import ProjectManagementCard from "./cards/ProjectManagementCard";
import PropertyManagementCard from "./cards/PropertyManagementCard";

const useStyles = makeStyles((theme) => ({
	mainContForDesktop: {
		width: "100%",
		display: "flex",
		justifyContent: "space-between",
		padding: "15px",
		[theme.breakpoints.down("md")]: {
			flexDirection: "column",
		},
		[theme.breakpoints.down("sm")]: {
			padding: "5px",
		},
	},
	left_container: {
		width: "68%",
		[theme.breakpoints.down("md")]: {
			width: "100%",
		}
	},
	left_top: {
		width: "100%",
		display: "flex",
		justifyContent: "space-around",
		borderRadius: "10px",
		padding: "0px 0px 10px",
		marginBottom: "20px",
		[theme.breakpoints.down("xs")]: {
			flexWrap: "wrap",
		}
	},
	left_top_card: {
		width: "18%",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		cursor: "pointer",
		[theme.breakpoints.down("xs")]: {
			width: "49%",
		}
	},
	left_top_img: {
		width: "55%",
		height: "auto"
	},
	left_top_header: {
		color: "#00345D",
		fontWeight: "500",
		textAlign: "center",
	},
	left_cards: {
		display: "flex",
		flexWrap: "wrap",
		justifyContent: "space-between",
	},
	right_container: {
		width: "30%",
		[theme.breakpoints.down("md")]: {
			width: "100%",
		}
	},
	orgInvtCont: {
		marginBottom: "20px",
		padding: "10px",
		display: "flex",
		alignItems: "center",
		borderRadius: "7px",
		justifyContent: "center"
	}
}));

const Dashboard = (props) => {
	const classes = useStyles();
	const theme = useTheme();
	const dispatch = useDispatch();
	const history = useHistory();

	const { user } = useSelector((state) => state.auth);
	let { invitations } = useSelector((state) => state.team);

	const [openSignSucess, setOpenSignSuccess] = useState(false);
	const [docs, setDocs] = useState([]);
	const [issues, setIssues] = useState([]);
	const [transactions, setTransactions] = useState([]);
	const [chartAccounts, setChartAccounts] = useState([]);
	const [reports, setReports] = useState([]);
	const [projectPortfolios, setProjectPortfolios] = useState([]);
	const [loader, setLoader] = useState(false);

	const isMobile = useMediaQuery(theme.breakpoints.down("md"));
	const isExMobile = useMediaQuery(theme.breakpoints.down("xs"));

	const handleCloseSignSuccess = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		setOpenSignSuccess(false);
	};

	const getData = async () => {
		setLoader(true);
		const res = await Api.post("profile/getData", {
			userId: user?._id,
			profileId: user?.profile,
			walletId: user?.wallet,
			isAdmin: true,
		});
		if (res?.data) {
			const data = res.data;

			console.log(data);
			setProjectPortfolios(data?.projectPortfolios || [])
			setDocs(data?.docs || []);
			setIssues(data?.issues || []);
			setReports(data?.analysisReports || []);
			setChartAccounts(data?.chartAccounts || []);
			setTransactions(data?.transactions || []);
			console.log(data);
		}
		setLoader(false);
	};

	useEffect(() => {
		getData();
	}, [invitations]);

	return (
		<div className={classes.mainContForDesktop}>
			<Snackbar
				open={openSignSucess}
				autoHideDuration={6000}
				onClose={handleCloseSignSuccess}
			>
				<Alert
					onClose={handleCloseSignSuccess}
					severity="success"
					sx={{ width: "100%" }}
				>
					Signature Provider Integration Success!
				</Alert>
			</Snackbar>

			<div className={classes.left_container}>
				<Paper className={classes.left_top}>
					<div
						className={classes.left_top_card}
						onClick={() => history.push("/investment/analysis/new")}
					>
						<img className={classes.left_top_img} src={AnalysisCardSvg} />
						<p className={classes.left_top_header}>Investment <br /> Analysis</p>
					</div>
					<div
						className={classes.left_top_card}
						onClick={() => history.push("/issues/profile/" + user?.profile)}
					>
						<img className={classes.left_top_img} src={ProjectManagementCardSvg} />
						<p className={classes.left_top_header}>Project <br /> Management</p>
					</div>
					<div
						className={classes.left_top_card}
						onClick={() => history.push("/projectportfolio/" + user?.profile)}
					>
						<img className={classes.left_top_img} src={propertyManagement} />
						<p className={classes.left_top_header}>Property <br /> Management</p>
					</div>
					<div
						className={classes.left_top_card}
						onClick={() => {
							if (user?.wallet) {
								history.push(`/finance/${user?.wallet}/bank-accounts`);
							}
						}}
					>
						<img className={classes.left_top_img} src={AccountingCardSvg} />
						<p className={classes.left_top_header}>Accounting <br /> & Payments</p>
					</div>
					<div
						className={classes.left_top_card}
						onClick={() => history.push("/docs/" + user?.profile)}
					>
						<img className={classes.left_top_img} src={DocumentCardSvg} />
						<p className={classes.left_top_header}>Document <br /> Management</p>
					</div>
				</Paper>

				{isExMobile && (
					<a href="#orgInviteSection" style={{ textDecoration: "none" }} >
						<Paper elevation={1} className={classes.orgInvtCont} >
							<h3 className={classes.left_top_header} >Organizations & Invites</h3>
						</Paper>
					</a>
				)}

				<div className={classes.left_cards}>
					<AnalysisCard
						reports={reports}
						loader={loader}
					/>
					<ProjectManagementCard
						issues={issues}
						profileId={user?.profile}
						loader={loader}
					/>
					<PropertyManagementCard
						profileId={user?.profile}
						projectPortfolios={projectPortfolios}
						setProjectPortfolios={setProjectPortfolios}
						loader={loader}
					/>
					<PaymentsCard
						chartAccounts={chartAccounts}
						transactions={transactions}
						walletId={user?.wallet}
						loader={loader}
					/>

					<DocumentCard
						docs={docs}
						profileId={user?.profile}
						loader={loader}
					/>
				</div>
			</div>

			<section id="orgInviteSection" className={classes.right_container}>
				<Organizations />
			</section>
		</div>
	);
};
export default Dashboard;
