import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        marginBottom: "20px",
        borderRadius: "10px",
        overflow: "hidden",
    },
    topbar: {
        width: "100%",
        display: "flex",
        padding: "8px 15px",
        backgroundColor: "#d1e8f5",
        alignItems: "center",
        justifyContent: "space-between",
    },
    bottomCont: {
        padding: "15px"
    },
    topInfoCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column"
    },
    dataCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
    },
    subCont: {
        width: "33%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        [theme.breakpoints.down("xs")]: {
            width: "50%",
            marginBottom: "10px"
        }
    }
}));

export default function TemplateTxSkeleton(props) {
    const classes = useStyles();

    return (
        <Paper elevation={2} className={classes.mainCont} >
            <div className={classes.topbar} >
                <div className={classes.topInfoCont} >
                    <Skeleton style={{ width: "90px", height: "20px", marginBottom: "5px" }} variant="rect" />
                    <Skeleton style={{ width: "70px", height: "17px" }} variant="rect" />
                </div>
                <div className={classes.topInfoCont} >
                    <Skeleton style={{ width: "90px", height: "20px", marginBottom: "5px" }} variant="rect" />
                    <Skeleton style={{ width: "70px", height: "17px" }} variant="rect" />
                </div>
            </div>
            <div className={classes.bottomCont} >
                <div className={classes.dataCont} >
                    <div className={classes.subCont} >
                        <Skeleton style={{ width: "70%", height: "20px", marginBottom: "5px" }} variant="rect" />
                        <Skeleton style={{ width: "60%", height: "17px" }} variant="rect" />
                    </div>
                    <div className={classes.subCont} >
                        <Skeleton style={{ width: "70%", height: "20px", marginBottom: "5px" }} variant="rect" />
                        <Skeleton style={{ width: "60%", height: "17px" }} variant="rect" />
                    </div>
                    <div className={classes.subCont} >
                        <Skeleton style={{ width: "70%", height: "20px", marginBottom: "5px" }} variant="rect" />
                        <Skeleton style={{ width: "60%", height: "17px" }} variant="rect" />
                    </div>
                </div>
            </div>
        </Paper>
    );
}