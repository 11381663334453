import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TeamMembers from "./team.members";
import AddMember from "./NewAddMember";
import Api from "../../../helpers/Api";
import ManageInvites from "./manage.invites";
import { Tab, Tabs, Paper } from "@material-ui/core";
import InsertInvitationIcon from "@material-ui/icons/InsertInvitation";
import PeopleIcon from "@material-ui/icons/People";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  tabs: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
    marginBottom: "15px"
  },
}));

const MemberSetting = (props) => {
  const classes = useStyles();
  const { teamId } = props;
  const dispatch = useDispatch();

  const [view, setView] = useState("Members");
  const [value, setValue] = useState("Members");
  const [invites, setInvites] = useState([]);
  const [invitesLoading, setInvitesLoading] = useState(true);
  const [members, setMembers] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [membersLoading, setMembersLoading] = useState(true);

  useEffect(() => {
    getProfieInfo();
  }, [teamId]);

  const getProfieInfo = async () => {
    setMembersLoading(true);
    const profiles = await Api.post("team/getProfiles", { teamIds: [teamId] });

    setMembers(profiles?.data[0]?.participants || []);
    setPermissions(profiles?.data[0]?.permissions || {});
    setMembersLoading(false);
  };

  useEffect(() => {
    getInvites()
  }, []);

  const getInvites = async () => {
    setInvitesLoading(true);
    await Api.post("invite/getTeamInvites", { team: teamId })
      .then((res) => {
        const invites = res.invites;
        setInvites(invites);
      })
      .catch((err) => {
        console.log(err)
      })
    setInvitesLoading(false);
  }

  const handleChange = (event, newValue) => {
    setView(newValue);
    setValue(newValue);
  };

  let View = (
    <AddMember
      teamId={teamId}
      invites={invites}
      setInvites={setInvites}
      invitesLoading={invitesLoading}
    />
  );

  switch (view) {
    case "Add People":
      View = (
        <AddMember
          teamId={teamId}
          invites={invites}
          setInvites={setInvites}
          invitesLoading={invitesLoading}
        />
      );
      break;

    case "Invites":
      View = (
        <ManageInvites
          teamId={teamId}
          invites={invites}
          invitesLoading={invitesLoading}
        />
      );
      break;

    case "Members":
      View = (
        <TeamMembers
          teamId={teamId}
          members={members}
          permissions={permissions}
          setMembers={setMembers}
          setPermissions={setPermissions}
          setLoading={setMembersLoading}
          loading={membersLoading}
        />
      );
      break;

    default:
      break;
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.tabs} elevation={2} >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          centered
        >
          <Tab
            icon={<PeopleIcon />}
            label="Members"
            value={"Members"}
          />
          <Tab
            icon={<PersonAddIcon />}
            label="Add People"
            value={"Add People"}
          />
          <Tab
            icon={<InsertInvitationIcon />}
            label="Invites"
            value={"Invites"}
          />
        </Tabs>
      </Paper>
      {View}
    </div>
  );
};
export default MemberSetting;