import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import Collapse from '@material-ui/core/Collapse';
import QuestionsAndAns from "./QuestionsAndAns";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "white",
        padding: "100px 180px 30px",
        [theme.breakpoints.down("lg")]: {
            padding: "100px 110px 30px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "80px 40px 30px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "60px 20px 20px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "40px 20px 20px",
        },
    },
    headerCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "70px",
        "& h3": {
            fontSize: "35px",
            fontWeight: "800",
            textAlign: "center",
            marginBottom: "15px"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "50px",
            "& h3": {
                fontSize: "28px",
                fontWeight: "700",
                marginBottom: "12px"
            },
        }
    },
    btnsCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexWrap: "wrap",
        marginBottom: "60px",
        "& .MuiButton-root": {
            width: "120px",
            margin: "0px 15px 15px"
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontWeight: "700",
            fontSize: "16px"
        },
        [theme.breakpoints.down("md")]: {
            "& .MuiButton-root": {
                width: "100px",
                margin: "0px",
                marginBottom: "12px"
            },
            "& .MuiButton-label": {
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: "14px"
            },
        },
        [theme.breakpoints.down("sm")]: {
            justifyContent: "space-around",
        }
    },
    faqCont: {
        width: "100%",
        padding: "20px 100px",
        backgroundColor: "#F8F8F6",
        [theme.breakpoints.down("sm")]: {
            padding: "20px 30px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "20px 10px",
        },
    }
}));

export default function FAQ({ fAQRef }) {
    const classes = useStyles();

    const [general, setGeneral] = useState(true)
    const [apps, setApps] = useState(false)
    const [projects, setProjects] = useState(false)
    const [network, setNetwork] = useState(false)
    const [pricing, setPricing] = useState(false)

    return (
        <section className={classes.root} ref={fAQRef} id="fAQ" >
            <div className={classes.headerCont} >
                <h3 data-aos={"fade-up"} >Find the Answer you Needs</h3>
            </div>

            <div className={classes.btnsCont} data-aos={"fade-up"} >
                <Button
                    variant={general ? "contained" : ""}
                    color={general ? "primary" : ""}
                    className={classes.btnSty}
                    onClick={() => {
                        setGeneral(true)
                        setApps(false)
                        setProjects(false)
                        setNetwork(false)
                        setPricing(false)
                    }}
                >
                    <span style={general ? { color: "white" } : { color: "#7E7E7E" }} >General</span>
                </Button>
                <Button
                    variant={apps ? "contained" : ""}
                    color={apps ? "primary" : ""}
                    onClick={() => {
                        setGeneral(false)
                        setProjects(false)
                        setNetwork(false)
                        setPricing(false)
                        setApps(true)
                    }}
                    className={classes.btnSty}
                >
                    <span style={apps ? { color: "white" } : { color: "#7E7E7E" }} >Apps</span>
                </Button>
                <Button
                    variant={projects ? "contained" : ""}
                    color={projects ? "primary" : ""}
                    onClick={() => {
                        setGeneral(false)
                        setProjects(true)
                        setNetwork(false)
                        setPricing(false)
                        setApps(false)
                    }}
                    className={classes.btnSty}
                >
                    <span style={projects ? { color: "white" } : { color: "#7E7E7E" }} >Projects</span>
                </Button>
                <Button
                    variant={network ? "contained" : ""}
                    color={network ? "primary" : ""}
                    onClick={() => {
                        setGeneral(false)
                        setProjects(false)
                        setNetwork(true)
                        setPricing(false)
                        setApps(false)
                    }}
                    className={classes.btnSty}
                >
                    <span style={network ? { color: "white" } : { color: "#7E7E7E" }} >Network</span>
                </Button>
                <Button
                    variant={pricing ? "contained" : ""}
                    color={pricing ? "primary" : ""}
                    onClick={() => {
                        setGeneral(false)
                        setProjects(false)
                        setNetwork(false)
                        setPricing(true)
                        setApps(false)
                    }}
                    className={classes.btnSty}
                >
                    <span style={pricing ? { color: "white" } : { color: "#7E7E7E" }} >Pricing</span>
                </Button>
            </div>

            <div className={classes.faqCont} data-aos={"fade-up"} >
                <Collapse in={general} timeout={500} >
                    <QuestionsAndAns
                        faqArr={[
                            {
                                question: "What is ContractFlo?",
                                ans: "ContractFlo is a powerful real estate management software that offers a suite of SaaS tools to manage your properties throughout their lifecycle. With built-in networking features, it also enables easy collaboration with other real estate professionals on your projects."
                            },
                            {
                                question: "How do I get started with Contractflo?",
                                ans: "Simply sign up using your email address to create a Contractflo account. Once you've created your profile, you can start creating projects and managing your real estate work right away."
                            },
                            {
                                question: "What does the ContractFlo dashboard do?",
                                ans: "The ContractFlo dashboard provides an overview of all the activities within your projects. It displays different apps and their respective tasks in a clear and organized manner, giving you a bird's eye view of your real estate work."
                            },
                            {
                                question: "What are Organizations in the ContractFlo dashboard?",
                                ans: "In the ContractFlo dashboard, Organizations are a feature that allows you to create a group and add team members to work together on projects. It's a great way to collaborate and streamline communication within your team."
                            },
                            {
                                question: "What are invites in the ContractFlo dashboard?",
                                ans: `The "invites" section in the ContractFlo dashboard displays all the invitations you've received from other projects or organizations using the platform. You can also use this section to send invitations to others to join your own projects or organizations.`
                            }
                        ]}
                    />
                </Collapse>

                <Collapse in={apps} timeout={500} >
                    <QuestionsAndAns
                        faqArr={[
                            {
                                question: "What are the different software tools available on Contractflo?",
                                ans: "Contractflo offers a suite of real estate Saas tools to manage your projects from end to end. Our current apps include investment analysis, property management, accounting and payments, project management, and documents. We are always working to improve our platform and may add more apps in the future based on our users' needs."
                            },
                            {
                                question: "What is the investment analysis app and how does it work?",
                                ans: "The investment analysis app on ContractFlo allows you to assess the profitability of various types of real estate investments, including rental properties (including AirBnB), BRRRR, and Flip projects. To get started, you can add a property manually or import it using the Zillow URL. From there, you can input some key assumptions to calculate metrics such as Cash Flow, ROI, Cap Rate, and more to help you make informed investment decisions."
                            },
                            {
                                question: "What is the property management app and how does it work?",
                                ans: "The property management app on ContractFlo enables you to efficiently manage your properties by adding units, tenants, and lease details. Simply create a project for each property and add team members with their respective roles: owner, admin, or tenant. You can then add unit details and lease information, such as rent amount, lease start and end dates, and security deposit. The system will generate invoices for rent payments and receipts once payments are made. Tenants can also report issues related to property maintenance and repairs through the app."
                            },
                            {
                                question: "What is the accounting and payment app and how does it work?",
                                ans: "The accounting and payments app allows you to track all income and expenses related to your project, including rent, repairs, and maintenance costs. You can connect your bank account and credit cards to automatically import transactions, or enter them manually. The app also generates invoices and receipts for rent payments, which can be sent to tenants and automatically recorded in the system. It also allows you to pay vendors and contractors directly from the app, keeping track of all expenses and payments in one place. Additionally, the app can generate financial reports such as profit and loss statements and balance sheets, giving you a clear picture of your project's financial health."
                            },
                            {
                                question: "How can I customize my accounting system?",
                                ans: "You have the ability to customize your accounting system by creating a chart of accounts that suits your accountant's requirements. You are given complete power and flexibility in this regard. You can update the chart account values to match those in your existing accounting system by making a journal entry."
                            },
                            {
                                question: "What is the Project Management app and how does it work?",
                                ans: "The project management app is designed to help you efficiently manage all aspects of your real estate project, including construction, rehab, and repairs. You can create and track tasks, issues, milestones, and timelines, as well as assign them to different team members. This helps you stay organized and ensure that everyone is on the same page with regard to project progress and deadlines. The app also allows you to communicate and collaborate with your team members, and to receive notifications and updates in real-time."
                            },
                            {
                                question: "What is the Document app and how does it work?",
                                ans: "The Document app in ContractFlo allows you to centralize and organize all the important documents related to your real estate project in one place. You can easily upload, store, and share documents with relevant team members or stakeholders. Additionally, the app offers an e-signature feature that allows you to get signatures on important documents from clients or partners, for an additional fee."
                            }
                        ]}
                    />
                </Collapse>

                <Collapse in={projects} timeout={500} >
                    <QuestionsAndAns
                        faqArr={[
                            {
                                question: "What are projects in ContractFlo?",
                                ans: "In ContractFlo, a project is a virtual workspace where you can manage all aspects of your real estate work using our suite of apps. You can create different projects for different properties and invite team members or collaborators to work together. Each project has its own set of apps, data, and settings that you can customize to fit your needs. Projects serve as the foundation for organizing, tracking, and optimizing your real estate workflow."
                            },
                            {
                                question: "Can I add multiple properties in a single project?",
                                ans: "No. Every property must be added as a separate project."
                            },
                            {
                                question: "How can I create a project and add property in it?",
                                ans: `To create a project and add a property to it, navigate to the Projects section and click on the "New Project" button located at the top right corner of the page. Enter the necessary details of the project, such as the name and address of the property. If you wish to use the project for investment analysis purposes, you can import the property from Zillow directly into the Investment Analysis app within the project.`
                            },
                            {
                                question: "How can I add other people to the project?",
                                ans: `You can add people to a project by navigating to the project and clicking on the "People" tab. From there, you can invite people to join the project by entering their email address and selecting their role (owner, admin or tenant). If the person is already on the ContractFlo network, you can search for them and send them an invite directly.`
                            },
                        ]}
                    />
                </Collapse>

                <Collapse in={network} timeout={500} >
                    <QuestionsAndAns
                        faqArr={[
                            {
                                question: "What is the ContractFlo Network?",
                                ans: "The ContractFlo Network is a community for real estate professionals and enthusiasts to connect with one another. Anyone can sign up and create a profile on the network to access features such as feeds, community engagement, following other members, and messaging."
                            },
                            {
                                question: "What are the benefits of joining Cflo network?",
                                ans: "By joining the ContractFlo network, you can showcase your real estate skills and accomplishments publicly, which can help you gain recognition and job opportunities. Additionally, you can also stay updated on industry news and trends, participate in discussions and forums related to real estate, and access exclusive content and events. The network also provides opportunities to learn from and collaborate with experienced professionals, potentially leading to new business opportunities and professional growth."
                            },
                            {
                                question: "Can I use the CFlo network for free?",
                                ans: "Yes, signing up for and using the ContractFlo network is completely free of charge."
                            },
                            {
                                question: "How can I find and post jobs and deals on the ContractFlo network?",
                                ans: `To find and post jobs and deals on the ContractFlo network, you can go to the respective communities - c/Jobs for posting and finding jobs, and c/Deals for posting and finding real estate deals. Once you are on the community page, you can browse through the posts and filter them based on your preferences. To post a job or a deal, click on the "Create post" button and provide all the necessary details. Make sure to include a clear and detailed description, location, and contact information.`
                            },
                            {
                                question: "Can I find and post jobs and/or deals on the Contractflo network for free?",
                                ans: "Yes, posting and finding jobs and deals on the ContractFlo network is completely free."
                            },
                        ]}
                    />
                </Collapse>

                <Collapse in={pricing} timeout={500} >
                    <QuestionsAndAns
                        faqArr={[
                            {
                                question: "Do i need to pay to get started with contractflo?",
                                ans: "No. The Starter version of Contractflo is completely free and available for anyone to sign up and us"
                            },
                            {
                                question: "Which of the apps can be used in the free Starter mode?",
                                ans: "In the free Starter mode, you can use the Document app, Project Management app, and the ContractFlo Network. Other apps such as Investment Analysis, Accounting and Payments, and Property Management require a paid subscription."
                            },
                            {
                                question: "How does the custom version of Contractflo work and what are its charges?",
                                ans: "The Custom version of Contractflo allows you to select the specific apps you want to use, and you will be charged only for the apps selected by you. The cost of each app is listed in the pricing section, and your monthly or annual charges will be calculated based on the apps you have selected. This model allows you to customize your Contractflo experience and only pay for the features you need."
                            },
                            {
                                question: "What is the ultimate version of Contractflo?",
                                ans: "The ultimate version of Contractflo gives you access to all the apps for a monthly fee of $30."
                            },
                            {
                                question: "Are there any restrictions on the number of projects that I can create?",
                                ans: "There are no restrictions on the number of projects you can create on Contractflo. You can create as many projects as you need without any additional charges."
                            },
                            {
                                question: "Can I add other members to my project for free?",
                                ans: "Yes, you can add other people to your project for free, and they will have access to the free apps. However, if you want to give them access to paid apps, they would need to purchase either the Custom or Ultimate version of ContractFlo."
                            },
                        ]}
                    />
                </Collapse>
            </div>
        </section>
    );
}
