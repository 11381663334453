import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import { FaHashtag } from "react-icons/fa";

const useStyles = makeStyles((theme) => ({
    catCont: {
        fontSize: "16px",
        fontWeight: "400",
        cursor: "pointer",
        color: "#505050",
        borderRadius: "5px",
        marginBottom: "8px",
        width: "100%",
        display: "flex",
        alignItems: "flex-start",
        padding: "5px 15px",
        "& h3": {
            marginLeft: "10px",
            fontSize: "17px",
            fontWeight: "500"
        }
    },
    skeleton: {
        width: "100%",
        height: "35px"
    }
}));


function CategoryFilter(props) {
    const classes = useStyles();
    const theme = useTheme();

    const { selectedCategories, setSelectedCategories, categories, getCategoryLoader } = props

    const clickCategory = (cate) => {
        let updatedArr = [...selectedCategories]
        if (selectedCategories.includes(cate)) {
            updatedArr = selectedCategories.filter((d) => d !== cate)
        } else {
            updatedArr.push(cate)
        }
        setSelectedCategories(updatedArr)
    }

    return (
        <>
            {!getCategoryLoader ? categories && categories.length > 0 && categories.map((category, i) => (
                <div
                    className={classes.catCont}
                    onClick={() => { clickCategory(category?.tagStrs) }}
                    style={selectedCategories.includes(category?.tagStrs) ? { backgroundColor: "#E9F0F8" } : {}}
                >
                    <FaHashtag
                        style={{
                            marginRight: "7px",
                            marginTop: "3px",
                            color: "#808080db",
                            fontSize: "17px"
                        }}
                    />
                    {category?.tagStrs}
                </div>
            )) : (<>
                <Skeleton className={classes.skeleton} />
                <Skeleton className={classes.skeleton} />
                <Skeleton className={classes.skeleton} />
                <Skeleton className={classes.skeleton} />
                <Skeleton className={classes.skeleton} />
                <Skeleton className={classes.skeleton} />
                <Skeleton className={classes.skeleton} />
            </>)}
        </>
    );
}

export default CategoryFilter;
