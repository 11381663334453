import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { TextField } from "@material-ui/core";
import "./editor.css"
import AllFileViewerFeed from "../styled/CommonComponents/AllFiles.Viewer.Feed";
import { getAllFiles } from "../styled/CommonComponents/api.call";
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import AddIcon from '@material-ui/icons/Add';
import { updateDeleteFlagForSingleFiles } from "../propertyManagement/apiCall";


const useStyles = makeStyles((theme) => ({
    noBorder: {
        border: "none",
        borderBottom: "1px solid #CCCCCC",
        borderRadius: "0px"
    },
    titleInput: {
        fontSize: "20px",
        padding: "0px",
        "& .MuiOutlinedInput-input": {
            paddingBottom: "12px",
            padding: "10px 11px 12px",
            margin: "0px"
        }
    },
    titleCont: {
        width: "100%",
        margin: "0px"
    }
}));

function DocEditor(props) {
    const classes = useStyles();
    const theme = useTheme()
    const dispatch = useDispatch();
    const {
        description,
        setDescription,
        placeholder,
        noFileUploadBtn,
        titlePlaceholder,
        title,
        setTitle,
        ...other
    } = props;

    const { createdFileIds } = useSelector((state) => state.file);

    const [files, setFiles] = useState([])

    useEffect(() => {
        if (createdFileIds) {
            getAllFiles({ files: createdFileIds })
                .then((data) => {
                    setFiles(data)
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [createdFileIds])

    const modules = {
        toolbar: [
            [{ header: "1" }, { header: "2" }, { font: [] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            ["bold", "italic", "underline", "strike", "blockquote"],
            [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
            ["link"],
            [{ script: "sub" }, { script: "super" }], // superscript/subscript
            [{ color: [] }, { background: [] }], // dropdown with defaults from theme
            ["clean"],
        ],
        clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
        },
    };

    const formats = [
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "script",
        "color",
        "background",
    ];

    const removeFun = async (id) => {
        let allfilesId = createdFileIds.filter((_id) => _id !== id)
        let allfiles = files.filter((obj) => obj?._id !== id)
        setFiles(allfiles)
        dispatch({
            type: "AddFile",
            payload: {
                createdFileIds: [...allfilesId],
            },
        })

        await updateDeleteFlagForSingleFiles({ fileId: id })
            .then((data) => {
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }


    return (
        <div>
            <div className="titleDivCont" >
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    id="phoneNumber"
                    disableUnderline={false}
                    value={title}
                    onChange={(e) => { setTitle(e.target.value); }}
                    autoFocus
                    placeholder={titlePlaceholder || "Title"}
                    InputProps={{
                        classes: { notchedOutline: classes.noBorder },
                        className: classes.titleInput
                    }}
                    className={classes.titleCont}
                />
            </div>

            <ReactQuill
                {...other}
                className='EditorStyle'
                value={description}
                placeholder={placeholder || "Description"}
                onChange={setDescription}
                modules={modules}
                formats={formats}
                theme='snow'
            >
            </ReactQuill>

            {files && files.length > 0 && (<div style={{ margin: "15px 0px 0px" }} >
                <AllFileViewerFeed
                    picVideoViewerHeight={"350px"}
                    picVideoViewerHeightSmall={"250px"}
                    picVideoViewerWidth={"100%"}
                    files={files ? files : []}
                    isDeletable={true}
                    removeFun={removeFun}
                />
            </div>)}

            {noFileUploadBtn ? null : (
                <div style={{ width: "100%", display: "flex", marginTop: "15px" }} >
                    <CustomFileUploadButton
                        showComponent={
                            <CustomBtn
                                startPart={<AddIcon />}
                                text={"Upload Files"}
                            />
                        }
                        parentType={"Doc"}
                        parentId={null}
                        fileNum={25}
                        givenMaxSize={26214400}
                        closeFunCall={()=>{ console.log("close dialog") }}
                    />
                </div>
            )}
        </div>
    );
}
export default DocEditor;