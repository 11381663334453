import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import PostAddIcon from '@material-ui/icons/PostAdd';
import PostFile from "./PostFile";
import arrayToReducer from "../../helpers/arrayToReducer";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import { Divider, Button, TextField } from "@material-ui/core";
import CreateFeedsPost from "./Create.Feeds.Post";
import { createUserFeedPost, getFeedPosts } from "./api.call";
import PostSkeleton from "../styled/CommonComponents/Post.Skeleton";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from '@material-ui/core';
import { useDebounce } from "react-use";

const useStyles = makeStyles((theme) => ({
	searchText: {
		backgroundColor: "white",
		borderRadius: "12px",
		margin: "0px"
	},
	noBorder: {
		border: "none",
	},
	feedBodyXCont: {
		width: "100%",
		overflow: "auto",
	},
	progressBox: {
		width: "100%",
		height: "70px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	}
}));

function getTomorrowDateTime() {
	var tomorrow = new Date();
	tomorrow.setDate(tomorrow.getDate() + 1);
	const date = moment(tomorrow).format("YYYY-MM-DDTkk:mm");
	return date;
}

function PostMemo({ posts, setPosts, searchTitle, setSearchTitle, communityIds }) {
	const classes = useStyles();
	const theme = useTheme()
	const history = useHistory();
	const dispatch = useDispatch();

	const { createdFileIds } = useSelector((state) => state.file);
	const { user } = useSelector((state) => state.auth);
	const observer = useRef();

	const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
	const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

	const [currPage, setCurrPage] = useState(0);
	const [reloadStatusChange, setReloadStatusChange] = useState(false);
	const [loading, setLoading] = useState(true);
	const [postIds, setPostIds] = useState([]);
	const [postDict, setPostDict] = useState({});
	const [hasMore, setHasMore] = useState(true);

	// create post useState
	const [postCreateLoading, setPostCreateLoading] = useState(false)
	const [newPostCreate, setNewPostCreate] = useState(false)
	const [title, setTitle] = useState("")
	const [description, setDescription] = useState("")
	const [isPoll, setIsPoll] = useState(false)
	const [pollOptions, setPollOptions] = useState([""]);
	const [pollExireAt, setPollExireAt] = useState(getTomorrowDateTime());
	const postFromBackend = 10;


	const findPostsForScroll = async () => {
		setLoading(true)
		await getFeedPosts({
			profile: user?.profile,
			communitiesId: communityIds || [],
			page: currPage || 0,
			limit: postFromBackend,
			searchTitle: searchTitle,
		})
			.then((data) => {
				console.log(data)
				if (data) {
					if (currPage === 0) {
						setPosts(data)
					} else {
						setPosts((prev) => [...prev, ...data])
					}

					if (data.length < postFromBackend) {
						setHasMore(false);
					} else {
						setHasMore(true);
					}
				}
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
			})
	}


	const lastElementRef = useCallback(
		(node) => {
			if (loading) return;
			if (observer.current) observer.current.disconnect();
			observer.current = new IntersectionObserver((entries) => {
				if (entries[0].isIntersecting && hasMore) {
					setCurrPage((prev) => {
						return prev + 1;
					});
				}
			});
			if (node) observer.current.observe(node);
		},
		[loading, hasMore]
	);


	useEffect(() => {
		if (currPage === 0) {
			setReloadStatusChange(!reloadStatusChange)
		} else {
			setCurrPage(0)
		}
	}, [searchTitle])


	useDebounce(
		() => {
			findPostsForScroll()
		},
		700,
		[currPage, reloadStatusChange]
	);


	async function publish() {
		if (title.length > 2 && description.length > 3) {
			setPostCreateLoading(true);
			let postBody = {
				title,
				description,
				published: true,
				profile: user?.profile,
				files: [...(Array.isArray(createdFileIds) ? createdFileIds : [])]
			};

			if (isPoll) {
				postBody.poll = {};
				postBody.poll.options = pollOptions;
				postBody.poll.expireAt = pollExireAt;
				postBody.poll.user = user;
				postBody.poll.profile = user?.profile;
				postBody.poll.parentModelName = "Post";
			}

			await createUserFeedPost(postBody)
				.then((data) => {
					console.log(data)
					let updatedPosts = [data, ...posts]
					setPosts(updatedPosts)

					dispatch({ type: "FileUploadReset" });
					setNewPostCreate(false)
					setTitle("")
					setDescription("")
					setIsPoll(false)
					setPollOptions([""]);
					setPollExireAt(getTomorrowDateTime());
					setPostCreateLoading(false)
				})
				.catch((err) => {
					console.log(err);
				})
		}
	}


	useEffect(() => {
		const { newDict, idArr } = arrayToReducer([...posts] || []);
		setPostDict({ ...newDict });
		setPostIds([...idArr]);
	}, [posts]);


	return (
		<div>
			<div className={classes.root}>
				{isMdSmall ? null : (<>
					<div style={{ marginBottom: "15px" }} >
						{newPostCreate ? (<>
							<CreateFeedsPost
								titlePlaceholder={"Title"}
								descPlaceholder={"What is in your mind"}
								closeIt={() => { setNewPostCreate(false) }}
								description={description}
								setDescription={setDescription}
								title={title}
								setTitle={setTitle}
								isPoll={isPoll}
								setIsPoll={setIsPoll}
								pollOptions={pollOptions}
								setPollOptions={setPollOptions}
								pollExireAt={pollExireAt}
								setPollExireAt={setPollExireAt}
								publish={publish}
								postCreateLoading={postCreateLoading}
							/>
						</>) : (
							<TextField
								variant="outlined"
								margin="normal"
								fullWidth
								value={searchTitle}
								onChange={(e) => { setSearchTitle(e.target.value) }}
								id="phoneNumber"
								disableUnderline={false}
								autoFocus
								className={classes.searchText}
								placeholder="Search or create a post..."
								InputProps={{
									startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "#7C7C8D" }} /></InputAdornment>),
									endAdornment: (<InputAdornment position="end">
										<Button
											variant="contained"
											color="primary"
											startIcon={<PostAddIcon />}
											style={{ borderRadius: "15px" }}
											onClick={() => {
												setTitle(searchTitle)
												setNewPostCreate(true)
											}}
										>
											{isExSmall ? "Post" : "New Post"}
										</Button>
									</InputAdornment>),
									classes: { notchedOutline: classes.noBorder }
								}}
							/>
						)}
					</div>
				</>)}
				<div className={classes.feedBodyXCont} >
					{postIds && postIds.length > 0 ? (
						postIds.map((el, idx) => {
							let lastItem = postIds.length - 1;
							return postDict[el] ? (
								idx === lastItem ? (
									<div ref={lastElementRef} >
										<PostFile
											commentLimit={5}
											key={postDict[el]._id}
											post={postDict[el]}
											sentProfile={user?.profile}
											addLink={false}
											setPostDict={setPostDict}
											topText={postDict[el]?.parentModelName === "Community" ? (
												<div>
													<div
														onClick={() => { history.push("/explore/forum/communities/" + postDict[el]?.channels[0]?.parent?.slug) }}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px", gap: "5px", cursor: "pointer" }}
													>
														<Avatar
															alt={postDict[el]?.channels[0]?.parent?.displayName}
															src={postDict[el]?.channels[0]?.parent?.displayPicture?.thumbUrl || postDict[el]?.channels[0]?.parent?.displayPicture?.url}
															style={{ width: "25px", height: "25px" }}
														/>
														<Typography
															variant="body1"
															style={{ fontWeight: "500", fontSize: "14px" }}
														>
															c/{postDict[el]?.channels[0]?.parent?.displayName}
														</Typography>
													</div>
													<Divider />
												</div>
											) : null
											}
										/>
									</div>
								) : (
									<div>
										<PostFile
											commentLimit={5}
											key={postDict[el]._id}
											post={postDict[el]}
											sentProfile={user?.profile}
											addLink={false}
											setPostDict={setPostDict}
											topText={postDict[el]?.parentModelName === "Community" ? (
												<div>
													<div
														onClick={() => { history.push("/explore/forum/communities/" + postDict[el]?.channels[0]?.parent?.slug) }}
														style={{ display: "flex", alignItems: "center", padding: "10px 15px", gap: "5px", cursor: "pointer" }}
													>
														<Avatar
															alt={postDict[el]?.channels[0]?.parent?.displayName}
															src={postDict[el]?.channels[0]?.parent?.displayPicture?.thumbUrl || postDict[el]?.channels[0]?.parent?.displayPicture?.url}
															style={{ width: "25px", height: "25px" }}
														/>
														<Typography
															variant="body1"
															style={{ fontWeight: "500", fontSize: "14px" }}
														>
															c/{postDict[el]?.channels[0]?.parent?.displayName}
														</Typography>
													</div>
													<Divider />
												</div>
											) : null
											}
										/>
									</div>
								)
							) : (
								<></>
							);
						})
					) : (<>
						{loading && (
							<PostSkeleton />
						)}
					</>)}
					{postIds && postIds.length > 0 && hasMore ? (
						<div className={classes.progressBox}>
							{loading && (
								<CircularProgress />
							)}
						</div>
					) : null}
				</div>
			</div>
		</div>
	);
}

const Post = React.memo(PostMemo);
export default Post;
