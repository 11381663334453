import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Paper,
	Snackbar,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import AddNewUnit from "./AddNewUnit";
import AddIcon from "@material-ui/icons/Add";
import Button from "@material-ui/core/Button";
import ProfileAppbar from "../profile/profile.appbar";
import tenant from "../../Assets/tenant.png";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import LessText from "../styled/CommonComponents/LessText";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getRelationUnitsByProject, getRentalUnitsByTeam } from "./apiCall";
import UnitRentalRelation from "./UnitRentalRelation";
import EditIcon from "@material-ui/icons/Edit";
import RentalUnitTable from "./RentalUnitTable";
import SettingsIcon from "@material-ui/icons/Settings";
import PolicyDialog from "./PolicyDialog";
import DeleteIcon from "@material-ui/icons/Delete";
import InfoIcon from "@material-ui/icons/Info";
import HomeWorkIcon from "@material-ui/icons/HomeWork";
import Api from "../../helpers/Api";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
		width: "100%",
	},
	header: {
		width: "100%",
		position: "absolute",
		left: "0px",
		display: "flex",
		top: "0px",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "5px 20px",
		marginTop: "0px",
		marginBottom: "0px",
		backgroundColor: "#ffffff",
		zIndex: "10",
		boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
	},
	titleTop: {
		fontSize: "19px",
		fontWeight: "550",
	},
	titleButton: {
		fontSize: "15px",
		display: "flex",
		opacity: "0.7",
		justifyContent: "space-between",
		alignItems: "center",
	},
	emptyCont: {
		height: "calc(100vh - 250px)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	tenantStyle: {
		width: "200px",
		height: "auto",
	},
	buttonWithOutUnit: {
		width: "300px",
		backgroundColor: "green",
	},
	progressCont: {
		width: "100%",
		height: "500px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},
	topBand1: {
		background: "linear-gradient(82.59deg, #00c48c 0%, #00a173 100%)",
		minHeight: "10px",
		borderTopLeftRadius: "10px",
		borderTopRightRadius: "10px",
	},
	topBand2: {
		minHeight: "10px",
		borderTopLeftRadius: "10px",
		borderTopRightRadius: "10px",
		background: "linear-gradient(81.67deg, #0084f4 0%, #1a4da2 100%)",
	},
	topBand3: {
		minHeight: "10px",
		borderTopLeftRadius: "10px",
		borderTopRightRadius: "10px",
		background: "linear-gradient(69.83deg, #0084f4 0%, #00c48c 100%)",
	},
	topBand4: {
		minHeight: "10px",
		borderTopLeftRadius: "10px",
		borderTopRightRadius: "10px",
		background: "linear-gradient(81.67deg, #ff647c 0%, #1f5dc5 100%)",
	},
	card: {
		width: "max(32%,13rem)",
		boxShadow: "2px 10px 20px rgba(0, 0, 0, 0.1)",
		borderRadius: "10px",
	},
}));

export default function PropertyManagementHome(props) {
	const history = useHistory();
	const classes = useStyles();
	const { teamId } = useParams();
	const { teamDictionary } = useSelector((state) => state.team);
	let team = teamDictionary[teamId];
	const [openSetup, setOpenSetup] = useState(false);
	const [rentalRelationUnits, setRentalRelationUnits] = useState([]);
	const [createDialog, setCreateDialog] = useState(false);
	const [policyDialogOpen, setPolicyDialogOpen] = useState(false);
	const [progressBool, setProgressBool] = useState(true);
	const [unitPage, setUnitPage] = useState("unitRowPage");
	const [selected, setSelected] = React.useState([]);
	const [allTransactions, setAllTransactions] = useState([]);
	const [errorTxt, setErrorTxt] = useState("");
	const [error, setError] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);
	const [topData, setTopData] = useState({
		income: 0,
		vacancy: "0% (0)",
		dues: 0,
	});
	const [currentRentalRelationUnit, setCurrentRentalRelationUnit] =
		useState();

	const deleteUnits = async () => {
		const units = (rentalRelationUnits || []).reduce(
			(a, v) => ({ ...a, [v?._id]: v }),
			{}
		);
		let activeUnits = [];
		selected.map((id) => {
			if (units?.[id]?.rentalRelation) {
				activeUnits.push(units?.[id]?.name);
			}
		});
		if (activeUnits.length > 0) {
			setErrorTxt(
				`You cannnot delete units with active leases : "${activeUnits.toString()}"`
			);
			setError(true);
		} else {
			const res = await Api.post("property/unit/deleteUnits", {
				unitIds: selected,
			});
			if (res?.success) {
				history.go(0);
			} else {
				console.error(res?.error);
			}
		}
		setDeleteDialog(false);
	};
	useEffect(() => {
		setProgressBool(true);
		getRentalUnitsByTeam({ teamId: teamId })
			.then((data) => {
				// console.log("res data = ", data);
				setRentalRelationUnits(data);
				let vacant = 0;
				data.forEach((doc) => {
					if (!doc?.rentalRelation) {
						vacant += 1;
					}
				});
				setTopData((prev) => {
					return {
						...prev,
						vacancy: `${
							((vacant * 100) / data.length).toFixed(0) || 0
						}% (${vacant})`,
					};
				});
				setProgressBool(false);

				// let titles = [];
				// rentalRelation.map((unit) => {
				// 	titles.push(unit?.unit?.name);
				// });
				// setTitleArr(titles);
			})
			.catch((err) => {
				console.log(err);
			});
	}, [teamId]);
	useEffect(() => {
		if (currentRentalRelationUnit) {
			history.push(
				`/propertymanagement/${teamId}/${currentRentalRelationUnit._id}`
			);
		}
	}, [currentRentalRelationUnit]);
	console.log("all relation units= ", rentalRelationUnits);
	const reloadRentalRelationUnits = async () => {
		await getRentalUnitsByTeam({ teamId: teamId })
			.then((data) => {
				setRentalRelationUnits(data);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getAllTransactions = async (rentals) => {
		const res = await Api.post("property/getalltransactions", {
			rentalIds: rentals,
		});
		if (res?.data) {
			console.log("tra ", res?.data);
			setAllTransactions(res?.data);
			let dues = 0;
			let income = 0;
			(res?.data).forEach((doc) => {
				income += doc?.finalAmount || 0;
				if (doc?.status != "Paid") {
					dues += doc?.finalAmount || 0;
				}
			});
			setTopData((prev) => {
				return { ...prev, income: income, dues: dues };
			});
		}
	};
	useEffect(() => {
		let rentals = [];
		(rentalRelationUnits || []).forEach((doc) => {
			if (doc?.rentalRelation) {
				rentals.push(doc?.rentalRelation?._id);
			}
		});
		if (rentals.length > 0) {
			getAllTransactions(rentals);
		}
	}, [rentalRelationUnits]);

	return (
		<div className={classes.root}>
			{progressBool ? (
				<div className={classes.progressCont}>
					<CircularProgress />
				</div>
			) : (
				<>
					<Snackbar
						open={error}
						anchorOrigin={{ vertical: "top", horizontal: "right" }}
						autoHideDuration={6000}
						onClose={() => setError(false)}
					>
						<Alert onClose={() => setError(false)} severity="error">
							{errorTxt}
						</Alert>
					</Snackbar>
					{unitPage === "unitEditPage" ? null : (
						<div>
							<ProfileAppbar
								name={
									team?.parent?.displayname ||
									"Property Management"
								}
								btns={
									<>
										<Button
											variant="contained"
											color="primary"
											className={classes.buttonWithUnit}
											startIcon={<SettingsIcon />}
											onClick={() =>
												setPolicyDialogOpen(true)
											}
										>
											Settings
										</Button>
									</>
								}
							/>
							<div>
								<>
									<PolicyDialog
										open={policyDialogOpen}
										projectTeamId={teamId}
										setOpen={setPolicyDialogOpen}
										team={team}
										// setTeam={setTeam}
										reloadUnits={reloadRentalRelationUnits}
										numRentalUnits={
											rentalRelationUnits?.length
										}
									/>

									<AddNewUnit
										openCreateDialog={createDialog}
										setOpenCreateDialog={setCreateDialog}
										reloadUnits={reloadRentalRelationUnits}
										numRentalUnits={
											rentalRelationUnits?.length
										}
									/>
								</>
							</div>
						</div>
					)}
					<div>
						{rentalRelationUnits?.length === 0 ? (
							<>
								<div className={classes.emptyCont}>
									<img
										className={classes.tenantStyle}
										src={tenant}
										alt={"tenant"}
									/>
									<Typography
										style={{
											margin: "10px 0px",
											fontSize: "14px",
										}}
									>
										Let's create new units with the "Add
										New" button
									</Typography>

									<Button
										style={{ marginTop: "3rem" }}
										color="primary"
										variant="contained"
										onClick={() => {
											setOpenSetup(true);
										}}
									>
										Setup Property Management
									</Button>
									<PolicyDialog
										open={openSetup}
										team={team}
										setOpen={setOpenSetup}
										projectTeamId={teamId}
										firstTime
									/>
								</div>
							</>
						) : unitPage === "unitRowPage" ? (
							<>
								{/* <Portfolio
                                            walletId={team?.wallet}
                                        /> */}
								{/* <RentalUnitRows
									setCurrentRentalRelationUnit={
										setCurrentRentalRelationUnit
									}
									setUnitPage={setUnitPage}
									allRelationUnits={rentalRelationUnits}
								/> */}

								<Paper style={{ marginTop: "4rem" }}>
									<div style={{ padding: "1rem" }}>
										<div>
											<div
												className={classes.card}
												style={{
													width: "96%",
													margin: "2%",
												}}
											>
												<div
													className={classes.topBand4}
												></div>
												<div
													style={{
														padding: "1.2rem 1rem",
														display: "flex",
														justifyContent:
															"space-between",
													}}
												>
													<div>
														<Typography variant="h5">
															{teamDictionary[
																teamId
															]?.parent
																?.propName ||
																teamDictionary[
																	teamId
																]?.parent
																	?.displayName}
														</Typography>
														<Typography variant="h5">
															{teamDictionary[
																teamId
															]?.parent?.address
																?.fullAddressLine ||
																"(No address added to project)"}
														</Typography>
														<Typography variant="h5">
															{`${
																(
																	rentalRelationUnits ||
																	[]
																).length
															} units`}
														</Typography>
													</div>
													<div>
														<Tooltip title="Edit project details.">
															<IconButton
																onClick={() =>
																	history.push(
																		{
																			pathname: `/projects/${teamId}`,
																			search: "?tab=about",
																		}
																	)
																}
															>
																<EditIcon />
															</IconButton>
														</Tooltip>

														<HomeWorkIcon
															style={{
																height: "100%",
																width: "5rem",
															}}
														/>
													</div>
												</div>
											</div>
										</div>
										<div
											style={{
												display: "flex",
												justifyContent: "space-around",
												width: "100%",
												flexWrap: "wrap",
											}}
										>
											<div className={classes.card}>
												<div
													className={classes.topBand1}
												></div>
												<div
													style={{
														padding: "1.2rem 1rem",
													}}
												>
													<div
														style={{
															display: "flex",
															gap: "5px",
															justifyContent:
																"center",
														}}
													>
														<Typography
															variant="h5"
															style={{
																color: "#6c6c6c",
																fontSize:
																	"1.18rem",
																textAlign:
																	"center",
															}}
														>
															Rental Income
														</Typography>
														<Tooltip title="Sum of all transactions for your currently active lease">
															<InfoIcon
																style={{
																	color: "#d7d7d7",
																}}
															/>
														</Tooltip>
													</div>
													<Typography
														variant="h3"
														style={{
															fontWeight: "600",
															fontSize: "2.5em",
															lineHeight: "64px",
															textAlign: "center",
															color: "#323c43",
														}}
													>
														{`$ ${topData.income}`}
													</Typography>
												</div>
											</div>
											<div className={classes.card}>
												<div
													className={classes.topBand2}
												></div>
												<div
													style={{
														padding: "1.2rem 1rem",
													}}
												>
													<div
														style={{
															display: "flex",
															gap: "5px",
															justifyContent:
																"center",
														}}
													>
														<Typography
															variant="h5"
															style={{
																color: "#6c6c6c",
																fontSize:
																	"1.18rem",
																textAlign:
																	"center",
															}}
														>
															Vacancies
														</Typography>
														<Tooltip title="Total vacant units in your project">
															<InfoIcon
																style={{
																	color: "#d7d7d7",
																}}
															/>
														</Tooltip>
													</div>
													<Typography
														variant="h3"
														style={{
															fontWeight: "600",
															fontSize: "2.5em",
															lineHeight: "64px",
															textAlign: "center",
															color: "#323c43",
														}}
													>
														{`${topData.vacancy}`}
													</Typography>
												</div>
											</div>
											<div className={classes.card}>
												<div
													className={classes.topBand3}
												></div>
												<div
													style={{
														padding: "1.2rem 1rem",
													}}
												>
													<div
														style={{
															display: "flex",
															gap: "5px",
															justifyContent:
																"center",
														}}
													>
														<Typography
															variant="h5"
															style={{
																color: "#6c6c6c",
																fontSize:
																	"1.18rem",
																textAlign:
																	"center",
															}}
														>
															Rental Dues
														</Typography>
														<Tooltip title="Sum of all transactions which are not paid for your currently active lease">
															<InfoIcon
																style={{
																	color: "#d7d7d7",
																}}
															/>
														</Tooltip>
													</div>
													<Typography
														variant="h3"
														style={{
															fontWeight: "600",
															fontSize: "2.5em",
															lineHeight: "64px",
															textAlign: "center",
															color: "#323c43",
														}}
													>
														{`$ ${topData.dues}`}
													</Typography>
												</div>
											</div>
										</div>
										<Typography
											gutterBottom
											variant="h4"
											style={{
												fontWeight: "600",
												marginTop: "1rem",
											}}
										>
											Units and Leases
										</Typography>
										<div
											style={{
												display: "flex",
												flexWrap: "wrap",
												justifyContent: "space-between",
												margin: "1rem 0",
											}}
										>
											<TextField
												variant="outlined"
												placeholder="Enter unit title"
												label="Search"
												fullWidth
												style={{ flexBasis: "80%" }}
											/>
											{selected.length == 0 ? (
												<Button
													variant="contained"
													color="primary"
													style={{
														width: "100%",
														flexBasis: "14%",
													}}
													className={
														classes.buttonWithUnit
													}
													startIcon={<AddIcon />}
													onClick={() => {
														setCreateDialog(true);
													}}
												>
													Add New
												</Button>
											) : (
												<Button
													variant="contained"
													color="primary"
													style={{
														width: "100%",
														flexBasis: "14%",
													}}
													className={
														classes.buttonWithUnit
													}
													startIcon={<DeleteIcon />}
													onClick={() => {
														setDeleteDialog(true);
													}}
												>
													Delete Units
												</Button>
											)}
											<Dialog
												open={deleteDialog}
												onClose={() => {
													setDeleteDialog(false);
												}}
											>
												<DialogTitle id="alert-dialog-title">
													{`Are you sure you want to delete ${selected.length} unit(s).`}
												</DialogTitle>
												<DialogContent>
													<DialogContentText id="alert-dialog-description">
														This action is not
														reversible. Please check
														your selection before
														proceeding.
													</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button
														onClick={() => {
															setDeleteDialog(
																false
															);
														}}
														color="primary"
														variant="outlined"
													>
														Cancel
													</Button>
													<Button
														onClick={deleteUnits}
														color="secondary"
														variant="contained"
														autoFocus
													>
														Delete
													</Button>
												</DialogActions>
											</Dialog>
										</div>
										<RentalUnitTable
											selected={selected}
											setSelected={setSelected}
											setCurrentRentalRelationUnit={
												setCurrentRentalRelationUnit
											}
											setUnitPage={setUnitPage}
											allRelationUnits={
												rentalRelationUnits
											}
										/>
									</div>
								</Paper>
							</>
						) : (
							//  unitPage === "unitEditPage" ? (
							// 	<UnitRentalRelation
							// 		setUnitPage={setUnitPage}
							// 		currentRentalRelationUnit={
							// 			currentRentalRelationUnit
							// 		}
							// 		titleArr={titleArr}
							// 		rentalRelationUnits={rentalRelationUnits}
							// 		setRentalRelationUnits={setRentalRelationUnits}
							// 		reloadRentalRelationUnits={
							// 			reloadRentalRelationUnits
							// 		}
							// 		team={team}
							// 		setTeam={setTeam}
							// 	/>
							// ) :
							<>Something went wrong!!!</>
						)}
					</div>
				</>
			)}
		</div>
	);
}
