import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import FilterSelectComponent from "./FilterSelectComponent";
import { TextField } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "95%",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    marginBottom: "0.8rem",
  },
  textBox: {
    width: "30%",
  },
}));

export default function FilterCondition({
  items,
  deleteCondition,
  updateCondition,
  condition,
  index,
  subIndex,
  required,
}) {
  const classes = useStyles();
  const history = useHistory();

  const { root, textBox } = classes;
  const [text, setText] = useState("");
  const [headervalue, setHeaderValue] = useState("");
  const [conditionValue, setConditionValue] = useState("");
  const [conjunction, setConjunction] = useState("");
  const conjunctionHeader = ["and", "or"];
  const conditionHeader = [
    "contains",
    "does not contain",
    "is",
    "is not",
    "is empty",
    "is not empty",
  ];

  useEffect(() => {
    setConjunction(condition?.conjunction);
    setHeaderValue(condition?.field);
    setConditionValue(condition?.operator);
    setText(condition?.value);
  }, [condition]);

  return (
    <div className={root}>
      {required ? (
        <>
          <FilterSelectComponent
            items={conjunctionHeader}
            currentVal={conjunction}
            condition={condition}
            updateCondition={updateCondition}
            index={index}
            title={"and"}
            ObjectKey={"conjunction"}
          />
          &nbsp;
        </>
      ) : (
        <span>{conjunction}</span>
      )}
      <FilterSelectComponent
        items={items}
        currentVal={headervalue}
        title={"Column"}
        condition={condition}
        updateCondition={updateCondition}
        index={index}
        ObjectKey={"field"}
      />
      <FilterSelectComponent
        items={conditionHeader}
        currentVal={conditionValue}
        title={"Condition"}
        condition={condition}
        updateCondition={updateCondition}
        index={index}
        ObjectKey={"operator"}
      />
      <TextField
        label="Enter a value"
        value={text}
        onChange={(e) => {
          setText(e.target.value);
          let temp = condition;
          temp["value"] = e.target.value;
          updateCondition(index, temp);
        }}
        variant="outlined"
        className={textBox}
        size="small"
      />
      <IconButton
        onClick={() => {
          deleteCondition(index, subIndex);
        }}
      >
        <Delete />
      </IconButton>
    </div>
  );
}

// {!firstCondition ? (
//         <>
//           <FilterSelectComponent
//             items={conjunctionHeader}
//             currentVal={conjunction}
//             condition={condition}
//             updateCondition={updateCondition}
//             index={index}
//             title={"Conjunc"}
//             pos={0}
//           />
//           &nbsp;
//         </>
//       ) : null}
