import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar, useScrollTrigger, useMediaQuery } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { Search as SearchIcon } from '@material-ui/icons';
import SearchBar from "./SimpleSearchBar";
import { useHistory } from "react-router-dom";
import Notification from "./notification";
import Setting from "./settings";
import YoutubeTuts from "../youtubeTuts";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    height: "24px",
    width: "24px",
    "& path": {
      fill: "rgba(0, 0, 0, 0.26)",
    },
  },
  actionsCont: {
    width: "100%",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "8px 20px"
  },
  btnsCont: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  notificationCont: {
    marginLeft: "35px",
    [theme.breakpoints.down('sm')]: {
      marginLeft: "20px",
    }
  },
  searchBtn: {
    height: "40px",
    width: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    backgroundColor: "#f5f4f6",
  },
  searchIcon: {
    fontSize: "20px"
  },
  searchCloseCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  }
}));

export default function AppbarComponent() {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: 0 });
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

  const [showSearchInput, setShowSearchInput] = useState(false)


  return (
    <AppBar
      position="relative"
      elevation={trigger ? 4 : 0}
      className={classes.appBarStyle + " appbar-main"}
    >
      <div className={classes.actionsCont} >
        {isMobile ? (<>
          {showSearchInput ? (<div className={classes.searchCloseCont} >
            <SearchBar />
            <CloseIcon
              style={{ color: "black" }}
              onClick={() => { setShowSearchInput(false) }}
            />
          </div>) : (<>
            <span className={classes.searchBtn} >
              <SearchIcon
                style={{ color: "#7C7C8D" }}
                onClick={() => { setShowSearchInput(true) }}
              />
            </span>
            <div className={classes.btnsCont} >
              <YoutubeTuts
                name={"CFLO Intro"}
                dialogTitle={"Introduction to ContractFlo"}
              />
              <span className={classes.notificationCont} >
                <Notification />
              </span>
              <span className={classes.notificationCont} >
                <Setting className={classes.menuIcon} />
              </span>
            </div>
          </>)}
        </>) : (<>
          <SearchBar />
          <div className={classes.btnsCont} >
            <YoutubeTuts
              name={"CFLO Intro"}
              dialogTitle={"Introduction to ContractFlo"}
            />
            <span className={classes.notificationCont} >
              <Notification />
            </span>
            <span className={classes.notificationCont} >
              <Setting className={classes.menuIcon} />
            </span>
          </div>
        </>)}
      </div>
    </AppBar>
  );
}
