import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({

}));

export default function Settings(props) {
  const classes = useStyles();
  const history = useHistory();
  const { walletId } = useParams();


  return (
    <div>
     
    </div>
  );
}