import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { IconButton, useMediaQuery } from "@material-ui/core";
import { Button } from "@material-ui/core";
import mainLogo from "../../../Assets/LogoPrimary.svg"
import { useHistory } from "react-router-dom";
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import Slide from "@material-ui/core/Slide";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "30px 180px 0px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
        [theme.breakpoints.down("lg")]: {
            padding: "30px 110px 0px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "30px 25px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "6px 15px 0px",
        },
    },
    logoTitleCont: {
        display: "flex",
        alignItems: "center",
        "& img": {
            width: "52px",
        },
        "& h3": {
            fontSize: "28px",
            color: theme.palette.primary.main
        },
        [theme.breakpoints.down("sm")]: {
            "& img": {
                width: "45px",
            },
            "& h3": {
                fontSize: "20px",
                marginLeft: "10px"
            },
        }
    },
    optionsCont: {
        display: "flex",
        alignItems: "center",
        "& .MuiButton-label": {
            fontSize: "16px",
            fontWeight: "600",
            margin: "0px 5px"
        },
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    signInLoginCont: {
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("sm")]: {
            display: "none"
        }
    },
    loginBtn: {
        marginRight: "10px",
        "& .MuiButton-label": {
            fontSize: "16px",
            fontWeight: "600",
        }
    },
    menuCont: {
        display: "none",
        [theme.breakpoints.down("sm")]: {
            display: "block"
        }
    },
    drawerPaper: {
        width: "250px",
        height: "calc(100vh - 60px)",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        paddingTop: "25px",
        paddingLeft: "25px",
        backgroundColor: "white",
        overflowY: "auto",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        "& .MuiButton-root": {
            paddingTop: "3px",
            paddingBottom: "3px",
            fontSize: "16px",
            marginBottom: "8px"
        }
    },
    subCont: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        paddingLeft: "15px",
        marginTop: "-6px",
        "& .MuiButton-root": {
            paddingTop: "4px",
            paddingBottom: "4px",
            marginBottom: "6px"
        },
        "& .MuiButton-label": {
            color: "gray",
            fontSize: "15px",
        }
    },
    collapseCont: {
        position: "absolute",
        top: "52px",
        width: "210px",
        backgroundColor: "white",
        left: "0px",
    },
    sliderCont: {
        position: "absolute",
        top: "60px",
        left: "0px",
        zIndex: "20px"
    }
}));

export default function TopBar({
    positionRef, setInvestmentAnalysis, setProjectManagement,
    setPropertyManagement, setAccountingPayment, setDocuments
}) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();

    const [currentSection, setCurrentSection] = useState("homePage")
    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
    const [openDrawer, setOpenDrawer] = useState(false)
    const [openSubCont, setOpenSubCont] = useState(false)

    const animate = () => {
        const current = window.scrollY

        const homePageEndPoint = positionRef.homePageRef.current?.offsetTop - 100
        const howCfloWorksEndPoint = positionRef.howCfloWorksRef.current?.offsetTop - 100
        const majorFeaturesEndPoint = positionRef.majorFeaturesRef.current?.offsetTop - 100
        const saasProductEndPoint = positionRef.saasProductRef.current?.offsetTop - 100
        const whyChooseCfloEndPoint = positionRef.whyChooseCfloRef.current?.offsetTop - 100
        const pricingEndPoint = positionRef.pricingRef.current?.offsetTop - 100
        const fAQEndPoint = positionRef.fAQRef.current?.offsetTop - 100
        const signupBarEndPoint = positionRef.signupBarRef.current?.offsetTop - 100
        const footerEndPoint = positionRef.footerRef.current?.offsetTop - 100

        if (current > homePageEndPoint && current < howCfloWorksEndPoint) {
            setCurrentSection("homePage")
        } else if (current > howCfloWorksEndPoint && current < majorFeaturesEndPoint) {
            setCurrentSection("howCfloWorks")
        } else if (current > majorFeaturesEndPoint && current < saasProductEndPoint) {
            setCurrentSection("majorFeatures")
        } else if (current > saasProductEndPoint && current < whyChooseCfloEndPoint) {
            setCurrentSection("saasProduct")
        } else if (current > whyChooseCfloEndPoint && current < pricingEndPoint) {
            setCurrentSection("whyChooseCflo")
        } else if (current > pricingEndPoint && current < fAQEndPoint) {
            setCurrentSection("pricing")
        } else if (current > fAQEndPoint && current < signupBarEndPoint) {
            setCurrentSection("fAQ")
        } else if (current > signupBarEndPoint) {
            setCurrentSection("footer")
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", animate, { passive: true });
    }, []);

    const scrollTo = (ref) => {
        if (!ref) return;
        ref.current.scrollIntoView({
            top: ref.offsetTop,
            left: 0,
            behavior: "smooth",
        });
    };


    return (
        <div className={classes.root}>
            <div className={classes.logoTitleCont} >
                <img src={mainLogo} alt="logo" />
                <h3>ContractFlo</h3>
            </div>

            <div className={classes.optionsCont} >
                <Button style={currentSection === "homePage" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.homePageRef);
                    }}
                >Home</Button>

                <div style={{ position: 'relative' }} >
                    <Button style={currentSection === "majorFeatures" ? { color: theme.palette.primary.main } : {}}
                        onClick={() => {
                            setOpenSubCont(!openSubCont);
                        }}
                    >{isMdSmall ? "Tools" : "Software Tools"}
                        {openSubCont ? (
                            <KeyboardArrowUpIcon style={{ marginLeft: "5px" }} />
                        ) : (
                            <KeyboardArrowDownIcon style={{ marginLeft: "5px" }} />
                        )}
                    </Button>
                    <div className={classes.collapseCont} >
                        <Collapse in={openSubCont} collapsedSize={0}>
                            <div className={classes.subCont} style={{ padding: "10px 0px 0px" }} >
                                <Button
                                    onClick={() => {
                                        setInvestmentAnalysis(true)
                                        setProjectManagement(false)
                                        setPropertyManagement(false)
                                        setAccountingPayment(false)
                                        setDocuments(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenSubCont(false);
                                    }}
                                >Investment Analysis</Button>
                                <Button
                                    onClick={() => {
                                        setProjectManagement(true)
                                        setInvestmentAnalysis(false)
                                        setPropertyManagement(false)
                                        setAccountingPayment(false)
                                        setDocuments(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenSubCont(false);
                                    }}
                                >Project Management</Button>
                                <Button
                                    onClick={() => {
                                        setPropertyManagement(true)
                                        setInvestmentAnalysis(false)
                                        setAccountingPayment(false)
                                        setDocuments(false)
                                        setProjectManagement(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenSubCont(false);
                                    }}
                                >Property Management</Button>
                                <Button
                                    onClick={() => {
                                        setAccountingPayment(true)
                                        setInvestmentAnalysis(false)
                                        setDocuments(false)
                                        setProjectManagement(false)
                                        setPropertyManagement(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenSubCont(false);
                                    }}
                                >Accounting & Payment</Button>
                                <Button
                                    onClick={() => {
                                        setDocuments(true)
                                        setInvestmentAnalysis(false)
                                        setProjectManagement(false)
                                        setPropertyManagement(false)
                                        setAccountingPayment(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenSubCont(false);
                                    }}
                                >Documents</Button>
                            </div>
                        </Collapse>
                    </div>
                </div>

                {/* <Button style={currentSection === "pricing" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.pricingRef);
                    }}
                >Pricing</Button> */}
                <Button style={currentSection === "whyChooseCflo" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.whyChooseCfloRef);
                    }}
                >Demo</Button>
                <Button style={currentSection === "fAQ" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.fAQRef);
                    }}
                >FAQs</Button>
                <Button>Blog</Button>
                <Button style={currentSection === "footer" ? { color: theme.palette.primary.main } : {}}
                    onClick={() => {
                        scrollTo(positionRef.footerRef);
                    }}
                >Support</Button>
            </div>

            <div className={classes.signInLoginCont} >
                <Button
                    className={classes.loginBtn}
                    onClick={() => {
                        const data = { type: "signin" }
                        const searchParams = new URLSearchParams(data)
                        const url = "login/?" + searchParams;
                        history.push(url)
                    }}
                >Sign in</Button>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        const data = { type: "signup" }
                        const searchParams = new URLSearchParams(data)
                        const url = "signup/?" + searchParams;
                        history.push(url)
                    }}
                >Sign up</Button>
            </div>

            <div className={classes.sliderCont} >
                <Slide
                    direction="right"
                    in={openDrawer}
                    mountOnEnter
                    unmountOnExit
                >
                    <div className={classes.drawerPaper} >
                        <Button style={currentSection === "homePage" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.homePageRef);
                                setOpenDrawer(false)
                            }}
                        >Home</Button>
                        <Button style={currentSection === "majorFeatures" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                setOpenSubCont(!openSubCont);
                            }}
                        >Software Tool
                            {openSubCont ? (
                                <KeyboardArrowUpIcon style={{ marginLeft: "5px" }} />
                            ) : (
                                <KeyboardArrowDownIcon style={{ marginLeft: "5px" }} />
                            )}
                        </Button>
                        <Collapse in={openSubCont} collapsedSize={0}>
                            <div className={classes.subCont} >
                                <Button
                                    onClick={() => {
                                        setInvestmentAnalysis(true)
                                        setProjectManagement(false)
                                        setPropertyManagement(false)
                                        setAccountingPayment(false)
                                        setDocuments(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenDrawer(false)
                                        setOpenSubCont(false);
                                    }}
                                >Investment Analysis</Button>
                                <Button
                                    onClick={() => {
                                        setProjectManagement(true)
                                        setInvestmentAnalysis(false)
                                        setPropertyManagement(false)
                                        setAccountingPayment(false)
                                        setDocuments(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenDrawer(false)
                                        setOpenSubCont(false);
                                    }}
                                >Project Management</Button>
                                <Button
                                    onClick={() => {
                                        setPropertyManagement(true)
                                        setInvestmentAnalysis(false)
                                        setAccountingPayment(false)
                                        setDocuments(false)
                                        setProjectManagement(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenDrawer(false)
                                        setOpenSubCont(false);
                                    }}
                                >Property Management</Button>
                                <Button
                                    onClick={() => {
                                        setAccountingPayment(true)
                                        setInvestmentAnalysis(false)
                                        setDocuments(false)
                                        setProjectManagement(false)
                                        setPropertyManagement(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenDrawer(false)
                                        setOpenSubCont(false);
                                    }}
                                >Accounting & Payment</Button>
                                <Button
                                    onClick={() => {
                                        setDocuments(true)
                                        setInvestmentAnalysis(false)
                                        setProjectManagement(false)
                                        setPropertyManagement(false)
                                        setAccountingPayment(false)
                                        scrollTo(positionRef.majorFeaturesRef);
                                        setOpenDrawer(false)
                                        setOpenSubCont(false);
                                    }}
                                >Documents</Button>
                            </div>
                        </Collapse>

                        {/* <Button style={currentSection === "pricing" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.pricingRef);
                                setOpenDrawer(false)
                            }}
                        >Pricing</Button> */}
                        <Button style={currentSection === "whyChooseCflo" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.whyChooseCfloRef);
                                setOpenDrawer(false)
                            }}
                        >Demo</Button>
                        <Button style={currentSection === "fAQ" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.fAQRef);
                                setOpenDrawer(false)
                            }}
                        >FAQs</Button>
                        <Button>Blog</Button>
                        <Button style={currentSection === "footer" ? { color: theme.palette.primary.main } : {}}
                            onClick={() => {
                                scrollTo(positionRef.footerRef);
                                setOpenDrawer(false)
                            }}
                        >Support</Button>

                        <Button
                            onClick={() => {
                                const data = { type: "signin" }
                                const searchParams = new URLSearchParams(data)
                                const url = "login/?" + searchParams;
                                history.push(url)
                            }}
                        >Sign in</Button>
                        <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ padding: "1px 15px" }}
                            onClick={() => {
                                const data = { type: "signup" }
                                const searchParams = new URLSearchParams(data)
                                const url = "signup/?" + searchParams;
                                history.push(url)
                            }}
                        >Sign up</Button>
                    </div>
                </Slide>
            </div>

            <IconButton
                className={classes.menuCont}
                onClick={() => { setOpenDrawer(!openDrawer) }}
            >
                {openDrawer ? (<CloseIcon />) : (<MenuIcon />)}
            </IconButton>
        </div>
    );
}