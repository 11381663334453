import React, { useEffect, useState } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Chip from "@material-ui/core/Chip";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { green, red } from "@material-ui/core/colors";
import FilterSelectComponent from "./FilterSelectComponent";
import FilterGroup from "./FilterGroup";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  dialogTitle: {
    "& p": {
      display: "inline",
      color: "#00345D",
      fontWeight: "600",
    },
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  actionButtons: {
    marginBottom: "1rem",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    "& Button": {
      marginRight: "1rem",
      textTransform: "none",
    },
  },
}));

export default function FilterDialog({
  open,
  setOpen,
  headers,
  conditions,
  setConditions,
  filterResults,
}) {
  const classes = useStyles();
  const history = useHistory();
  const { dialogTitle, closeButton, dialogContainer, actionButtons } = classes;
  const conjunctionHeader = ["and", "or"];

  const handleClose = () => {
    setOpen(false);
  };

  const addGroup = () => {
    let emptyGroup = [];
    let arr = [...conditions, emptyGroup];
    console.log(arr);
    setConditions(arr);
  };

  const deleteGroup = (index) => {
    setConditions([
      ...conditions.slice(0, index),
      ...conditions.slice(index + 1),
    ]);
  };

  const addCondition = (index) => {
    let emptyCondition = {};
    let arr = [...conditions[index], emptyCondition];
    console.log(arr);
    setConditions([
      ...conditions.slice(0, index),
      arr,
      ...conditions.slice(index + 1),
    ]);
  };

  const deleteCondition = (index, subIndex) => {
    let arr = conditions[index];
    //delete subIndex of this arr
    arr = [...arr.slice(0, subIndex), ...arr.slice(subIndex + 1)];
    setConditions([
      ...conditions.slice(0, index),
      arr,
      ...conditions.slice(index + 1),
    ]);
  };

  const updateCondition = (index, obj) => {
    console.log("index: ", index, "arr: ", obj);
    // setConditions([
    //   ...conditions.slice(0, index),
    //   arr,
    //   ...conditions(index + 1),
    // ]);
  };

  //   useEffect(() => {
  //     console.log(issue);
  //   }, []);

  useEffect(() => {
    console.log(conditions);
  }, [conditions]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        className={dialogContainer}
        PaperProps={{
          style: { borderRadius: 10 },
        }}
      >
        <DialogTitle id="alert-dialog-title" className={dialogTitle}>
          <p>Advanced Filter</p>
          <IconButton className={closeButton} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>In this view, show records</span>
            {conditions?.length > 0 ? (
              <div>
                {conditions.map((condition, index) => {
                  console.log(condition);
                  let currConjunction = condition[0]?.conjunction;
                  {
                    /* if (index == 0) { */
                  }
                  return (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        marginTop: `${index != 0 ? "1rem" : null}`,
                      }}
                    >
                      <span>{index == 0 ? "Where" : "and"}</span>&nbsp;
                      <FilterGroup
                        items={headers}
                        condition={condition}
                        isEmpty={false}
                        addCondition={addCondition}
                        deleteCondition={deleteCondition}
                        updateCondition={updateCondition}
                        deleteGroup={deleteGroup}
                        index={index}
                      />
                    </div>
                  );
                  {
                    /* } else {
                    return (
                      <div
                        key={index}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          marginTop: "1rem",
                        }}
                      >
                        <span>and</span>&nbsp;
                        <FilterGroup
                          items={headers}
                          condition={condition}
                          isEmpty={false}
                          addCondition={addCondition}
                          deleteCondition={deleteCondition}
                          updateCondition={updateCondition}
                          deleteGroup={deleteGroup}
                          index={index}
                        />
                      </div>
                    );
                  } */
                  }
                })}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "center",
                }}
              >
                <p>No filters applied...</p>
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div className={actionButtons}>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={addGroup}
              startIcon=<AddIcon />
            >
              Group
            </Button>
            <Button
              size="small"
              variant="contained"
              color="primary"
              onClick={() => {
                filterResults();
                setOpen(false);
              }}
            >
              Filter
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// <Button
//               size="small"
//               variant="outlined"
//               color="primary"
//               onClick={addCondition}
//               startIcon=<AddIcon />
//             >
//               Condition
//             </Button>

{
  /* <span>Where</span>&nbsp;
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <FilterGroup
                    items={headers}
                    isEmpty={true}
                    deleteCondition={deleteCondition}
                    updateCondition={updateCondition}
                    firstCondition={true}
                    index={0}
                  />
                </div> */
}
