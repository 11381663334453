import React, { useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RiUserAddFill, RiUserUnfollowFill } from "react-icons/ri";
import { followUnfollowApiHit } from "./api";

const useStyles = makeStyles((theme) => ({
    singleLine: {
        width: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "10px"
    },
    picNameCont: {
        width: "calc(100% - 165px)",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        [theme.breakpoints.down("xs")]: {
            width: "calc(100% - 150px)",
        }
    },
    picSty: {
        width: "35px",
        height: "35px",
        [theme.breakpoints.down("xs")]: {
            width: "25px",
            height: "25px",
        }
    },
    detailsCont: {
        marginLeft: "10px",
        "& h3": {
            fontSize: "16px"
        },
        "& p": {
            fontSize: "14px"
        },
        [theme.breakpoints.down("xs")]: {
            marginLeft: "5px",
            "& h3": {
                fontSize: "13px",
                fontWeight: "500"
            },
            "& p": {
                fontSize: "11px"
            },
        }
    },
    editButtonSty: {
        padding: "5px 12px",
        borderRadius: "20px",
        backgroundColor: "white",
        fontSize: "15px",
        border: "1px solid #cccbcb",
        color: "black",
        cursor: "pointer",
        fontWeight: "600",
        "&:hover": {
            backgroundColor: "#f7f4f4"
        },
        [theme.breakpoints.down("xs")]: {
            padding: "5px 10px",
            borderRadius: "15px",
            fontSize: "13px",
        }
    },
}));

const FollowerSingle = ({ userData, curProfile, locFollowingIdsArr, getFollowerAndFollowings, getFolloerAndFollowingIds }) => {
    const classes = useStyles();
    const history = useHistory();
    const auth = useSelector((state) => state.auth);
    const { user } = auth;

    const [followingLoading, setFollowingLoading] = useState(false)

    const handleFollow = async () => {
        setFollowingLoading(true)
        await followUnfollowApiHit({
            mainProfile: user?.profile,
            otherProfile: userData?.userProfile?._id,
            mainUser: user?._id,
            otherUser: userData?.userProfile?.parent?._id
        })
            .then(async (data) => {
                if (curProfile?._id == user?.profile) {
                    await getFollowerAndFollowings()
                } else {
                    await getFolloerAndFollowingIds()
                }
                setFollowingLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    };


    return (
        <div className={classes.singleLine} >
            <div className={classes.picNameCont} onClick={() => { history.push('/profile/view/' + userData?.userProfile?._id); }} >
                <Avatar className={classes.picSty} src={userData?.userProfile?.parent?.displayPicture?.thumbUrl || userData?.userProfile?.parent?.displayPicture?.url} />
                <div className={classes.detailsCont} >
                    <h3>{userData?.userProfile?.parent?.displayName}</h3>
                    <p>{userData?.userProfile?.parent?.description}</p>
                </div>
            </div>

            {userData?.userProfile?._id == user?.profile ? (<div></div>) : (
                <button
                    className={classes.editButtonSty}
                    style={{ marginRight: "15px", color: "#2e73f8" }}
                    disabled={followingLoading}
                    onClick={() => { handleFollow(userData) }}
                >{followingLoading ? (<>
                    <CircularProgress size={20} style={{ marginBottom: "-4px" }} /> <span>Updating</span>
                </>) : (<>
                    {locFollowingIdsArr.includes(userData?.userProfile?._id) ? (<>
                        <RiUserUnfollowFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Unfollow</span>
                    </>) : (<>
                        <RiUserAddFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Follow</span>
                    </>)}
                </>)}
                </button>
            )}
        </div>
    );
};
export default FollowerSingle;