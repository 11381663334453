import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countriesStateMap } from "../countryState"
import LessText from "./LessText";
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    statesNameCont: {
        paddingTop: "10px",
        maxHeight: "200px",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        "& div": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontSize: "16px",
            fontWeight: "400",
            borderRadius: "4px",
            marginRight: "8px",
            marginBottom: "10px",
            padding: "0px 6px",
            backgroundColor: "#E9F0F8",
        }
    },
    removeLocation: {
        fontSize: "20px",
        color: "gray",
        cursor: "pointer",
        marginLeft: "7px"
    }
}));

function LocationSingleautoFilter(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { locationTags, setLocationTags } = props;

    const [locationStrArr, setLocationStrArr] = useState([])
    const [locationObj, setLocationObj] = useState(null)
    const [locationObjText, setLocationObjText] = useState("")


    useEffect(() => {
        let allCountries = Object.keys(countriesStateMap)
        let objArr = []
        allCountries.map((country) => {
            const statsArr = countriesStateMap?.[country]
            objArr.push({
                str: country,
                state: null,
                isCountry: true,
                country: country
            })

            statsArr.map((s) => {
                objArr.push({
                    str: `${s}, ${country}`,
                    state: s,
                    isCountry: false,
                    country: country
                })
            })
        })
        setLocationStrArr(objArr)
    }, [])

    const addNewLocation = (val) => {
        let arr = [...locationTags, val]
        setLocationTags(arr)
        setLocationObj(null)
        setLocationObjText("")
    }

    const onStateRemove = (val) => {
        let arr = locationTags.filter((tag) => tag?.str !== val)
        setLocationTags(arr)
    }

    return (
        <div style={{ width: "100%" }} >
            <Autocomplete
                id="free-solo-demo"
                freeSolo
                value={locationObj}
                inputValue={locationObjText}
                options={locationStrArr}
                getOptionLabel={(option) => { return option?.str }}
                onInputChange={(event, newValue) => {
                    setLocationObjText(newValue);
                }}
                getOptionSelected={(option) => {
                    return option?.str == locationObj?.str
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        style={{ margin: "0px" }}
                        margin="normal"
                        variant="outlined"
                        placeholder={"Enter Location"}
                    />
                )}
                onChange={(event, value) => {
                    addNewLocation(value)
                }}
                style={{ width: "100%" }}
                size="small"
            />
            <div className={classes.statesNameCont} >
                {locationTags && locationTags.length > 0 && locationTags.map((loc, i) => (
                    <div>
                        <LessText
                            string={loc?.str}
                            limit={22}
                        />
                        <CancelIcon className={classes.removeLocation} onClick={() => { onStateRemove(loc.str) }} />
                    </div>
                ))}
            </div>
        </div>
    );
}
export default LocationSingleautoFilter;