import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { TextField } from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { countriesStateMap } from "../countryState"
import CloseIcon from '@material-ui/icons/Close';
import locationEmptyIcon from "../../../Assets/locationTagEmpty.svg"

const useStyles = makeStyles((theme) => ({
    statesNameCont: {
        paddingTop: "10px",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "flex-start",
        "& div": {
            display: "flex",
            position: "relative",
            alignItems: "center",
            marginRight: "8px",
            marginBottom: "7px",
            fontSize: "16px",
            fontWeight: "400",
            borderRadius: "4px",
            padding: "0px 6px",
            backgroundColor: "#E9F0F8",
            "& .iconCont": {
                display: "none"
            },
            "&:hover .iconCont": {
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                top: "0px",
                left: "0px",
                paddingTop: "1px",
                backgroundColor: "rgba(255, 255, 255, 0.4)"
            }
        }
    },
    removeLocation: {
        fontSize: "24px",
        cursor: "pointer",
    },
    autocompleteCont: {
        display: "flex",
        alignItems: "center",
    },
    iconTagCont: {
        width: "100%",
        marginBottom: "15px",
        marginTop: "15px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "10px 0px",
        "& img": {
            height: "150px",
            width: "auto",
        },
        [theme.breakpoints.down('md')]: {
            "& img": {
                width: "220px",
                height: "auto"
            }
        },
        [theme.breakpoints.down('sm')]: {
            "& img": {
                width: "170px",
                height: "auto"
            }
        }
    }
}));

function AddLocationTag(props) {
    const classes = useStyles();
    const theme = useTheme();
    const { locationTags, setLocationTags, noemptyImage, iconTagContGiven } = props;

    const [locationStrArr, setLocationStrArr] = useState([])
    const [locationObj, setLocationObj] = useState(null)
    const [locationObjText, setLocationObjText] = useState("")


    useEffect(() => {
        let allCountries = Object.keys(countriesStateMap)
        let objArr = []
        allCountries.map((country) => {
            const statsArr = countriesStateMap?.[country]
            objArr.push({
                str: country,
                state: null,
                isCountry: true,
                country: country
            })

            statsArr.map((s) => {
                objArr.push({
                    str: `${s}, ${country}`,
                    state: s,
                    isCountry: false,
                    country: country
                })
            })
        })
        setLocationStrArr(objArr)
    }, [])

    const addNewLocation = (val) => {
        let arr = [...locationTags, val]
        setLocationTags(arr)
        setLocationObj(null)
        setLocationObjText("")
    }

    const onStateRemove = (val) => {
        let arr = locationTags.filter((tag) => tag?.str !== val)
        setLocationTags(arr)
    }

    return (
        <div>
            <div className={classes.autocompleteCont} >
                <Autocomplete
                    id="free-solo-demo"
                    freeSolo
                    value={locationObj}
                    inputValue={locationObjText}
                    options={locationStrArr}
                    getOptionLabel={(option) => { return option?.str }}
                    onInputChange={(event, newValue) => {
                        setLocationObjText(newValue);
                    }}
                    getOptionSelected={(option) => {
                        return option?.str == locationObj?.str
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            style={{ margin: "0px" }}
                            margin="normal"
                            variant="outlined"
                            placeholder={"Enter Location"}
                        />
                    )}
                    onChange={(event, value) => {
                        addNewLocation(value)
                    }}
                    style={{ width: "100%" }}
                    size="small"
                />
            </div>

            {locationTags && locationTags.length > 0 ? (<div className={classes.statesNameCont} >
                {locationTags && locationTags.map((loc, i) => (
                    <div>
                        {loc?.str}
                        <div className="iconCont" >
                            <CloseIcon className={classes.removeLocation} onClick={() => { onStateRemove(loc.str) }} />
                        </div>
                    </div>
                ))}
            </div>) : (<>
                {noemptyImage ? (<div className={iconTagContGiven} >
                    <img src={locationEmptyIcon} />
                </div>) : (<div className={classes.iconTagCont} >
                    <img src={locationEmptyIcon} />
                </div>)}
            </>)}
        </div>
    );
}
export default AddLocationTag;