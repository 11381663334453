import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useMediaQuery, Button } from "@material-ui/core";
import { getDocsByTypes } from "./api.call";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import { format } from "timeago.js";
import TableSkeleton from "../../styled/CommonComponents/Table.Skeleton";
import emptyIcon from "../../../Assets/emptyData.svg"
import CircularProgress from '@material-ui/core/CircularProgress';
import DocCard from "../profile.doc.card";
import PreviewDialog from "./Preview.Dialog";


const useStyles = makeStyles((theme) => ({
    root: {
        padding: "20px 10px"
    },
    iconSty: {
        fontSize: "20px",
        marginRight: "5px",
        marginBottom: "-5px"
    },
    head: {
        fontSize: "15px",
        fontWeight: "510"
    },
    bodyCont: {
        maxHeight: "calc(100vh - 290px)",
        overflowY: "auto"
    },
    loadMoreBtn: {
        width: "100%",
        margin: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    emptyImageCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "60px"
    },
    emptySty: {
        width: "300px",
        height: "auto"
    },
    cardCont: {
        maxHeight: "calc(100vh - 290px)",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        overflowY: "auto",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
    }
}));


export default function SimpleDocTable(props) {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { profileId } = useParams();
    const history = useHistory();

    const { auth } = useSelector((state) => state);
    const user = auth?.user;
    const docSignState = useSelector((state) => state.docSign);
    const accessToken = docSignState.accessToken;

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const [docsArr, setDocsArr] = useState(null)
    const [curPage, setCurPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const [selectedVal, setSelectedVal] = useState(null)
    const [openAddNew, setOpenAddNew] = useState(false)

    const dataLimit = 10

    const getDocs = async () => {
        setLoading(true)
        await getDocsByTypes({
            profileId,
            docType: "DOCUMENT",
            curPage,
            limit: dataLimit
        })
            .then((data) => {
                if (curPage === 0) {
                    setDocsArr(data || [])
                } else {
                    setDocsArr((prev) => [...prev, ...data])
                }

                if (data.length < dataLimit) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                setLoading(false)
                console.log(data);
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getDocs()
    }, [curPage])

    const onClickDataRow = (data) => {
        setSelectedVal(data)
        setOpenAddNew(true)
    }


    return (<>
        {!docsArr && loading ? (
            <TableSkeleton />
        ) : (
            <>{docsArr.length === 0 ? (<div className={classes.emptyImageCont} >
                <img src={emptyIcon} className={classes.emptySty} />
            </div>) : (<div className={classes.root}>
                {isMobile ? (<div className={classes.cardCont} >
                    {docsArr && docsArr.map((docData, i) => (
                        <span onClick={() => { onClickDataRow(docData) }}>
                            <DocCard key={i} doc={docData} />
                        </span>
                    ))}
                    {hasMore && (
                        <div className={classes.loadMoreBtn} >
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Button
                                    onClick={() => { setCurPage((prev) => { return prev + 1 }) }}
                                    variant="contained"
                                    color="primary"
                                >
                                    Load More
                                </Button>
                            )}
                        </div>
                    )}
                </div>) : (
                    <TableContainer component={Paper} className={classes.bodyCont} >
                        <Table className={classes.table} stickyHeader aria-label="sticky table" size="small" >
                            <TableHead style={{ position: "relative", zIndex: "99" }} >
                                <TableRow>
                                    <TableCell align="left" >
                                        <span className={classes.head} >Author</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >Title</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >Files</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >participants</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >Version</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >Likes</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >Comments</span>
                                    </TableCell>
                                    <TableCell align="right" >
                                        <span className={classes.head} >Date</span>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {docsArr && docsArr.map((docData) => (
                                    <TableRow
                                        key={docData?._id}
                                        onClick={() => {
                                            onClickDataRow(docData)
                                        }}
                                    >
                                        <TableCell align="left" style={{ padding: "0px" }} >
                                            <div>
                                                <span style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                    <Avatar
                                                        alt={docData?.user?.displayName}
                                                        src={docData?.user?.displayPicture?.thumbUrl || docData?.user?.displayPicture?.url}
                                                        style={{ marginLeft: "7px", width: "30px", height: "30px" }}
                                                    />
                                                    <p style={{ marginLeft: "10px" }} >{docData?.user?.displayName}</p>
                                                </span>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">{docData?.title}</TableCell>
                                        <TableCell align="right">{docData?.files?.length || 0}</TableCell>
                                        <TableCell align="right" >
                                            <div style={{ display: "flex", justifyContent: "flex-end" }} >
                                                <AvatarGroup max={4}>
                                                    {docData?.shared && docData?.shared.map((userData, i) => (
                                                        <Avatar
                                                            key={i}
                                                            alt={userData?.parent?.displayName}
                                                            src={userData?.parent?.displayPicture?.thumbUrl || userData?.parent?.displayPicture?.url}
                                                            style={{ width: "30px", height: "30px" }}
                                                        />
                                                    ))}
                                                </AvatarGroup>
                                            </div>
                                        </TableCell>
                                        <TableCell align="right">{docData?.versions?.length || 1}</TableCell>
                                        <TableCell align="right">{docData?.likeCount}</TableCell>
                                        <TableCell align="right">{docData?.commentCount}</TableCell>
                                        <TableCell align="right">{format(docData?.updatedAt)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        {hasMore && (
                            <div className={classes.loadMoreBtn} >
                                {loading ? (
                                    <CircularProgress />
                                ) : (
                                    <Button
                                        onClick={() => { setCurPage((prev) => { return prev + 1 }) }}
                                        variant="contained"
                                        color="primary"
                                    >
                                        Load More
                                    </Button>
                                )}
                            </div>
                        )}
                    </TableContainer>
                )}
            </div>)}
            </>)
        }
        <PreviewDialog
            data={selectedVal}
            openAddNew={openAddNew}
            setOpenAddNew={setOpenAddNew}
        />
    </>);
}
