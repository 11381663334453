import React, { useEffect, useRef, useState } from 'react';
import { IconButton } from '@material-ui/core';
import TemplateCard from './TemplateCard';
import { getTxByTemplateId } from '../transaction/api';
import { makeStyles } from '@material-ui/core/styles';
import TxCard from './TxCard';
import Drawer from "@material-ui/core/Drawer";
import ClearIcon from '@material-ui/icons/Clear';
import TemplateTxSkeleton from '../../../styled/skeletons/TemplateTxSkeleton';

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    width: "500px",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    [theme.breakpoints.down("xs")]: {
      width: "100vw",
    },
  },
  titleAndClose: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    padding: "5px 15px 0px",
    justifyContent: "space-between",
    "& h3": {
      fontSize: "22px"
    }
  },
  txsCont: {
    padding: "0px 15px",
    width: "100%",
  }
}));

export default function SlidingSideBar({ walletId, openTemplateDrawer, setOpenTemplateDrawer, selectedGenerator, editClickUrl, viewClickUrl }) {
  const classes = useStyles();

  const [allTxs, setAllTxs] = useState([]);
  const [txLen, setTxLen] = useState(0);
  const [txLoader, setTxLoader] = useState(false)


  useEffect(() => {
    setTxLoader(true)
    getTxByTemplateId({ templateId: selectedGenerator?._id })
      .then((data) => {
        setAllTxs(data)
        setTxLen(data?.length || 0)
        setTxLoader(false)
      })
      .catch((error) => {
        console.log(error);
      })

  }, [selectedGenerator]);

  const handleDrawerToggle = () => {
    setOpenTemplateDrawer(!openTemplateDrawer)
  }


  return (
    <Drawer
      variant="temporary"
      anchor={"right"}
      open={openTemplateDrawer}
      onClose={handleDrawerToggle}
      classes={{
        paper: classes.drawerPaper
      }}
      ModalProps={{
        keepMounted: true
      }}
    >
      <div className={classes.titleAndClose} >
        <h3>Generator Details</h3>
        <IconButton>
          <ClearIcon
            onClick={() => { setOpenTemplateDrawer(false) }}
            style={{ fontSize: "25px" }}
          />
        </IconButton>
      </div>

      <TemplateCard
        selectedGenerator={selectedGenerator}
        txLen={txLen}
        walletId={walletId}
      />

      {txLoader ? (<div className={classes.txsCont} >
        <TemplateTxSkeleton />
        <TemplateTxSkeleton />
        <TemplateTxSkeleton />
      </div>) : (<div className={classes.txsCont} >
        {allTxs && allTxs.map((txData) => (
          <TxCard
            txData={txData}
            editClickUrl={editClickUrl}
            viewClickUrl={viewClickUrl}
          />
        ))}
      </div>)}
    </Drawer>
  );
}