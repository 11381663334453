import React, { useEffect, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import { addProfileSectionApiHit } from "../api";
import { useMediaQuery } from '@material-ui/core';
import NormalDialog from "../../styled/CommonComponents/NormalDialog";
import { allLanguagesName } from "../../styled/CommonComponents/languages.array"
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinearProgress from '@material-ui/core/LinearProgress';
import * as yup from "yup";

const schema = yup.object().shape({
  language: yup.string().required("Language should be required"),
  proficiency: yup.string().required("Proficiency name should be required"),
})

const useStyles = makeStyles((theme) => ({
  textLineCont: {
    width: "550px",
    display: "flex",
    alignItems: "center",
    marginBottom: "20px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "450px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px",
    },
  },
  halfCont: {
    width: "47%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  label: {
    fontSize: "15px",
    fontWeight: "500",
    marginTop: "7px",
    marginBottom: "0px"
  }
}));

const proficiencyArray = [
  "Elementary Proficiency",
  "Limited Working Proficiency",
  "Professional Working Proficiency",
  "Full Professional Proficiency",
  "Native or Billingual Proficiency",
];

const LanguageDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { user } = useSelector((state) => state.auth);

  const [name, setName] = useState("");
  const [proficiency, setProficiency] = useState("");
  const [loading, setLoading] = useState(false)
  const [validationObj, setValidationObj] = useState({})

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setValidationObj({})
  }, [name,
    proficiency])

  const saveData = async (e) => {
    e.preventDefault();
    const formData = {
      language: name,
      proficiency: proficiency
    }

    await schema.validate(formData, { abortEarly: false })
      .then(async (data) => {
        setLoading(true)
        let userId = user?._id
        let arrayKey = "languages"
        let sectionDataArr = [{
          name: name,
          proficiency: proficiency
        }]
        await addProfileSectionApiHit({ userId, arrayKey, sectionDataArr })
          .then((data) => {
            console.log(data)
            dispatch({
              type: "AddAuth",
              payload: {
                user: {
                  ...user,
                  languages: data?.languages,
                },
              },
            });
            setLoading(false)
          })
          .catch((err) => {
            console.log(err);
          })
        setName("")
        setProficiency("")
        setOpen(false)
      })
      .catch((err) => {
        let errorArr = err?.inner
        let obj = {}
        errorArr.map((err) => {
          obj[err?.path] = err?.message
        })
        setValidationObj(obj)
      })
  };




  return (
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={"Add Language"}
      content={<form onSubmit={saveData}>
        <div className={classes.textLineCont} >
          <div className={classes.halfCont} >
            <p className={classes.label} >Language</p>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              value={name}
              options={allLanguagesName}
              getOptionLabel={(option) => { return option }}
              getOptionSelected={(option) => {
                return option === name
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ margin: "0px" }}
                  margin="normal"
                  variant="outlined"
                  placeholder={"Enter language"}
                />
              )}
              onChange={(event, value) => {
                setName(value)
              }}
              style={{ width: "100%" }}
              size="small"
            />
            {validationObj?.language && (<p style={{ color: "#EE1D52" }} >{validationObj?.language}</p>)}
          </div>
          <div className={classes.halfCont} >
            <p className={classes.label} >Proficiency</p>
            <Autocomplete
              id="free-solo-demo"
              freeSolo
              value={proficiency}
              options={proficiencyArray}
              getOptionLabel={(option) => { return option }}
              getOptionSelected={(option) => {
                return option === proficiency
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  style={{ margin: "0px" }}
                  margin="normal"
                  variant="outlined"
                  placeholder={"Enter proficiency"}
                />
              )}
              onChange={(event, value) => {
                setProficiency(value)
              }}
              style={{ width: "100%" }}
              size="small"
            />
            {validationObj?.proficiency && (<p style={{ color: "#EE1D52" }} >{validationObj?.proficiency}</p>)}
          </div>
        </div>
        <div style={{ height: "5px", marginTop: "2px" }} >
          {loading && (<LinearProgress />)}
        </div>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "3px 7px 0px" }} >
          <div></div>
          <Button
            variant="contained"
            color="primary"
            disabled={loading}
            type="submit"
            startIcon={<SaveIcon />}
            size={isSmall ? "small" : "medium"}
          >Save</Button>
        </div>
      </form>}
    />);
};

export default LanguageDialog;
