import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import PostFile from "../post/PostFile";

const useStyles = makeStyles((theme) => ({
    cont: {
        padding: "10px 250px 40px",
        [theme.breakpoints.down('md')]: {
            padding: "10px 150px 40px",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "10px 50px 40px",
        },
        [theme.breakpoints.down('xs')]: {
            padding: "10px 10px 40px",
        },
    }
}));

export default function SinglePostView() {
    const classes = useStyles();
    const theme = useTheme();

    return (<div className={classes.cont} >
        <PostFile />
    </div>)
}