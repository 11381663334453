import {
	Avatar,
	Button,
	makeStyles,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Api from "../../helpers/Api";
import ProfileAppbar from "../profile/profile.appbar";
const useStyles = makeStyles((theme) => ({
	root: {
		padding: "4rem 1rem 0",
	},
}));

function ProjectPortfolioIndex() {
	const { id } = useParams();
	const classes = useStyles();
	const history = useHistory();

	const [rows, setRows] = useState([]);

	const setTableRows = (data) => {
		let temp = (data || []).map((portDoc, idx) => {
			let _id = portDoc?._id;
			let sno = idx + 1;
			let title = portDoc?.name;
			let dp = portDoc?.displayPicture?.url;
			let participant = portDoc?.access || [];
			let createdBy = portDoc?.createdBy?.parent?.displayName;
			let createdAt = new Date(portDoc?.createdAt);
			return { sno, title, participant, createdAt, createdBy, _id, dp };
		});
		setRows([...temp]);
	};
	const getPortfolios = async () => {
		const res = await Api.post("projectportfolio/getbyuser", {
			profile: id,
		});
		const data = res?.data;
		setTableRows(data);
		console.log("data = ", data);
	};
	useEffect(() => {
		getPortfolios();
	}, []);
	return (
		<Paper className={classes.root}>
			<ProfileAppbar name={"Project Portfolio"} btns={<></>} />
			<Typography variant="h4">Project Portfolio</Typography>
			<Typography>
				You can easily manage all your projects in one place. With our
				user-friendly interface, you can keep track of your projects'
				progress, financials, and deadlines with ease.
			</Typography>
			<div style={{ margin: "2rem 0" }}>
				<TableContainer component={Paper}>
					<Table aria-label="simple table">
						<TableHead>
							<TableRow>
								<TableCell align="center">S.No. </TableCell>
								<TableCell align="center">Title</TableCell>
								<TableCell align="center">
									Participants
								</TableCell>
								<TableCell align="center">Created By</TableCell>
								<TableCell align="center">Created At</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => {
								return (
									<TableRow
										hover
										role="checkbox"
										tabIndex={-1}
										key={row._id}
										onClick={() => {
											history.push(
												`/projectportfolio/view/${row?._id}`
											);
										}}
										style={{
											cursor: "pointer",
											padding: "0px",
											height: "25px",
										}}
									>
										<TableCell align="center">
											{row?.sno}
										</TableCell>
										<TableCell align={"center"}>
											{row?.title}
										</TableCell>
										<TableCell
											align="center"
											className={
												row?.access &&
												row?.access.length > 0
													? classes.participantsCont
													: classes.emptyParticipantCont
											}
											key={"participants"}
										>
											<AvatarGroup
												max={5}
												style={{
													justifyContent: "center",
												}}
											>
												{row?.participant.map(
													(person, idx) => {
														// console.log(person);
														return (
															<Avatar
																className={
																	classes.avatar
																}
																key={idx}
																alt={
																	person
																		?.parent
																		?.displayName
																}
																src={
																	person
																		?.parent
																		?.displayPicture
																		?.url
																}
															/>
														);
													}
												)}
											</AvatarGroup>
											{/* {row?.populatedParticipants.length} */}
										</TableCell>
										<TableCell align="center">
											{row?.createdBy}
										</TableCell>

										<TableCell align="center">
											{format(row?.createdAt, "MM-dd-yy")}
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
			</div>
		</Paper>
	);
}

export default ProjectPortfolioIndex;
