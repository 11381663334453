export const schedules = [ "Schedule", "Reminder", "Unschedule" ];
  
export const intervalTypes = [ "Daily", "Weekly", "Monthly", "Yearly" ];

export const days = [ "Day", "First", "Second", "Third", "Fourth", "Last" ]

export const daysMonth = [ "1st", "2nd", "3rd", "4th", "5th", "6th", "7th", "8th", "9th", "10th", "11th", "12th","13th",
                           "14th", "15th", "16th", "17th", "18th", "19th", "20th", "21st", "22nd", "23rd", "24th", "25th", "26th", "27th","28th"];

export const daysOfWeek = [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

export const month = [ "January","February", "March","April", "May", "June", "July", "August", "September", "October", "November", "December" ]

export const endDateType = [ "None", "By", "After" ]
