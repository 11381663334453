import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    padding: " 10px 10px 0px",
    width: "100%",
    cursor: "pointer",
  },
  avatar: {
    marginRight: "10px",
    width: "25px",
    height: "25px",
  },
  name: {
    fontSize: "14px",
    fontWeight: "400",
  },
}));

function LikeCard({ likeUser }) {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div
      className={classes.root}
      onClick={() => {
        history.push(`/profile/view/${likeUser?._id}` || "/");
      }}
    >
      <Avatar
        className={classes.avatar}
        src={likeUser?.parent?.displayPicture?.thumbUrl || likeUser?.parent?.displayPicture?.url}
      />{" "}
      <Typography className={classes.name}>
        {likeUser?.parent?.displayName}
      </Typography>
    </div>
  );
}

export default LikeCard;
