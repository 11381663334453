import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import FullFeatureCard from "./FullFeatureCard";
import investmentAnalysisImage from "../../../Assets/ContractFloAssets/SVG/InvestmentAnalysis.svg"
import projectManagementImage from "../../../Assets/ContractFloAssets/SVG/ProjectManagement.svg"
import propertyManagementImage from "../../../Assets/ContractFloAssets/SVG/PropertyManagement.svg"
import accountingPaymentImage from "../../../Assets/ContractFloAssets/SVG/AccountingPayment.svg"
import documentsImage from "../../../Assets/ContractFloAssets/SVG/Documents.svg"
import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        backgroundColor: "white",
        padding: "110px 180px 10px",
        [theme.breakpoints.down("lg")]: {
            padding: "110px 110px 10px",
        },
        [theme.breakpoints.down("md")]: {
            padding: "110px 80px 10px",
        },
        [theme.breakpoints.down("sm")]: {
            padding: "90px 40px 10px",
        },
        [theme.breakpoints.down("xs")]: {
            padding: "60px 20px 10px",
        },
    },
    headerCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "50px",
        "& h3": {
            fontSize: "35px",
            fontWeight: "800",
            textAlign: "center",
            marginBottom: "15px"
        },
        "& p": {
            fontSize: "14px",
            color: "#777575",
            textAlign: "center",
            width: "700px",
            lineHeight: "28px"
        },
        [theme.breakpoints.down("sm")]: {
            marginBottom: "50px",
            "& p": {
                width: "100%",
                fontSize: "12px",
                lineHeight: "20px"
            },
            "& h3": {
                fontSize: "28px",
                fontWeight: "700",
                marginBottom: "12px"
            },
        }
    },
    btnsCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        flexWrap: "wrap",
        marginBottom: "60px",
        "& .MuiButton-root": {
            width: "220px",
            marginBottom: "15px"
        },
        "& .MuiButton-label": {
            textTransform: "capitalize",
            fontWeight: "700",
            fontSize: "16px"
        },
        [theme.breakpoints.down("md")]: {
            "& .MuiButton-root": {
                width: "190px",
                marginBottom: "12px"
            },
            "& .MuiButton-label": {
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: "14px"
            },
        }
    },
    mainBodyCont: {
        width: "100%",
        height: "550px",
        backgroundColor: "#F8F8F6",
        position: "relative",
        overflow: "hidden",
        [theme.breakpoints.down("md")]: {
            height: "480px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "835px"
        },
        [theme.breakpoints.down("xs")]: {
            height: "940px"
        },
    },
    dataCont: {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
    }
}));

export default function MajorFeatures({
    majorFeaturesRef, investmentAnalysis, setInvestmentAnalysis, projectManagement,
    setProjectManagement, propertyManagement, setPropertyManagement, accountingPayment,
    setAccountingPayment, documents, setDocuments
}) {
    const classes = useStyles();

    return (
        <section className={classes.root} ref={majorFeaturesRef} id="majorFeatures" >
            <div className={classes.headerCont} >
                <h3 data-aos={"fade-up"} >Our SaaS tools</h3>
                <p data-aos={"fade-up"} >The complete set of SaaS tools offered by ContractFlo is tailored to meet all your real estate needs, providing support at every stage of your project's life cycle.</p>
            </div>

            <div className={classes.btnsCont} data-aos={"fade-up"} >
                <Button
                    variant={investmentAnalysis ? "contained" : ""}
                    color={investmentAnalysis ? "primary" : ""}
                    onClick={() => {
                        setInvestmentAnalysis(true)
                        setProjectManagement(false)
                        setPropertyManagement(false)
                        setAccountingPayment(false)
                        setDocuments(false)
                    }}
                >
                    <span style={investmentAnalysis ? { color: "white" } : { color: "#7E7E7E" }} >Investment Analysis</span>
                </Button>
                <Button
                    variant={projectManagement ? "contained" : ""}
                    color={projectManagement ? "primary" : ""}
                    onClick={() => {
                        setProjectManagement(true)
                        setInvestmentAnalysis(false)
                        setPropertyManagement(false)
                        setAccountingPayment(false)
                        setDocuments(false)
                    }}
                >
                    <span style={projectManagement ? { color: "white" } : { color: "#7E7E7E" }} >Project Management</span>
                </Button>
                <Button
                    variant={propertyManagement ? "contained" : ""}
                    color={propertyManagement ? "primary" : ""}
                    onClick={() => {
                        setPropertyManagement(true)
                        setInvestmentAnalysis(false)
                        setAccountingPayment(false)
                        setDocuments(false)
                        setProjectManagement(false)
                    }}
                >
                    <span style={propertyManagement ? { color: "white" } : { color: "#7E7E7E" }} >Property Management</span>
                </Button>
                <Button
                    variant={accountingPayment ? "contained" : ""}
                    color={accountingPayment ? "primary" : ""}
                    onClick={() => {
                        setAccountingPayment(true)
                        setInvestmentAnalysis(false)
                        setDocuments(false)
                        setProjectManagement(false)
                        setPropertyManagement(false)
                    }}
                >
                    <span style={accountingPayment ? { color: "white" } : { color: "#7E7E7E" }} >Accounting & Payment</span>
                </Button>
                <Button
                    variant={documents ? "contained" : ""}
                    color={documents ? "primary" : ""}
                    onClick={() => {
                        setDocuments(true)
                        setInvestmentAnalysis(false)
                        setProjectManagement(false)
                        setPropertyManagement(false)
                        setAccountingPayment(false)
                    }}
                >
                    <span style={documents ? { color: "white" } : { color: "#7E7E7E" }} >Documents</span>
                </Button>
            </div>



            <div className={classes.mainBodyCont} data-aos={"fade-up"} >
                <Slide direction="left" timeout={500} in={investmentAnalysis} mountOnEnter unmountOnExit >
                    <div className={classes.dataCont} >
                        <FullFeatureCard
                            photo={investmentAnalysisImage}
                            header={"Investment Analysis"}
                            subHeader={"Make informed and data-driven investment choices with our powerful analysis software."}
                            lists={[
                                "Analyze rental(including AirBnB), BRRRR and Flip type investment deals",
                                "Calculate offer price based on your investment return metrics.",
                                "Rental and sales comps to help you accurately estimate your returns and find best real estate deals.",
                                "Calculate ROI, Cap Rate, NPV, Cash flow, IRR, Debt coverage ratio, and other metrics along with their long term projections.",
                                "Analyze unlimited properties. Enter data manually or import property directly from zillow.",
                            ]}
                        />
                    </div>
                </Slide>


                <Slide direction="left" timeout={500} in={projectManagement} mountOnEnter unmountOnExit >
                    <div className={classes.dataCont} >
                        <FullFeatureCard
                            photo={projectManagementImage}
                            header={"Project Management"}
                            subHeader={"Real estate project tracking: Milestones, timelines, and task management"}
                            lists={[
                                "Create templates(forms) to model any business process from task management, customer relationship to rehab, repair and construction processes like daily reports, RFIs and more.",
                                "Efficiently allocate tasks, exchange data, assess requirements, gather feedback, and streamline team communication. ",
                                "Keep stakeholders informed, track progress, and ensure timely completion of tasks by coordinating with cross-functional teams.",
                                "Customize workflows with templates(forms) for your team, and use them across projects or import publicly available templates."
                            ]}
                        />
                    </div>
                </Slide>

                <Slide direction="left" timeout={500} in={propertyManagement} mountOnEnter unmountOnExit >
                    <div className={classes.dataCont} >
                        <FullFeatureCard
                            photo={propertyManagementImage}
                            header={"Property Management"}
                            subHeader={"Simplify property management with automated rent collection and maintenance tracking."}
                            lists={[
                                "Create and add leases for your property and its units.",
                                "Put rent collection on autopilot: onboard your tenants, send reminders when rent is due, get real-time updates when rent is paid.",
                                "Stay informed and manage any issue raised by the tenant.",
                                "Get 24/7 visibility into your portfolio’s performance with reports that are automatically generated and updated in real time on your dashboard.",
                            ]}
                        />
                    </div>
                </Slide>


                <Slide direction="left" timeout={500} in={accountingPayment} mountOnEnter unmountOnExit >
                    <div className={classes.dataCont} >
                        <FullFeatureCard
                            photo={accountingPaymentImage}
                            header={"Accounting & Payment"}
                            subHeader={"Streamline your real estate finances with our  customizable accounting system."}
                            lists={[
                                "Securely sync your bank and credit card accounts with Stripe/Dwolla.",
                                "Create professional custom invoices, send payment reminders, and match payments to invoices, automatically.",
                                "Automatically create transactions as scheduled using our generator.",
                                "Generate income statements, balance sheets, cash flow reports and get customized reporting through chart accounts.",
                                "Independent accounting system for each user, organization and property. "
                            ]}
                        />
                    </div>
                </Slide>

                <Slide direction="left" timeout={500} in={documents} mountOnEnter unmountOnExit >
                    <div className={classes.dataCont} >
                        <FullFeatureCard
                            photo={documentsImage}
                            header={"Documents"}
                            subHeader={"Get Rid of Paper Clutter and stay organized with Our Real Estate Document System."}
                            lists={[
                                "Upload, store and share files securely.",
                                "Send docs for e-signature using Docusign.",
                                "Create templates for documents that are used recurrently.",
                                "Share and access publicly available documents provided by Contractflo community."
                            ]}
                        />
                    </div>
                </Slide>
            </div>
        </section>
    );
}