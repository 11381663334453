import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import { useTheme } from "@material-ui/core";
import {
	useMediaQuery,
	TextField,
	InputAdornment,
	IconButton,
} from "@material-ui/core";
import teamUtils from "../team/team.utils";
import Api from "../../helpers/Api";
import Tooltip from "@material-ui/core/Tooltip";
import SearchIcon from "@material-ui/icons/Search";
import AddIcon from "@material-ui/icons/Add";
import ProjectsTable from "./projects.table";
import CreateProjectDialog from "./create.project.dialog";
import UserInvites from "../team/ManageMembers/user.invites";
import GoogleMapComponent from "../styled/CommonComponents/Google.Map";
import CustomBtn from "../styled/CommonComponents/CustomBtn.js";
import { MdOutlineNotificationAdd } from "react-icons/md";
import NormalDialog from "../styled/CommonComponents/NormalDialog";
import { nanoid } from "nanoid";
const { handleTeams } = teamUtils;

const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
		height: `calc(100vh - ${theme.appbar.height})`,
		padding: "10px 10px 7px",
		[theme.breakpoints.down("xs")]: {
			height: `calc(100vh - 120px)`,
			padding: "5px 5px 2px",
		},
	},
	tableCont: {
		width: "100%",
		height: "100%",
		backgroundColor: "white",
	},
	searchInputBtnCont: {
		width: "100%",
		height: "60px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "0px 15px",
		[theme.breakpoints.down("xs")]: {
			padding: "0px 5px",
		},
	},
	reqNotification: {
		position: "relative",
	},
	searchTextBox: {
		width: "50%",
		[theme.breakpoints.down("xs")]: {
			"& .MuiOutlinedInput-adornedStart": {
				paddingLeft: "5px",
			},
		},
	},
	invitCont: {
		width: "730px",
		paddingLeft: "10px",
		minHeight: "300px",
		maxHeight: "400px",
		overflowY: "auto",
		padding: "15px 0px",
		"&::-webkit-scrollbar": {
			display: "none",
		},
		scrollbarWidth: "none",
		[theme.breakpoints.down("sm")]: {
			width: "290px",
		},
	},
	inviIconBtn: {
		padding: "12px",
		[theme.breakpoints.down("xs")]: {
			padding: "5px",
		},
	},
	inviIcon: {
		color: theme.palette.primary.main,
		fontSize: "35px",
		[theme.breakpoints.down("xs")]: {
			fontSize: "25px",
		},
	},
	notificationNo: {
		position: "absolute",
		width: "22px",
		height: "22px",
		borderRadius: "50%",
		top: "10px",
		left: "32px",
		backgroundColor: theme.palette.primary.main,
		color: "white",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		fontSize: "16px",
		[theme.breakpoints.down("xs")]: {
			top: "5px",
			left: "20px",
			fontSize: "12px",
			width: "18px",
			height: "18px",
		},
	},
	mapAndTableCont: {
		width: "100%",
		height: "calc(100% - 60px)",
	},
	mapAndTableContForDesktop: {
		width: "100%",
		height: "100%",
		display: "flex",
		alignItems: "flex-start",
		justifyContent: "space-between",
		overflowY: "auto",
		[theme.breakpoints.down("md")]: {
			display: "block",
		},
	},
	projectTableCont: {
		width: "56%",
		height: "100%",
		overflowY: "hidden",
		[theme.breakpoints.down("md")]: {
			width: "100%",
			height: "100%",
		},
	},
	mapCont: {
		width: "44%",
		maxHeight: "100%",
		overflowY: "hidden",
		[theme.breakpoints.down("md")]: {
			width: "100%",
			height: "70%",
		},
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			height: "200px",
		},
	},
	noBorder: {
		border: "none",
	},
	titleInput: {
		fontSize: "18px",
		backgroundColor: "#f5f4f6",
		borderRadius: "10px",
		"& .MuiOutlinedInput-input": {
			padding: "12px 10px",
		},
	},
}));

export default function Projects() {
	const theme = useTheme();
	const dispatch = useDispatch();
	const classes = useStyles();

	const state = useSelector((state) => state);
	let { invitations: allInvitations } = useSelector((state) => state.team);
	const { auth, team: teamReducer } = useSelector((state) => state);
	const { teamIds, sortedProjectTeamIds, teamDictionary } = teamReducer;
	const { user, userProfile } = auth;
	const userId = user?._id;

	const [searchQuery, setSearchQuery] = useState("");
	const [rows, setRows] = useState([]);
	const [dialogOpen, setDialogOpen] = useState(false);
	const [filteredRows, setFilteredRows] = useState([]);
	const [geoPointers, setGeoPointers] = useState([]);
	const [showInvitation, setShowInvitation] = useState(false);
	const [numberOfInvt, setNumberOfInvt] = useState(0);

	const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
	const mdScreen = useMediaQuery(theme.breakpoints.down("md"));

	const getDataFromReducer = () => {
		var tempRows = [];
		teamIds.map((teamId) => {
			var value = teamDictionary[teamId];
			if (value?.parentModelName === "Project" && value?.parent) {
				tempRows.push(value);
			}
		});
		setRows(tempRows);
		setFilteredRows(tempRows);
	};

	useEffect(() => {
		getDataFromReducer();
	}, [teamDictionary]);

	const addCreatedOne = (newProject) => {
		let updatedProjects = [newProject, ...rows];
		let locFilteredRows = [newProject, ...filteredRows];

		setRows(updatedProjects);
		setFilteredRows(locFilteredRows);
	};

	const onProjectNameChange = async (value) => {
		setSearchQuery(value);
		if (value) {
			let filteredProjects = [];
			rows.map((project) => {
				let name = project?.parent?.displayName;
				const patt = new RegExp(value, "i");
				const res = patt.test(name);
				if (res) {
					filteredProjects.push(project);
				}
			});

			setFilteredRows(filteredProjects);
		} else {
			setFilteredRows(rows);
		}
	};

	useEffect(() => {
		let count = 0;
		if (Array.isArray(allInvitations))
			allInvitations.map((invit) => {
				if (invit?.teamType === "Project") {
					count++;
				}
			});

		setNumberOfInvt(count);
	}, [allInvitations]);

	// this is to create the sample project
	const createProjectApi = () => {
		Api.post("project/create", {
			owner: user._id,
			ownerModelName: user.model,
			projectIdCode: `sp-${nanoid(10)}`,
			sample: true,
			user: userId,
			ownerModelName: "User",
			ownerProfile: user?.profile,
			creator: user.model === "User" ? userId : userProfile._id,
			participants: [user.profile],
			displayName: "Sample Project",
			description: "This is to demonstrate how projects will work.",
		}).then((team) => {
			handleTeams([team], state, dispatch);
		});
	};

	// this is also to create the sample project
	useEffect(() => {
		if (teamIds.length == 0) {
			createProjectApi();
		}
	}, [sortedProjectTeamIds]);

	useEffect(() => {
		let mapPointerArr = [];

		filteredRows.map((datarow) => {
			if (datarow?.parent?.latitude && datarow?.parent?.longitude) {
				mapPointerArr.push({
					latitude: datarow?.parent?.latitude,
					longitude: datarow?.parent?.longitude,
					label: datarow?.parent?.displayName,
					character: datarow?.parent?.displayName
						? datarow?.parent?.displayName.charAt(0)
						: "P",
					marker_color: "FF5F1F",
					marker_text_color: "ffffff",
				});
			}
		});

		setGeoPointers(mapPointerArr);
	}, [filteredRows]);

	return (
		<div className={classes.root}>
			<Paper className={classes.tableCont} elevation={0}>
				<div className={classes.searchInputBtnCont}>
					<Tooltip
						className={classes.reqNotification}
						title="Invitations"
						placement="top"
						onClick={() => {
							setShowInvitation(true);
						}}
					>
						<IconButton className={classes.inviIconBtn}>
							<MdOutlineNotificationAdd
								className={classes.inviIcon}
							/>
							<div className={classes.notificationNo}>
								{numberOfInvt}
							</div>
						</IconButton>
					</Tooltip>
					<TextField
						variant="outlined"
						onChange={(e) => onProjectNameChange(e.target.value)}
						value={searchQuery}
						placeholder="Search By Project Name"
						className={classes.searchTextBox}
						InputProps={{
							classes: { notchedOutline: classes.noBorder },
							className: classes.titleInput,
							startAdornment: (
								<InputAdornment position="start">
									<SearchIcon style={{ color: "#7C7C8D" }} />
								</InputAdornment>
							),
						}}
					/>
					<CustomBtn
						text={mdScreen ? "New" : "New Project"}
						onClick={() => {
							setDialogOpen(true);
						}}
						startPart={<AddIcon />}
					/>
				</div>
				<div className={classes.mapAndTableCont}>
					{mdScreen ? (
						<div className={classes.mapAndTableContForDesktop}>
							<div className={classes.mapCont}>
								<GoogleMapComponent
									marks={geoPointers}
									MakerType={"maker"}
									height={smallScreen ? 200 : 520}
									redius={smallScreen ? 350 : 800}
								/>
							</div>
							<div className={classes.projectTableCont}>
								<ProjectsTable rows={filteredRows} />
							</div>
						</div>
					) : (
						<div className={classes.mapAndTableContForDesktop}>
							<div className={classes.projectTableCont}>
								<ProjectsTable rows={filteredRows} />
							</div>
							<div className={classes.mapCont}>
								<GoogleMapComponent
									marks={geoPointers}
									MakerType={"maker"}
									height={smallScreen ? 200 : 520}
									redius={smallScreen ? 350 : 800}
								/>
							</div>
						</div>
					)}
				</div>
			</Paper>
			<NormalDialog
				openDialog={showInvitation}
				handleCloseShare={() => {
					setShowInvitation(false);
				}}
				pageTitle={"Invitations"}
				content={
					<div className={classes.invitCont}>
						<UserInvites
							type={"Project"}
							addCreatedOne={addCreatedOne}
						/>
					</div>
				}
			/>
			<CreateProjectDialog
				open={dialogOpen}
				setOpen={setDialogOpen}
				addCreatedOne={addCreatedOne}
			/>
		</div>
	);
}
