import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import { SlPencil } from "react-icons/sl";
import { IconButton } from "@material-ui/core";
import { useMediaQuery } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Avatar from "@material-ui/core/Avatar";
import profileLanguage from "../../../Assets/profileCertification.svg"
import { TbCertificate } from "react-icons/tb";
import { IoOpenOutline } from "react-icons/io5";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const useStyles = makeStyles((theme) => ({
  paper__section: {
    display: "flex",
    flexDirection: "column",
    fontSize: "1.05rem",
    marginTop: "1rem",
    border: "1px solid white",
    borderRadius: "3px",
    padding: "5px 15px 15px",
    "& $h2": {
      fontWeight: "400",
      fontSize: "1.5rem",
      display: "inline",
    },
    "& p": {
      color: "grey",
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      "& p": {
        width: "100%",
      },
    }
  },
  section__header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      fontWeight: "600",
      color: "#00345d",
    },
    [theme.breakpoints.down("xs")]: {
      "& h3": {
        width: "160px"
      },
    }
  },
  section__header2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& h3": {
      fontSize: "20px",
      color: "#2e73f8",
    }
  },
  emptyCont: {
    padding: "10px 0px",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    "& img": {
      width: "auto",
      height: "130px",
      marginTop: "-40px",
      marginBottom: "-15px"
    }
  },
  allExpEdit: {
    padding: "0px 30px",
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "95%",
      padding: "0px",
    },
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  singleExperienceCont: {
    display: "flex",
    alignItems: "flex-start",
    padding: "25px 5px"
  },
  imageCont: {
    marginRight: "15px"
  },
  experienceRight: {
    width: "calc(100% - 60px)",
    marginTop: "-4px",
    "& h3": {
      fontSize: "20px",
      fontWeight: "500"
    },
    "& h4": {
      fontSize: "16px",
      fontWeight: "450",
      display: "flex",
      alignItems: "center",
    },
    "& h5": {
      fontSize: "14px",
      fontWeight: "350",
      display: "flex",
      alignItems: "center",
    },
    [theme.breakpoints.down("xs")]: {
      "& h5": {
        flexDirection: "column",
        alignItems: "flex-start"
      }
    }
  },
  credentialLink: {
    width: "175px",
    border: "1px solid gray",
    padding: "2px 0px",
    fontSize: "16px",
    fontWeight: "500",
    color: "gray",
    marginTop: "10px",
    borderRadius: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  }
}));

const colorArr = [
  "#F39014",
  "#0A66C2",
  "#36e055"
]

const LicenseSection = ({
  isOwnProfile,
  profile,
  setShowLicense,
  setDialog,
  setOpenEditView,
  setProfileEditView
}) => {
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      {isOwnProfile ? (
        <Paper className={classes.paper__section}>
          <div className={classes.section__header}>
            <h3><span><TbCertificate style={{ marginBottom: "-2px" }} /></span> Licenses & Certifications</h3>
            <div>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setShowLicense(true);
                  setDialog("licenses");
                }}
              >
                <AddIcon />
              </IconButton>
              <IconButton
                className={classes.editBtn}
                onClick={() => {
                  setProfileEditView("certificat")
                  setOpenEditView(true)
                }}
              >
                <SlPencil />
              </IconButton>
            </div>
          </div>
          {user?.licenses && user?.licenses?.length !== 0 ? (
            <div className={classes.allExpEdit} >
              {user?.licenses.map((license, index) => (<>
                <div
                  className={classes.singleExperienceCont}
                  key={index}
                  style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                >
                  <Avatar
                    variant="square"
                    className={classes.imageCont}
                    style={{ backgroundColor: colorArr[index % 3] }}
                  >
                    <VerifiedUserIcon fontSize="large" />
                  </Avatar>
                  <div className={classes.experienceRight}>
                    <h3>{license?.title}</h3>
                    <h4>{license?.issuer}</h4>
                    <h5>
                      <div>{`Issued ${license?.start_date_honor}`}</div>
                      {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                      <div>{`Expires ${license?.end_date}`}</div>
                    </h5>
                    <h5>{license?.credentialId ? `Credential ID: ${license?.credentialId}` : null}</h5>
                    {license?.license_url && (
                      <div
                        className={classes.credentialLink}
                        onClick={() => { window.open(license?.license_url, '_blank').focus(); }}
                      > Show credential <IoOpenOutline /> </div>
                    )}
                  </div>
                </div>
              </>))}
            </div>
          ) : (<div className={classes.emptyCont} >
            <img src={profileLanguage} />
          </div>)}
        </Paper>
      ) : (
        <>
          {profile?.parent?.licenses && profile?.parent?.licenses?.length !== 0 ? (
            <Paper className={classes.paper__section}>
              <div className={classes.section__header2}>
                <h3><span><TbCertificate style={{ marginBottom: "-2px" }} /></span> Licenses & Certifications</h3>
                <div>
                </div>
              </div>
              <div className={classes.allExpEdit} >
                {profile?.parent?.licenses.map((license, index) => (
                  <div
                    className={classes.singleExperienceCont}
                    key={index}
                    style={index !== 0 ? { borderTop: "1px solid #e4e7e7" } : {}}
                  >
                    <Avatar
                      variant="square"
                      className={classes.imageCont}
                      style={{ backgroundColor: colorArr[index % 3] }}
                    >
                      <VerifiedUserIcon fontSize="large" />
                    </Avatar>
                    <div className={classes.experienceRight}>
                      <h3>{license?.title}</h3>
                      <h4>{license?.issuer}</h4>
                      <h5>
                        <div>{`Issued ${license?.start_date_honor}`}</div>
                        {isSmall ? null : (<FiberManualRecordIcon style={{ width: "5px", height: "5px", margin: "0px 4px" }} />)}
                        <div>{`Expires ${license?.end_date}`}</div>
                      </h5>
                      <h5>{license?.credentialId ? `Credential ID: ${license?.credentialId}` : null}</h5>
                      {license?.license_url && (
                        <div
                          className={classes.credentialLink}
                          onClick={() => { window.open(license?.license_url, '_blank').focus(); }}
                        > Show credential <IoOpenOutline /> </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </Paper>
          ) : null}
        </>
      )}
    </div>
  );
};
export default LicenseSection;