import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Api from "../../helpers/Api";
import OrgProfileTopView from "./OrgProfileTopView";
import UserProfileTopView from "./UserProfileTopView";
import GuestLayoutHeader from "../Navbar/GuestLayout.Header";
import GuestLayout from "../layout/GuestLayout";
import PostSideBar from "../post/PostSideBar";
import SuggestedCommunitySidebar from "../community/SuggestedCommunitiesSidebar";
import { getSuggestedCommunities } from "../community/api.call";
import useGetAdminProfiles from "./useGetAdminProfiles";
import AccountSkeleton from "../styled/CommonComponents/Account.Skeleton";


const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px 40px 30px",
    [theme.breakpoints.down("md")]: {
      padding: "10px 15px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px 10px 30px",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px 30px",
    },
  },
  profileCont: {
    width: "80%",
    [theme.breakpoints.down("md")]: {
      width: "70%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    }
  },
  sideBar: {
    width: "19%",
    position: "relative",
    [theme.breakpoints.down("md")]: {
      width: "28%",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  overLay: {
    position: "absolute",
    top: "0px",
    left: "0px",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0)"
  }
}));

export default function PublicProfileView() {
  const classes = useStyles();
  const { adminProfiles } = useGetAdminProfiles();
  const { user } = useSelector((state) => state.auth);
  const userProfileId = user?.profile;
  const { profileId } = useParams();
  const [profile, setProfile] = useState(null);
  const [suggestedCommunites, setSuggestedCommunities] = useState(null)
  const [suggestionLoading, setSuggestionLoading] = useState(false)

  const getAllSuggestedCommunities = async () => {
    setSuggestionLoading(true)
    await getSuggestedCommunities({
      profile: user?.profile,
      limit: 10,
      page: 0,
    })
      .then((data) => {
        setSuggestedCommunities(data || [])
        setSuggestionLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const getProfileData = async () => {
    const res = await Api.post("profile/getProfileParent", {
      profileId,
    });
    setProfile(res.data);
  }

  useEffect(() => {
    getAllSuggestedCommunities()
    getProfileData()
  }, [user?.profile])


  return (
    <GuestLayout
      HeaderProp={GuestLayoutHeader}
      headerHeight={"50px"}
    >
      <div className={classes.container}>
        <div className={classes.profileCont} >
          {profile?._id ? (
            profile?.parentModelName === "User" ? (
              <UserProfileTopView
                isGuestView={true}
                profile={profile}
                adminProfiles={adminProfiles}
                onSearch={true}
                isOwnProfile={userProfileId === profileId}
              />
            ) : (
              <OrgProfileTopView
                profile={profile}
                adminProfiles={adminProfiles}
                onSearch={true}
              />
            )
          ) : <>
            <AccountSkeleton />
          </>}
        </div>
        <div className={classes.sideBar} >
          <PostSideBar />
          <SuggestedCommunitySidebar
            suggestedCommunites={suggestedCommunites}
            suggestionLoading={suggestionLoading}
          />
          <div className={classes.overLay} ></div>
        </div>
      </div>
    </GuestLayout>
  )
}