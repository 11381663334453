import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Avatar, useMediaQuery } from "@material-ui/core";
import useGetProfile from "../profile/useGetProfile"
import YoutubeTuts from "../youtubeTuts";
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import {
  Button, Dialog, DialogActions, DialogContent,
  DialogContentText, DialogTitle, Snackbar, Chip
} from "@material-ui/core";
import Api from "../../helpers/Api";
import { Alert } from "@material-ui/lab";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import SimpleDocTable from "./diffDocTables/Simple.Doc.Table";
import PublicDocTable from "./diffDocTables/Public.Doc.Table";
import ESignTempleteTable from "./diffDocTables/ESign.Templete.Table";
import ESignEnvelopeTable from "./diffDocTables/ESign.Envelope.Table";
import { FaFileSignature } from "react-icons/fa";
import { MdOutlinePrivacyTip, MdOutlinePublic } from "react-icons/md";
import AllFoldersView from "./All.Folders.View";
import AddNewDialog from "./Add.New.Dialog";
import { BsFolderFill } from "react-icons/bs";

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "100%",
    height: "calc(100vh - 80px)",
    padding: "0px 0px 0px 50px",
    backgroundColor: "white",
    [theme.breakpoints.down("xs")]: {
      padding: "0px 0px 0px 10px",
      height: "calc(100vh - 130px)",
    }
  },
  docHead: {
    width: "100%",
    height: "70px",
    display: "flex",
    paddingRight: "30px",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #d8d5d5",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "5px",
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "17px",
      fontWeight: "500",
      marginLeft: "7px"
    },
    [theme.breakpoints.down("xs")]: {
      "& h3": {
        display: "none"
      },
    }
  },
  allDocsCont: {
    width: "100%",
    height: "calc(100% - 70px)",
    overflowY: "auto",
    paddingRight: "40px",
    [theme.breakpoints.down("xs")]: {
      paddingRight: "10px",
    }
  },
  folderHaed: {
    width: "100%",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      opacity: "0.8",
      display: "flex",
      alignItems: "center",
    }
  },
  allfoldersCont: {
    marginTop: "20px",
    display: "flex",
    flexWrap: "wrap",
  },
  allTypesDocsCont: {
    height: "",
    "& h3": {
      fontSize: "19px",
      margin: "30px 0px 20px"
    }
  },
  navOptionCont: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid #d8d5d5",
    "& div": {
      padding: "5px 15px",
      cursor: "pointer",
      fontSize: "17px",
      color: "gray",
      display: "flex",
      alignItems: "center",
      fontWeight: "500"
    },
    [theme.breakpoints.down("xs")]: {
      "& div": {
        padding: "4px 10px",
        fontSize: "14px",
      },
    }
  },
  selectedType: {
    padding: "5px 15px",
    fontSize: "17px",
    display: "flex",
    alignItems: "center",
    fontWeight: "500",
    color: theme.palette.primary.main,
    borderBottom: `2px solid ${theme.palette.primary.main}`
  },
  tableCont: {
    [theme.breakpoints.down("xs")]: {
      maxHeight: "calc(100vh - 285px)",
      overflowY: "auto",
    }
  }
}));

export default function ProfileDocs(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const profile = useGetProfile(profileId);

  const { auth } = useSelector((state) => state);
  const user = auth?.user;
  const docSignState = useSelector((state) => state.docSign);
  const accessToken = docSignState.accessToken;

  const [docusignAuthRedirectOpen, setDocusignAuthRedirectOpen] = useState(false);
  const [signProviderUserText, setSignProviderUserText] = useState("-");
  const [openSignSucess, setOpenSignSuccess] = useState(false);
  const [docTypeView, setDocTypeView] = useState("simple")

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClickOpenDocusignVerification = () => {
    setDocusignAuthRedirectOpen(true);
  };

  const handleCloseCancelDocusignVerification = () => {
    setDocusignAuthRedirectOpen(false);
    dispatch({
      type: "ToggleSignIn",
      payload: {
        isSignedIn: false,
      },
    });
    // window.location.href = config.BASE_URL;
  };

  const handleCloseDocusignVerification = () => {
    const redirect_uri = process.env.REACT_APP_DOCUSIGN_REDIRECT_URL;
    dispatch({
      type: "AddDoc",
      payload: {
        docusignProfileId: profileId,
      },
    });
    dispatch({
      type: "ToggleSignIn",
      payload: {
        isSignedIn: true,
      },
    });
    setDocusignAuthRedirectOpen(false);

    const integrationKey = process.env.REACT_APP_DOCUSIGN_APP_INTEGRATION_KEY;
    const url = process.env.REACT_APP_DOCUSIGN_BASE_SIGNIN_URI + `/auth?response_type=code&scope=signature%20impersonation&client_id=${integrationKey}&redirect_uri=${redirect_uri}`;
    window.location = url;
  };

  const handleClickSignSuccess = () => {
    setOpenSignSuccess(true);
  };

  const handleCloseSignSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSignSuccess(false);
  };

  const parseUrlForSign = () => {
    // let codeFromUrl = window.location.href.split("?code=")[1];
    // localStorage.setItem("codeFromUrl", codeFromUrl);
    let codeFromUrl = localStorage.getItem("codeFromUrl");
    const payload = { codeFromUrl: codeFromUrl };
    if (codeFromUrl != undefined && codeFromUrl != "") {
      Api.post("doc/sign/authdata", payload)
        .then((res) => {
          dispatch({
            type: "SignToken",
            payload: {
              accessToken: res.access_token,
              refreshToken: res.refresh_token,
            },
          });
        })
        .catch((err) => console.log(err));
      dispatch({
        type: "DocSignAuth",
        payload: codeFromUrl,
      });
      if (codeFromUrl) handleClickSignSuccess();
    }
  };

  //will be used in future DO NOT DELETE
  const getEnvelopeStatus = (envId) => {
    console.log("././././..............", envId);
    const payload = { accessToken: accessToken, envelopeId: envId };
    if (!!envId) {
      Api.post("doc/sign/envelopedata", payload)
        .then((res) => {
          return res.status;
        })
        .catch((err) => {
          console.log(err);
          return "Request Failure";
        });
    }
  };

  const checkSignProviderSignIn = () => {
    const payload = { accessToken: accessToken };
    Api.post("doc/sign/userdata", payload)
      .then((res) => {
        console.log("Userdata result is  ", res);
        if (res.email != undefined && res.email != "") {
          setSignProviderUserText(`${res.given_name} ${res.family_name}`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    checkSignProviderSignIn();
  }, []);



  return (
    <div className={classes.mainCont} >
      <Snackbar
        open={openSignSucess}
        autoHideDuration={6000}
        onClose={handleCloseSignSuccess}
      >
        <Alert
          onClose={handleCloseSignSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          Signature Provider Integration Success!
        </Alert>
      </Snackbar>
      <div className={classes.docHead} >
        <div
          className={classes.header}
          style={profile?.parentModelName === "DocFolder" ? {} : { cursor: "pointer" }}
          onClick={() => {
            if (profile?.parentModelName !== "DocFolder") {
              var path = `/profile/view/${profile?._id}`
              history.push(path);
            }
          }}
        >
          {profile?.parentModelName === "DocFolder" ? (<>
            {profile?.parent?.displayPicture?.thumbUrl || profile?.parent?.displayPicture?.url ? (
              <Avatar src={profile?.parent?.displayPicture?.thumbUrl || profile?.parent?.displayPicture?.url} />
            ) : (
              <BsFolderFill style={{ fontSize: "35px", marginRight: "7px", color: "gray" }} />
            )}
            <h3>{profile?.parent?.title}</h3>
          </>) : (<>
            <Avatar src={profile?.parent?.displayPicture?.thumbUrl || profile?.parent?.displayPicture?.url} />
            <h3>{profile?.parent?.displayName}</h3>
          </>)}
        </div>
        <div style={{ display: "flex", alignItems: "center" }} >
          {isMobile ? (<>
            {signProviderUserText != "-" ? (
              <Chip label={signProviderUserText} />
            ) : (
              <CustomBtn
                startPart={<VpnKeyIcon />}
                text={"DocuSign"}
                onClick={handleClickOpenDocusignVerification}
                style={{ marginRight: "10px" }}
              />
            )}
            <AddNewDialog profileId={profileId} />
            <YoutubeTuts
              name={"Documents"}
              dialogTitle={"Document Management"}
            />
          </>) : (<>
            {signProviderUserText != "-" ? (
              <Chip label={signProviderUserText} />
            ) : (
              <CustomBtn
                startPart={<VpnKeyIcon />}
                text={"DocuSign ™ Login"}
                onClick={handleClickOpenDocusignVerification}
                style={{ marginRight: "15px" }}
              />
            )}
            <AddNewDialog profileId={profileId} />
            <YoutubeTuts
              name={"Documents"}
              dialogTitle={"Document Management"}
            />
          </>)}
          <Dialog
            open={docusignAuthRedirectOpen}
            onClose={handleCloseDocusignVerification}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Integrating with signature provider?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Before continuing, you need to provide your consent by
                logging in with Docusign. After login come to this option
                again. Please allow for Pop-ups for this website to proceed.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseCancelDocusignVerification}>
                Go back
              </Button>
              <Button
                onClick={handleCloseDocusignVerification}
                autoFocus
                color="success"
                variant="contained"
              >
                Continue with docusign
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
      <div className={classes.allDocsCont} >
        <div>
          <div className={classes.folderHaed} >
            <h3>Folder</h3>
            <div></div>
          </div>
          <div className={classes.allfoldersCont} >
            <AllFoldersView />
          </div>
        </div>
        <div className={classes.allTypesDocsCont} >
          <h3>Document Management</h3>
          <div className={classes.navOptionCont} >
            <div
              className={docTypeView === "simple" && classes.selectedType}
              onClick={() => { setDocTypeView("simple") }}
              style={docTypeView === "simple" ? { color: theme.palette.primary.main } : {}}
            ><MdOutlinePrivacyTip style={{ marginRight: "5px" }} /> Private</div>
            <div
              className={docTypeView === "public" && classes.selectedType}
              onClick={() => { setDocTypeView("public") }}
              style={docTypeView === "public" ? { color: theme.palette.primary.main } : {}}
            ><MdOutlinePublic style={{ marginRight: "5px" }} /> Public</div>
            <div
              className={docTypeView === "eSignTemplate" && classes.selectedType}
              onClick={() => { setDocTypeView("eSignTemplate") }}
              style={docTypeView === "eSignTemplate" ? { color: theme.palette.primary.main } : {}}
            ><FaFileSignature style={{ marginRight: "5px" }} />Template</div>
            <div
              className={docTypeView === "eSignEnvelope" && classes.selectedType}
              onClick={() => { setDocTypeView("eSignEnvelope") }}
              style={docTypeView === "eSignEnvelope" ? { color: theme.palette.primary.main } : {}}
            ><FaFileSignature style={{ marginRight: "5px" }} />Envelope</div>
          </div>
          {docTypeView === "simple" && (<div className={classes.tableCont} >
            <SimpleDocTable />
          </div>)}
          {docTypeView === "public" && (<div className={classes.tableCont} >
            <PublicDocTable />
          </div>)}
          {docTypeView === "eSignTemplate" && (<div className={classes.tableCont} >
            <ESignTempleteTable />
          </div>)}
          {docTypeView === "eSignEnvelope" && (<div className={classes.tableCont} >
            <ESignEnvelopeTable />
          </div>)}
        </div>
      </div>
    </div>
  );
}
