import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTxTemplateCountByWallet, getTxTemplateByWallet } from '../../commonComponent/transaction/api';
import SlidingSideBar from '../../commonComponent/generator/SlidingSideBar';
import TransactionsTable from '../../commonComponent/Transactions.Table';

export default function BillGenerators(props) {
  const { walletId } = useParams()

  const [openTemplateDrawer, setOpenTemplateDrawer] = useState(false)
  const [selectedGenerator, setSelectedGenerator] = useState(null)

  return (
    <>
      <TransactionsTable
        walletId={walletId}
        editClickUrl={`/finance/${walletId}/bill-tmplate/edit/`}
        viewClickUrl={``}
        getDataApiHitFun={getTxTemplateByWallet}
        getDataObj={{
          walletId: walletId,
          type: "Bill"
        }}
        setSelectedTx={setSelectedGenerator}
        setOpenDetails={setOpenTemplateDrawer}
        getNumberOfDataRowApiHit={getTxTemplateCountByWallet}
        getDataNumberObj={{
          walletId: walletId,
          type: "Bill"
        }}
        emptyText={"No bill generator available"}
      />

      {selectedGenerator && (
        <SlidingSideBar
          openTemplateDrawer={openTemplateDrawer}
          setOpenTemplateDrawer={setOpenTemplateDrawer}
          selectedGenerator={selectedGenerator}
          editClickUrl={`/finance/${walletId}/bill/edit/`}
          viewClickUrl={`/finance/${walletId}/bill/view/`}
          walletId={walletId}
        />
      )}
    </>
  );
}