import {
  Box,
  Divider,
  IconButton,
  InputBase,
  makeStyles,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import FilesViewer from "../file/Viewer/FilesViewer";
import QuestionOptions from "./question.options";
import QuestionType from "./questiontype";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Api from "../../helpers/Api";
import { useDebounce } from "react-use";
import arrayToReducer from "../../helpers/arrayToReducer";
import { blue } from "@material-ui/core/colors";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import FileCopyIcon from "@material-ui/icons/FileCopy";

const useStyles = makeStyles((theme) => ({
  question: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // border: '1px solid red',
    width: "90%",
    [theme.breakpoints.down("sm")]: {
      width: "98%",
    },
    padding: "1rem",
    // marginBottom: "1rem",
    position: "relative",
    // overflow: "hidden",
    borderRadius: "15px",
    boxShadow: "rgba(0, 0, 0, 0.22) 4px 4px 4px",
    // height: "250px",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  // bar: {
  //   position: "absolute",
  //   width: "10px",
  //   backgroundColor: "#407afd",
  //   height: "100%",
  //   top: "0px",
  //   left: "-6px",
  //   transition: "all 200ms ease-in",
  // },
  barActive: {
    left: "0px !important",
  },
  questionTypeContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    width: "100%",
    // border:"1px solid green"
  },
  questionHeader: {
    textAlign: "left",
  },
  questionText: {
    width: "35%",
    padding: "2px",
    marginTop: "0.5rem",
  },
  questionAnswer: {
    width: "100%",
    // border: '1px solid red',
    // borderBottom: "1px solid grey",
  },
  questionEdit: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    // border: "1px solid green",
    justifyContent: "flex-end",
  },
}));
export default function Question(props) {
  const classes = useStyles();
  const {
    addQuestion,
    form,
    question,
    createQuestion,
    index,
    onDelete,
    isActive,
    setActiveQuestion,
    questionIds,
    setQuestionIds,
    setQuestionDict,
    questionDict,
  } = props;

  const [questionId, setQuestionId] = useState(question?._id);

  const [type, setType] = useState(question?.type || "Short Answer");
  const [questionText, setquestionText] = useState(
    question?.questionText || ""
  );
  const [questionImage, setquestionImage] = useState(
    question?.questionImage || ""
  );

  const [links, setLinks] = useState(question?.links || []);

  const [optionIds, setOptionIds] = useState([]);
  const [optionDict, setOptionDict] = useState([]);

  const [optionColIds, setOptionColIds] = useState([]);
  const [optionColDict, setOptionColDict] = useState([]);

  const [optionRowIds, setOptionRowIds] = useState([]);
  const [optionRowDict, setOptionRowDict] = useState([]);

  const [questionObj, setQuestionObj] = useState({
    type: question?.type || "Short Answer",
    questionText: question?.questionText || "",
    questionImage: question?.questionImage || "",
    links: question?.links || [],
  });
  useEffect(() => {
    if (question?.options.length > 0) {
      const { newDict, idArr } = arrayToReducer(question?.options);
      console.log("newDict = ", newDict);

      setOptionIds(idArr);
      setOptionDict(newDict);
    }
  }, [question]);
  useEffect(() => {
    if (question?.options.length > 0) {
      const { newDict, idArr } = arrayToReducer(question?.optionCols);

      setOptionColIds(idArr);
      setOptionColDict(newDict);
    }
    // console.log("updated: ", question);
  }, [question]);

  useDebounce(
    () => {
      if (questionId) {
        updateApi({
          _id: questionId,
          type,
          questionText,
          questionImage,
          links,
        });
      }
    },
    100,
    [type, questionText, questionImage, links]
  );

  const updateApi = async (question) => {
    console.log("updateeeeee");
    // setLoading(true);
    const res = await Api.post("question/update", question);
    // console.log(res);
    // setUpdated(true);
    // setLoading(false);
    const { newDict, idArr } = arrayToReducer(res?.data);
    console.log(res?.data);
    console.log("Question = ", newDict);
    setQuestionDict({
      ...questionDict,
      ...newDict,
    });
  };
  // if()

  return (
    <Box
      component={"div"}
      style={{
        width: "100%",
        minWidth: "95%",
        height: "100%",
        marginBottom: "1rem",
      }}
      onClick={() => {
        console.log(question?._id);
        setActiveQuestion(question?._id);
      }}
    >
      <Paper className={classes.question} variant="outlined">
        <div
          className={`${classes.bar} ${isActive ? classes.barActive : ""}`}
        ></div>
        <div
          className={
            isActive
              ? `${classes.questionTypeContainer}`
              : `${classes.questionHeader}`
          }
        >
          {isActive ? (
            <TextField
              size="small"
              //   variant="filled"
              label="Question"
              className={classes.questionText}
              value={questionText}
              onChange={(e) => setquestionText(e.target.value)}
              // disabled={!isActive}
            />
          ) : (
            <Typography variant="h6" component="p">
              {questionText ? questionText : "Question"}
            </Typography>
          )}{" "}
          &nbsp;
          {isActive && <QuestionType setType={setType} type={type} />}
        </div>
        <div className={classes.questionAnswer}>
          <QuestionOptions
            type={type}
            form={form}
            optionIds={optionIds}
            setOptionIds={setOptionIds}
            optionDict={optionDict}
            setOptionDict={setOptionDict}
            optionColIds={optionColIds}
            setOptionColIds={setOptionColIds}
            optionColDict={optionColDict}
            setOptionColDict={setOptionColDict}
            // optionRowIds={optionRowIds}
            // setOptionRowIds={setOptionRowIds}
            // optionRowDict={optionRowDict}
            // setOptionRowDict={setOptionRowDict}

            questionId={question?._id}
            question={question}
            questionIds={questionIds}
            setQuestionIds={setQuestionIds}
            questionDict={questionDict}
            setQuestionDict={setQuestionDict}
            isActive={isActive}
          />
        </div>
        {isActive && (
          <div className={classes.questionEdit}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                width: "15%",
              }}
            >
              <Tooltip title="Add new">
                <IconButton
                  aria-label="add"
                  onClick={() => {
                    // if (index + 1 == questionIds?.length) {
                    //   createQuestion(-1);
                    // } else {
                    createQuestion(index + 1);
                    // }
                  }}
                >
                  <AddCircleOutlineIcon />
                </IconButton>
              </Tooltip>
              {/* <Tooltip title="Add new">
                <IconButton aria-label="copy">
                  <FileCopyIcon />
                </IconButton>
              </Tooltip> */}

              <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => {
                    onDelete(question, index);
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </div>
          </div>
        )}
      </Paper>
    </Box>
  );
}
