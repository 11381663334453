import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, Button } from "@material-ui/core";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
    root: {
        width: "30%",
        height: "465px",
        borderRadius: "12px",
        backgroundColor: "white",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
        [theme.breakpoints.down("md")]: {
            width: "350px",
            marginBottom: "30px",
            borderRadius: "10px",
        },
        [theme.breakpoints.down("xs")]: {
            width: "320px",
            borderRadius: "8px",
        }
    },
    checkAndTextCont: {
        display: "flex",
        alignItems: "center",
        paddingLeft: "20px",
        "& div": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingRight: "20px"
        },
        "& p": {
            marginLeft: "5px",
            fontSize: "17px",
            fontWeight: "510"
        },
        [theme.breakpoints.down("xs")]: {
            paddingLeft: "10px",
            "& p": {
                marginLeft: "5px",
                fontSize: "15px",
                fontWeight: "500"
            },
        }
    },
    textCont: {
        padding: "30px 30px 14px",
        "& h4": {
            fontSize: "16px",
        },
        "& h3": {
            fontSize: "35px",
            fontWeight: "800"
        },
        "& h5": {
            fontSize: "17px"
        },
    },
    btnSty: {
        margin: "20px 30px 15px",
        "& .MuiButton-label": {
            textTransform: "capitalize"
        },
    }
}));

export default function FreeCard({ isMonthly }) {
    const classes = useStyles();

    const [projectManagement, setProjectManagement] = useState(true)
    const [documents, setDocuments] = useState(true)
    const [investmentAnalysis, setInvestmentAnalysis] = useState(false)
    const [propertyManagement, setPropertyManagement] = useState(false)
    const [accountingPayments, setAccountingPayments] = useState(false)

    return (
        <div className={classes.root} data-aos={"fade-up"} >
            <div className={classes.textCont} >
                <h4>Starter</h4>
                <h3>Free</h3>
                <h5>Apps</h5>
            </div>

            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={projectManagement}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    color="primary"
                />
                <p style={projectManagement ? { opacity: "1" } : { opacity: "0.6" }} >Project Management</p>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={documents}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    color="primary"
                />
                <p style={documents ? { opacity: "1" } : { opacity: "0.6" }} >Documents</p>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={investmentAnalysis}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    color="primary"
                />
                <div style={investmentAnalysis ? { opacity: "1" } : { opacity: "0.6" }} >
                    <p>Investment Analysis</p>
                    <p>$ {isMonthly ? "10" : "96"}</p>
                </div>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={propertyManagement}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    color="primary"
                />
                <div style={propertyManagement ? { opacity: "1" } : { opacity: "0.6" }} >
                    <p>Property management</p>
                    <p>$ {isMonthly ? "15" : "144"}</p>
                </div>
            </div>
            <div className={classes.checkAndTextCont} >
                <Checkbox
                    checked={accountingPayments}
                    checkedIcon={<CheckCircleIcon style={{ fontSize: "28px" }} />}
                    icon={<CancelIcon style={{ fontSize: "28px" }} />}
                    color="primary"
                />
                <div style={accountingPayments ? { opacity: "1" } : { opacity: "0.6" }} >
                    <p>Accounting & Payments</p>
                    <p>$ {isMonthly ? "15" : "144"}</p>
                </div>
            </div>

            <Button
                variant="contained"
                color="primary"
                className={classes.btnSty}
            >
                Get Started
            </Button>
        </div>
    );
}