import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import dwollaOnlyIcon from "../../../../../Assets/dwollaOnlyIcon.svg"
import CustomerTypeDialog from './customer.type.dialog';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "100%",
    padding: "20px 30px 30px",
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    marginBottom: "30px",
    [theme.breakpoints.down('xs')]: {
      padding: "15px 15px 20px",
    }
  },
  dwollaHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoCont: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginLeft: "10px"
    },
    [theme.breakpoints.down('xs')]: {
      "& h3": {
        fontSize: "22px",
        fontWeight: "500",
        marginLeft: "5px"
      },
    }
  },
  imgStyle: {
    height: "30px",
    width: "auto",
    [theme.breakpoints.down('xs')]: {
      height: "27px",
    }
  },
  paraText: {
    fontSize: '0.9rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    [theme.breakpoints.down('xs')]: {
      fontSize: "13px"
    }
  },
}));

export default function ConnectDwollaBtn(props) {
  const { wallet } = props;
  const theme = useTheme()
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));
  const [open, setOpen] = useState(false);

  return (
    <>
      <CustomerTypeDialog
        open={open}
        setOpen={setOpen}
        walletId={wallet?._id || null}
      />
      <Paper className={classes.paperStyle}>
        <div className={classes.dwollaHeader} >
          <div className={classes.logoCont} >
            <img
              src={dwollaOnlyIcon}
              className={classes.imgStyle}
            />
            <h3>Dwolla</h3>
          </div>
          <Button
            onClick={() => { setOpen(true) }}
            variant="contained"
            color="primary"
            startIcon={<PersonAddIcon />}
            size={smallScreen ? "small" : "medium"}
          >
            Create Account
          </Button>
        </div>

        <p className={classes.paraText}>
          Dwolla is preferred for US 🇺🇸 based businesses using ACH. It costs few cents in fees (0.5% - 50 cents max). We will negotiate <b>same day ACH</b> soon. It costs <b>$1 for an account</b>.
        </p>
        <p className={classes.paraText}>
          Dwolla enables you to send money to <b>anyone</b> with bank account info
        </p>
      </Paper>
    </>
  );
}
