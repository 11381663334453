
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import ButtonBase from '@material-ui/core/ButtonBase';
import { generateToken } from './api';

const useStyles = makeStyles((theme) => ({

}));

export default function PlaidBankLinkHelper(props) {
  const { walletId } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: 'AddWallet',
      payload: {
        plaidLinkToken: null,
      },
    });
  }, []);

  return (
    <ButtonBase onClick={() => { generateToken(walletId, dispatch, history) }} >
      {props.children}
    </ButtonBase>
  );
} 