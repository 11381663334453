export const nameSizeMax = '20rem';
export const categorySizeMax = '12rem';
export const qtySizeMax = '6rem';
export const rateSizeMax = '7rem';
export const taxSizeMax = '7rem';
export const totalSizeMax = '8rem';
export const fullMax = '51rem';


export const nameInputSizeMax = '19rem';
export const qtyInputSizeMax = '5rem';
export const rateInputSizeMax = '6rem';
export const taxInputSizeMax = '6rem';
export const totalInputSizeMax = '9rem';
