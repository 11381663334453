import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import emptyIcon from "../../../Assets/emptyData.svg"
import LessText from '../../styled/CommonComponents/LessText';
import Pagination from "@material-ui/lab/Pagination";
import Skeleton from '@material-ui/lab/Skeleton';
import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: "100%",
        overflowX: "auto",
    },
    root: {
        width: "100%",
        minWidth: "1200px",
        height: "100%",
        overflowX: "auto",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        "& .MuiTablePagination-root": {
            border: "1px solid #d2d1d1",
            marginTop: "-3px"
        }
    },
    container: {
        width: '100%',
        height: "calc(100% - 52px)",
    },
    tbHeaderCell: {
        color: "#696969",
        fontSize: "15px",
        padding: "0px",
    },
    tbBodyCell: {
        padding: "8px 5px"
    },
    pageLoaderCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        "& img": {
            width: "250px",
            height: "auto"
        },
        "& h3": {
            fontSize: "15px",
            fontWeight: "400",
            marginTop: "10px"
        }
    },
    paginationCont: {
        width: "100%",
        height: "50px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "1px solid #d2d1d1",
    }
}));

export default function TransactionsTable({
    walletId, getDataApiHitFun, getDataObj, getNumberOfDataRowApiHit,
    getDataNumberObj, emptyText, setSelectedTx, setOpenDetails
}) {
    const history = useHistory();
    const classes = useStyles();

    const [allData, setAllData] = useState([{ data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }, { data: null }])
    const [totalPages, setTotalPages] = useState(0)
    const [pagesBool, setPagesBool] = useState([true])
    const [curPage, setCurPage] = useState(1)
    const [pageLimit, setPageLimit] = useState(15)



    const getDataForTable = async (allDatak) => {
        await getDataApiHitFun({
            ...getDataObj,
            curPage: (curPage - 1),
            limit: pageLimit
        })
            .then((allDataArray) => {
                if (allDataArray && allDataArray.length > 0) {
                    let pageStart = (curPage - 1) * pageLimit

                    let updatedDataArray = [...allDatak]

                    allDataArray.map((data) => {
                        updatedDataArray[pageStart] = data;
                        pageStart++;
                    })

                    setAllData(updatedDataArray)
                } else {
                    setAllData([])
                }
            })
            .catch((error) => {
                console.log(error)
                setAllData([])
            })
    }

    useEffect(() => {
        getNumberOfDataRowApiHit(getDataNumberObj)
            .then((data) => {
                let totalNumbers = data[0]?.numberOfDocs || 0
                let dataArr = [];

                if (totalNumbers && totalNumbers !== 0) {
                    for (let i = 0; i < totalNumbers; i++) {
                        dataArr.push({ data: null })
                    }
                    setAllData(dataArr)

                    let locTotalPage = Math.ceil(totalNumbers / pageLimit);
                    setTotalPages(locTotalPage)

                    let arr = []
                    for (let i = 0; i < locTotalPage; i++) {
                        if (i === 0) {
                            arr.push(true)
                        } else {
                            arr.push(false)
                        }
                    }
                    setPagesBool(arr)
                }

                getDataForTable(dataArr)
            })
            .catch((err) => {
                console.log(err)
            })
    }, [walletId])

    useEffect(() => {
        if (!pagesBool[curPage - 1] && curPage !== 1) {
            getDataForTable(allData)
            let arr = [...pagesBool]
            arr[curPage - 1] = true
            setPagesBool(arr)
        }
    }, [curPage])

    const getFormatedData = (data) => {
        let formatedData = new Intl.NumberFormat('en-GB', { notation: "compact", compactDisplay: "short" }).format(data);
        return formatedData;
    }

    return (
        <div className={classes.mainCont} >
            {allData && allData.length > 0 ? (<Paper className={classes.root}>
                <TableContainer className={classes.container}>
                    <Table stickyHeader aria-label="sticky table" size={'small'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "15px", width: "65px" }} >
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "95px" }} >
                                    Date
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "80px" }} >
                                    Type
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "70px" }} >
                                    No
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px", width: "150px" }} >
                                    Customer
                                </TableCell>
                                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                                    Memo
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "120px" }} >
                                    Due Date
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "90px" }} >
                                    Amount
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                                    Amount Paid
                                </TableCell>
                                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "15px", width: "110px" }} >
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {allData && allData.length > 0 && allData.slice((curPage - 1) * pageLimit, (curPage - 1) * pageLimit + pageLimit).map((data) => {
                                return (
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        tabIndex={-1}
                                        key={data._id}
                                        onClick={() => {
                                            if (data?._id) {
                                                setSelectedTx(data);
                                                setOpenDetails(true);
                                            }
                                        }}
                                        style={data?._id ? { cursor: "pointer" } : {}}
                                    >
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "15px", width: "65px" }} >
                                            {data?._id ? (
                                                <Avatar
                                                    alt={walletId === data?.firstPartyWallet ?
                                                        data?.secondParty?.parent?.displayName :
                                                        data?.firstParty?.parent?.displayName}
                                                    src={walletId === data?.firstPartyWallet ?
                                                        data?.secondParty?.parent?.displayPicture?.url :
                                                        data?.firstParty?.parent?.displayPicture?.url}
                                                    style={{ width: "30px", height: "30px" }}
                                                />
                                            ) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                {new Date(data?.createdAt).getMonth() + 1 + "/" + new Date(data?.createdAt).getDate() + "/" + new Date(data?.createdAt).getFullYear()}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "80px" }} >
                                            {data?._id ? (<>
                                                {data?.type === "Bill" ? (walletId === data?.firstPartyWallet ? (<>Expense</>) : (<>Receipt</>)) : (<></>)}
                                                {data?.type === "Invoice" ? (walletId === data?.firstPartyWallet ? (<>Invoice</>) : (<>Bill</>)) : (<></>)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "70px" }} >
                                            {data?._id ? (<>
                                                {data?.type === "Bill" && data?.billNo}
                                                {data?.type === "Invoice" && data?.invNo}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", width: "150px" }} >
                                            {data?._id ? (<>
                                                <LessText
                                                    limit={15}
                                                    string={walletId === data?.firstPartyWallet ?
                                                        data?.secondParty?.parent?.displayName :
                                                        data?.firstParty?.parent?.displayName}
                                                />
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="left" className={classes.tbBodyCell} style={{ padding: "0px", paddingLeft: "5px", fontSize: "12px", wordBreak: "break-all" }} >
                                            {data?._id ? (<>
                                                <LessText
                                                    limit={70}
                                                    string={data?.memo}
                                                />
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "120px" }} >
                                            {data?._id ? (<>
                                                {new Date(data?.dueDate).getMonth() + 1 + "/" + new Date(data?.dueDate).getDate() + "/" + new Date(data?.dueDate).getFullYear()}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "90px" }} >
                                            {data?._id ? (<>
                                                ${getFormatedData(data?.finalAmount || 0)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "5px", width: "110px" }} >
                                            {data?._id ? (<>
                                                ${getFormatedData(data?.amountPaid || 0)}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                        <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "0px", paddingRight: "15px", width: "110px" }} >
                                            {data?._id ? (<>
                                                {data?.status}
                                            </>) : (
                                                <Skeleton variant="rect" style={{ width: "100%", height: "20px" }} />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <div className={classes.paginationCont} >
                    <Pagination
                        count={totalPages}
                        page={curPage}
                        color="primary"
                        siblingCount={0}
                        onChange={(event, value) => { setCurPage(value) }}
                    />
                </div>
            </Paper>) : (<div className={classes.pageLoaderCont} >
                <img src={emptyIcon} />
                <h3>{emptyText}</h3>
            </div>)}
        </div>
    );
}















// [
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "63e3dc3a28a7088760178414",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "dueDate": "2023-02-08T17:30:34.200Z",
//         "createdAt": "2023-02-08T17:30:34.200Z",
//         "invNo": 2012,
//         "finalAmount": 540,
//         "secondParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "secondPartyWallet": "629cb54a5631657dc08df732"
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "63582705295da43dbce3bed1",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "dueDate": "2022-10-25T18:12:21.719Z",
//         "createdAt": "2022-10-25T18:12:21.720Z",
//         "invNo": 2011,
//         "finalAmount": 0,
//         "secondParty": {
//             "_id": "61e56958342a145c6c502a39",
//             "parent": {
//                 "displayName": "Rajiv Harlalka",
//                 "_id": "61e56957342a145c6c502a1b",
//                 "displayPicture": {
//                     "_id": "61e56957342a145c6c502a1a",
//                     "url": "https://lh3.googleusercontent.com/a-/AOh14GiIJUb9BFmP_jm3m1qq3eKlB5nEwSfaYKChgMk44g=s96-c",
//                     "thumbUrl": "https://lh3.googleusercontent.com/a-/AOh14GiIJUb9BFmP_jm3m1qq3eKlB5nEwSfaYKChgMk44g=s96-c"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "secondPartyWallet": "61e56957342a145c6c502a1c"
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 34,
//         "_id": "632dea5c164218bf1809e2b0",
//         "invNo": 2010,
//         "type": "Invoice",
//         "finalAmount": 1220,
//         "dueDate": "2022-09-30T17:16:00.000Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "secondPartyWallet": "62e8d06ee40b42329c4c5d94",
//         "secondParty": {
//             "_id": "62e8d06fe40b42329c4c5db6",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "62e8d06be40b42329c4c5d93",
//                 "displayPicture": {
//                     "_id": "62e8d06be40b42329c4c5d92",
//                     "url": "https://firebasestorage.googleapis.com/v0/b/contractflo-prod.appspot.com/o/Profile%20DP.svg?alt=media",
//                     "thumbUrl": "https://firebasestorage.googleapis.com/v0/b/contractflo-prod.appspot.com/o/Profile%20DP.svg?alt=media"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "createdAt": "2022-09-23T17:18:20.009Z"
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "63220a1b4b1c052940507645",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "dueDate": "2022-10-15T17:13:58.379Z",
//         "createdAt": "2022-09-14T17:06:35.084Z",
//         "invNo": 2009,
//         "finalAmount": 4567.5,
//         "secondParty": {
//             "_id": "631cc1299054177f54699d3b",
//             "parent": {
//                 "displayName": "check unit",
//                 "_id": "631cc1299054177f54699d3a",
//                 "displayPicture": {
//                     "_id": "631cc1299054177f54699d39",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "secondPartyWallet": "631cc12c9054177f54699d3f"
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "631ece7140124b8ce4b8f4db",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "dueDate": "2022-09-12T06:15:13.081Z",
//         "createdAt": "2022-09-12T06:15:13.082Z",
//         "invNo": 2008,
//         "finalAmount": 3040.5,
//         "secondParty": {
//             "_id": "61db1ec448a9665fe030fecd",
//             "parent": {
//                 "displayName": "Karan Parmar",
//                 "_id": "61db1ec448a9665fe030feaf",
//                 "displayPicture": {
//                     "_id": "61db1ec448a9665fe030feae",
//                     "url": "https://lh3.googleusercontent.com/a-/AOh14GhfOuZ0S6SLWSV--jVHUx-8jpUcBDnHtg86kvPj5Q=s96-c",
//                     "thumbUrl": "https://lh3.googleusercontent.com/a-/AOh14GhfOuZ0S6SLWSV--jVHUx-8jpUcBDnHtg86kvPj5Q=s96-c"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "secondPartyWallet": "61db1ec448a9665fe030feb0"
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "631ecb3740124b8ce4b8f4b7",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "dueDate": "2022-09-12T06:01:27.402Z",
//         "createdAt": "2022-09-12T06:01:27.402Z",
//         "invNo": 1034,
//         "finalAmount": 0
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "631db0362f8d9465e4c9af85",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "dueDate": "2022-09-11T09:53:58.624Z",
//         "createdAt": "2022-09-11T09:53:58.624Z",
//         "invNo": 1033,
//         "finalAmount": 0,
//         "secondParty": {
//             "_id": "625670059084210944dc0148",
//             "parent": {
//                 "displayName": "Rana Dabnath",
//                 "_id": "625670019084210944dc012a",
//                 "displayPicture": {
//                     "_id": "625670019084210944dc0129",
//                     "url": "https://lh3.googleusercontent.com/a-/AOh14GgzjZeHtvf8B84gWRK-3XAR4SMmadI9QZastFyV=s96-c",
//                     "thumbUrl": "https://lh3.googleusercontent.com/a-/AOh14GgzjZeHtvf8B84gWRK-3XAR4SMmadI9QZastFyV=s96-c"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "secondPartyWallet": "625670039084210944dc012b"
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 0,
//         "_id": "63164c87c359b806b05dc330",
//         "type": "Invoice",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "dueDate": "2022-09-05T19:22:47.305Z",
//         "createdAt": "2022-09-05T19:22:47.305Z",
//         "invNo": 1031,
//         "finalAmount": 0
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 40,
//         "_id": "6308822eb8c78d5a4063eb79",
//         "invNo": 1030,
//         "type": "Invoice",
//         "finalAmount": 150,
//         "dueDate": "2022-10-06T03:04:00.000Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T08:19:58.340Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         }
//     },
//     {
//         "status": "Paid",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 66,
//         "_id": "6308732af4630c7edc8fffe0",
//         "invNo": 1029,
//         "type": "Invoice",
//         "finalAmount": 1221,
//         "dueDate": "2022-08-26T06:07:33.443Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T07:15:54.800Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "amountPaid": 1257
//     },
//     {
//         "status": "Processing",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 30,
//         "_id": "6308708c633cb8787c582177",
//         "invNo": 1028,
//         "type": "Invoice",
//         "finalAmount": 2080,
//         "dueDate": "2022-10-13T05:56:00.000Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T07:04:44.330Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         }
//     },
//     {
//         "status": "Paid",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 30,
//         "_id": "63086fd7c0b67f4b0871c4ee",
//         "invNo": 1027,
//         "type": "Invoice",
//         "finalAmount": 630,
//         "dueDate": "2022-10-13T05:56:00.000Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T07:01:43.445Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         },
//         "amountPaid": 648
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 66,
//         "_id": "63086f02bf446843d48d687f",
//         "invNo": 1026,
//         "type": "Invoice",
//         "finalAmount": 1821,
//         "dueDate": "2022-08-26T06:07:33.443Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T06:58:10.699Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         }
//     },
//     {
//         "status": "Processing",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 66,
//         "_id": "63086eb5a0bafa27a4120c67",
//         "invNo": 1025,
//         "type": "Invoice",
//         "finalAmount": 1221,
//         "dueDate": "2022-08-26T06:07:33.443Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T06:56:53.383Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         }
//     },
//     {
//         "status": "Draft",
//         "memo": "",
//         "lateFeeApplicable": false,
//         "lateFeeAmount": 40,
//         "_id": "63086c3a5ce4bc85849a8878",
//         "invNo": 1024,
//         "type": "Invoice",
//         "finalAmount": 600,
//         "dueDate": "2022-10-06T03:04:00.000Z",
//         "firstPartyWallet": "629cb54a5631657dc08df732",
//         "firstParty": {
//             "_id": "629cb5475631657dc08df72e",
//             "parent": {
//                 "displayName": "New After Wallet Added",
//                 "_id": "629cb5475631657dc08df72d",
//                 "displayPicture": {
//                     "_id": "629cb5465631657dc08df72c",
//                     "url": "https://i.ibb.co/L87QjS5/msproject.png",
//                     "thumbUrl": "https://i.ibb.co/L87QjS5/msproject.png"
//                 }
//             },
//             "parentModelName": "Project"
//         },
//         "createdAt": "2022-08-26T06:46:18.624Z",
//         "secondPartyWallet": "61efbc8a9a455199e48d0276",
//         "secondParty": {
//             "_id": "61efbc8b9a455199e48d0293",
//             "parent": {
//                 "displayName": "Rana Debnath",
//                 "_id": "61efbc8a9a455199e48d0275",
//                 "displayPicture": {
//                     "_id": "63f52d4b4c5ef343b026bbc6",
//                     "url": "https://contractflo-dev.s3-us-west-2.amazonaws.com/61efbc8a9a455199e48d0275/63f52d4b4c5ef343b026bbc6/f30bf288-bb81-44a4-871c-319a0218993e.jpg"
//                 }
//             },
//             "parentModelName": "User"
//         }
//     }
// ]