import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, useMediaQuery, useTheme } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { MdPhotoSizeSelectActual, MdAddPhotoAlternate } from "react-icons/md";
import VisibilityIcon from '@material-ui/icons/Visibility';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CustomFileUploadButton from "../../file/Uploader/CustomFileUploadButton";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        position: "relative",
        "& .overlay": {
            display: "none",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0,0,0,0.1)",
            position: "absolute",
            top: "0px",
            left: "0px",
            alignItems: "center",
            justifyContent: "center"
        },
        "&:hover .overlay": {
            display: "flex",
        }
    },
    singleImageCont: {
        height: "48%",
        width: "32.5%",
        border: "1px dashed #c6c9cc",
        borderRadius: "7px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundSize: "cover",
        backgroundPosition: "center",
        flexDirection: "column",
        "& p": {
            fontSize: "12px",
            color: "gray",
            marginTop: "7px"
        },
        [theme.breakpoints.down('sm')]: {
            height: "100%",
        }
    },
    photoIcon: {
        width: "40%",
        height: "40%",
        color: "#d7d4d4"
    },
    iconCont: {
        width: "35px",
        height: "35px",
        color: theme.palette.primary.main
    },
    iconsCont: {
        width: "150px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    moreTextCont: {
        position: "absolute",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0,0,0,0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "white",
        "& h3": {
            fontSize: "35px",
            fontWeight: "700",
            color: "white"
        },
        "& h4": {
            fontSize: "13px",
            fontWeight: "400",
            color: "white"
        },
        "& p": {
            fontSize: "18px",
            fontWeight: "510",
            color: "white",
            marginTop: "-5px"
        },
        [theme.breakpoints.down('sm')]: {
            "& h3": {
                fontSize: "25px"
            },
            "& h4": {
                fontSize: "10px",
            },
            "& p": {
                fontSize: "13px",
            },
        }
    },
    fullImageViewr: {
        position: "absolute",
        top: "0px",
        left: "0px",
        width: "100%",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.4)",
        zIndex: "9999"
    },
    closeBtnCont: {
        position: "absolute",
        top: "10px",
        right: "10px"
    },
    imageArrowCont: {
        width: "100%",
        height: "100%",
        display: "flex",
        padding: "20px 0px"
    },
    mainImageCont: {
        width: "calc(100% - 200px)",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100% - 80px)",
        }
    },
    arrowCont: {
        width: "100px",
        height: '100%',
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "40px",
        }
    },
    arrowIcon: {
        color: "white",
        fontSize: "35px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "20px",
        }
    }
}));


export default function MultiplePhotoViewer(props) {
    const { actualImages, addPhotosToProjectApiCall, projectData } = props;
    const history = useHistory();
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();

    const [openFullView, setOpenFullView] = useState(false)
    const [size, setSize] = useState(0);
    const [curPhoto, setCurPhoto] = useState(0)
    const [images, setImages] = useState([])

    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    useEffect(() => {
        let dataArr = [...actualImages]

        if (isSmall) {
            let len = actualImages.length
            if (len < 3) {
                let needMore = 3 - len;
                for (let i = 0; i < needMore; i++) {
                    dataArr.push({
                        url: null,
                        _id: null
                    })
                }
            }
        } else {
            let len = actualImages.length
            if (len < 6) {
                let needMore = 6 - len;
                for (let i = 0; i < needMore; i++) {
                    dataArr.push({
                        url: null,
                        _id: null
                    })
                }
            }
        }
        setSize(actualImages.length)
        setImages(dataArr)
    }, [actualImages, isSmall])


    return (<>
        <div className={classes.root}>
            {isSmall ? (<>
                {images.slice(0, 3).map((image, i) => {
                    if (i === 2) {
                        return (image?.url ? (
                            <div
                                className={classes.singleImageCont}
                                style={{
                                    backgroundImage: `url(${image?.url})`,
                                    position: "relative",
                                    border: "none"
                                }}
                            >
                                <div className={classes.moreTextCont} >
                                    <h3>+{size - 3}</h3>
                                    <div style={{ marginLeft: "5px" }} >
                                        <h4>More</h4>
                                        <p>Photos</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.singleImageCont} >
                                <MdPhotoSizeSelectActual className={classes.photoIcon} />
                            </div>
                        ))
                    } else {
                        return (image?.url ? (
                            <div
                                className={classes.singleImageCont}
                                style={{
                                    backgroundImage: `url(${image?.url})`,
                                    border: "none"
                                }}
                            >
                            </div>
                        ) : (
                            <div className={classes.singleImageCont} >
                                <MdPhotoSizeSelectActual className={classes.photoIcon} />
                            </div>
                        ))
                    }
                })}
            </>) : (<>
                {images.slice(0, 6).map((image, i) => {
                    if (i === 5) {
                        return (image?.url ? (
                            <div
                                className={classes.singleImageCont}
                                style={{
                                    backgroundImage: `url(${image?.url})`,
                                    position: "relative",
                                    border: "none"
                                }}
                            >
                                <div className={classes.moreTextCont} >
                                    <h3>+{size - 6}</h3>
                                    <div style={{ marginLeft: "5px" }} >
                                        <h4>More</h4>
                                        <p>Photos</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className={classes.singleImageCont} >
                                <MdPhotoSizeSelectActual className={classes.photoIcon} />
                            </div>
                        ))
                    } else {
                        return (image?.url ? (
                            <div
                                className={classes.singleImageCont}
                                style={{
                                    backgroundImage: `url(${image?.url})`,
                                    border: "none"
                                }}
                            >
                            </div>
                        ) : (
                            <div className={classes.singleImageCont} >
                                <MdPhotoSizeSelectActual className={classes.photoIcon} />
                            </div>
                        ))
                    }
                })}
            </>)}
            <div className="overlay" >
                <div className={classes.iconsCont} >
                    <CustomFileUploadButton
                        showComponent={
                            <IconButton style={{ backgroundColor: "white", padding: "7px" }} >
                                <MdAddPhotoAlternate className={classes.iconCont} />
                            </IconButton>
                        }
                        parentType={"Project"}
                        parentId={projectData?._id}
                        fileNum={25}
                        givenFileTypes={["image", "video"]}
                        givenMaxSize={26214400}
                        closeFunCall={addPhotosToProjectApiCall}
                    />
                    {actualImages && actualImages.length > 0 ? (
                        <IconButton
                            style={{ backgroundColor: "white", padding: "7px" }}
                            onClick={() => { setOpenFullView(true) }}
                        >
                            <VisibilityIcon className={classes.iconCont} />
                        </IconButton>
                    ) : (
                        <IconButton
                            style={{ backgroundColor: "white", padding: "7px", opacity: "0.7" }}
                            disabled
                        >
                            <VisibilityIcon className={classes.iconCont} />
                        </IconButton>
                    )}
                </div>
            </div>
        </div>

        {openFullView && (
            <div className={classes.fullImageViewr} >
                <IconButton
                    className={classes.closeBtnCont}
                    onClick={() => { setOpenFullView(false) }}
                >
                    <CancelIcon style={{ color: "white", fontSize: "35px" }} />
                </IconButton>
                <div className={classes.imageArrowCont} >
                    <div className={classes.arrowCont} >
                        <IconButton
                            onClick={() => {
                                let next = curPhoto - 1;
                                if (next < 0) {
                                    setCurPhoto(size - 1)
                                } else {
                                    next = next % size
                                    setCurPhoto(next)
                                }
                            }}
                        >
                            <ArrowBackIosIcon className={classes.arrowIcon} />
                        </IconButton>
                    </div>
                    <div className={classes.mainImageCont} >
                        <img
                            style={{ width: "100%", maxHeight: "100%" }}
                            src={actualImages[curPhoto]?.url}
                        />
                    </div>
                    <div className={classes.arrowCont} >
                        <IconButton
                            onClick={() => {
                                let next = curPhoto + 1;
                                next = next % size
                                setCurPhoto(next)
                            }}
                        >
                            <ArrowForwardIosIcon className={classes.arrowIcon} />
                        </IconButton>
                    </div>
                </div>
            </div>
        )}
    </>);
}