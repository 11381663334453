import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTheme } from "styled-components";
import Paper from "@material-ui/core/Paper";
import { useDispatch } from "react-redux";
import IconButton from "@material-ui/core/IconButton";
import SelectDocDialog from "../../doc/select.dialog";
import DocumentCardSvg from "../../../Assets/Doc_logo.svg";
import DescriptionIcon from "@material-ui/icons/Description";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Avatar } from "@material-ui/core";
import { AvatarGroup } from "@material-ui/lab";
import PreviewDialog from "../../doc/diffDocTables/Preview.Dialog";
import AddNewDialog from "../../doc/Add.New.Dialog";
import LessText from "../../styled/CommonComponents/LessText";
import { format } from "timeago.js";
import emptyIcon from "../../../Assets/vivekEmpty.svg"
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  cardContainer: {
    width: "49%",
    height: "370px",
    borderRadius: "10px",
    marginBottom: "20px",
    padding: "10px 15px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "10px 5px",
    },
  },
  cardTopHeading: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& > h3": {
      fontWeight: "510",
      fontSize: "17px",
      color: "#193B56"
    }
  },
  createBtn: {
    width: "25px",
    height: "25px",
    borderRadius: "6px",
    backgroundColor: "#c1def6",
    color: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: "#70b8f4",
      color: "white"
    }
  },
  topBox: {
    width: "100%",
    padding: "3px 10px",
    display: "flex",
    cursor: "pointer",
    alignItems: "flex-start",
    marginTop: "10px",
    marginBottom: "20px",
    marginRight: "15px",
    "& > p": {
      fontSize: "15px",
      marginTop: "10px",
      color: "#696969"
    },
    "&:hover": {
      backgroundColor: "#edecec"
    }
  },
  topBoxSvgCont: {
    width: "120px",
    height: "70px",
    display: "flex",
    overflow: "hidden",
    alignItems: "center",
    justifyContent: "center",
    "& > img": {
      width: "95px",
      height: "95px",
    }
  },
  container: {
    width: "100%",
    height: "215px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  timeSty: {
    fontSize: "10px",
    fontWeight: "500",
    color: "gray"
  },
  iconSty: {
    fontSize: "38px"
  },
  tbHeaderCell: {
    borderTop: "1px solid #e0e0e0",
    color: "#696969",
    fontSize: "15px",
    padding: "0px",
  },
  tbBodyCell: {
    border: "none",
    padding: "8px 5px"
  },
  avatarGroupSty: {
    maxWidth: "100px",
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px"
    }
  },
  skeletonCont: {
    paddingTop: "10px",
    width: "100%",
    height: "175px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  emptyCont: {
    display: "flex",
    width: "100%",
    height: "175px",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "center",
    overflow: "hidden",
    "& img": {
      height: "100px",
      width: "auto"
    },
    "& p": {
      fontSize: "12px",
      color: "gray",
      textAlign: "center",
      marginTop: "5px"
    }
  }
}));

const AnalysisCard = ({ docs, profileId, loader }) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();

  const [docDialogOpen, setDialogOpen] = useState(false);
  const [docPreviewDialog, setDocPreviewDialog] = useState(false);
  const [selectedDoc, setSelectedDoc] = useState({});
  const [addNewDialog, setAddNewDialog] = useState(false)

  return (
    <Paper className={classes.cardContainer}>
      <div className={classes.cardTopHeading} >
        <h3>Documents</h3>
        <IconButton
          className={classes.createBtn}
          onClick={() => { setAddNewDialog(true) }}
          color="primary"
        >+</IconButton>
      </div>

      <Paper
        elevation={1}
        onClick={() => history.push("/docs/" + profileId)}
        className={classes.topBox}
      >
        <div className={classes.topBoxSvgCont} >
          <img src={DocumentCardSvg} />
        </div>
        <p>Create documents, share publicly or across teams and use e-signature.</p>
      </Paper>

      {loader ? (<>
        <TableContainer style={{ width: "100%" }} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Title
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  Files
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                  Shared
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={classes.skeletonCont} >
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
          <Skeleton variant="rect" style={{ width: "100%", marginBottom: "10px" }} height={50} />
        </div>
      </>) : (<>{docs && docs.length > 0 ? (
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Title
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  Files
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                  Shared
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {docs && docs.length > 0 && docs.map((row, index) => (
                <TableRow
                  hover
                  role="checkbox"
                  tabIndex={-1}
                  key={index}
                  onClick={() => {
                    setSelectedDoc(docs[index]);
                    setDocPreviewDialog(true);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <TableCell align="left" className={classes.tbBodyCell} style={{ paddingLeft: "0px" }} >
                    <div style={{ display: "flex", alignItems: "center" }} >
                      <DescriptionIcon className={classes.iconSty} color="primary" />
                      <div style={{ marginLeft: "5px" }} >
                        <p style={{ fontSize: "12px", color: "#333333", fontWeight: "510", textTransform: "capitalize" }} >
                          <LessText
                            limit={14}
                            string={row?.title}
                          />
                        </p>
                        <p style={{ fontSize: "10px", color: "gray" }} >
                          {`By `}
                          <LessText limit={11} string={row?.user?.displayName} />
                        </p>
                        <p className={classes.timeSty} >{format(row?.createdAt)}</p>
                      </div>
                    </div>
                  </TableCell>
                  <TableCell align="center" className={classes.tbBodyCell} style={{ padding: "0px", color: "#4F4F4F", fontSize: "13px", fontWeight: "510" }} >{row && row.files && row.files.length > 0 ? row.files.length : 0}{` File(s)`}</TableCell>
                  <TableCell align="right" className={classes.tbBodyCell} style={{ padding: "10px 5px" }} >
                    <div style={{ display: "flex", justifyContent: "flex-end" }} >
                      <AvatarGroup max={3} className={classes.avatarGroupSty} >
                        {row?.shared && row?.shared.length > 0 && row?.shared.map((participant, idx) => (
                          <Avatar
                            key={idx}
                            alt={participant?.parent?.displayName}
                            src={participant?.parent?.displayPicture?.url}
                          />
                        ))}
                      </AvatarGroup>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (<>
        <TableContainer style={{ width: "100%" }} >
          <Table stickyHeader aria-label="sticky table" size="small">
            <TableHead>
              <TableRow style={{ position: "relative", zIndex: "99", height: "40px" }} >
                <TableCell align="left" className={classes.tbHeaderCell} style={{ paddingLeft: "5px" }} >
                  Title
                </TableCell>
                <TableCell align="center" className={classes.tbHeaderCell} style={{ padding: "0px", width: "90px" }} >
                  Files
                </TableCell>
                <TableCell align="right" className={classes.tbHeaderCell} style={{ paddingRight: "5px", width: "110px" }} >
                  Shared
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </TableContainer>
        <div className={classes.emptyCont} >
          <img src={emptyIcon} />
          <p>There is nothing to show here.
          <br />
            <span
              style={{
                color: theme.palette.primary.main,
                marginLeft: "3px",
                cursor: "pointer"
              }}
              onClick={() => { setAddNewDialog(true) }}
            >Click to add new doc/folder.</span>
          </p>
        </div>
      </>)}</>)}

      {docDialogOpen && (
        <SelectDocDialog
          setDialogOpen={setDialogOpen}
        />
      )}
      <PreviewDialog
        data={selectedDoc}
        openAddNew={docPreviewDialog}
        setOpenAddNew={setDocPreviewDialog}
      />
      {addNewDialog && (
        <AddNewDialog
          profileId={profileId}
          defaultOpen={true}
          setOuterOpen={setAddNewDialog}
        />
      )}
    </Paper>
  );
};
export default AnalysisCard;