import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Avatar from "@material-ui/core/Avatar";
import moment from "moment";
import AvatarGroup from "@material-ui/lab/AvatarGroup";
import LessText from "../styled/CommonComponents/LessText";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    "& .MuiTablePagination-root": {
      border: "1px solid #d2d1d1",
      marginTop: "-3px"
    }
  },
  container: {
    width: "100%",
    height: "calc(100% - 52px)"
  },
  tableBodyCont: {
    width: "100%",
    height: "100%",
    overflowY: "auto",
    display: "flex",
    justifyContent: "space-around",
    flexWrap: "wrap",
    paddingTop: "10px",
  },
  cardCont: {
    position: "relative",
    width: "320px",
    padding: "15px",
    paddingBottom: "0.2rem",
    marginBottom: "20px",
    cursor: "pointer",
    boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    marginLeft: "1px",
    marginRight: "1px",
  },
  titleStyle: {
    fontSize: "13px",
    fontWeight: "450",
    paddingBottom: "3px",
  },
  bottomLabel: {
    position: "absolute",
    top: "10px",
    right: "10px",
    display: "flex",
    flexDirection: "column",
  },
  avatarGroupSty: {
    maxWidth: "70px",
    "& .MuiAvatar-root": {
      width: "30px",
      height: "30px",
    },
  },
  avatarGroupStyForCard: {
    maxWidth: "70px",
    justifyContent: "flex-end",
    "& .MuiAvatar-root": {
      width: "25px",
      height: "25px",
    },
  },
}));


export default function ProjectsTable(props) {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const { rows } = props;
  const { user } = useSelector((state) => state.auth);

  const mediumSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [filteredRows, setFilteredRows] = useState([]);

  useEffect(() => { setFilteredRows(rows) }, [rows]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div className={classes.root}>
      {!smallScreen ? (
        <>
          <TableContainer className={classes.container}>
            <Table stickyHeader aria-label="sticky table" size="small">
              <TableHead style={{ position: "relative", zIndex: "99" }}>
                <TableRow>
                  <TableCell align="left" style={{ fontSize: "15px", color: "#193B56", padding: "5px", paddingLeft: "10px" }} >
                    Project
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: "15px", color: "#193B56", padding: "5px", width: mediumSmallScreen ? "135px" : "170px" }} >
                    Participants
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: "15px", color: "#193B56", padding: "5px", width: mediumSmallScreen ? "100px" : "120px" }} >
                    Accessibility
                  </TableCell>
                  <TableCell align="right" style={{ fontSize: "15px", color: "#193B56", padding: "5px", paddingRight: "10px", width: mediumSmallScreen ? "120px" : "160px" }} >
                    Created
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row._id}
                    onClick={() => { history.push(`/projects/${row?._id}`) }}
                    style={{ cursor: "pointer", padding: "0px" }}
                  >
                    <TableCell align="left" style={{ padding: "5px", paddingLeft: "10px" }} >
                      <div style={{ display: "flex", alignItems: "center" }} >
                        <Avatar
                          style={{ width: "30px", height: "30px" }}
                          alt="project pic"
                          src={row?.parent?.displayPicture?.url}
                        />
                        <span style={{ marginLeft: "7px", fontSize: "13px" }} >
                          <LessText limit={46} string={row?.parent?.displayName} />
                        </span>
                      </div>
                    </TableCell>
                    <TableCell align="right" style={{ padding: "5px" }} >
                      <div style={{ display: "flex", justifyContent: "flex-end" }} >
                        <AvatarGroup max={4} className={classes.avatarGroupSty} >
                          {row?.populatedParticipants && row?.populatedParticipants.length > 0 && row?.populatedParticipants.map((participant) => (
                            <Avatar
                              alt={participant?.parent?.displayName}
                              src={participant?.parent?.displayPicture?.url}
                            />
                          ))}
                        </AvatarGroup>
                      </div>
                    </TableCell>
                    <TableCell align="right" style={{ padding: "5px", fontSize: "13px" }} >
                      {row?.parent?.public ? "Public" : "Private"}
                    </TableCell>
                    <TableCell align="right" style={{ padding: "5px", fontSize: "13px", paddingRight: "10px" }} >
                      {moment(row?.parent?.updatedAt).format("DD MMM YYYY")}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[20, 25, 50, 100]}
            component="div"
            count={filteredRows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <div className={classes.tableBodyCont}>
          {filteredRows.length > 0 && (
            <>
              {filteredRows.map((row) => (
                <div className={classes.cardCont}
                  onClick={() => { history.push(`/projects/${row?._id}`) }}
                >
                  <div style={{ display: "flex", marginBottom: "10px" }} >
                    <Avatar variant="square" style={{ height: "25px", width: "25px" }}
                      alt="project pic"
                      src={row?.parent?.displayPicture?.url}
                    />
                    <div style={{ marginLeft: "10px", marginTop: "-6px" }}>
                      <Typography className={classes.titleStyle}>
                        <LessText limit={20} string={row?.parent?.displayName} />
                      </Typography>
                      <div style={{ display: "flex" }}>
                        <p
                          style={{
                            fontSize: "12px",
                            opacity: "0.8",
                            border: "1px solid gray",
                            width: "50px",
                            textAlign: "center",
                            borderRadius: "10px",
                          }}
                        >
                          {row?.parent?.public ? "Public" : "Private"}
                        </p>
                        <p
                          style={{
                            fontSize: "12px",
                            opacity: "0.8",
                            border: "1px solid gray",
                            width: "50px",
                            textAlign: "center",
                            borderRadius: "10px",
                            marginLeft: "10px",
                            backgroundColor: theme.palette.primary.main,
                            color: "white",
                          }}
                        >
                          {row?.permissions[user?.profile]}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className={classes.bottomLabel}>
                    <p style={{ opacity: "0.7", fontWeight: "400", fontSize: "0.7rem" }} >
                      {moment(row?.parent?.updatedAt).format("DD MMM YYYY")}
                    </p>
                    <AvatarGroup max={2} className={classes.avatarGroupStyForCard} >
                      {row?.populatedParticipants && row?.populatedParticipants.length > 0 && row?.populatedParticipants.map((participant) => (
                        <Avatar
                          alt={participant?.parent?.displayName}
                          src={participant?.parent?.displayPicture?.url}
                        />
                      ))}
                    </AvatarGroup>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
}
