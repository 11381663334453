import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import Api from '../../../../../helpers/Api';
import stripeLogo from '../../../../../Assets/stripeLogo.png'
import PersonAddIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles((theme) => ({
  paperStyle: {
    width: "100%",
    padding: "20px 30px 30px",
    borderRadius: "12px",
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
    [theme.breakpoints.down('xs')]: {
      padding: "15px 15px 20px",
    }
  },
  dwollaHeader: {
    width: "100%",
    display: "flex",
    marginBottom: "20px",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logoCont: {
    display: "flex",
    alignItems: "center",
    "& h3": {
      fontSize: "25px",
      fontWeight: "600",
      marginLeft: "5px"
    },
    [theme.breakpoints.down('xs')]: {
      "& h3": {
        fontSize: "22px",
        fontWeight: "500",
        marginLeft: "1px"
      },
    }
  },
  imgStyle: {
    height: "30px",
    width: "auto",
    [theme.breakpoints.down('xs')]: {
      height: "27px",
    }
  },
  paraText: {
    fontSize: '0.9rem',
    fontWeight: '400',
    marginTop: '0.5rem',
    "& a": {
      textDecoration: "none",
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: "13px"
    }
  },
}));

export default function ConnectStripeBtn(props) {
  const { wallet, setWallet } = props;
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const walletId = wallet?._id;

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const createConnectAccount = async () => {
    if (walletId) {
      dispatch({
        type: 'AddWallet',
        payload: {
          connectWalletId: walletId,
          financeLoading: true
        },
      });

      if (wallet.stripeConnectAccountId) {
        const res = await Api.post('stripe/connect/account/link', { walletId: wallet?._id });

        const accountLink = res.data;
        const url = accountLink?.url;
        if (url) {
          window.location.replace(url);
        }
      }
      else {
        const dataRes = await Api.post('stripe/connect/account/create', { walletId: wallet?._id });

        const data = dataRes.data;
        const walletRes = data?.wallet;
        const accountLink = data?.accountLink;
        if (data && walletRes && accountLink) {
          const newWallet = {
            ...wallet,
            stripeConnectAccountId: walletRes.stripeConnectAccountId,
          };


          setWallet(newWallet);
          const url = accountLink?.url;
          if (url) {
            window.location.replace(url);
          }
        }
      }

      dispatch({
        type: 'AddWallet',
        payload: {
          financeLoading: false
        }
      })
    }
  };

  return (
    <Paper className={classes.paperStyle}>
      <div className={classes.dwollaHeader} >
        <div className={classes.logoCont}>
          <img
            src={stripeLogo}
            className={classes.imgStyle}
          />
          <h3>Stripe</h3>
        </div>
        <Button
          onClick={() => {
            dispatch({
              type: 'AddWallet',
              payload: {
                stripeWalletId: walletId,
              },
            });
            createConnectAccount();
          }}
          variant="contained"
          color="primary"
          startIcon={<PersonAddIcon />}
          size={smallScreen ? "small" : "medium"}
        >
          Create Account
        </Button>
      </div>

      <p className={classes.paraText}>
        Stripe is available <a target="_blank" href="https://stripe.com/global">internationally</a>.
        Supports multiple payment methods. We currently support bank transfers (ACH fee 0.8% - $5 max)
        in US only, <a target="_blank" href="mailto:team@contractflo.com" style={{ marginRight: "3px" }} >reach out</a>
        to us for your country specific payments). Stripe account is <b>free</b>.
      </p>
      <p className={classes.paraText}>
        Stripe lets you send money <b>only</b> to people on Stripe network.
      </p>
    </Paper>
  );
}