import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "../landing/footer/footer";
import GuestLayoutHeader from "../Navbar/GuestLayout.Header";

const useStyles = makeStyles((theme) => ({
    allComCont: {
        maxHeight: "100vh",
        maxWidth: "100vw",
        overflow: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    mainComponentCont: {
        width: "100%",
        overflowX: "hidden",
        overflowY: "auto",
        backgroundColor: "#F5F7FA",
    }
}));


const GuestLayout = ({ children, HeaderProp, headerHeight }) => {
    const classes = useStyles();
    const Header = HeaderProp ? HeaderProp : GuestLayoutHeader;
    const hight = headerHeight ? headerHeight : "70px"

    return (
        <div className={classes.allComCont} >
            <Header height={hight} />
            <div
                className={classes.mainComponentCont}
                style={{ height: `calc(100vh - ${hight})` }}
            >
                {children}
                <Footer />
            </div>
        </div>
    );
};

export default GuestLayout;