import React, { useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "space-between",
        [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
        }
    },
    imageCont: {
        width: "50%",
        padding: "0px 30px 0px",
        "& img": {
            width: "100%",
            height: "auto"
        },
        [theme.breakpoints.down("md")]: {
            width: "38%",
            padding: "30px 10px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginBottom: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            "& img": {
                height: "300px",
                width: "auto",
            },
        },
        [theme.breakpoints.down("xs")]: {
            "& img": {
                height: "240px",
                width: "auto",
            },
        }
    },

    detailsCont: {
        width: "50%",
        padding: "50px 40px 0px",
        "& h3": {
            fontSize: "30px",
            marginBottom: "35px",
            fontWeight: "700"
        },
        "& h4": {
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "26px",
            marginBottom: "20px"
        },
        "& ul": {
            marginLeft: "30px"
        },
        "& li": {
            fontSize: "14px",
            color: "#7E7E7E",
            lineHeight: "25px",
            marginBottom: "15px"
        },
        [theme.breakpoints.down("md")]: {
            width: "62%",
            padding: "25px 15px 0px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        }
    }
}));

export default function FullFeatureCard({ photo, header, subHeader, lists }) {
    const classes = useStyles();



    return (
        <div className={classes.root} >
            <div className={classes.imageCont} >
                <img src={photo} alt="fPhoto" />
            </div>
            <div className={classes.detailsCont} >
                <h3>{header}</h3>
                <h4>{subHeader}</h4>
                <ul>
                    {lists.map((list, i) => (
                        <li key={i} >{list}</li>
                    ))}
                </ul>
            </div>
        </div>
    );
}