import React, { useState, useEffect, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Api from "../../helpers/Api";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { Avatar, makeStyles } from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import "../styled/agGrid.css";
import PopupCellRenderer from "../styled/PopupCellRenderer";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "1rem",
    width: "90%",
  },
  row_header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  grid: {
    marginLeft: "1rem",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#f0f0f0",
    fontSize: "0.95rem",
    fontWeight: "normal",
    border: "1px solid #ccc",
  },
}));

const DefaultResponses = (props) => {
  const {
    rowIds,
    setRowIds,
    rowDict,
    form,
    formTable,
    questionDict,
    questionIds,
    setTable,
    tableId,
    user,
    question,
  } = props;

  const classes = useStyles();

  const { root, row_header, grid } = classes;

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [hoveredRowId, setHoveredRowId] = useState(null);

  const gridApiRef = useRef(null);

  const onRowMouseEnter = (event) => {
    console.log(event);
  };

  const onRowMouseLeave = () => {
    setHoveredRowId(null);
  };

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return null;
    }

    const isHovered = params.node.id === hoveredRowId;
    const backgroundColor = isHovered ? "#e6f7ff" : null;

    return { backgroundColor };
  };

  const onAddClick = async (api, node) => {
    console.log("create row called");
    let rowIndex = node.rowIndex;
    let addIndex = rowIndex + 1;
    let allResponses = [];
    questionIds.map((questionId) => {
      allResponses.push({
        form: form,
        question: questionId,
      });
    });
    let newRow = {
      question: question,
      form: form,
      formTable: tableId,
      createdBy: user?.profile,
    };
    console.log("table id", tableId);
    const res = await Api.post("formtable/row/create", {
      allResponses: allResponses,
      newRow: newRow,
      tableId,
      index: addIndex,
    });
    //get the rowId added
    if (res?.data) {
      setTable(res?.data);
    }
  };

  const onDeleteClick = async (api, node) => {
    //delete this row
    let index = node.rowIndex;
    let row = rowDict[rowIds[index]];
    setRowIds([...rowIds.slice(0, index), ...rowIds.slice(index + 1)]);
    let deletedResponses = [];
    row.responses.map((response) => {
      deletedResponses.push(response._id);
    });
    await Api.post("formtable/row/delete", {
      row,
      deletedResponses,
    });
    console.log("deleted successfully");
  };

  //rows
  const getRows = () => {
    let responseRows = rowIds || [];
    let rowArr = [];
    responseRows.map((rowId) => {
      let row = rowDict[rowId];
      let rowObj = {};
      row.responses.map((currResponse) => {
        rowObj[currResponse.question] = "";
      });
      rowArr.push(rowObj);
    });
    setRowData(rowArr);
  };

  //columns
  const getColumns = () => {
    let questions = questionIds || [];
    let columnArr = [];
    questions.map((questionId) => {
      let question = questionDict[questionId];
      let obj = {};
      obj = {
        headerName: question?.questionText || "Untitled",
        field: question?._id,
        enableRowGroup: true,
        resizable: true,
        flex: 1,
        minWidth: 100,
      };
      columnArr.push(obj);
    });
    setColumnDefs(columnArr);
  };

  useEffect(() => {
    getRows();
    getColumns();
  }, [rowIds.length, questionIds.length, questionDict]);

  //   const onGridReady = (params) => {
  //     gridApiRef.current = params.api;
  //     const columnDefs = gridApiRef.current.getColumnDefs();
  //     const totalWidth = columnDefs.reduce((total, column) => {
  //       return total + column.width;
  //     }, 0);
  //     const gridElement = document.querySelector(".ag-theme-alpine");
  //     gridElement.style.width = `${totalWidth}px`;
  //   };

  return (
    <div className={root}>
      <div className={row_header}>
        <Typography variant="h6">Default Responses</Typography>
      </div>
      {/* dynamic table here */}
      <div
        className={`ag-theme-alpine ${grid}`}
        style={{ height: "400px", borderRadius: "5px" }}
      >
        <AgGridReact
          columnDefs={[
            {
              headerName: "Action",
              cellRenderer: PopupCellRenderer,
              cellRendererParams: {
                onAddClick: onAddClick,
                onDeleteClick: onDeleteClick,
              },
              field: "menu",
              pinned: "left",
              editable: false,
              maxWidth: 100,
              // cellStyle: { paddingBottom: "15px" },
            },
            ...columnDefs,
          ]}
          rowData={rowData}
          getRowStyle={getRowStyle}
          onRowMouseEnter={onRowMouseEnter}
          onRowMouseLeave={onRowMouseLeave}
          //   onGridReady={onGridReady}
          pagination={true}
          pivotPanelShow={"always"}
          paginationAutoPageSize={true}
          suppressClickEdit={true}
        />
      </div>
    </div>
  );
};

export default DefaultResponses;
