import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { BsFolderFill } from "react-icons/bs";
import Api from "../../helpers/Api";
import { useMediaQuery, Button } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Skeleton } from "@material-ui/lab";
import emptyIcon from "../../Assets/EmptyInboxOutline.svg"
import LessText from "../styled/CommonComponents/LessText";


const useStyles = makeStyles((theme) => ({
    allfoldersCont: {
        width: "100%",
        maxHeight: "375px",
        overflowY: "auto",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        display: "flex",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]: {
            justifyContent: "space-around",
        }
    },
    folderCont: {
        border: "1px solid #d8d5d5",
        width: "240px",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
        padding: "7px",
        marginRight: "15px",
        marginBottom: "15px",
        "& p": {
            fontWeight: "500"
        },
        [theme.breakpoints.down("md")]: {
            marginRight: "0px",
        },
        [theme.breakpoints.down("sm")]: {
            width: "145px",
        }
    },
    loadMoreBtn: {
        width: "100%",
        margin: "15px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    skeletonSty: {
        width: "300px",
        height: "55px",
        margin: "0px",
        padding: "0px",
        marginRight: "15px",
        marginBottom: "15px"
    },
    emptyIconCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
            width: "160px",
            height: "auto"
        }
    }
}));

export default function AllFoldersView(props) {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const dispatch = useDispatch();
    const { profileId } = useParams();

    const { auth } = useSelector((state) => state);
    const user = auth?.user;

    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    const [folders, setFolders] = useState(null);
    const [curPage, setCurPage] = useState(0)
    const [hasMore, setHasMore] = useState(true)
    const [loading, setLoading] = useState(true)
    const dataLimit = 10

    const getFolders = async () => {
        setLoading(true)
        await Api.post("doc/folder/get/by-page", {
            profileId,
            curPage,
            limit: dataLimit
        })
            .then((datak) => {
                let data = datak.data
                if (curPage === 0) {
                    setFolders(data || [])
                } else {
                    setFolders((prev) => [...prev, ...data])
                }

                if (data.length < dataLimit) {
                    setHasMore(false);
                } else {
                    setHasMore(true);
                }
                setLoading(false)
                console.log(data);
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        getFolders()
    }, [curPage])



    return (<>{!folders && loading ? (<div className={classes.allfoldersCont} >
        <Skeleton className={classes.skeletonSty} variant="text" />
        <Skeleton className={classes.skeletonSty} variant="text" />
        <Skeleton className={classes.skeletonSty} variant="text" />
        <Skeleton className={classes.skeletonSty} variant="text" />
        <Skeleton className={classes.skeletonSty} variant="text" />
        <Skeleton className={classes.skeletonSty} variant="text" />
    </div>) : (<>
        {!folders || folders.length === 0 ? (<div className={classes.emptyIconCont} >
            <img src={emptyIcon} />
        </div>) : (
            <div className={classes.allfoldersCont} >
                {folders && folders.map((folder) => (
                    <div
                        className={classes.folderCont}
                        onClick={() => {
                            var path = "/doc/folder/" + folder?.profile;
                            history.push(path);
                        }}
                    >
                        <BsFolderFill style={{ fontSize: "25px", marginRight: "7px", color: "gray" }} />
                        <p>
                            <LessText
                                limit={isMobile ? 12 : 20}
                                string={folder?.title || ""}
                            />
                        </p>
                    </div>
                ))}
                {hasMore && (
                    <div className={classes.loadMoreBtn} >
                        {loading ? (
                            <CircularProgress />
                        ) : (
                            <Button
                                onClick={() => { setCurPage((prev) => { return prev + 1 }) }}
                                variant="contained"
                                color="primary"
                            >
                                Load More
                            </Button>
                        )}
                    </div>
                )}
            </div>
        )}
    </>)}
    </>);
}
