import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	Grid,
	IconButton,
	makeStyles,
	Menu,
	MenuItem,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from "@date-io/date-fns";
import React, { useEffect, useState } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MapIcon from "@material-ui/icons/Map";
import { Link, useHistory, useParams } from "react-router-dom";
import { format } from "date-fns";
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import Api from "../../helpers/Api";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import { useSelector } from "react-redux";
import CloseIcon from "@material-ui/icons/Close";
import ReplayIcon from "@material-ui/icons/Replay";

const useStyles = makeStyles((theme) => ({
	root: {
		marginTop: "66px",
	},
	margin: {
		margin: theme.spacing(1),
	},
	loaderCont: {
		position: "fixed",
		top: "0px",
		right: "0px",
		width: "100vw",
		height: "100vh",
		zIndex: "1000",
		paddingLeft: "100px",
		backgroundColor: "rgba(0, 0, 0, 0.7)",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		[theme.breakpoints.down("xs")]: {
			paddingLeft: "0px",
		},
	},

	unitEditHeader: {
		width: "100%",
		position: "absolute",
		left: "0px",
		display: "flex",
		top: "0px",
		justifyContent: "space-between",
		alignItems: "center",
		padding: "15px 20px",
		marginTop: "0px",
		marginBottom: "0px",
		backgroundColor: "#ffffff",
		zIndex: "10",
		boxShadow: "rgba(0, 0, 0, 0.05) 0px 1px 2px 0px",
	},

	unitTitle: {
		fontSize: "20px",
		fontWeight: "520",
		marginLeft: "20px",
		opacity: "0.8",
	},
	backIcon: {
		fontSize: "35px",
		opacity: "0.8",
		cursor: "pointer",
	},
	unitEditBody: {
		width: "100%",
		display: "flex",
		marginTop: "20px",
		justifyContent: "space-between",
	},
	sideNavBar: {
		minHeight: "calc(100vh - 70px)",
		width: "20%",
		// borderRight: '1px solid #BDBDBD',
		paddingTop: "30px",
		paddingLeft: "20px",
		backgroundColor: "#ffffff",
	},
	editMainCont: {
		width: "80%",
		padding: "20px",
		paddingBottom: "40px",
	},
	singleOption: {
		display: "flex",
		alignItems: "center",
		height: "47px",
		fontSize: "16px",
		fontWeight: "530",
		cursor: "pointer",
		backgroundColor: "#FFFFFF",
		"&:hover": {
			background: "#e7e7e7",
		},
	},
	selectedSingleOption: {
		display: "flex",
		alignItems: "center",
		height: "47px",
		fontSize: "16px",
		fontWeight: "530",
		cursor: "pointer",
		background: "#e7e7e7",
	},
	sideBlue: {
		width: "4px",
		height: "47px",
		marginRight: "5px",
		marginLeft: "-8px",
		backgroundColor: "#46A8F1",
	},
	displayNone: {
		display: "none",
	},
	iconStyle: {
		fontSize: "27px",
		marginRight: "10px",
	},
	editStyle: {
		color: "#46A8F1",
		marginLeft: "10px",
		cursor: "pointer",
	},
	showDisplayRow: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: "10px",
	},
	datePicker: {
		marginTop: "0",
	},
	link: {
		color: "#2a7ab0",
		cursor: "pointer",
		"&:hover": {
			textDecoration: "underline",
		},
	},
	text: {
		fontSize: 20,
		fontWeight: "bold",
		margin: "0rem 1rem",
	},
	hoverUnderline: {
		"&:hover": {
			textDecoration: "underline",
			color: "blue",
			cursor: "pointer",
		},
	},
	hoverUnderlineGray: {
		"&:hover": {
			backgroundColor: "#f2f2f2",
			cursor: "pointer",
		},
	},
}));
function LeaseViewPage(props) {
	const history = useHistory();
	const { unit, setUnit } = props;
	const classes = useStyles();
	const { teamId, unitId } = useParams();
	const { user } = useSelector((state) => state.auth);
	// const [showTable, setShowTable] = React.useState(false);
	const [rows, setRows] = useState([]);
	const [applianceEdit, setApplianceEdit] = useState(false);
	const [notesEdit, setNotesEdit] = useState(false);
	const [unitName, setUnitName] = useState("");
	const [nameEdit, setNameEdit] = useState(false);
	const [listingEdit, setListingEdit] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorElNotes, setAnchorElNotes] = React.useState(null);
	const [listingData, setListingData] = useState({
		marketRent: 0,
		area: 0,
		description: "",
	});
	const [newAppliance, setNewAppliance] = useState({
		name: "",
		make: "",
		model: "",
		description: "",
		InstalledOn: new Date(),
		WarrentyEndsOn: new Date(),
	});
	const [newNotes, setNewNotes] = useState("");
	const [currentAppliance, setCurrentAppliance] = useState({});
	const [currentNote, setCurrentNote] = useState({});
	const [notesDialog, setNotesDialog] = useState(null);
	const [applianceDialog, setApplianceDialog] = useState(null);

	const getLastServiceDate = (app) => {
		let lastDate = "";
		app.history.map((event) => {
			if (event.eventType == "Serviced") {
				if (lastDate) {
					if (lastDate < new Date(event?.serviceDate))
						lastDate = new Date(event?.serviceDate);
				} else lastDate = new Date(event?.serviceDate);
			}
		});
		if (lastDate) return `Last serviced ${format(lastDate, "MM/dd/yyyy")}`;
		else return "";
	};
	const updateUnitListingData = async () => {
		try {
			const res = await Api.post("property/unit/updateListingData", {
				_id: unit?._id,
				obj: {
					marketRent: listingData?.marketRent || unit?.marketRent,
					area: listingData?.area || unit?.area,
					description: listingData?.description || unit?.description,
				},
			});
			if (res?.data) {
				setUnit((prev) => {
					return {
						...prev,
						marketRent: listingData?.marketRent || unit?.marketRent,
						area: listingData?.area || unit?.area,
						description:
							listingData?.description || unit?.description,
					};
				});
				setListingEdit(false);
			} else {
				console.error(res?.error);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleClick = (event, app) => {
		setAnchorEl(event.currentTarget);
		setCurrentAppliance(app);
	};
	const handleClickNotes = (event, app) => {
		setAnchorElNotes(event.currentTarget);
		setCurrentNote(app);
	};
	const handleClose = () => {
		setAnchorEl(null);
		setAnchorElNotes(null);
	};
	const updateUnitName = async () => {
		try {
			const res = await Api.post("property/unit/updateName", {
				_id: unit?._id,
				name: unitName,
			});
			if (res?.success) {
				setUnit((prev) => {
					return {
						...prev,
						name: unitName,
					};
				});
				setNameEdit(false);
			} else {
				console.error(res?.error);
			}
		} catch (err) {
			console.error(err);
		}
	};
	const setListingDataFunc = (txt) => {
		setListingData((prev) => {
			return {
				...prev,
				marketRent: txt,
			};
		});
	};

	const addNewAppliance = async () => {
		try {
			const res = await Api.post("property/unit/addAppliance", {
				unitId: unit?._id,
				app: {
					...(newAppliance || {}),
					unit: unit?._id,
				},
			});
			if (res?.data) {
				setUnit((prev) => {
					return {
						...prev,
						appliances: [...(prev?.appliances || []), res?.data],
					};
				});
			} else {
				console.error(res?.error);
			}
		} catch (err) {
			console.error(err);
		}
	};
	const handleNewNote = async () => {
		try {
			const res = await Api.post("property/unit/addNotes", {
				unitId: unit?._id,
				notes: {
					details: newNotes,
					updatedByUser: user?.profile,
					unit: unit?._id,
				},
			});
			if (res?.data) {
				setUnit((prev) => {
					return {
						...prev,
						notes: [...(prev?.notes || []), res?.data],
					};
				});
			} else {
				console.error(res?.error);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleNoteUpdate = async () => {
		try {
			const res = await Api.post("property/unit/editNotes", {
				noteId: currentNote?._id,
				noteBody: {
					details: newNotes,
				},
			});
			if (res?.data) {
				setUnit((prev) => {
					let tempNotes = prev.notes.map((doc) => {
						if (doc?._id == currentNote?._id) {
							return { ...doc, details: newNotes };
						} else return doc;
					});
					return { ...prev, notes: tempNotes };
				});
			} else {
				console.error(res?.error);
			}
		} catch (err) {
			console.error(err);
		}
	};

	const handleDeleteNote = async () => {
		try {
			const res = await Api.post("property/unit/deleteNotes", {
				noteId: currentNote?._id,
				unitId: unit?._id,
			});
			if (res?.data) {
				setUnit((prev) => {
					let tempNotes = prev?.notes.filter(
						(doc) => doc?._id != currentNote?._id
					);
					return { ...prev, notes: tempNotes };
				});
			} else {
				console.error(res?.error);
			}
		} catch (error) {
			console.error(error);
		}
	};
	const handleApplianceDelete = async () => {
		try {
			const res = await Api.post("property/unit/deleteAppliance", {
				AppId: currentAppliance?._id,
				unitId: unit?._id,
			});
			if (res?.data) {
				setUnit((prev) => {
					let tempNotes = prev?.appliances.filter(
						(doc) => doc?._id != currentAppliance?._id
					);
					return { ...prev, appliances: tempNotes };
				});
			} else {
				console.error(res?.error);
			}
		} catch (error) {
			console.error(error);
		}
	};

	const handleEditAppliance = async () => {
		try {
			let history = [];
			(currentAppliance?.history || []).map((doc) => {
				if (!doc?.deleted) {
					if (doc?._id) {
						history.push({
							_id: doc?._id,
							eventType: doc?.eventType,
							serviceDate: doc?.serviceDate,
							details: doc?.details,
						});
					} else {
						history.push({
							eventType: doc?.eventType,
							serviceDate: doc?.serviceDate,
							details: doc?.details,
						});
					}
				}
			});
			let newObj = { ...currentAppliance, history: history };
			const res = await Api.post("property/unit/editAppliance", {
				AppId: currentAppliance?._id,
				AppEventBody: newObj,
			});
			if (res?.data) {
				setUnit((prev) => {
					let appliances = (prev?.appliances || []).map((doc) => {
						if (doc?._id == currentAppliance?._id) {
							return { ...newObj };
						} else {
							return doc;
						}
					});
					return { ...prev, appliances: appliances };
				});
			} else {
				console.error(res?.error);
			}
		} catch (error) {
			console.error(error);
		}
		setApplianceDialog(null);
	};

	useEffect(() => {
		if (unit?.allRentalRelations) {
			let tempRows = [];
			(unit?.allRentalRelations || []).map((lease) => {
				let status = lease?.active ? "Active" : "Inactive";
				let startEnd = `${format(
					new Date(lease?.leaseStarted),
					"MM/dd/yyyy"
				)} - ${format(new Date(lease?.leaseEnd), "MM/dd/yyyy")}`;
				let name = `${lease?.tenantFullInfo?.contactInfo?.firstName} ${lease?.tenantFullInfo?.contactInfo?.lastName}`;
				let type = lease?.leaseType;
				let rent = lease?.rent?.amount;
				let tenantPassword = lease?.tenantPassword;
				let _id = lease?._id;
				tempRows.push({
					status,
					startEnd,
					name,
					type,
					rent,
					_id,
					tenantPassword,
				});
			});
			setRows([...tempRows]);
		}
		setUnitName(unit?.name);
		setListingData({
			marketRent: unit?.marketRent || 0,
			description: unit?.description || "",
			area: unit?.area || 0,
		});
	}, [unit]);
	console.log("unit = ", unit);
	console.log("capp = ", currentAppliance);
	return (
		<Paper square style={{ padding: "2rem" }}>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					{!unit?.rentalRelation && (
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								padding: "1rem 1.5rem",
								backgroundColor: "rgb(232, 244, 253)",
								borderRadius: "15px",
							}}
						>
							<div>
								<Typography varaint="h6">
									Unit is currently vacant
								</Typography>

								<Typography variant="subtitle2">
									To attach a lease to this unit click here
								</Typography>
							</div>
							<Button
								size="small"
								className={classes.margin}
								variant="contained"
								color="primary"
								onClick={() => {
									history.push(
										`/propertymanagement/${teamId}/${unit._id}/newlease`
									);
								}}
							>
								Draft Lease
							</Button>
						</div>
					)}
				</Grid>

				<Grid item xs={12}>
					{nameEdit ? (
						<>
							<div style={{ display: "flex", gap: "5px" }}>
								<TextField
									variant="outlined"
									label="Unit Name"
									value={unitName}
									onChange={(e) =>
										setUnitName(e.target.value)
									}
								/>
								<Button
									variant="contained"
									color="primary"
									startIcon={<CheckIcon />}
									onClick={() => {
										if (unitName) {
											updateUnitName();
										}
									}}
								/>
								<Button
									variant="outlined"
									color="secondary"
									onClick={() => setNameEdit(false)}
									startIcon={<ClearIcon />}
								/>
							</div>
						</>
					) : (
						<>
							<Typography variant="h4" component={"span"}>
								{unit?.name}{" "}
							</Typography>
							<Typography
								component={"span"}
								className={classes.hoverUnderline}
								onClick={() => setNameEdit(true)}
							>
								Edit
							</Typography>
						</>
					)}
					<Divider />
					{unit?.project?.address?.fullAddressLine && (
						<Grid container style={{ marginTop: "1%" }}>
							<Grid item xs={10}>
								<Typography
									variant="h4"
									style={{
										fontSize: "20px",
										fontWeight: "400",
										marginBottom: "3px",
									}}
								>
									Address
								</Typography>
								<Typography>
									{unit?.project?.address?.fullAddressLine}
								</Typography>
								<Button
									startIcon={<MapIcon />}
									rel="noopener noreferrer"
									href={`https://www.google.com/maps?q=${unit?.project?.address?.fullAddressLine}`}
									target="_blank"
								>
									Map it
								</Button>
							</Grid>
							<Grid item xs={2}>
								<div>
									<img
										src="https://images.pexels.com/photos/439391/pexels-photo-439391.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
										style={{ width: "90%", height: "90%" }}
									/>
								</div>
							</Grid>
						</Grid>
					)}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5" component={"span"}>
						Listing Information{" "}
					</Typography>{" "}
					<Typography
						component={"span"}
						className={classes.hoverUnderline}
						onClick={() => setListingEdit(true)}
					>
						Edit
					</Typography>
					<Divider />
					<Grid container spacing={2} style={{ marginTop: "1%" }}>
						<Grid item xs={2}>
							{listingEdit ? (
								<>
									<TextField
										variant="outlined"
										type="number"
										label="Market Rent"
										value={listingData?.marketRent}
										onChange={(e) => {
											let txt = e.target.value;
											setListingDataFunc(txt);
										}}
									/>
								</>
							) : (
								<>
									<Typography
										style={{
											fontSize: "20px",
											fontWeight: "400",
											marginBottom: "3px",
										}}
									>
										Market Rent
									</Typography>
									<Typography>{`$ ${
										unit?.marketRent || 0
									}`}</Typography>
								</>
							)}
						</Grid>
						<Grid item xs={10}>
							{listingEdit ? (
								<>
									<TextField
										variant="outlined"
										type="number"
										label="Area in sq.ft"
										value={listingData?.area}
										onChange={(e) => {
											let txt = e.target.value;
											setListingData((prev) => {
												return {
													...prev,
													area: txt,
												};
											});
										}}
									/>
								</>
							) : (
								<>
									<Typography
										style={{
											fontSize: "20px",
											fontWeight: "400",
											marginBottom: "3px",
										}}
									>
										Size
									</Typography>
									<Typography>
										{unit?.area || 0} sq. ft
									</Typography>{" "}
								</>
							)}
						</Grid>
						<Grid item xs={12}>
							{listingEdit ? (
								<>
									<TextField
										variant="outlined"
										label="Description"
										value={listingData?.description}
										style={{ width: "50%" }}
										onChange={(e) => {
											let txt = e.target.value;
											setListingData((prev) => {
												return {
													...prev,
													description: txt,
												};
											});
										}}
									/>
								</>
							) : (
								<>
									<Typography
										style={{
											fontSize: "20px",
											fontWeight: "400",
											marginBottom: "3px",
										}}
									>
										Description
									</Typography>
									<Typography>{unit?.description}</Typography>
								</>
							)}
						</Grid>
						<Grid
							item
							xs={12}
							style={listingEdit ? {} : { display: "none" }}
						>
							<Button
								color="primary"
								variant="contained"
								style={{ marginRight: "12px" }}
								onClick={updateUnitListingData}
							>
								Save
							</Button>
							<Button
								color="primary"
								variant="outlined"
								onClick={() => setListingEdit(false)}
							>
								Cancel
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5" component={"span"}>
						Leases{" "}
					</Typography>{" "}
					<Divider />
					<TableContainer
						component={Paper}
						style={{ marginTop: "1rem" }}
					>
						<Table
							className={classes.table}
							aria-label="simple table"
						>
							<TableHead style={{ backgroundColor: "#f2f2f2" }}>
								<TableRow>
									<TableCell
										style={{
											border: "1px solid lightgrey",
										}}
									>
										Status
									</TableCell>
									<TableCell
										style={{
											border: "1px solid lightgrey",
										}}
										align="center"
									>
										Start - End
									</TableCell>
									<TableCell
										style={{
											border: "1px solid lightgrey",
										}}
										align="center"
									>
										Tenant
									</TableCell>
									<TableCell
										style={{
											border: "1px solid lightgrey",
										}}
										align="center"
									>
										Password
									</TableCell>
									<TableCell
										style={{
											border: "1px solid lightgrey",
										}}
										align="center"
									>
										Rent
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.length == 0 ? (
									<TableRow>
										<TableCell colSpan={5}>
											You don't have any leases for this
											unit right now.
										</TableCell>
									</TableRow>
								) : (
									rows.map((row, idx) => (
										<TableRow key={idx}>
											<TableCell
												component="th"
												scope="row"
												style={{
													border: "1px solid lightgrey",
												}}
											>
												{row.status}
											</TableCell>
											<TableCell
												align="center"
												style={{
													border: "1px solid lightgrey",
												}}
											>
												{row.startEnd}
											</TableCell>
											<TableCell
												align="center"
												style={{
													border: "1px solid lightgrey",
												}}
											>
												{row.name}
											</TableCell>
											<TableCell
												align="center"
												style={{
													border: "1px solid lightgrey",
												}}
											>
												{row.tenantPassword}
											</TableCell>
											<TableCell
												align="center"
												style={{
													border: "1px solid lightgrey",
												}}
											>
												{`$ ${row.rent}`}
											</TableCell>
										</TableRow>
									))
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5" component={"span"}>
						Appliance{" "}
					</Typography>{" "}
					{!applianceEdit && (
						<Typography
							component={"span"}
							className={classes.hoverUnderline}
							onClick={() => setApplianceEdit(true)}
						>
							Add
						</Typography>
					)}
					<Divider />
					{applianceEdit ? (
						<div style={{ marginTop: "1rem" }}>
							<Grid container spacing={2}>
								<Grid item xs={3}>
									<TextField
										variant="outlined"
										fullWidth
										label="Name (Required)"
										value={newAppliance.name}
										onChange={(e) => {
											let txt = e.target.value;
											setNewAppliance((prev) => {
												return { ...prev, name: txt };
											});
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										variant="outlined"
										label="Make"
										fullWidth
										value={newAppliance.make}
										onChange={(e) => {
											let txt = e.target.value;
											setNewAppliance((prev) => {
												return { ...prev, make: txt };
											});
										}}
									/>
								</Grid>
								<Grid item xs={3}>
									<TextField
										variant="outlined"
										label="Model"
										fullWidth
										value={newAppliance.model}
										onChange={(e) => {
											let txt = e.target.value;
											setNewAppliance((prev) => {
												return { ...prev, model: txt };
											});
										}}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										label="Description"
										multiline
										// rows={5}
										minRows={4}
										variant="outlined"
										fullWidth
										value={newAppliance.description}
										onChange={(e) => {
											let txt = e.target.value;
											setNewAppliance((prev) => {
												return {
													...prev,
													description: txt,
												};
											});
										}}
									/>
								</Grid>
								<Grid item xs={6} />
								<Grid item xs={6}>
									<div
										style={{ display: "flex", gap: "10px" }}
									>
										<MuiPickersUtilsProvider
											utils={DateFnsUtils}
										>
											<KeyboardDatePicker
												value={newAppliance.InstalledOn}
												className={classes.datePicker}
												margin="normal"
												id="date-picker-dialog"
												label="Installed"
												format="MM/dd/yyyy"
												onChange={(date) => {
													setNewAppliance((prev) => {
														return {
															...prev,
															InstalledOn: date,
														};
													});
												}}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
											/>

											<KeyboardDatePicker
												value={
													newAppliance.WarrentyEndsOn
												}
												className={classes.datePicker}
												margin="normal"
												id="due-date-picker"
												label="Warrenty Ends"
												format="MM/dd/yyyy"
												onChange={(date) => {
													setNewAppliance((prev) => {
														return {
															...prev,
															WarrentyEndsOn:
																date,
														};
													});
												}}
												KeyboardButtonProps={{
													"aria-label": "change date",
												}}
											/>
										</MuiPickersUtilsProvider>
									</div>
								</Grid>
							</Grid>
							<div style={{ display: "flex", gap: "10px" }}>
								<Button
									variant="contained"
									color="primary"
									onClick={() => {
										addNewAppliance();
										setApplianceEdit(false);
									}}
								>
									Save
								</Button>
								<Button
									variant="outlined"
									onClick={() => setApplianceEdit(false)}
								>
									Cancel
								</Button>
							</div>
						</div>
					) : (unit?.appliances || []).length == 0 ? (
						<Typography style={{ marginTop: "1rem" }}>
							You don't have any appliances for this unit right
							now. Add your first appliance
						</Typography>
					) : (
						<div style={{ marginTop: "1rem" }}>
							{(unit?.appliances || [])
								.sort((a, b) => {
									return (
										new Date(a.InstalledOn) -
										new Date(b.InstalledOn)
									);
								})
								.map((app, idx) => (
									<div
										onClick={() => {
											setCurrentAppliance(app);
											setApplianceDialog("edit");
										}}
										className={classes.hoverUnderlineGray}
										style={
											idx == 0
												? {
														display: "flex",

														justifyContent:
															"space-between",
														padding: "4px 5px",
														border: "1px solid lightgray",
														alignItems: "center",
												  }
												: {
														display: "flex",

														justifyContent:
															"space-between",
														padding: "4px 5px",
														border: "1px solid lightgray",
														borderTop: 0,
														alignItems: "center",
												  }
										}
									>
										<div
											style={{
												display: "flex",
												flexDirection: "column",
											}}
										>
											<Typography
												component={"div"}
												style={{ marginLeft: "0.5rem" }}
											>
												{app?.name}
											</Typography>
											{getLastServiceDate(app) && (
												<Typography
													style={{
														marginLeft: "0.5rem",
													}}
													variant="caption"
													component={"div"}
												>
													{getLastServiceDate(app)}
												</Typography>
											)}
										</div>
										<div>
											<Typography variant="button">{`Installed ${format(
												new Date(app?.InstalledOn),
												"MM/dd/yyyy"
											)}`}</Typography>
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													handleClick(e, app);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</div>
									</div>
								))}
							<Menu
								id="simple-menu"
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										setApplianceDialog("edit");
										handleClose();
									}}
								>
									Edit
								</MenuItem>
								<MenuItem
									onClick={() => {
										setApplianceDialog("delete");
										handleClose();
									}}
								>
									Delete
								</MenuItem>
							</Menu>
						</div>
					)}
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5" component={"span"}>
						Recent Notes{" "}
					</Typography>{" "}
					{!notesEdit && (
						<Typography
							component={"span"}
							onClick={() => setNotesEdit(true)}
							className={classes.hoverUnderline}
						>
							Add
						</Typography>
					)}
					<Divider />
					{notesEdit ? (
						<div style={{ marginTop: "1rem" }}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography variant="h5">
										Add Note
									</Typography>
								</Grid>

								<Grid item xs={6}>
									<TextField
										label="Description"
										multiline
										// rows={5}
										minRows={4}
										value={newNotes}
										onChange={(e) =>
											setNewNotes(e.target.value)
										}
										variant="outlined"
										fullWidth
									/>
								</Grid>
							</Grid>
							<div
								style={{
									display: "flex",
									gap: "10px",
									marginTop: "1rem",
								}}
							>
								<Button
									variant="contained"
									color="primary"
									onClick={(e) => {
										handleNewNote();
										setNotesEdit(false);
									}}
								>
									Save
								</Button>
								<Button
									variant="outlined"
									onClick={() => setNotesEdit(false)}
								>
									Cancel
								</Button>
							</div>
						</div>
					) : (unit?.notes || []).length == 0 ? (
						<Typography style={{ marginTop: "1rem" }}>
							You don't have any notes for this rental unit right
							now. Add your first note
						</Typography>
					) : (
						<div style={{ marginTop: "1rem" }}>
							{(unit?.notes || [])
								.sort((a, b) => {
									return (
										new Date(a.updatedAt) -
										new Date(b.updatedAt)
									);
								})
								.map((app, idx) => (
									<div
										className={classes.hoverUnderlineGray}
										onClick={(e) => {
											setCurrentNote(app);
											setNotesDialog("view");
										}}
										style={
											idx == 0
												? {
														display: "flex",

														justifyContent:
															"space-between",
														padding: "4px 5px",
														border: "1px solid lightgray",
														alignItems: "center",
												  }
												: {
														display: "flex",

														justifyContent:
															"space-between",
														padding: "4px 5px",
														border: "1px solid lightgray",
														borderTop: 0,
														alignItems: "center",
												  }
										}
									>
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Typography
												style={{
													whiteSpace: "pre-line",
													marginLeft: "0.5rem",
												}}
											>
												{app?.details}
											</Typography>
										</div>
										<Typography variant="button">
											{format(
												new Date(app?.updatedAt),
												"MM/dd/yyyy 'at' h:mm a"
											)}
										</Typography>
										<div
											style={{
												display: "flex",
												alignItems: "center",
											}}
										>
											<Typography>
												{app?.updatedByUser?.parent
													?.displayName ||
													user?.displayName}
											</Typography>
											<IconButton
												onClick={(e) => {
													e.stopPropagation();
													handleClickNotes(e, app);
												}}
											>
												<MoreHorizIcon />
											</IconButton>
										</div>
									</div>
								))}
							<Menu
								id="simple-menu"
								anchorEl={anchorElNotes}
								open={Boolean(anchorElNotes)}
								onClose={handleClose}
							>
								<MenuItem
									onClick={() => {
										setNewNotes(currentNote?.details);
										setNotesDialog("edit");
									}}
								>
									Edit
								</MenuItem>
								<MenuItem
									onClick={() => {
										setNotesDialog("view");
										handleClose();
									}}
								>
									View
								</MenuItem>
								<MenuItem
									onClick={() => {
										setNotesDialog("delete");
										handleClose();
									}}
								>
									Delete
								</MenuItem>
							</Menu>
						</div>
					)}
				</Grid>
				{/* notes view dialog */}
				<Dialog
					open={notesDialog == "view"}
					onClose={() => setNotesDialog(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth={"sm"}
					fullWidth
				>
					<DialogTitle id="alert-dialog-title">View Note</DialogTitle>
					<DialogContent>
						<div
							style={{
								display: "flex",
								gap: "5rem",
								margin: "1.5rem 1rem",
							}}
						>
							<div>
								<Typography
									variant="h6"
									style={{ fontWeight: "bold" }}
									component={"div"}
								>
									Date Updated
								</Typography>
								<Typography component={"div"}>
									{format(
										new Date(
											currentNote?.updatedAt || null
										),
										"MM/dd/yyyy 'at' h:mm a"
									)}
								</Typography>
							</div>
							<div>
								<Typography
									variant="h6"
									style={{ fontWeight: "bold" }}
									component={"div"}
								>
									Updated By
								</Typography>
								<Typography component={"div"}>
									{currentNote?.updatedByUser?.parent
										?.displayName || user?.displayName}
								</Typography>
							</div>
						</div>
						<div style={{ margin: "1rem" }}>
							<Typography
								variant="h6"
								style={{ fontWeight: "bold" }}
								component={"div"}
							>
								Details
							</Typography>
							<Typography
								style={{
									whiteSpace: "pre-line",
								}}
							>
								{currentNote?.details}
							</Typography>
						</div>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setNotesDialog(null)}
							color="primary"
							variant="outlined"
						>
							Done
						</Button>
					</DialogActions>
				</Dialog>
				{/* notes edit dialog */}
				<Dialog
					open={notesDialog == "edit"}
					onClose={() => {
						setNewNotes("");
						setNotesDialog(null);
					}}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					maxWidth={"sm"}
					fullWidth
				>
					<DialogTitle id="alert-dialog-title">Edit Note</DialogTitle>
					<DialogContent>
						<TextField
							label="Description"
							multiline
							// rows={5}
							minRows={4}
							value={newNotes}
							onChange={(e) => setNewNotes(e.target.value)}
							variant="outlined"
							fullWidth
						/>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => {
								setNewNotes("");
								setNotesDialog(null);
							}}
							color="primary"
						>
							Discard
						</Button>
						<Button
							onClick={() => {
								handleNoteUpdate();
								setNotesDialog(null);
							}}
							variant="contained"
							color="primary"
							autoFocus
						>
							Update
						</Button>
					</DialogActions>
				</Dialog>
				{/* confirm delete dialog */}
				<Dialog
					open={notesDialog == "delete"}
					onClose={() => setNotesDialog(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{"Delete Note?"}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete this note?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setNotesDialog(null)}
							color="primary"
							variant="outlined"
						>
							Discard
						</Button>
						<Button
							onClick={() => {
								handleDeleteNote();
								setNotesDialog(null);
							}}
							color="primary"
							variant="contained"
							autoFocus
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				{/* application delete */}
				<Dialog
					open={applianceDialog == "delete"}
					onClose={() => setApplianceDialog(null)}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						{`Delete ${currentAppliance?.name}?`}
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							Are you sure you want to delete this appliance?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setApplianceDialog(null)}
							color="primary"
							variant="outlined"
						>
							Discard
						</Button>
						<Button
							onClick={() => {
								handleApplianceDelete();
								setApplianceDialog(null);
							}}
							color="primary"
							variant="contained"
							autoFocus
						>
							Delete
						</Button>
					</DialogActions>
				</Dialog>
				{/* Application View */}
				<Dialog
					open={applianceDialog == "edit"}
					onClose={() => setApplianceDialog(null)}
					maxWidth={"md"}
					fullWidth
				>
					<DialogTitle id="alert-dialog-title">
						{`Edit Appliance`}
					</DialogTitle>
					<DialogContent>
						<Grid container spacing={2}>
							<Grid item xs={4}>
								<TextField
									variant="outlined"
									fullWidth
									label="Name (Required)"
									value={currentAppliance.name}
									onChange={(e) => {
										let txt = e.target.value;
										setCurrentAppliance((prev) => {
											return { ...prev, name: txt };
										});
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									variant="outlined"
									label="Make"
									fullWidth
									value={currentAppliance.make}
									onChange={(e) => {
										let txt = e.target.value;
										setCurrentAppliance((prev) => {
											return { ...prev, make: txt };
										});
									}}
								/>
							</Grid>
							<Grid item xs={4}>
								<TextField
									variant="outlined"
									label="Model"
									fullWidth
									value={currentAppliance.model}
									onChange={(e) => {
										let txt = e.target.value;
										setCurrentAppliance((prev) => {
											return { ...prev, model: txt };
										});
									}}
								/>
							</Grid>
							<Grid item xs={9}>
								<TextField
									label="Description"
									multiline
									// rows={5}
									minRows={4}
									variant="outlined"
									fullWidth
									value={currentAppliance.description}
									onChange={(e) => {
										let txt = e.target.value;
										setCurrentAppliance((prev) => {
											return {
												...prev,
												description: txt,
											};
										});
									}}
								/>
							</Grid>
							<Grid item xs={4} />
							<Grid item xs={9}>
								<div style={{ display: "flex", gap: "10px" }}>
									<MuiPickersUtilsProvider
										utils={DateFnsUtils}
									>
										<KeyboardDatePicker
											value={currentAppliance.InstalledOn}
											className={classes.datePicker}
											margin="normal"
											id="date-picker-dialog"
											label="Installed"
											format="MM/dd/yyyy"
											onChange={(date) => {
												setCurrentAppliance((prev) => {
													return {
														...prev,
														InstalledOn: date,
													};
												});
											}}
											KeyboardButtonProps={{
												"aria-label": "change date",
											}}
										/>

										<KeyboardDatePicker
											value={
												currentAppliance.WarrentyEndsOn
											}
											className={classes.datePicker}
											margin="normal"
											id="due-date-picker"
											label="Warrenty Ends"
											format="MM/dd/yyyy"
											onChange={(date) => {
												setCurrentAppliance((prev) => {
													return {
														...prev,
														WarrentyEndsOn: date,
													};
												});
											}}
											KeyboardButtonProps={{
												"aria-label": "change date",
											}}
										/>
									</MuiPickersUtilsProvider>
								</div>
							</Grid>
							<Grid item xs={12}>
								<Typography>Appliance history</Typography>
								<TableContainer>
									<Table
										style={{
											border: "1px solid lightgray",
										}}
									>
										<TableHead>
											<TableRow
												style={{
													backgroundColor: "#f2f2f2",
												}}
											>
												<TableCell
													align="center"
													style={{
														borderRight:
															"1px solid lightgray",
													}}
												>
													Service Date
												</TableCell>
												<TableCell
													align="center"
													style={{
														borderRight:
															"1px solid lightgray",
													}}
												>
													Event Type
												</TableCell>
												<TableCell align="center">
													Details
												</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{(currentAppliance?.history || [])
												.length == 0 ? (
												<TableRow>
													<TableCell colSpan={3}>
														There are no records to
														display.
													</TableCell>
												</TableRow>
											) : (
												(
													currentAppliance?.history ||
													[]
												).map((row, idx) => (
													<TableRow
														key={idx}
														style={
															row?.deleted
																? {
																		textDecoration:
																			"line-through",
																  }
																: {}
														}
													>
														<TableCell
															style={{
																borderRight:
																	"1px solid lightgray",
															}}
														>
															{row?._id ? (
																`${format(
																	new Date(
																		row?.serviceDate
																	),
																	"MM/dd/yyyy"
																)}`
															) : (
																<MuiPickersUtilsProvider
																	utils={
																		DateFnsUtils
																	}
																>
																	<KeyboardDatePicker
																		value={
																			row?.serviceDate
																		}
																		className={
																			classes.datePicker
																		}
																		margin="normal"
																		id="date-picker-dialog"
																		format="MM/dd/yyyy"
																		onChange={(
																			date
																		) => {
																			setCurrentAppliance(
																				(
																					prev
																				) => {
																					let temp =
																						(
																							prev?.history ||
																							[]
																						).map(
																							(
																								doc,
																								index
																							) => {
																								if (
																									index ==
																									idx
																								) {
																									doc.serviceDate =
																										date;
																								}
																								return doc;
																							}
																						);
																					return {
																						...prev,
																						history:
																							temp,
																					};
																				}
																			);
																		}}
																		KeyboardButtonProps={{
																			"aria-label":
																				"change date",
																		}}
																	/>
																</MuiPickersUtilsProvider>
															)}
														</TableCell>
														<TableCell
															style={{
																borderRight:
																	"1px solid lightgray",
															}}
														>
															{row?._id ? (
																`${row?.eventType}`
															) : (
																<FormControl
																	variant="outlined"
																	style={{
																		width: "100%",
																	}}
																>
																	<Select
																		value={
																			row?.eventType
																		}
																		onChange={(
																			e
																		) => {
																			let txt =
																				e
																					.target
																					.value;
																			setCurrentAppliance(
																				(
																					prev
																				) => {
																					let temp =
																						(
																							prev?.history ||
																							[]
																						).map(
																							(
																								doc,
																								index
																							) => {
																								if (
																									index ==
																									idx
																								) {
																									doc.eventType =
																										txt;
																								}
																								return doc;
																							}
																						);
																					return {
																						...prev,
																						history:
																							temp,
																					};
																				}
																			);
																		}}
																		displayEmpty
																	>
																		<MenuItem
																			value={
																				"Serviced"
																			}
																		>
																			Serviced
																		</MenuItem>
																		<MenuItem
																			value={
																				"Uninstalled"
																			}
																		>
																			Uninstalled
																		</MenuItem>
																	</Select>
																</FormControl>
															)}
														</TableCell>
														<TableCell>
															<div
																style={{
																	display:
																		"flex",
																	alignItems:
																		"center",
																}}
															>
																{row?._id ? (
																	<Typography
																		style={{
																			flexBasis:
																				"0.9",
																		}}
																	>
																		{
																			row?.details
																		}
																	</Typography>
																) : (
																	<TextField
																		variant="outlined"
																		style={{
																			flexBasis:
																				"0.9",
																		}}
																		value={
																			row?.details
																		}
																		onChange={(
																			e
																		) => {
																			let txt =
																				e
																					.target
																					.value;
																			setCurrentAppliance(
																				(
																					prev
																				) => {
																					let temp =
																						(
																							prev?.history ||
																							[]
																						).map(
																							(
																								doc,
																								index
																							) => {
																								if (
																									index ==
																									idx
																								) {
																									doc.details =
																										txt;
																								}
																								return doc;
																							}
																						);
																					return {
																						...prev,
																						history:
																							temp,
																					};
																				}
																			);
																		}}
																	/>
																)}
																<IconButton
																	onClick={() => {
																		setCurrentAppliance(
																			(
																				prev
																			) => {
																				let temp =
																					(
																						prev?.history ||
																						[]
																					).map(
																						(
																							doc,
																							index
																						) => {
																							if (
																								index ==
																								idx
																							) {
																								doc.deleted =
																									!doc.deleted;
																							}
																							return doc;
																						}
																					);
																				return {
																					...prev,
																					history:
																						temp,
																				};
																			}
																		);
																	}}
																>
																	{row?.deleted ? (
																		<ReplayIcon />
																	) : (
																		<CloseIcon />
																	)}
																</IconButton>
															</div>
														</TableCell>
													</TableRow>
												))
											)}
										</TableBody>
									</Table>
									<Button
										startIcon={<AddIcon />}
										onClick={() => {
											setCurrentAppliance((prev) => {
												return {
													...prev,
													history: [
														...(prev?.history ||
															[]),
														{
															serviceDate:
																new Date(),
															eventType: "",
															details: "",
															deleted: false,
														},
													],
												};
											});
										}}
									>
										Add Event
									</Button>
								</TableContainer>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<Button
							onClick={() => setApplianceDialog(null)}
							color="primary"
							variant="outlined"
						>
							Discard
						</Button>
						<Button
							onClick={handleEditAppliance}
							color="primary"
							variant="contained"
						>
							Update
						</Button>
					</DialogActions>
				</Dialog>
			</Grid>
		</Paper>
	);
}

export default LeaseViewPage;
