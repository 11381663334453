import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Api from "../../helpers/Api";
import useShared from "../share/useShared.js";
import ShareIconBtn from "../share/share.icon.btn.js";
import SharedList from "../share/sharedList.js";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import DocEditor from "./Doc.Editor";
import CustomBtn from "../styled/CommonComponents/CustomBtn";
import Paper from '@material-ui/core/Paper';
import SaveIcon from '@material-ui/icons/Save';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#F8F8F8",
    padding: "0px 30px",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 10px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "calc(100vh - 60px)",
    }
  },
  navBar: {
    width: "100%",
    height: "70px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      height: "50px",
    }
  },
  mainCont: {
    width: "100%",
    height: "calc(100% - 70px)",
    display: "flex",
    overflowY: "auto",
    flexDirection: "column",
    padding: "0px 0px 40px",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down('sm')]: {
      padding: "0px 0px 30px",
    },
    [theme.breakpoints.down('xs')]: {
      height: "calc(100% - 50px)",
    }
  },
  flexShow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& h3": {
      fontWeight: "510",
      fontSize: "20px",
      color: "#00345D"
    },
    [theme.breakpoints.down('xs')]: {
      "& h3": {
        fontWeight: "500",
        fontSize: "17px",
        color: "#00345D"
      },
    },
  },
  backIcon: {
    fontSize: "30px",
    marginRight: "10px",
    cursor: "pointer",
    [theme.breakpoints.down('xs')]: {
      fontSize: "25px",
      marginRight: "5px",
    }
  },
  shareTitle: {
    fontSize: "19px",
    fontWeight: "500",
    margin: '0px 0px 10px',
    [theme.breakpoints.down('xs')]: {
      fontSize: "16px",
    }
  },
  editorCont: {
    marginTop: "20px",
    padding: "0px 10px 15px"
  },
  shareCont: {
    padding: "5px 10px",
    marginTop: "20px"
  },
  onlyEditorCont: {
    width: "100%",
    marginTop: "-30px"
  }
}));

function CreateProfileDoc(props) {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { profileId } = useParams();
  const location = useLocation();

  const file = useSelector((state) => state.file);
  const { createdFileIds } = file;
  const { user } = useSelector((state) => state.auth);
  const userProfileId = user?.profile;

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [isPrivate, setPrivate] = useState(false);

  const sharedProps = useShared({ initShared: [profileId, userProfileId], initAssigned: [] });
  var { privateButton, sharedPeoples } = SharedList(sharedProps, isPrivate, setPrivate);


  const createDocApi = async () => {
    let pathStr = location.pathname
    let pathArr = pathStr.split("/");
    let typeStr = ""
    if (pathArr[2] === "create" && pathStr.includes("/doc/create/")) {
      typeStr = "DOCUMENT";
    }
    if (pathArr[2] === "template" && pathStr.includes("/doc/template/")) {
      typeStr = "ESIGN_TEMPLATE";
    }

    const docObject = {
      user: user._id,
      profile: profileId,
      title,
      description,
      shared: Array.from(new Set(sharedProps?.shared)),
      assigned: sharedProps?.assigned,
      isPrivate,
      files: createdFileIds,
      activeUserId: user?._id,
      activeUserProfile: user?.profile,
      docType: typeStr,
    };

    await Api.post("doc/create", docObject);

    dispatch({
      type: "AddApiAlert",
      payload: {
        success: true,
        message: "Doc created successfully",
      },
    });

    dispatch({ type: "FileUploadReset" });

    history.goBack();
  };


  return (
    <div className={classes.root}>
      <div className={classes.navBar} >
        <div className={classes.flexShow} >
          <KeyboardBackspaceIcon
            onClick={() => {
              history.goBack();
            }}
            className={classes.backIcon}
          />
          <h3>Create Document</h3>
        </div>
        <CustomBtn
          startPart={<SaveIcon />}
          disabled={title && title.length > 2 ? false : true}
          text={"Save"}
          onClick={() => { createDocApi() }}
        />
      </div>

      <div className={classes.mainCont} >
        {sharedProps?.shareDrawer}
        {sharedProps?.assignedDialog}
        <Paper elevation={1} className={classes.editorCont} >
          <div className={classes.onlyEditorCont} >
            <DocEditor
              description={description}
              setDescription={setDescription}
              title={title}
              setTitle={setTitle}
            />
          </div>
        </Paper>
        <Paper className={classes.shareCont} >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
            <Typography variant="h4" className={classes.shareTitle}>Shared</Typography>
            <div>
              <span style={{ marginRight: "15px" }} >
                {privateButton}
              </span>
              <ShareIconBtn
                open={sharedProps?.open}
                setOpen={sharedProps?.setOpen}
              />
            </div>
          </div>
          <div>{sharedPeoples}</div>
        </Paper>
      </div>
    </div>
  )
}
export default CreateProfileDoc;