import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { RiUserAddFill, RiUserUnfollowFill } from "react-icons/ri";
import { followUnfollowApiHit } from './api';
import SendMessage from '../profileChat/send.message';


const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "20px",
    marginBottom: "25px",
    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)",
    [theme.breakpoints.down("xs")]: {
      marginBottom: "15px",
      padding: "10px",
    }
  },
  skillsAndMarketsCont: {
    marginTop: "25px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "15px",
    }
  },
  tagsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "7px",
    "& h2": {
      fontSize: "16px",
      fontWeight: "510",
      marginTop: "-4px",
      color: "#170f49",
      marginRight: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      "& h2": {
        fontSize: "14px",
        marginRight: "10px"
      },
    }
  },
  allTagsCont: {
    width: "calc(100% - 60px)",
    display: "flex",
    flexWrap: "wrap",
    "& div": {
      backgroundColor: "#DFE9FF",
      marginRight: "6px",
      marginBottom: "5px",
      padding: "2px 10px",
      fontWeight: "500",
      borderRadius: "4px"
    },
    [theme.breakpoints.down("xs")]: {
      "& div": {
        marginRight: "5px",
        marginBottom: "3px",
        padding: "1px 5px",
        borderRadius: "3px",
        fontSize: "12px"
      },
    }
  },
  nameBtnCont: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  avatarSty: {
    width: "60px",
    height: "60px",
    marginRight: "15px",
    [theme.breakpoints.down("xs")]: {
      width: "45px",
      height: "45px",
      marginRight: "10px",
    }
  },
  picNameCont: {
    display: "flex",
    cursor: "pointer",
    alignItems: "center"
  },
  nameDetailsCont: {
    display: "flex",
    flexDirection: "column",
    "& p": {
      fontSize: "14px",
      color: "gray",
      fontWeight: "500"
    },
    "& h3": {
      fontSize: "18px"
    },
    [theme.breakpoints.down("xs")]: {
      "& p": {
        fontSize: "12px",
        color: "gray",
        fontWeight: "500"
      },
      "& h3": {
        fontSize: "15px",
        fontWeight: "510"
      }
    }
  },
  editButtonSty: {
    padding: "5px 12px",
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "15px",
    border: "1px solid #cccbcb",
    color: "black",
    cursor: "pointer",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#f7f4f4"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      borderRadius: "15px",
      fontSize: "13px",
    }
  },
  btnCont: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    }
  }
}));

const colorArr = [
  "#FFE7E7",
  "#FFEDD8",
  "#FFEFEF",
  "#F8F6E9",
  "#F8E9E9",
  "#E9F8F0",
  "#E9F8F0"
]

export default function BasicUserCard(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const { userData, followerIds, followingIds, getAllFollowersIdOnly } = props;
  const { user } = useSelector((state) => state.auth);

  const [followingLoading, setFollowingLoading] = useState(false)
  const [isFollowing, setIsFollowing] = useState(false)
  const [isFollower, setIsFollower] = useState(false)

  useEffect(() => {
    if (followerIds.includes(userData?.profile)) {
      setIsFollower(true)
    } else {
      setIsFollower(false)
    }
  }, [followerIds, userData])

  useEffect(() => {
    if (followingIds.includes(userData?.profile)) {
      setIsFollowing(true)
    } else {
      setIsFollowing(false)
    }
  }, [followingIds, userData])

  const handleFollow = async (otherUser) => {
    setFollowingLoading(true)
    await followUnfollowApiHit({
      mainProfile: user?.profile,
      otherProfile: otherUser?.profile,
      mainUser: user?._id,
      otherUser: otherUser?._id
    })
      .then(async (data) => {
        await getAllFollowersIdOnly()

        setFollowingLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  };


  return (
    <Paper className={classes.root} >
      <div className={classes.nameBtnCont} >
        <div className={classes.picNameCont} onClick={() => {
          history.push('/profile/view/' + userData?.profile);
        }} >
          <Avatar className={classes.avatarSty} src={userData?.displayPicture?.url || userData?.displayPicture?.thumbUrl} />
          <div className={classes.nameDetailsCont} >
            <h3>{userData?.displayName}</h3>
            {userData?.description || userData?.description ? (
              <p>{userData?.description}</p>
            ) : null}
            {userData?.address?.line1 || userData?.address?.streetAddress ? (
              <p>{userData?.address?.line1 || userData?.address?.streetAddress}</p>
            ) : null}
          </div>
        </div>
        <div></div>
      </div>

      {(userData?.skills && userData?.skills.length > 0) || (userData?.markets && userData?.markets.length > 0) ? (
        <div className={classes.skillsAndMarketsCont} >
          {userData?.skills && userData?.skills.length > 0 && (
            <div className={classes.tagsCont} >
              <h2>Roles</h2>
              <div className={classes.allTagsCont} >
                {userData?.skills && userData?.skills.map((skill, i) => (
                  <div style={{ backgroundColor: colorArr[i % 7] }} >{skill}</div>
                ))}
              </div>
            </div>
          )}
          {userData?.markets && userData?.markets.length > 0 && (
            <div className={classes.tagsCont} style={{ marginBottom: "0px" }} >
              <h2>Markets</h2>
              <div className={classes.allTagsCont} >
                {userData?.markets && userData?.markets.map((loc, i) => (
                  <div>{loc.split("_")[0]}</div>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : null}

      {user?.profile === userData?.profile ? (<div></div>) : (<div className={classes.btnCont} >
        <button
          className={classes.editButtonSty}
          style={{ marginRight: "15px", color: "#2e73f8" }}
          disabled={followingLoading}
          onClick={() => { handleFollow(userData) }}
        >{followingLoading ? (<>
          <CircularProgress size={20} style={{ marginBottom: "-4px" }} /> <span>Updating</span>
        </>) : (<>
          {isFollower && isFollowing ? (<>
            <RiUserUnfollowFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Unfollow</span>
          </>) : (<>
            {isFollower ? (<>
              <RiUserAddFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Follow Back</span>
            </>) : (<>
              {isFollowing ? (<>
                <RiUserUnfollowFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Unfollow</span>
              </>) : (<>
                <RiUserAddFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Follow</span>
              </>)}
            </>)}
          </>)}
        </>)}
        </button>

        <SendMessage
          senderProfile={user}
          receiverProfile={userData}
        />
      </div>)}
    </Paper>
  );
}