import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import { followUnfollowApiHit, getFollowerAndFollowingsApiHit, updateUserInfo } from "./api";
import EducationDialog from "./Dialogs/EducationDialog";
import ExperienceDialog from "./Dialogs/ExperienceDialog";
import ProjectDialog from "./Dialogs/ProjectDialog";
import LangugageDialog from "./Dialogs/LanguageDialog";
import LicenseDialog from "./Dialogs/LicenseDialog";
import SendMessage from "../profileChat/send.message";
import UserAbout from "./UserAbout/index";
import { FaCalendarAlt } from "react-icons/fa";
import moment from "moment";
import CircularProgress from '@material-ui/core/CircularProgress';
import { ImLocation2 } from "react-icons/im";
import { RiUserAddFill, RiUserFollowFill } from "react-icons/ri";
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CustomFileUploadButton from "../file/Uploader/CustomFileUploadButton";
import FollowingsFollowers from "./Followers.Followings";


const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    borderTop: "1px solid white",
    borderBottom: "0",
    borderRadius: "0px",
    paddingBottom: "1rem",
  },
  overlay: {
    position: "relative",
    backgroundColor: "#f2f2f2",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    height: "15rem",
    width: "100%",
    borderRadius: "1px solid white",
    borderBottom: "0",
    [theme.breakpoints.down("xs")]: {
      height: "10rem",
    }
  },
  coverUploadButton: {
    position: "absolute",
    right: "10px",
    backgroundColor: "white",
    padding: "4px",
    border: "1px solid #2296f3",
    borderRadius: "100%",
    top: "10px",
  },
  imgParent: {
    position: "absolute",
    zIndex: 100,
    cursor: (props) => (props.isOwnProfile ? "pointer" : null),
    top: "130px",
    left: "10px",
    "&:hover": {
      "& $hoverUploadButton": {
        opacity: 1,
      },
    },
    [theme.breakpoints.down("xs")]: {
      top: "90px",
    }
  },
  imgAvatar: {
    height: "10rem",
    width: "10rem",
    objectFit: "cover",
    borderRadius: "100%",
    [theme.breakpoints.down("xs")]: {
      height: "7rem",
      width: "7rem",
    }
  },
  profileContent: {
    display: "flex",
    justifyContent: "space-between",
    marginLeft: "1.5rem",
    "& $h2": {
      fontSize: "24px",
      fontWeight: "800",
      display: "inline",
      marginBottom: "2px"
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
      "& $h2": {
        fontSize: "20px",
        fontWeight: "600"
      },
    }
  },
  locationAndJoinCont: {
    display: "flex",
    alignItems: "center",
    marginTop: "2px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "flex-start"
    }
  },
  content_left: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "left",
    "& $p": {
      fontSize: "14px",
      color: "grey",
    },
    "& h4": {
      fontSize: "1rem",
      color: "black",
      fontWeight: "400"
    },
    [theme.breakpoints.down("xs")]: {
      "& h4": {
        fontSize: "16px",
      },
      "& $p": {
        fontSize: "13px",
      },
    }
  },
  skillsAndMarketsCont: {
    marginTop: "25px"
  },
  tagsCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "7px",
    "& h2": {
      fontSize: "16px",
      fontWeight: "510",
      marginTop: "-4px",
      color: "#170f49",
      marginRight: "15px"
    },
    [theme.breakpoints.down("xs")]: {
      "& h2": {
        fontSize: "14px",
        marginRight: "10px"
      },
    }
  },
  allTagsCont: {
    width: "calc(100% - 65px)",
    display: "flex",
    flexWrap: "wrap",
    "& div": {
      backgroundColor: "#DFE9FF",
      marginRight: "8px",
      marginBottom: "5px",
      padding: "2px 10px",
      fontWeight: "500",
      borderRadius: "4px"
    },
    [theme.breakpoints.down("xs")]: {
      "& div": {
        marginRight: "5px",
        marginBottom: "3px",
        padding: "1px 5px",
        borderRadius: "3px",
        fontSize: "12px"
      },
    }
  },
  editButtonSty: {
    padding: "5px 12px",
    borderRadius: "20px",
    backgroundColor: "white",
    fontSize: "15px",
    border: "1px solid #cccbcb",
    color: "black",
    cursor: "pointer",
    fontWeight: "600",
    "&:hover": {
      backgroundColor: "#f7f4f4"
    },
    [theme.breakpoints.down("xs")]: {
      padding: "5px 10px",
      borderRadius: "15px",
      fontSize: "13px",
    }
  },
  headerActionBtns: {
    display: "flex",
    alignItems: "center",
    marginLeft: "1.5rem",
    marginTop: "15px",
    [theme.breakpoints.down("xs")]: {
      marginLeft: "15px",
    }
  },
  editIcon: {
    marginLeft: "auto",
    height: "50px",
    paddingTop: "10px",
    paddingRight: "15px"
  },
  hoverUploadButton: {
    transition: ".5s ease",
    position: "absolute",
    opacity: 0,
    backgroundColor: "white",
    border: "1px solid white",
    borderRadius: "100%",
    top: "50%",
    left: "50%",
    padding: "4px",
    transform: "translate(-50%, -50%)",
  },
  followBox: {
    display: "flex",
    marginTop: "20px",
    justifyContent: 'flex-start',
    fontSize: "16px",
    color: "#3c3c3c"
  },
  followerCount: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer"
  },
  followBold: {
    fontWeight: "600",
    marginRight: "5px"
  }
}));

const colorArr = [
  "#FFE7E7",
  "#FFEDD8",
  "#FFEFEF",
  "#F8F6E9",
  "#F8E9E9",
  "#E9F8F0",
  "#E9F8F0"
]

const UserProfileTopView = ({
  profile,
  isOwnProfile,
  setOpenEditView,
  setProfileEditView
}) => {
  const componentRef = useRef();
  const history = useHistory();
  const theme = useTheme()
  const classes = useStyles({ isOwnProfile });
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { createdFileIds } = useSelector((state) => state.file);

  const [followingCount, setFollowingCount] = useState(profile?.parent?.followingCount || 0);
  const [followerCount, setFollowerCount] = useState(profile?.parent?.followerCount || 0);
  const [followersArr, setFollowersArr] = useState([])
  const [followingArr, setFollowingArr] = useState([])
  const [followerIdsArr, setFollowerIdsArr] = useState([])
  const [followingIdsArr, setFollowingIdsArr] = useState([])
  const [isFollowing, setIsFollowing] = useState(false);
  const [followingLoading, setFollowingLoading] = useState(false)
  const [dialog, setDialog] = useState(false);
  const [showExperience, setShowExperience] = useState(true);
  const [showEducation, setShowEducation] = useState(true);
  const [showProject, setShowProject] = useState(true);
  const [showLang, setShowLang] = useState(true);
  const [showLicense, setShowLicense] = useState(true);
  const [openFollowing, setOpenFollowing] = useState(false);
  const [openFollowers, setOpenFollowers] = useState(false);


  //profile picture and cover update
  const setUploadedProfilePicture = async () => {
    const userObj = { displayPicture: createdFileIds[0] }
    await updateUserInfo({
      userId: user?._id,
      userInfo: userObj,
    })
      .then((data) => {
        const obj = {
          displayPicture: data?.displayPicture,
        }

        dispatch({
          type: "AddAuth",
          payload: {
            user: {
              ...user,
              ...obj
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
    dispatch({ type: "FileUploadReset" });
  }

  const setUploadedCoverPicture = async () => {
    const userObj = { cover: createdFileIds[0] }
    await updateUserInfo({
      userId: user?._id,
      userInfo: userObj,
    })
      .then((data) => {
        const obj = {
          cover: data?.cover,
        }

        dispatch({
          type: "AddAuth",
          payload: {
            user: {
              ...user,
              ...obj,
            },
          },
        });
      })
      .catch((err) => {
        console.log(err);
      })
    dispatch({ type: "FileUploadReset" });
  }

  // get followers and followings and to check isFollowing
  const getFollowerAndFollowings = async () => {
    await getFollowerAndFollowingsApiHit({ profileId: profile?._id })
      .then((data) => {
        let followersLocArr = []
        let followingsLocArr = []
        let followerIdsLocArr = []
        let followingIdsLocArr = []
        let isFollowingLoc = false

        data.map((follow) => {
          if (profile?._id === follow?.profile?._id) {
            followersLocArr.push(follow)
            followerIdsLocArr.push(follow?.userProfile?._id)
            if (follow?.userProfile?._id === user?.profile) {
              isFollowingLoc = true
            }
          } else if (profile?._id === follow?.userProfile?._id) {
            followingsLocArr.push(follow)
            followingIdsLocArr.push(follow?.profile?._id)
          }
        })

        setFollowerIdsArr(followerIdsLocArr)
        setFollowingIdsArr(followingIdsLocArr)
        setIsFollowing(isFollowingLoc);
        setFollowerCount(followersLocArr.length)
        setFollowingCount(followingsLocArr.length)
        setFollowersArr(followersLocArr)
        setFollowingArr(followingsLocArr)

        if (isOwnProfile === true) {
          const obj = {
            followerCount: followersLocArr.length,
            followingCount: followingsLocArr.length
          }

          dispatch({
            type: "AddAuth",
            payload: {
              user: {
                ...user,
                ...obj
              },
            },
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
  }

  useEffect(() => {
    getFollowerAndFollowings()
  }, [profile?._id]);

  // follow or unfollow
  const handleFollow = async () => {
    setFollowingLoading(true)
    await followUnfollowApiHit({
      mainProfile: user?.profile,
      otherProfile: profile?._id,
      mainUser: user?._id,
      otherUser: profile?.parent?._id
    })
      .then(async (data) => {
        await getFollowerAndFollowings()

        if (data) {
          if (data?.followerCount) {
            setFollowerCount(data?.followerCount || 0);
          }
          if (data?.followingCount) {
            setFollowingCount(data?.followingCount || 0);
          }
        }
        setFollowingLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  };


  return (
    <div className={classes.root} ref={componentRef}>
      <Paper className={classes.root}>
        <div className={classes.overlay} style={{ backgroundImage: `url(${profile?.parent?.cover?.url})` }} >
          {isOwnProfile ? (
            <div className={classes.coverUploadButton}>
              <CustomFileUploadButton
                showComponent={
                  <PhotoCameraIcon style={{ fontSize: "35px", marginBottom: "-4px" }} />
                }
                parentType={"User"}
                parentId={user?._id}
                fileNum={1}
                givenFileTypes={["image"]}
                givenMaxSize={26214400}
                closeFunCall={setUploadedCoverPicture}
              />
            </div>
          ) : null}
          <div className={classes.imgParent}>
            <Avatar className={classes.imgAvatar} src={profile?.parent?.displayPicture?.url || "https://firebasestorage.googleapis.com/v0/b/contractflo-prod.appspot.com/o/Profile%20DP.svg?alt=media"} />
            {isOwnProfile ? (
              <div className={classes.hoverUploadButton}>
                <CustomFileUploadButton
                  showComponent={
                    <PhotoCameraIcon style={{ fontSize: "35px", marginBottom: "-4px" }} />
                  }
                  parentType={"User"}
                  parentId={user?._id}
                  fileNum={1}
                  givenFileTypes={["image"]}
                  givenMaxSize={26214400}
                  closeFunCall={setUploadedProfilePicture}
                />
              </div>
            ) : null}
          </div>
        </div>
        <div className={classes.editIcon}>
          {isOwnProfile ? (
            <div>
              <button
                className={classes.editButtonSty}
                onClick={() => {
                  setProfileEditView("basicInfo")
                  setOpenEditView(true)
                }}
              >
                Edit Profile
              </button>
            </div>
          ) : null}
        </div>

        <div className={classes.profileContent}>
          <div className={classes.content_left}>
            <h2>{profile?.parent?.displayName || "Edit Profile"}</h2>
            {profile?.parent?.description && (<h4>{profile?.parent?.description}</h4>)}
            {(profile?.parent?.createdAt || profile?.parent?.locationCity || profile?.parent?.locationCountry) && (
              <div className={classes.locationAndJoinCont} >
                {(profile?.parent?.locationCity || profile?.parent?.locationCountry) && (
                  <div style={{ display: 'flex', alignItems: 'center', marginTop: "5px", marginRight: "15px" }} >
                    <ImLocation2 style={{ marginRight: "5px", fontSize: "16px", color: "gray" }} />
                    <p>{profile?.parent?.locationCity} {profile?.parent?.locationCountry ? `, ${profile?.parent?.locationCountry}` : null}</p>
                  </div>
                )}
                {profile?.parent?.createdAt && (
                  <div style={{ display: "flex", alignItems: "center", marginTop: "5px" }} >
                    <FaCalendarAlt style={{ marginRight: "5px", fontSize: "15px", color: "gray" }} />
                    <p>Joined {moment(profile?.parent?.createdAt).format("MMM Do YYYY")}</p>
                  </div>
                )}
              </div>
            )}

            {(profile?.parent?.skills && profile?.parent?.skills.length > 0) || (profile?.parent?.markets && profile?.parent?.markets.length > 0) ? (
              <div className={classes.skillsAndMarketsCont} >
                {profile?.parent?.skills && profile?.parent?.skills.length > 0 && (
                  <div className={classes.tagsCont} >
                    <h2>Roles</h2>
                    <div className={classes.allTagsCont} >
                      {profile?.parent?.skills && profile?.parent?.skills.map((skill, i) => (
                        <div style={{ backgroundColor: colorArr[i % 7] }} >{skill}</div>
                      ))}
                    </div>
                  </div>
                )}
                {profile?.parent?.markets && profile?.parent?.markets.length > 0 && (
                  <div className={classes.tagsCont} style={{ marginBottom: "0px" }} >
                    <h2>Markets</h2>
                    <div className={classes.allTagsCont} >
                      {profile?.parent?.markets && profile?.parent?.markets.map((loc, i) => (
                        <div>{loc.split("_")[0]}</div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            ) : null}

            <div className={classes.followBox}>
              <div
                className={classes.followerCount}
                onClick={() => {
                  setOpenFollowers(true)
                }}
              >
                <span className={classes.followBold}>
                  {followerCount || 0}
                </span>{" "}
                <p style={{ fontWeight: "510" }} >followers</p>
              </div>
              <div
                className={classes.followerCount}
                style={{ marginLeft: '.7rem' }}
                onClick={() => {
                  setOpenFollowing(true)
                }}
              >
                <span className={classes.followBold}>
                  {followingCount || 0}
                </span>{" "}
                <p style={{ fontWeight: "510" }} >following</p>
              </div>
            </div>
          </div>
          <div className={classes.content_right}></div>
        </div>
        <FollowingsFollowers
          openFollowersFollowing={openFollowing}
          setOpenFollowersFollowing={setOpenFollowing}
          title={"Following"}
          getFollowerAndFollowings={getFollowerAndFollowings}
          dataArr={followingArr}
          followerIdsArr={followerIdsArr}
          followingIdsArr={followingIdsArr}
          curProfile={profile}
        />

        <FollowingsFollowers
          openFollowersFollowing={openFollowers}
          setOpenFollowersFollowing={setOpenFollowers}
          title={"Followers"}
          getFollowerAndFollowings={getFollowerAndFollowings}
          dataArr={followersArr}
          followerIdsArr={followerIdsArr}
          followingIdsArr={followingIdsArr}
          curProfile={profile}
        />
        {isOwnProfile ? (
          <div></div>
        ) : (
          <div className={classes.headerActionBtns}>
            <button
              className={classes.editButtonSty}
              style={{ marginRight: "15px", color: "#2e73f8" }}
              disabled={followingLoading}
              onClick={handleFollow}
            >{followingLoading ? (<>
              <CircularProgress size={20} style={{ marginBottom: "-4px" }} /> <span>Updating</span>
            </>) : (<>{isFollowing ? (<>
              <RiUserFollowFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Following</span>
            </>) : (<>
              <RiUserAddFill style={{ fontSize: "19px", marginBottom: "-4px", marginRight: "2px" }} /> <span>Follow</span>
            </>)}</>)}
            </button>
            <SendMessage
              senderProfile={user}
              receiverProfile={profile?.parent}
            />
          </div>
        )}
      </Paper>

      <UserAbout
        isOwnProfile={isOwnProfile}
        profile={profile}
        setDialog={setDialog}
        setShowExperience={setShowExperience}
        setShowEducation={setShowEducation}
        setShowProject={setShowProject}
        setShowLang={setShowLang}
        setShowLicense={setShowLicense}
        setOpenEditView={setOpenEditView}
        setProfileEditView={setProfileEditView}
      />

      {dialog === "experience" && (
        <ExperienceDialog
          open={showExperience}
          setOpen={setShowExperience}
        />
      )}

      {dialog === "projects" && (
        <ProjectDialog
          open={showProject}
          setOpen={setShowProject}
        />
      )}

      {dialog === "education" && (
        <EducationDialog
          open={showEducation}
          setOpen={setShowEducation}
        />
      )}

      {dialog === "languages" && (
        <LangugageDialog
          open={showLang}
          setOpen={setShowLang}
        />
      )}

      {dialog === "licenses" && (
        <LicenseDialog
          open={showLicense}
          setOpen={setShowLicense}
        />
      )}
    </div>
  );
};
export default UserProfileTopView;