import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { getCategoryWithCount } from "./api";
import { useTheme } from "@material-ui/core/styles";
import { TextField, Button } from "@material-ui/core";
import CategoryFilter from "../styled/CommonComponents/Category.Filter";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PostAddIcon from '@material-ui/icons/PostAdd';
import LocationCountryState from "../styled/CommonComponents/Location.Country.State";
import ResourcesFeed from "./Resources.Feed";
import { useMediaQuery } from '@material-ui/core';
import { FiFilter } from "react-icons/fi";
import CancelIcon from '@material-ui/icons/Cancel';
import Drawer from "@material-ui/core/Drawer";
import Skeleton from '@material-ui/lab/Skeleton';
import { getSuggestedCommunities, getUserCommunities } from "../community/api.call";
import UserCommunities from "../community/UserCommunites";
import SuggestedCommunitySidebar from "../community/SuggestedCommunitiesSidebar";



const useStyles = makeStyles((theme) => ({
    mainCont: {
        width: "100%",
        height: `calc(100vh - ${theme.appbar.height})`,
        display: "flex",
        justifyContent: "space-between",
        padding: "5px",
        paddingTop: "25px",
        [theme.breakpoints.down('sm')]: {
            padding: "0px",
            paddingTop: "10px",
        }
    },
    feedCont: {
        width: "56%",
        height: "100%",
        overflowY: "auto",
        padding: "0px 30px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "0px 10px",
        }
    },
    leftSideCont: {
        width: "23%",
        height: "100%",
        overflowY: "auto",
        paddingLeft: "20px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    rightSideCont: {
        width: "23%",
        height: "100%",
        overflowY: "auto",
        paddingRight: "20px",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    titleSty: {
        fontSize: "20px",
    },
    categoryCont: {
        height: "230px",
        width: "100%",
        overflowY: "auto",
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        "&::-webkit-scrollbar": {
            display: "none"
        },
        scrollbarWidth: "none",
    },
    loaderCont: {
        width: "100%",
        borderTop: "1px solid #e3e0e0",
        height: "4px",
    },
    searchText: {
        backgroundColor: "white",
        borderRadius: "12px",
        margin: "0px"
    },
    noBorder: {
        border: "none",
    },
    filterLocationCont: {
        height: "230px",
        width: "100%",
        overflowY: "auto",
        display: "flex",
        flexDirection: "column",
    },
    drawerPaper: {
        width: "300px",
        height: "100vh",
        padding: "20px 10px 0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
    },
    actionBtnCont: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "0px 12px 5px",
        marginBottom: "15px",
        [theme.breakpoints.down('sm')]: {
            padding: "0px 12px 0px",
            marginBottom: "10px",
        }
    },
    filterCont: {
        display: "flex",
        fontSize: "15px",
        alignItems: "center",
        textTransform: "capitalize"
    },
    arrowCont: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "20px",
        "& p": {
            fontSize: "19px",
            fontWeight: "510"
        }
    },
    iconSty: {
        cursor: "pointer",
    }
}));

function DocResources() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const theme = useTheme()
    const { user } = useSelector((state) => state.auth);
    const { categorySlug } = useParams();

    const isMdSmall = useMediaQuery(theme.breakpoints.down("md"));
    const isExSmall = useMediaQuery(theme.breakpoints.down("xs"));

    const [categories, setCategories] = useState([])
    const [selectedCategories, setSelectedCategories] = useState([categorySlug])
    const [locationTags, setLocationTags] = useState([])
    const [getCategoryLoader, setGetCategoryLoader] = useState(false)
    const [changePageState, setChangePageState] = useState(false)
    const [searchTitle, setSearchTitle] = useState("")
    const [communites, setCommunities] = useState(null)
    const [mobileOpen, setMobileOpen] = useState(false);
    const [communityLoading, setCommunityLoading] = useState(false)
    const [suggestedCommunites, setSuggestedCommunities] = useState(null)
    const [suggestionLoading, setSuggestionLoading] = useState(false)


    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen);
    }

    const getOwnCommunites = async () => {
        setCommunityLoading(true)
        await getUserCommunities({
            profile: user?.profile
        })
            .then((data) => {
                console.log(data);
                setCommunities(data || [])
                setCommunityLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const getAllSuggestedCommunities = async () => {
        setSuggestionLoading(true)
        await getSuggestedCommunities({
            profile: user?.profile,
            limit: 10,
            page: 0,
        })
            .then((data) => {
                console.log(data)
                setSuggestedCommunities(data || [])
                setSuggestionLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    useEffect(() => {
        getAllSuggestedCommunities()
        getOwnCommunites()
    }, [user?.profile])


    useEffect(() => {
        if (categorySlug) {
            setSelectedCategories([categorySlug])
        } else {
            setSelectedCategories([])
        }
    }, [])

    // get category
    const getAllCategoryWithCount = async () => {
        setGetCategoryLoader(true)
        await getCategoryWithCount({})
            .then((data) => {
                let arr = data[0]?.category_details
                arr.sort((a, b) => {
                    return b.count - a.count;
                })
                setCategories(arr)
                setGetCategoryLoader(false)
            })
            .catch((err) => {
                console.log(err);
            })
    }

    useEffect(() => {
        getAllCategoryWithCount()
    }, [])


    return (
        <div className={classes.mainCont} >
            {isMdSmall ? null : (
                <div className={classes.leftSideCont} >
                    <Accordion
                        style={{
                            borderRadius: "7px",
                            marginBottom: "20px",
                            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
                        }}
                        defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ height: "50px", minHeight: "0px" }}
                        >
                            <h3 className={classes.titleSty}>Category</h3>
                        </AccordionSummary>
                        <div className={classes.loaderCont} >
                        </div>
                        <AccordionDetails>
                            <div className={classes.categoryCont} >
                                <CategoryFilter
                                    selectedCategories={selectedCategories}
                                    setSelectedCategories={setSelectedCategories}
                                    categories={categories}
                                    getCategoryLoader={getCategoryLoader}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        style={{
                            borderRadius: "7px",
                            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
                        }}
                        defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ height: "50px", minHeight: "0px" }}
                        >
                            <h3 className={classes.titleSty}>Location</h3>
                        </AccordionSummary>
                        <div className={classes.loaderCont} >
                        </div>
                        <AccordionDetails>
                            <div className={classes.filterLocationCont} >
                                <LocationCountryState
                                    locationTags={locationTags}
                                    setLocationTags={setLocationTags}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            )}

            <div className={classes.feedCont} >
                <TextField
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    value={searchTitle}
                    onChange={(e) => { setSearchTitle(e.target.value) }}
                    id="phoneNumber"
                    disableUnderline={false}
                    autoFocus
                    className={classes.searchText}
                    placeholder="Search or create a Doc..."
                    InputProps={{
                        startAdornment: (<InputAdornment position="start"><SearchIcon style={{ color: "#7C7C8D" }} /></InputAdornment>),
                        endAdornment: (<InputAdornment position="end">
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<PostAddIcon />}
                                style={{ borderRadius: "15px" }}
                                onClick={() => { history.push(`/publicdoc/create/${user?.profile}/new`) }}
                            >
                                {isExSmall ? "Post" : "New Post"}
                            </Button>
                        </InputAdornment>),
                        classes: { notchedOutline: classes.noBorder }
                    }}
                    style={{ marginBottom: "15px" }}
                />

                {isMdSmall ? (
                    <div className={classes.actionBtnCont} >
                        <div></div>

                        {!getCategoryLoader ? (
                            <Button onClick={() => { setMobileOpen(true) }} >
                                <div className={classes.filterCont} >
                                    Filter <FiFilter style={{ marginLeft: "5px" }} />
                                </div>
                            </Button>
                        ) : (
                            <Skeleton style={{ width: "70px", height: "50px" }} variant="text" />
                        )}
                    </div>
                ) : null}

                <ResourcesFeed
                    setChangePageState={setChangePageState}
                    changePageState={changePageState}
                    searchTitle={searchTitle}
                    selectedCategories={selectedCategories}
                    locationTags={locationTags}
                />
            </div>

            {isMdSmall ? null : (
                <div className={classes.rightSideCont} >
                    <UserCommunities
                        communites={communites}
                        communityLoading={communityLoading}
                        isAddNewBtn={false}
                    />
                    <SuggestedCommunitySidebar
                        suggestedCommunites={suggestedCommunites}
                        reloadSuggestion={getAllSuggestedCommunities}
                        reloadOwnCommunities={getOwnCommunites}
                        suggestionLoading={suggestionLoading}
                    />
                </div>
            )}

            <Drawer
                variant="temporary"
                anchor={"right"}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                    paper: classes.drawerPaper
                }}
                ModalProps={{
                    keepMounted: true
                }}
            >
                <div className={classes.arrowCont} >
                    <p>Filter</p>
                    <CancelIcon
                        className={classes.iconSty}
                        onClick={() => { setMobileOpen(false) }}
                    />
                </div>
                <div>
                    <Accordion
                        style={{
                            borderRadius: "7px",
                            marginBottom: "20px",
                            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
                        }}
                        defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ height: "50px", minHeight: "0px" }}
                        >
                            <h3 className={classes.titleSty}>Category</h3>
                        </AccordionSummary>
                        <div className={classes.loaderCont} >
                        </div>
                        <AccordionDetails>
                            <div className={classes.categoryCont} >
                                <CategoryFilter
                                    selectedCategories={selectedCategories}
                                    setSelectedCategories={setSelectedCategories}
                                    categories={categories}
                                    getCategoryLoader={getCategoryLoader}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        style={{
                            borderRadius: "7px",
                            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.12)"
                        }}
                        defaultExpanded
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            style={{ height: "50px", minHeight: "0px" }}
                        >
                            <h3 className={classes.titleSty}>Location</h3>
                        </AccordionSummary>
                        <div className={classes.loaderCont} >
                        </div>
                        <AccordionDetails>
                            <div className={classes.filterLocationCont} >
                                <LocationCountryState
                                    locationTags={locationTags}
                                    setLocationTags={setLocationTags}
                                />
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </Drawer>
        </div>
    );
}
export default DocResources;