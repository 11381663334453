import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useParams, useHistory } from 'react-router-dom';
import { getRelationWithType } from '../api';
import CommonView from '../CommonView';
import AddFinRelConfirm from '../AddFinRel.Confirm';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  }
}));
export default function Customers(props) {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();

  const {  } = useSelector((state) => state);
  const {root, row, col, relationCard, relationAvtNameCont} = classes;
  const { walletId } = useParams();
  const [customers, setCustomers] = useState([]);


  useEffect(() => {

    getRelationWithType({
      walletId: walletId,
      type: "Customer"
    })
      .then((data) => {
        setCustomers(data)
      })
      .catch((error) => {
        console.log(error);
      })

  }, [])


  const updateNetwork = () => {

    getRelationWithType({
      walletId: walletId,
      type: "Customer"
    })
      .then((data) => {
        setCustomers(data)
      })
      .catch((error) => {
        console.log(error);
      })

  }


  return (
    <div className={root}>
      <AddFinRelConfirm
        relationType={"Customer"}
        updateNetwork={updateNetwork}
      />
      {customers &&
        <CommonView
          networks={customers}
          option={"customer"}
        />}
    </div>
  );
}
