import React, { useState, useEffect, useRef } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import TopBar from "./topBar";
import HomePage from "./homePage";
import { useDispatch } from "react-redux";
import HowCfloWorks from "./howCfloWorks";
import MajorFeatures from "./majorFeatures";
import SaasProduct from "./saasProduct";
import WhyChooseCflo from "./whyChooseCflo";
import Pricing from "./pricing";
import AOS from "aos";
import "aos/dist/aos.css";
import FAQ from "./FAQ";
import { useMediaQuery } from "@material-ui/core";
import SignupBar from "./signupBar";
import Footer from "./footer/Index";
import { useGoogleOneTapLogin } from "react-google-one-tap-login";
import sha256 from "../../helpers/sha256";
import firebase from "firebase";
import jwt_decode from "jwt-decode";
import config from "../../config/index";
import { firebaseLoginHelper } from "../auth/auth.utils";
import Api from "../../helpers/Api";

const useStyles = makeStyles((theme) => ({
    topBar: {
        position: "fixed",
        top: "0px",
        left: "0px",
        width: "100vw",
        height: "90px",
        zIndex: "10",
        backgroundColor: "white",
        [theme.breakpoints.down("sm")]: {
            height: "60px",
        }
    },
    bodyCont: {
        marginTop: "90px",
        width: "100%",
        [theme.breakpoints.down("sm")]: {
            marginTop: "60px",
        }
    }
}));

export default function Landing() {
    const classes = useStyles();
    const dispatch = useDispatch();
    const theme = useTheme();

    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const homePageRef = useRef(null);
    const howCfloWorksRef = useRef(null);
    const majorFeaturesRef = useRef(null);
    const saasProductRef = useRef(null);
    const whyChooseCfloRef = useRef(null);
    const pricingRef = useRef(null);
    const fAQRef = useRef(null);
    const signupBarRef = useRef(null);
    const footerRef = useRef(null);

    const [investmentAnalysis, setInvestmentAnalysis] = useState(true)
    const [projectManagement, setProjectManagement] = useState(false)
    const [propertyManagement, setPropertyManagement] = useState(false)
    const [accountingPayment, setAccountingPayment] = useState(false)
    const [documents, setDocuments] = useState(false)
    const [positionRef, setPositionRef] = useState({
        homePageRef,
        howCfloWorksRef,
        majorFeaturesRef,
        saasProductRef,
        whyChooseCfloRef,
        pricingRef,
        fAQRef,
        signupBarRef,
        footerRef
    });

    useGoogleOneTapLogin({
        onError: (error) => console.log(error),
        onSuccess: async (response) => {
            console.log(response);
            // firebase
            //   .auth()
            //   .getRedirectResult()
            //   .then(function (result) {
            //     if (result.credential) {
            //       // This gives you the OAuth Access Token for that provider.
            //       var token = result.credential.accessToken;
            //     }
            //     var user = result.user;
            //   });
            const email = response.email;
            const displayName = response.name;

            const saltfromEnv = process.env.GOOGLE_ONE_TAP_CUSTOM_SALT;
            const password = await sha256(response.email + saltfromEnv);
            const imageUrl = response.picture;
            console.log("Image Uri", imageUrl);
            // // Start a sign in process for an unauthenticated user.
            // var provider = new firebase.auth.OAuthProvider("google.com");
            // provider.addScope("profile");
            // provider.addScope("email");
            firebase
                .auth()
                .createUserWithEmailAndPassword(email, password)
                .then((result) => {

                    // naya user hai, 
                    console.log(result, " is the authenticated user ", result.user);
                    //Add the profile photo
                    result.photoURL = imageUrl;
                    result.displayName = displayName;

                    firebaseLoginHelper(result, dispatch)

                })
                .catch((e) => {
                    // Login
                    firebase
                        .auth()
                        .signInWithEmailAndPassword(email, password)
                        .then((result) => {
                            console.log(result, " is the authenticated user ", result.user);
                            //update the profile photo
                            result.photoURL = imageUrl

                        })
                        .catch((e) => {
                            //Finally failed
                            console.log(e, " is the firebase error while login");
                        });
                    console.log(e, " is the firebase error while signup");
                });
            // const auth = getAuth();
            // createUserWithEmailAndPassword(auth, email, password)
            //   .then((userCredential) => {
            //     // Signed in
            //     const user = userCredential.user;
            //     console.log(user);
            //     // ...
            //   })
            //   .catch((error) => {
            //     const errorCode = error.code;
            //     const errorMessage = error.message;
            //     console.log("error is", errorCode)
            //     // ..
            //   });
        },
        googleAccountConfigs: {
            client_id:
                // "1022242836655-082rs8inm6tbes99jps1op7msp4t69l3.apps.googleusercontent.com", // Your google client id here !!!""
                config?.googleOneTapClientId,
            callback: async (res) => {
                console.log(res, ' is the data')
                var googletoken = res.credential;
                var response = jwt_decode(googletoken);
                const email = response.email;
                const displayName = response.name;
                const saltfromEnv = process.env.GOOGLE_ONE_TAP_CUSTOM_SALT;
                const password = await sha256(response.email + saltfromEnv);
                const imageUrl = response.picture;
                console.log(response, ' is the decoded')

                firebase
                    .auth()
                    .createUserWithEmailAndPassword(email, password)
                    .then((result) => {

                        // naya user hai, 
                        console.log(result, " is the authenticated user ", result.user);
                        //Add the profile photo
                        let firebaseUser = JSON.parse(JSON.stringify(result.user));
                        firebaseUser.photoURL = imageUrl;
                        firebaseUser.displayName = displayName;

                        firebaseLoginHelper(firebaseUser, dispatch)

                    })
                    .catch(async (e) => {
                        console.log(e, ' is the error catched')
                        // user already exists
                        console.log(googletoken, ' is the googletoken')
                        await Api.post('googleOneTapSignIn', { googletoken })
                            .then(res => {
                                console.log(res, ' is the res')
                                const data = res?.data;
                                const user = data?.user;
                                const firebaseToken = data?.firebaseToken
                                const uid = user?.uid
                                const activeUserId = user?._id;
                                const activeUserProfileId = user?.profile

                                localStorage.setItem("token", firebaseToken);
                                localStorage.setItem("activeUserId", activeUserId);
                                localStorage.setItem("activeUserProfileId", activeUserProfileId);
                                localStorage.setItem("loggedInFirebaseUId", uid);
                                const userId = user._id;
                                const personDictionary = {};
                                personDictionary[userId] = user;
                                dispatch({
                                    type: "SET_AUTH_USER",
                                    user,
                                });
                            })

                    })
                // var header = JSON.parse(base64urlDecode(headerSeg));
                // var payload = JSON.parse(base64urlDecode(payloadSeg));
                // console.log(header,payload);
            }
        },
    });

    useEffect(() => {
        AOS.init({
            duration: 1500,
            offset: 100,
            once: false,
        });
    }, []);

    return (
        <div>
            <div className={classes.topBar} >
                <TopBar
                    positionRef={positionRef}
                    setInvestmentAnalysis={setInvestmentAnalysis}
                    setProjectManagement={setProjectManagement}
                    setPropertyManagement={setPropertyManagement}
                    setAccountingPayment={setAccountingPayment}
                    setDocuments={setDocuments}
                />
            </div>

            <div className={classes.bodyCont} >
                <HomePage homePageRef={homePageRef} />
                <HowCfloWorks howCfloWorksRef={howCfloWorksRef} />
                <MajorFeatures
                    majorFeaturesRef={majorFeaturesRef}
                    investmentAnalysis={investmentAnalysis}
                    setInvestmentAnalysis={setInvestmentAnalysis}
                    projectManagement={projectManagement}
                    setProjectManagement={setProjectManagement}
                    propertyManagement={propertyManagement}
                    setPropertyManagement={setPropertyManagement}
                    accountingPayment={accountingPayment}
                    setAccountingPayment={setAccountingPayment}
                    documents={documents}
                    setDocuments={setDocuments}
                />
                <SaasProduct saasProductRef={saasProductRef} />
                <WhyChooseCflo whyChooseCfloRef={whyChooseCfloRef} />
                {/* <Pricing pricingRef={pricingRef} /> */}
                <FAQ fAQRef={fAQRef} />
                <SignupBar signupBarRef={signupBarRef} />
                <Footer footerRef={footerRef} positionRef={positionRef} />
            </div>
        </div>
    );
}