import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from '@material-ui/lab/Skeleton';
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "325px",
        marginRight: "15px",
        marginBottom: "15px",
        padding: "15px 10px",
        boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.15)",
        borderRadius: "15px",
        [theme.breakpoints.only("xs")]: {
            width: "280px",
            borderRadius: "10px",
            marginRight: "0px",
        }
    },
    cardHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    cardsCont: {
        display: "flex",
        justifyContent: 'center',
        flexDirection: 'column',
        padding: "20px"
    },
    namesCont: {
        width: "calc(100% - 60px)",
        [theme.breakpoints.only("xs")]: {
            width: "calc(100% - 50px)",
        }
    },
    avatarSty: {
        width: "50px",
        height: "50px",
    },
    lines: {
        width: "100%",
        height: "60px",
    }
}));

function PlaidBankSkeleton() {
    const classes = useStyles();

    return (
        <Paper className={classes.root} >
            <div className={classes.cardHeader} >
                <Skeleton variant="circle" className={classes.avatarSty} />
                <div className={classes.namesCont} >
                    <Skeleton style={{ width: "100%", height: "25px", marginBottom: "7px" }} variant="rect" />
                    <Skeleton style={{ width: "90%", height: "15px" }} variant="rect" />
                </div>
            </div>
            <div className={classes.cardsCont} >
                <Skeleton variant="rect" className={classes.lines} style={{ marginBottom: "10px" }} />
                <Skeleton variant="rect" className={classes.lines} />
            </div>
        </Paper>
    );
}
export default PlaidBankSkeleton; 