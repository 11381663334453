import React, { useState, useEffect, useRef, useMemo } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-community/styles/ag-theme-material.css";
import "ag-grid-community/styles/ag-theme-balham.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import Api from "../../helpers/Api";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import { Avatar, makeStyles } from "@material-ui/core";
import { AddCircle, Delete } from "@material-ui/icons";
import FormTableCellDialog from "./form.table.cell.dialog";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import MenuIcon from "@material-ui/icons/Menu";
import "../styled/agGrid.css";
import PopupCellRenderer from "../styled/PopupCellRenderer";

const useStyles = makeStyles((theme) => ({
  grid: {
    marginLeft: "auto",
    marginRight: "auto",
    fontFamily: "Roboto, sans-serif",
    backgroundColor: "#f0f0f0",
    fontSize: "0.95rem",
    fontWeight: "normal",
    border: "1px solid #ccc",
  },
}));

const FormTableResponse = (props) => {
  const {
    viewMode,
    issue,
    updateApi,
    question,
    questionId,
    formRes,
    setformResDict,
    formResDict,
    qMap,
    tMap,
    setFormTMap,
    table,
    tableRes,
    setTableRes,
  } = props;

  const classes = useStyles();

  const { grid } = classes;

  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [columnMap, setColumnMap] = useState({});
  const [colIdxMap, setColIdxMap] = useState({});
  const [hoveredRowId, setHoveredRowId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openChoose, setOpenChoose] = useState(false);
  const [qType, setQType] = useState("");
  const [response, setResponse] = useState("");

  const gridApiRef = useRef(null);

  //grid functions
  function handleCellClicked(params) {
    if (!viewMode) {
      let rowIndex = params.rowIndex;
      let colId = params.column.colId;
      let question = columnMap[colId];
      let row = tableRes?.rows[rowIndex];
      let colIdx = colIdxMap[colId];
      let resp = row?.responses[colIdx];
      let type = question?.type;
      setQType(type);
      setResponse(resp);
      if (colId != "menu") {
        if (
          type == "User" ||
          type == "Orgnization" ||
          type == "Project" ||
          type == "Issue" ||
          type == "Template"
        ) {
          setOpenChoose(true);
        } else {
          setDialogOpen(true);
        }
      }
    }
  }

  const onRowMouseEnter = (event) => {
    console.log(event);
  };

  const onRowMouseLeave = () => {
    setHoveredRowId(null);
  };

  const getRowStyle = (params) => {
    if (params.node.rowPinned) {
      return null;
    }

    const isHovered = params.node.id === hoveredRowId;
    const backgroundColor = isHovered ? "#e6f7ff" : null;

    return { backgroundColor };
  };

  const onAddClick = async (api, node) => {
    let rowIndex = node.rowIndex;
    let addIndex = rowIndex + 1;
    let obj = {
      index: addIndex,
      formId: question?.form,
      questionsId: question?._id,
      tableId: table?._id,
      tableResponsesId: tableRes?._id,
      questions: table?.questions,
    };
    const res = await Api.post("formtable/responses/addRow", obj);
    //get the rowId added
    if (res?.data) {
      setTableRes(res.data);
    }
  };

  const onDeleteClick = async (api, node) => {
    //delete this row
    let rowIndex = node.rowIndex;
    let row = tableRes?.rows[rowIndex];
    let obj = {
      row: row,
      tableResponsesId: tableRes?._id,
    };
    const res = await Api.post("formtable/responses/deleteRow", obj);
    if (res?.data) {
      setTableRes(res.data);
    }
  };

  const AvatarRenderer = (props) => {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          src={props?.value?.avatar}
          style={{ height: "20px", width: "20px" }}
        />
        &nbsp;<span>{props?.value?.displayName}</span>
      </div>
    );
  };

  const frameworkComponents = {
    avatarRenderer: AvatarRenderer,
  };

  //columns
  const getQuestions = () => {
    let allQuestions = table?.questions || [];
    let columnArr = [],
      cMap = {},
      cIdxMap = {};
    allQuestions.map((q, index) => {
      let obj = {};
      let id = q._id;
      console.log(id);
      if (
        q?.type == "User" ||
        q?.type == "Project" ||
        q?.type == "Organization"
      ) {
        obj = {
          headerName: q?.questionText || "Untitled",
          field: q?._id,
          editable: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,
          filter: true,
          flex: 1,
          minWidth: 100,
          cellRenderer: "avatarRenderer",
        };
      } else {
        obj = {
          headerName: q?.questionText || "Untitled",
          field: q?._id,
          editable: true,
          enableRowGroup: true,
          enablePivot: true,
          enableValue: true,
          sortable: true,
          resizable: true,
          filter: true,
          flex: 1,
          minWidth: 100,
        };
      }
      cMap[q._id] = q;
      cIdxMap[q._id] = index;
      columnArr.push(obj);
    });
    setColumnDefs(columnArr);
    setColumnMap(cMap);
    setColIdxMap(cIdxMap);
  };

  const getVal = (currResponse, thisType) => {
    switch (thisType) {
      case "Short Answer":
      case "Long Answer":
        return currResponse?.text;
      case "Date":
        let date = new Date(currResponse?.date);
        return date.toLocaleTimeString();
      case "Time":
        let obj = new Date(currResponse?.time);
        return obj.toLocaleTimeString();
      case "Number":
        return currResponse?.numeric;
      case "Project":
      case "Organization":
      case "User":
        return {
          avatar: currResponse?.parent?.displayPicture?.url,
          displayName: currResponse?.parent?.displayName,
        };
      case "Issue":
      case "Template":
        return currResponse?.parent?.title;
    }
  };

  //rows
  const getResponseRows = () => {
    let allQuestions = table?.questions || [];
    let responseRows = tableRes?.rows || [];
    let rowArr = [];
    responseRows.map((currRow) => {
      //this row has responses
      let rowObj = {};
      currRow.responses.map((currResponse, index) => {
        let thisType = allQuestions[index]?.type || "Short Answer";
        let thisVal = getVal(currResponse, thisType);
        rowObj[currResponse.question] = thisVal;
      });
      rowArr.push(rowObj);
    });
    setRowData(rowArr);
  };

  const updateResponse = async (obj) => {
    console.log(obj);
    const res = await Api.post("formtable/responses/updateResponse", obj);
    if (res?.data) {
      setTableRes(res.data);
    }
  };

  useEffect(() => {
    getQuestions();
    getResponseRows();
  }, [issue?.tableResponses, tableRes?.rows]);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    const columnDefs = gridApiRef.current.getColumnDefs();
    const totalWidth = columnDefs.reduce((total, column) => {
      return total + column.width;
    }, 0);
    const gridElement = document.querySelector(".ag-theme-alpine");
    gridElement.style.width = `${totalWidth}px`;
  };

  return (
    <div
      className={`ag-theme-alpine ${grid}`}
      style={{ height: "400px", borderRadius: "5px" }}
    >
      <AgGridReact
        columnDefs={
          viewMode
            ? columnDefs
            : [
                {
                  headerName: "Action",
                  cellRenderer: PopupCellRenderer,
                  cellRendererParams: {
                    onAddClick: onAddClick,
                    onDeleteClick: onDeleteClick,
                  },
                  field: "menu",
                  pinned: "left",
                  editable: false,
                  maxWidth: 100,
                  // cellStyle: { paddingBottom: "15px" },
                },
                ...columnDefs,
              ]
        }
        rowData={rowData}
        frameworkComponents={frameworkComponents}
        getRowStyle={getRowStyle}
        onRowMouseEnter={onRowMouseEnter}
        onRowMouseLeave={onRowMouseLeave}
        onCellClicked={handleCellClicked}
        onGridReady={onGridReady}
        pagination={true}
        pivotPanelShow={"always"}
        paginationAutoPageSize={true}
        suppressClickEdit={true}
      />
      <FormTableCellDialog
        open={dialogOpen}
        qType={qType}
        onClose={() => setDialogOpen(false)}
        updateResponse={updateResponse}
        tableResponsesId={tableRes?._id}
        response={response}
        openChoose={openChoose}
        setOpenChoose={setOpenChoose}
      />
    </div>
  );
};

export default FormTableResponse;

// const DeleteRenderer = (props) => {
//   const { api, node } = props;

//   return (
//     <IconButton
//       color="secondary"
//       aria-label="Delete Row"
//       component="span"
//       className="icon-container"
//       onClick={() => onDeleteClick(api, node)}
//       style={{ cursor: "pointer" }}
//       size="small"
//     >
//       <DeleteIcon />
//     </IconButton>
//   );
// };

// const AddRenderer = (props) => {
//   const { api, node } = props;

//   return (
//     <IconButton
//       color="primary"
//       aria-label="Delete Row"
//       component="span"
//       className="icon-container"
//       onClick={() => onAddClick(api, node)}
//       style={{ cursor: "pointer" }}
//       size="small"
//     >
//       <AddIcon />
//     </IconButton>
//   );
// };

// const MenuRenderer = (props) => {
//   const { api, node } = props;
//   console.log(props);
//   return (
//     <Tippy
//       ref={gridApiRef}
//       content={dropDownContent}
//       visible={visible}
//       onClickOutside={hide}
//       allowHTML={true}
//       arrow={false}
//       appendTo={document.body}
//       interactive={true}
//       placement="right"
//     >
//       <IconButton
//         color="primary"
//         aria-label="Action Button"
//         component="span"
//         className="icon-container"
//         onClick={visible ? hide : show}
//         style={{ cursor: "pointer" }}
//         size="small"
//       >
//         <MenuIcon />
//       </IconButton>
//     </Tippy>
//   );
// };
{
  /* <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        // onGridReady={(params) => {
        //   params.api.sizeColumnsToFit();
        // }}
        // onCellValueChanged={(event) => {
        //   setRowData([...rowData]);
        // }}
      /> */
}
// const FormTableResponse = (props) => {
//   const { question, updateApi, questionId, formResDict, qMap, tMap, table } =
//     props;

//   const [rows, setRows] = useState();
//   const [cols, setCols] = useState();
//   const [questions, setQuestions] = useState([]);
//   const [responseRows, setResponseRows] = useState([]);

//   const getQuestions = async () => {
//     let tableId = table._id;
//     let res = await Api.post("formtable/get", {
//       tableId,
//     });
//     let allQuestions = res?.data?.questions || [];
//     setQuestions(allQuestions);
//   };

//   const getResponseRows = () => {
//     setResponseRows(tMap[table._id]);
//   };

//   useEffect(() => {
//     getQuestions();
//     getResponseRows();
//   }, []);

//   console.log(table._id);
//   console.log(tMap);
//   console.log(questions);
//   console.log(responseRows);

//   const handleAddRow = () => {
//     setRows([
//       ...rows,
//       {
//         id: rows.length + 1,
//         name: `Row ${rows.length + 1}`,
//         col1: "",
//         col2: "",
//         col3: "",
//       },
//     ]);
//   };

//   const handleRowNameChange = (id, value) => {
//     const updatedRows = [...rows];
//     const index = updatedRows.findIndex((row) => row.id === id);
//     updatedRows[index].name = value;
//     setRows(updatedRows);
//   };

//   const handleCellChange = (id, col, value) => {
//     const updatedRows = [...rows];
//     const index = updatedRows.findIndex((row) => row.id === id);
//     updatedRows[index][col] = value;
//     setRows(updatedRows);
//   };

//   const handleDeleteRow = (id) => {
//     const updatedRows = rows.filter((row) => row.id !== id);
//     setRows(updatedRows);
//   };

//   return (
//     <TableContainer component={Paper}>
//       <Table>
//         <TableHead>
//           <TableRow>
//             {questions?.length > 0 &&
//               questions.map((question, index) => (
//                 <TableCell key={index}>
//                   {question?.questionText || "Untitled"}
//                 </TableCell>
//               ))}
//             <TableCell></TableCell>
//           </TableRow>
//         </TableHead>
//         <TableBody>
//           {responseRows?.length > 0 &&
//             responseRows.map((row, rowIndex) => (
//               <TableRow key={rowIndex}>
//                 {row.responses.map((response, colIndex) => (
//                   <TableCell key={colIndex}>
//                     <TextField
//                       value={response?.text || ""}
//                       //   onChange={(event) =>
//                       //     handleRowNameChange(row.id, event.target.value)
//                       //   }
//                     />
//                   </TableCell>
//                 ))}
//                 <TableCell>
//                   <IconButton>
//                     <Delete />
//                   </IconButton>
//                 </TableCell>
//               </TableRow>
//             ))}
//         </TableBody>
//       </Table>
//       <IconButton style={{ textAlign: "right" }}>
//         <AddCircle />
//       </IconButton>
//     </TableContainer>
//   );
// };
