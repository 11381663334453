import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Paper } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import NormalDialog from '../../../../styled/CommonComponents/NormalDialog';
import { getBankAccounts } from '../../api.call';
import { bankLogoMap } from "../../../../../helpers/PlaidBanksWithLogo"
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import BankSelectCardSkeleton from '../../../../styled/skeletons/BankSelectCardSkeleton';
import { ImLink } from "react-icons/im";
import { Button, useTheme, useMediaQuery } from '@material-ui/core';
import bankAccountIcon from "../../../../../Assets/BankAccountIcon.svg"
import LinearProgress from '@material-ui/core/LinearProgress';
import LessText from '../../../../styled/CommonComponents/LessText';

const useStyles = makeStyles((theme) => ({
  mainCont: {
    width: "400px",
    maxHeight: "400px",
    padding: "15px 5px 10px",
    overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none"
    },
    scrollbarWidth: "none",
    [theme.breakpoints.down('xs')]: {
      width: "300px",
    }
  },
  bankSingleCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    padding: "5px 10px",
    borderRadius: "5px",
    border: "1px solid #989898",
    "& h3": {
      fontSize: "16px",
      fontWeight: "510"
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      color: "gray"
    },
    "& h4": {
      fontSize: "14px",
      fontWeight: "450",
      color: "gray",
    },
    [theme.breakpoints.down('xs')]: {
      padding: "5px",
      "& h3": {
        fontSize: "14px",
      },
      "& p": {
        fontSize: "13px",
      },
      "& h4": {
        fontSize: "12px",
      },
    }
  },
  selectedBankSingleCont: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: "15px",
    padding: "5px 10px",
    borderRadius: "7px",
    backgroundColor: "#009C79",
    "& h3": {
      fontSize: "16px",
      fontWeight: "510",
      color: "white"
    },
    "& p": {
      fontSize: "15px",
      fontWeight: "500",
      color: "#e4dfdf"
    },
    "& h4": {
      fontSize: "14px",
      fontWeight: "450",
      color: "#e4dfdf"
    },
    [theme.breakpoints.down('xs')]: {
      padding: "5px",
      "& h3": {
        fontSize: "14px",
      },
      "& p": {
        fontSize: "13px",
      },
      "& h4": {
        fontSize: "12px",
      },
    }
  },
  bankLogoCont: {
    width: "47px",
    height: "47px",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "4px",
    border: `2px solid ${theme.palette.primary.main}`,
    [theme.breakpoints.only("xs")]: {
      padding: "2px 6px",
      width: "35px",
      height: "35px",
    }
  },
  bankIcon: {
    width: "37px",
    height: "40px",
    color: "#0E68AC",
    [theme.breakpoints.only("xs")]: {
      width: "25px",
      height: "27px",
    }
  },
  detailsCont: {
    marginLeft: "15px",
    [theme.breakpoints.only("xs")]: {
      marginLeft: "10px",
    }
  },
  emptyCont: {
    width: "400px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: "25px 15px 10px",
    "& img": {
      width: "250px",
      height: "auto",
      marginBottom: "15px"
    },
    "& p": {
      fontSize: "14px",
      color: "gray",
      textAlign: "center",
    },
    "& a": {
      textDecoration: "none",
      color: theme.palette.primary.main,
      margin: "0px 3px"
    },
    [theme.breakpoints.down('xs')]: {
      width: "300px",
      "& img": {
        width: "200px",
        marginBottom: "15px"
      },
      "& p": {
        fontSize: "13px",
      }
    }
  }
}));

export default function SelectBankAccountDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const { setOpen, open, wallet, addFundingSrcfun, header, addLoading, fundSrcIds } = props;

  const [loading, setLoading] = useState(false);
  const [plaidBankAccounts, setPlaidBankAccounts] = useState([])
  const [selectedPlaidBankAccount, setSelectedPlaidBankAccount] = useState(null)
  const [selectedFundSrc, setSelectedFundSrc] = useState(null)

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("xs"));

  const handleClose = () => {
    setOpen(false);
  };

  const getPlaidBankAccounts = async () => {
    setLoading(true)
    await getBankAccounts({ walletId: wallet?._id })
      .then((data) => {
        setPlaidBankAccounts(data)
      })
      .catch((err) => {
        console.log(err)
      })
    setLoading(false)
  }

  useEffect(() => {
    getPlaidBankAccounts()
  }, [wallet, wallet?._id])


  return (<>
    <NormalDialog
      openDialog={open}
      handleCloseShare={handleClose}
      pageTitle={header}
      content={<>
        {loading ? (<div className={classes.mainCont} >
          <BankSelectCardSkeleton />
          <BankSelectCardSkeleton />
          <BankSelectCardSkeleton />
        </div>) : (<>
          {plaidBankAccounts && plaidBankAccounts.length > 0 ? (<>
            <div className={classes.mainCont} >
              {plaidBankAccounts && plaidBankAccounts.map((bankAccount, index) => {
                const fundSrc = bankAccount?.account?.accounts || []

                return (
                  fundSrc.map((fundingData) => {
                    if (fundSrcIds.includes(fundingData?._id)) {
                      return (<Paper
                        elevation={0}
                        className={classes.bankSingleCont}
                        style={{ opacity: "0.5" }}
                      >
                        <div className={classes.bankLogoCont} >
                          {null ? (
                            <img src={bankLogoMap?.[bankAccount?.account?.institution?.name || ""]} />
                          ) : (
                            <AccountBalanceIcon className={classes.bankIcon} />
                          )}
                        </div>
                        <div className={classes.detailsCont} >
                          <h3>
                            <LessText
                              limit={25}
                              string={bankAccount?.nickName}
                            />
                          </h3>
                          <p>
                            <LessText
                              limit={20}
                              string={bankAccount?.account?.institution?.name}
                            />
                          </p>
                          <h4>
                            <LessText
                              limit={35}
                              string={`${fundingData?.name} | ${fundingData?.type} | ${fundingData?.subtype}`}
                            />
                          </h4>
                        </div>
                      </Paper>)
                    } else {
                      return (<Paper
                        elevation={0}
                        className={selectedFundSrc?._id === fundingData?._id ? classes.selectedBankSingleCont : classes.bankSingleCont}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setSelectedPlaidBankAccount(bankAccount)
                          setSelectedFundSrc(fundingData)
                        }}
                      >
                        <div
                          className={classes.bankLogoCont}
                          style={selectedFundSrc?._id === fundingData?._id ? { borderColor: "white" } : {}}
                        >
                          {null ? (
                            <img src={bankLogoMap?.[bankAccount?.account?.institution?.name || ""]} />
                          ) : (
                            <AccountBalanceIcon
                              className={classes.bankIcon}
                              style={selectedFundSrc?._id === fundingData?._id ? { color: "white" } : {}}
                            />
                          )}
                        </div>
                        <div className={classes.detailsCont} >
                          <h3>
                            <LessText
                              limit={25}
                              string={bankAccount?.nickName}
                            />
                          </h3>
                          <p>
                            <LessText
                              limit={20}
                              string={bankAccount?.account?.institution?.name}
                            />
                          </p>
                          <h4>
                            <LessText
                              limit={35}
                              string={`${fundingData?.name} | ${fundingData?.type} | ${fundingData?.subtype}`}
                            />
                          </h4>
                        </div>
                      </Paper>)
                    }
                  })
                )
              })}
            </div>
            <div style={{ margin: "5px 0px", height: "5px" }} >
              {addLoading && (<LinearProgress />)}
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              <Button
                onClick={() => { addFundingSrcfun(selectedPlaidBankAccount, selectedFundSrc) }}
                variant="contained"
                color="primary"
                startIcon={<ImLink />}
                disabled={selectedPlaidBankAccount && selectedFundSrc ? false : true}
                size={smallScreen ? "small" : "medium"}
              >Connect</Button>
            </div>
          </>) : (<div className={classes.emptyCont} >
            <img src={bankAccountIcon} />
            <p>
              No, bank account is connected with plaid.
              <a onClick={() => { history.push(`/finance/${wallet?._id}/bank-accounts`) }} >Click here</a>
              to add bank account(s) with plaid
            </p>
          </div>)}
        </>)}
      </>}
    />
  </>);
}