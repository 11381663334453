import { useState, useRef } from "react";
import React from "react";
import Tippy from "@tippyjs/react";
import Api from "../../helpers/Api";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  menuContainer: {
    fontFamily:
      "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif",
    position: "absolute",
    zIndex: 1000,
    minWidth: "10rem",
    padding: "0.5rem 0",
    margin: 0,
    fontSize: "1rem",
    textAlign: "left",
    listStyle: "none",
    backgroundColor: "#fff",
    backgroundClip: "padding-box",
    border: "1px solid rgba(0, 0, 0, 0.15)",
    borderRadius: "0.25rem",
  },
  menuItem: {
    display: "block",
    padding: "0.25rem 1rem",
    clear: "both",
    fontWeight: "400",
    color: "212529",
    textAlign: "inherit",
    textDecoration: "none",
    whiteSpace: "nowrap",
    border: 0,
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
      background: "rgb(240, 240, 240)",
    },
    "&.active": {
      backgroundColor: "#dc3545",
      borderColor: "#dc3545",
      color: "white",
    },
  },
}));

const PopupCellRenderer = (props) => {
  const classes = useStyles();
  const { menuContainer, menuItem } = classes;
  console.log(props);
  const { api, node, onAddClick, onDeleteClick } = props;
  const tippyRef = useRef();
  const [visible, setVisible] = useState(false);
  const show = () => {
    console.log("visible");
    setVisible(true);
  };
  const hide = () => {
    console.log("invisible");
    setVisible(false);
  };

  const dropDownContent = (api, node) => {
    return (
      <div className={menuContainer}>
        <div
          onClick={() => {
            onAddClick(api, node);
            hide();
          }}
          className={menuItem}
        >
          Add Row
        </div>
        <div
          onClick={() => {
            onDeleteClick(api, node);
            hide();
          }}
          className={menuItem}
        >
          Delete Row
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Tippy
        ref={tippyRef}
        content={dropDownContent(api, node)}
        visible={visible}
        onClickOutside={hide}
        allowHTML={true}
        arrow={false}
        appendTo={document.body}
        interactive={true}
        placement="right"
      >
        <IconButton
          color="primary"
          aria-label="Action Button"
          component="span"
          className="icon-container"
          onClick={visible ? hide : show}
          style={{ cursor: "pointer" }}
          size="small"
        >
          <MenuIcon />
        </IconButton>
      </Tippy>
      <span>{node.rowIndex + 1}</span>
    </div>
  );
};

export default PopupCellRenderer;
