const { default: Api } = require("../../../helpers/Api");

export const getDocsByTypes = async (obj) => {
    try {
        const res = await Api.post("doc/get/by-type", obj);
        const result = res?.data;
        return result;
    } catch (error) {
        console.log(error);
    }
};